<template lang="">
    <transition name="fade">
        <div v-if="popup === true" @click.stop="cancel" class="fixed top-0 left-0 z-[100] w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div @click.stop class="bg-white rounded-md p-8 m-auto relative">
                <div class="font-semibold text-xl">{{ title }}</div>
                <div class="py-2">{{ description }}</div>
                <div class="flex justify-end mt-4">
                    <button class="bg-white border rounded-full py-2 px-4 mr-2 hover:bg-gray-100 active:bg-gray-300" @click="cancel">{{ cancelButton }}</button>
                    <button class=" rounded-full text-white py-2 px-4" :class="style" @click="confirm">{{ okButton }}</button>
                </div>
                <button @click="cancel" class="absolute top-4 right-4 bg-white rounded-full h-8 w-8 flex items-center justify-center hover:bg-gray-100 active:bg-gray-300">&#10006;</button>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'confirm-alert',
    data: ()=> ({
        popup: false,
        title: undefined,
        description: undefined,
        okButton: undefined,
        cancelButton: 'Отмена', 
        resolvePromise: undefined,
        rejectPromise: undefined,
        type: 'confirm-alert',
    }),
    emits: ['update:show'],
    methods: {
        show(opts = {}){
            this.popup = true
            this.title = opts.title
            this.description = opts.description
            this.okButton = opts.okButton
            this.type = opts.type || 'confirm-alert'

            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }

            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        cancel() {
            this.popup = false
            this.resolvePromise(false)
        },
        confirm(){
            this.popup = false
            this.resolvePromise(true)
        }
    },
    computed:{
        style(){
            switch(this.type){
                case "confirm-success":
                    return "bg-green-500 hover:bg-green-700 active:bg-green-900"
                    break;
                case "confirm-alert":
                    return "bg-red-500 hover:bg-red-700 active:bg-red-900"
                    break;
                default:
                    return "bg-indigo-500 hover:bg-indigo-700 active:bg-indigo-900"
                    break;
            }
        }
    }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>