<template lang="">
    <transition name="fade">
        <div v-if="popup === true" @click.stop="cancel" class="fixed top-0 left-0 z-[100] w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div @click.stop class="bg-white rounded-md p-8 m-auto relative">
                <div class="font-semibold text-xl">{{ title }}</div>
                <div class="py-2">{{ description }}</div>
                <div class="flex">
                    <select v-model="date.month" class="border-b py-2 px-4">
                        <option value="0">Январь</option>
                        <option value="1">Февраль</option>
                        <option value="2">Март</option>
                        <option value="3">Апрель</option>
                        <option value="4">Май</option>
                        <option value="5">Июнь</option>
                        <option value="6">Июль</option>
                        <option value="7">Август</option>
                        <option value="8">Сентябрь</option>
                        <option value="9">Октябрь</option>
                        <option value="10">Ноябрь</option>
                        <option value="11">Декабрь</option>
                    </select>
                    <select v-model="date.year" class="border-b py-2 px-4">
                        <option  v-for="i in 5" :value="date.year - i + 1" >{{ date.year - i + 1 }}</option>
                    </select>
                </div>
                <div class="flex justify-end mt-4">
                    <div class="flex flex-col">
                        <div class="flex border-b">
                            <div v-for="wdn in date.weekdaynames" class="w-1/7 flex justify-center items-center py-2">{{ wdn }}</div>
                        </div>
                        <div class="flex flex-wrap">
                            <div v-for="pmd in monthfirstday" class="w-1/7"></div>
                            <div v-for="day in monthlastday" class="w-1/7 flex justify-center items-center py-2 cursor-pointer select-none"
                                @click="date.monthday = day"
                                :class="[date.monthday == day ? 'bg-yellow-300 rounded-md' : '']"
                            >{{ day }}</div>
                        </div>
                        <div class="flex justify-end py-2">
                            <button class="bg-white hover:bg-gray-100 active:bg-gray-300 border rounded-full py-2 px-4 mr-2" @click="cancel">{{ cancelButton }}</button>
                            <button class="bg-green-400 hover:bg-green-500 active:bg-green-600 rounded-full text-white py-2 px-4" @click="confirm">Выбрать</button>
                        </div>
                    </div>
                </div>
                <button @click="cancel" class="absolute top-4 right-4 bg-white rounded-full h-8 w-8 flex items-center justify-center hover:bg-gray-100 active:bg-gray-300">&#10006;</button>
            </div>
        </div>
    </transition>
</template>
<script>

// const [date, setDate] = useState('dd');

export default {
    name: 'datepicker-alert',
    data: () => ({
        popup: false,
        title: undefined,
        description: undefined,
        okButton: undefined,
        cancelButton: 'Отмена',
        resolvePromise: undefined,
        rejectPromise: undefined,
        date: {
            year: 1970,
            month: 0,
            monthday: 0,
            weekdaynames: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
        },
        days_in_months: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    }),
    emits: ['update:show'],
    methods: {
        show (opts = {}) {
            this.popup = true
            this.title = opts.title
            this.description = opts.description
            this.okButton = opts.okButton

            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }

            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        cancel () {
            this.popup = false
            this.resolvePromise(false)
        },
        confirm (day) {
            this.popup = false
            this.resolvePromise({ date: this.dateLocal })
        }
    },
    computed: {
        monthlastday () {
            if (this.date.year % 4 === 0 && this.date.month === '1') {
                return 29
            } else {
                const ld = this.days_in_months[this.date.month]
                return ld
            }
        },
        monthfirstday () {
            // let ld = new Date(this.date.year, this.date.month, 0).getDate();
            const pm = new Date(this.date.year, this.date.month, 0).getDay()
            return pm
        },
        dateLocal () {
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            return zeroPad(this.date.monthday, 2) + '.' + zeroPad(parseInt(this.date.month) + 1, 2) + '.' + this.date.year
        }
    },
    mounted () {
        // init date
        const current_date = new Date()
        const year = current_date.getFullYear()
        const month = current_date.getMonth()
        const day = current_date.getDay()
        const monthday = current_date.getDate()

        this.date.year = year
        this.date.month = month
        this.date.monthday = monthday

        // function y(e) {
        //     return e.getFullYear() + "/" + (e.getMonth() + 1) + "/" + e.getDate();
        // }
    }
}
</script>
<style scoped>
    .w-1\/7
    {
        width: 14.2857142857%
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
