<template lang="">
    <div class="text-2xl text-gray-800 font-semibold pt-4 pl-2 hidden"><h1>Прием заказов</h1></div>
    <div class="flex flex-col md:flex-row relative pt-4">
        <div class="absolute h-full w-full top-0 left-0 z-50 flex items-center justify-center bg-gray-100 rounded-lg" v-show="isSendingOrder">
            загрузка
        </div>
        <div class="flex flex-col w-full md:w-2/3 border-dashed border-2 border-gray-100 rounded-lg">
            <div class="flex flex-row border-b">
                <div class="w-3/12">
                    <my-dialog v-model:show="searchForCustomer" contentclass="h-full w-1/2">
                        <template #header>Номер телефона</template>
                        <template #default>
                            <div class="relative flex items-stretch rounded-md border">
                                <div class="px-2 bg-gray-100 flex justify-center items-center" @click="searchForCustomerNumpad = !searchForCustomerNumpad">
                                    <img src="https://img.icons8.com/?size=256&id=79626&format=png" class="h-6" />
                                </div>
                                <input class="py-2 px-4 w-full rounded-md" ref="customerPhone" v-model="order.customer.phone" @keyup="getCustomerByPhoneInput" type="text" placeholder="Поиск по номеру телефона" />
                                <div class="w-1/5 bg-gray-100  flex justify-center items-center cursor-pointer hover:bg-gray-300" @click="getCustomerByPhone">
                                    <img src="https://img.icons8.com/?size=256&id=7695&format=png" class="h-6"/>
                                </div>
                            </div>
                            <div class="w-full py-2 flex overflow-x-auto">
                                <div class="rounded-md p-2 mr-2 bg-gray-100 cursor-pointer hover:bg-yellow-300" v-for="phoneCode, index in phoneCodes" @click="()=>{ order.customer.phone = phoneCode; /* this.$refs.customerPhone.focus() */ }" :key="index">{{ phoneCode }}</div>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="w-4/12 py-2 pt-6 mt-6 flex flex-col border-r bg-gray-500 rounded-lg" style="max-width: 250px" v-show="searchForCustomerNumpad">
                                    <div class="flex flex-col">
                                        <div class="w-full flex flex-row items-start justify-between">
                                            <div v-for="numb in [7,8,9]" :key="numb"
                                            class="w-4/12 rounded-md p-4 m-2 bg-gray-100 cursor-pointer hover:bg-yellow-300 text-center"
                                            @click="()=>{ order.customer.phone += numb; /* this.$refs.customerPhone.focus(); */ getCustomerByPhone() }"
                                            >{{ numb }}</div>
                                        </div>
                                        <div class="w-full flex flex-row  items-start justify-between">
                                            <div v-for="numb in [4,5,6]" :key="numb"
                                            class="w-4/12 rounded-md p-4 m-2 bg-gray-100 cursor-pointer hover:bg-yellow-300 text-center"
                                            @click="()=>{ order.customer.phone += numb; /* this.$refs.customerPhone.focus(); */ getCustomerByPhone() }"
                                            >{{ numb }}</div>
                                        </div>
                                        <div class="w-full flex flex-row  items-start justify-between">
                                            <div v-for="numb in [1,2,3]" :key="numb"
                                            class="w-4/12 rounded-md p-4 m-2 bg-gray-100 cursor-pointer hover:bg-yellow-300 text-center"
                                            @click="()=>{ order.customer.phone += numb; /* this.$refs.customerPhone.focus(); */ getCustomerByPhone() }"
                                            >{{ numb }}</div>
                                        </div>
                                        <div class="w-full flex flex-row  items-start justify-between">
                                            <div class="w-4/12 rounded-md p-4 m-2 bg-gray-100 cursor-pointer hover:bg-yellow-300 text-center"
                                                @click="()=>{ order.customer.phone = order.customer.phone.substring(0, order.customer.phone.length - 1); /* this.$refs.customerPhone.focus(); */ getCustomerByPhone() }"
                                                >&larr;</div>
                                            <div class="w-4/12 rounded-md p-4 m-2 bg-gray-100 cursor-pointer hover:bg-yellow-300 text-center"
                                                @click="()=>{ order.customer.phone += 0; /* this.$refs.customerPhone.focus(); */ getCustomerByPhone() }"
                                                >0</div>
                                            <div class="w-4/12 rounded-md p-4 m-2 bg-gray-100 cursor-pointer hover:bg-yellow-300 text-center"
                                                @click="()=>{ order.customer.phone = ''; /* this.$refs.customerPhone.focus() */ }"
                                                >x</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="searchingForCustomer">loading...</div>
                                <div class="py-2 h-full w-full flex flex-wrap">
                                    <div class="py-2 pb-4 w-full h-full overflow-y-auto" v-for="suggested_customer in customerSuggestions" :key="suggested_customer.id">
                                        <div class="flex items-center hover:bg-gray-50 rounded-md relative w-full">
                                            <div></div>
                                            <div class="flex flex-col p-2 w-full">
                                                <div class="p-2 text-md flex items-center">
                                                    <b>{{ suggested_customer.number }}</b>
                                                    <div class="px-2">
                                                        {{ suggested_customer.customer?.first_name || 'noname' }}
                                                    </div>
                                                    <div v-if="suggested_customer.customer?.id" class="flex flex-col">
                                                        <!-- {{ suggested_customer.customer?.id }} -->
                                                        <div class="flex">
                                                            <div v-if="suggested_customer.customer?.labels" class="flex flex-wrap">
                                                                <div v-for="label,index in suggested_customer.customer.labels" class="ml-2 px-2 text-base bg-yellow-100 rounded-md flex items-center"  :key="index">
                                                                    <div>{{ label.title }}</div>
                                                                    <div class="ml-2 w-4 h-4 bg-red-100 rounded-full cursor-pointer flex justify-center items-center hover:bg-red-300" @click="detachCustomerLabel(suggested_customer.customer, label)">x</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- {{ suggested_customer.customer }} -->
                                                    </div>
                                                    <div v-else>
                                                        <div class="ml-4 px-4 flex justify-center items-center rounded-full bg-gray-300 hover:bg-green-300 cursor-pointer" @click="createCustomerByPhone(suggested_customer)">+ создать клиента с таким номером</div>
                                                    </div>
                                                </div>
                                                <div v-if="suggested_customer.customer?.id" class="p-2">
                                                    <div class="flex flex-row">
                                                        <div class="w-1/2">
                                                            День рождения клиента:
                                                        </div>
                                                        <div class="w-1/2">
                                                            {{ suggested_customer.customer.birthday }}
                                                        </div>
                                                    </div>
                                                    <div class="flex flex-row">
                                                        <div class="w-1/2">
                                                            Первый заказ:
                                                        </div>
                                                        <div class="w-1/2">
                                                            {{ suggested_customer.customer.first_delivery_at }}
                                                        </div>
                                                    </div>
                                                    <div class="flex flex-row">
                                                        <div class="w-1/2">
                                                            Последний заказ:
                                                        </div>
                                                        <div class="w-1/2">
                                                            {{ suggested_customer.customer.latest_delivery_at }}
                                                        </div>
                                                    </div>
                                                    <div class="flex flex-row">
                                                        <div class="w-1/2">
                                                            Доставленных заказов:
                                                        </div>
                                                        <div class="w-1/2">
                                                            {{ suggested_customer.customer.orders_delivered_numb }}
                                                        </div>
                                                    </div>
                                                    <div class="flex flex-row">
                                                        <div class="w-1/2">
                                                            Отмененных заказов:
                                                        </div>
                                                        <div class="w-1/2">
                                                            {{ suggested_customer.customer.orders_cancelled_numb }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="customerLabels && suggested_customer.customer?.id" class="flex text-xs ">
                                                    <div v-for="label,index in customerLabels" :key="index">
                                                        <div class="ml-2 p-2 bg-yellow-50 rounded-full hover:bg-yellow-100 zctive:bg-yellow-300 cursor-pointer" @click="attachCustomerLabel(suggested_customer.customer, label)">{{ label.title }}</div>
                                                    </div>
                                                </div>
                                                <div v-for="su_delivery_address, index in suggested_customer.customer?.addresses" class="w-full flex justify-between items-center border-b border-white" :key="index">
                                                    <div class="flex cursor-pointer hover:bg-yellow-300 p-2"
                                                        @click="selectCustomerWithAddress(suggested_customer, su_delivery_address)" >
                                                        <!-- <div class="pr-2">{{ su_delivery_address.country.name }}, </div> -->
                                                        <div class="pr-2">{{ su_delivery_address.city?.name }}, </div>
                                                        <div class="pr-2">{{ su_delivery_address.street?.name }}, </div>
                                                        <div class="pr-2">{{ su_delivery_address.building?.number }}</div>
                                                        <div class="pr-2" v-if="su_delivery_address.pivot.appartment_number">
                                                            - {{ su_delivery_address.pivot.appartment_number }}
                                                        </div>
                                                        <!-- <div class="pr-2">${{ su_delivery_address.delivery_cost }}</div> -->
                                                        <div class="pr-2" v-if="su_delivery_address.pivot.floor">
                                                            э{{ su_delivery_address.pivot.floor }}
                                                        </div>
                                                        <div class="pr-2" v-if="su_delivery_address.pivot.entrance">
                                                            п{{ su_delivery_address.pivot.entrance }}
                                                        </div>
                                                        <div class="pr-2" v-if="su_delivery_address.pivot.has_intercom == 0">
                                                            * по звонку
                                                        </div>
                                                    </div>
                                                    <div class="w-8 h-8 flex justify-center items-center font-semibold rounded-full bg-gray-300 hover:bg-red-300 cursor-pointer" @click="detachAddressFromCustomer(suggested_customer, su_delivery_address)">x</div>
                                                </div>
                                                <div class="cursor-pointer hover:bg-yellow-300 p-2 my-3 w-full" @click="selectCustomerWithNewAddress(suggested_customer)"> самовывоз</div>
                                                <div class="cursor-pointer hover:bg-yellow-300 p-2 w-full" @click="selectCustomerWithNewAddress(suggested_customer)"> + новый адрес</div>
                                                <div v-if="suggested_customer.customer?.id == null" class="absolute right-4 top-4">
                                                    <div class="w-8 h-8 flex justify-center items-center font-semibold rounded-full bg-gray-300 hover:bg-red-300 cursor-pointer" @click="deletePhone(suggested_customer)">x</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="customerSuggestions.length == 0" class="w-full">
                                        <div v-if="order.customer.phone.length > 2">
                                            <!-- <div @click="storeNewPhone()">Создать нового пользователя</div> -->
                                            <!-- <i class="text-lg p-4">Такой номер телефона не найден</i> -->
                                            <div class="p-2 text-2xl">{{ order.customer.phone }}</div>
                                            <!-- <div class="py-2 px-4 text-center rounded-md bg-gray-100 cursor-pointer hover:bg-yellow-300" @click="storeNewPhone()">+ добавить в базу</div> -->
                                        </div>
                                        <div v-else class="py-4 text-center">
                                            <i class="text-lg text-gray-500">Введите номер телефона клиента</i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </my-dialog>
                    <div class="w-full h-full flex items-center cursor-pointer rounded-tl-lg hover:bg-yellow-300 p-4" @click="openCustomerSearchDialog">
                        <div class=" pr-4">
                            <img :src="order.customer.avatar" class="w-12 rounded-full" />
                        </div>
                        <div class="w-full flex flex-col">
                            <div class="w-full text-sm font-semibold">{{ order.customer.name  || 'Неизвестно'}}</div>
                            <div class="w-full text-sm">{{ order.customer.phone || 'Телефон'}}</div>
                        </div>
                    </div>
                </div>
                <div class="w-6/12">
                    <my-dialog v-model:show="editDeliveryAddress" contentclass="h-full w-1/2">
                        <template #header>Адрес доставки</template>
                        <template #default>
                            <section class="flex flex-col w-full">
                                <div class="flex flex-col w-full">
                                    <div class="w-full flex flex-col p-2">
                                        <!-- <div class="w-full pb-4 flex">
                                            <div class="w-1/2 flex flex-col">
                                                <label class="px-2">Страна</label>
                                                <input v-model="order.address.country.name" disabled class="p-2 border-b outline-none"/>
                                            </div>
                                            <div class="w-1/2 pb-4 flex flex-col">
                                                <label class="px-2">Город</label>
                                                <select v-model="order.address.city" class="p-2 border-b outline-none">
                                                    <option v-for="city in cities" :value="city">{{ city.name }}</option>
                                                </select>
                                            </div>
                                        </div> -->
                                        <div class="w-full border-green-300" :class="[ order.address?.id != 0 ? 'border-b-2 border-t-2' : ''] ">
                                            <div class="w-full pt-2 flex">
                                                <div class="w-9/12 pb-4 flex flex-col">
                                                    <label class="px-2">Улица</label>
                                                    <input v-model="order.address.street.name" @keyup.enter="getStreetByName()" class="p-2 border outline-none"/>
                                                </div>
                                                <div class="w-3/12 pb-4 flex flex-col">
                                                    <label class="px-2">Дом</label>
                                                    <input v-model="order.address.building.number" class="p-2 border outline-none" @keyup="encodeAddress"/>
                                                </div>
                                            </div>
                                            <div v-if="order.address?.id != 0" class="w-full pt-2 flex items-center">
                                                <div class="w-6/12 pb-2 flex flex-col">
                                                    <label class="px-2">Лейбл</label>
                                                    <input v-model="order.address.label" class="p-2 border outline-none" @change="updateAddressInfo('label', order.address.label)"/>
                                                </div>
                                                <div class="w-3/12 pb-2 flex flex-col">
                                                    <label class="px-2">Доставка</label>
                                                    <input v-model="order.address.delivery_cost" class="p-2 border outline-none" @change="updateAddressInfo('delivery_cost', order.address.delivery_cost)"/>
                                                </div>
                                                <div class="w-3/12 pt-3 flex flex-col">
                                                    <div class="ml-4 py-2 px-4 bg-yellow-300 text-center text-black hover:bg-yellow-500 cursor-pointer rounded-md" @click="setOrderDeliveryCost(order.address.delivery_cost)">Применить</div>
                                                </div>
                                            </div>
                                            <div class="w-full pb-2 flex flex-col text-xs">
                                                <div v-if="order.address?.id != 0"  class="flex items-center justify-between">
                                                    адрес существует {{ order.address.id }}
                                                </div>
                                                <div v-else class="flex items-center justify-start">
                                                    <div class="flex items-center bg-gray-100">
                                                        <div class="text-lg py-1 px-4">Адрес не существует</div>
                                                        <div class="ml-4 py-2 px-4 bg-yellow-300 text-black hover:bg-yellow-500 cursor-pointer rounded-md" @click="storeAddress">Создать</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w-full pt-8 pb-4 flex">
                                            <div class="w-4/12 flex flex-col">
                                                <label class=" text-xs">Квартира</label>
                                                <input v-model="order.address.appartment_number" class="p-2 border outline-none"/>
                                            </div>
                                            <div class="w-3/12 flex flex-col">
                                                <label class=" text-xs">Подъезд</label>
                                                <input v-model="order.address.entrance" class="p-2 border outline-none"/>
                                            </div>
                                            <div class="w-2/12 flex flex-col">
                                                <label class=" text-xs">Этаж</label>
                                                <input v-model="order.address.floor" class="p-2 border outline-none"/>
                                            </div>
                                            <div class="w-3/12 flex flex-col items-center">
                                                <label class="text-xs">Домофон</label>
                                                <div class="w-full flex flex-row px-2 pt-2">
                                                    <div class="w-1/3 flex flex-row">
                                                        <input v-model="order.address.has_intercom" type="radio" value="1" class="p-2 border-b"/>
                                                    </div>
                                                    <div class="w-1/3 flex flex-row text-xs">
                                                        Да
                                                    </div>
                                                </div>
                                                <div class="w-full flex flex-row px-2">
                                                    <div class="w-1/3 flex flex-row">
                                                        <input v-model="order.address.has_intercom" type="radio" value="0" class="p-2 border-b"/>
                                                    </div>
                                                    <div class="w-1/3 flex flex-row text-xs">
                                                        Нет
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full pb-2 flex flex-col">
                                            <label class="px-2">Детали</label>
                                            <input v-model="order.address.details" class="p-2 border-b outline-none"/>
                                        </div>
                                        <div v-if="order.customer.id && order.address.id" class="text-gray-300"><div @click="updateCustomerAddress" class="py-2 px-4 text-center hover:bg-yellow-300 cursor-pointer rounded-md text-black">Сохранить адрес для клиента</div></div>
                                    </div>
                                    <div class="w-full pb-4 flex flex-col">
                                        <label class="px-2">Поиск {{ addressSearchResults }}</label>
                                        <input v-model="addressSearch" @keyup.enter="getAddressByLabel()" class="p-2 border outline-none" placeholder="Поиск по названию: Школа №7, гор больница"/>
                                    </div>
                                    <div class="flex w-full">
                                        <div v-if="suggestedAddresses.length > 0" class="w-full">
                                            <div v-for="address in suggestedAddresses" class="w-full" :key="address.id">
                                                <div @click="setAddress(address)" class="py-2 px-2 text-md border-b hover:bg-yellow-300 flex items-center justify-between">
                                                    <div class="w-1/6">{{ address.id }}</div>
                                                    <div class="w-1/6">{{ address.country?.name }}</div>
                                                    <div class="w-1/6">{{ address.city?.name }}</div>
                                                    <div class="w-1/6">{{ address.street?.name }}</div>
                                                    <div class="w-1/6">{{ address.building?.number }}</div>
                                                    <div class="w-1/6">{{ address.label }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="streetsSuggestions.length > 0" class="w-full bg-gray-100 flex flex-col overflow-y-auto" style="max-height: 650px;">
                                            <div v-for="street, index in streetsSuggestions" class="py-2 px-2 text-md border-b hover:bg-yellow-300 flex items-center justify-between" @click="selectStreetFromSuggestions(street)" :key="index">
                                                <div>{{ street.name }}</div>
                                                <div class="w-8 h-8 rounded-full bg-gray-300 text-sm flex justify-center items-center">{{ street.buildings.length }}</div>
                                            </div>
                                        </div>
                                        <div v-if="buildingsSuggestions.length > 0" class="w-full bg-gray-100 flex flex-wrap overflow-y-auto"
                                            style="max-height: 650px;">
                                            <div v-for="building, index in buildingsSuggestions" class="w-1/4 p-2 text-md border-b hover:bg-yellow-300 flex justify-center items-center" @click="selectBuildingFromSuggestions(building)" :key="index">{{ building.number }} {{ building.label }}</div>
                                        </div>
                                        <div v-if="customerbyAddressSuggestions.length > 0" class="w-full bg-gray-100 flex flex-col overflow-y-auto"
                                            style="max-height: 650px;">
                                            <div v-for="customer, index in customerbyAddressSuggestions" class="w-full p-2 text-md border-b hover:bg-yellow-300 flex justify-center items-center" :key="index">
                                                <div class="w-1/2 flex items-center">
                                                    кв.{{ customer.pivot?.appartment_number }}
                                                    п.{{ customer.pivot?.entrance }}
                                                    э.{{ customer.pivot?.floor }}
                                                    <!-- id: {{ customer.id }} -->
                                                </div>
                                                <div class="w-1/2">
                                                    <div v-for="phone, index in customer.phones" :key="index">
                                                        {{ phone.number }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <!-- <section>
                                <div class="" @click="storeNewAddress()">Добавить адрес в базу</div>
                            </section> -->
                        </template>
                    </my-dialog>
                    <div class="w-full h-full flex items-center cursor-pointer hover:bg-yellow-300 p-4" @click="openDeliveryAddressDialog()">
                        <div class=" pr-4">
                            <img src="https://icons.veryicon.com/png/o/miscellaneous/basic-linear-icon/address-101.png" class="w-12 rounded-full" />
                        </div>
                        <div class="w-full flex flex-col">
                            <div class="w-full text-sm font-semibold">{{ address_str }}</div>
                        </div>
                    </div>
                </div>
                <div class="w-3/12">
                    <my-dialog v-model:show="editDeliveryTime">
                        <template #header>Время доставки</template>
                        <template #default>
                            <section class="flex flex-col py-4">
                                <h3 class="text-md font-semibold">Желаемые дата и время</h3>
                                <div class="flex flex-row py-4">
                                    <div class="w-1/2 flex flex-col">
                                        <label class="px-2">Дата</label>
                                        <input v-model="order.date.date" type="date" class="p-2 border-b outline-none"/>
                                    </div>
                                    <div class="w-1/2 flex flex-col">
                                        <label class="px-2">Время</label>
                                        <input v-model="order.date.time" type="time" class="p-2 border-b outline-none"/>
                                    </div>
                                </div>
                            </section>
                        </template>
                    </my-dialog>
                    <div class="w-full h-full flex items-center cursor-pointer hover:bg-yellow-300 p-4" @click="this.editDeliveryTime = true">
                        <div class=" pr-4">
                            <img src="https://cdn-icons-png.flaticon.com/512/3917/3917267.png" class="w-12 rounded-full p-1" />
                        </div>
                        <div class="w-full flex flex-col">
                            <div class="w-full text-sm font-semibold">{{ order.date.date }}</div>
                            <div class="w-full text-sm">{{ order.date.time }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col h-full">
                <div class="w-full h-full max-h-96 overflow-y-auto">
                    <my-dialog v-model:show="editDeliveryItems" contentclass="w-3/4">
                        <template #header>Все блюда</template>
                        <template #default>
                            <div class="container">
                                <input class="border-b py-2 px-4 w-full" ref="mealSearchInput" v-model="mealSearchName" @keyup.enter="getMealByName" type="text" placeholder="Поиск блюда названию"/>
                                <!-- <div v-else>
                                    <div class="p-8"><i>добавить блюда к заказу</i></div>
                                </div> -->
                                <div class="w-full py-2 flex flex-wrap">
                                    <div class="rounded-md py-2 px-4 mr-2 mt-2 bg-gray-100 cursor-pointer hover:bg-yellow-300">
                                        <div @click="()=>{ getCombos(); mealsSubTags = null}" class="flex flex-row items-center">
                                            <img src="https://static.omelette.kz/images/tag_icons/combo.png" class="w-6 mr-2" />
                                            <div>Комбо</div>
                                        </div>
                                    </div>
                                    <div class="rounded-md py-2 px-4 mr-2 mt-2 bg-gray-100 cursor-pointer hover:bg-yellow-300">
                                        <div @click="()=>{ getCustomerLatestOrder()}" class="flex flex-row items-center">
                                            <img src="https://static.omelette.kz/images/tag_icons/combo.png" class="w-6 mr-2" />
                                            <div>Orders</div>
                                        </div>
                                    </div>
                                    <div class="rounded-md py-2 px-4 mr-2 mt-2 bg-gray-100 cursor-pointer hover:bg-yellow-300">
                                        <div @click="()=>{ getCustomerGifts()}" class="flex flex-row items-center">
                                            <img src="https://static.omelette.kz/images/tag_icons/combo.png" class="w-6 mr-2" />
                                            <div>Gifts</div>
                                        </div>
                                    </div>
                                    <div class="rounded-md py-2 px-4 mr-2 mt-2 bg-gray-100 cursor-pointer hover:bg-yellow-300" v-for="tag in mealsTags" :key="tag.id">
                                        <div @click="()=>{ getMealByTag(tag); mealsSubTags = tag.children }" class="flex flex-row items-center">
                                            <div>{{ tag.emoji }}</div>
                                            <div>{{ tag.name }}</div>
                                            <!-- <div>{{ tag }}</div> -->
                                        </div>
                                        <!-- <div :class="[isActive ? activeClass : '', errorClass]"></div> -->
                                        <!-- <div v-for="sub_tag in tag.children">
                                            <div @click="">{{ sub_tag.name }}</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="w-full h-full flex">
                                    <div class="py-4 h-full overflow-y-auto flex flex-wrap w-full" v-if="mealSuggestions.length > 0 || mealsSubTags">
                                        <div class="w-full py-2 flex flex-wrap">
                                            <div class="rounded-md py-2 px-4 mr-2 mt-2 bg-gray-100 cursor-pointer hover:bg-yellow-300" v-for="tag in mealsSubTags" :key="tag.id">
                                                <div @click="getMealByTag(tag)" class="flex flex-row items-center">
                                                    <div>{{ tag.name }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-1/4 p-2 cursor-pointer hover:bg-yellow-300 active:bg-yellow-500 flex flex-row items-start rounded-lg" v-for="suggested_meal in mealSuggestions" :key="suggested_meal.id" @click="addItemToOrder(suggested_meal)">
                                            <img :src="suggested_meal.images[0]?.url" class="w-12 mr-2 rounded-full bg-gray-100" />
                                            <div class="w-full flex flex-col justify-between">
                                                <div class="font-semibold">{{ suggested_meal.name }}</div>
                                                <!-- <div class="text-sm">{{ suggested_meal.description }}</div> -->
                                                <div class="text-sm">{{ suggested_meal.price }}</div>
                                                <div class="text-sm">
                                                    <div v-for="content, index in suggested_meal.contents" :key="index">{{ content.name }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="latestOrder" class="w-1/4 p-2 flex flex-col text-sm">
                                        <div class="flex justify-between items-center">
                                            <div class="p-2 text-lg">История заказов:</div>
                                            <div @click="latestOrder = null" class="h-6 w-6 bg-gray-100 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded-full">x</div>
                                        </div>
                                        <div v-for="meal, index in latestOrder" :key="index">
                                            <div @click="addItemToOrder(meal)" class="py-2 px-4 rounded-md hover:bg-yellow-300 active:bg-yellow-500 cursor-pointer">
                                                {{ meal.name || 'noname' }} {{ meal.amount || '' }}x{{ meal.price || '' }} {{ meal.is_bonus || '' }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="order.customer?.gifts" class="w-1/4 p-2 text-sm">
                                        <div class="flex justify-between items-center">
                                            <div class="p-2 text-lg">Подарки клиента:</div>
                                            <div @click="order.customer.gifts = null" class="h-6 w-6 bg-gray-100 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded-full">x</div>
                                        </div>
                                        <div v-for="meal, index in order.customer?.gifts" :key="index">
                                            <div @click="addItemToOrder(meal)" class="py-2 px-4 rounded-md hover:bg-yellow-300 active:bg-yellow-500 cursor-pointer">
                                                {{ meal.name || 'noname' }} x{{ meal.amount || '' }} {{ meal.is_bonus ? '🎁' : '' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </my-dialog>
                    <div class="w-full h-full" v-if="order.items.length > 0">
                        <!-- <div class="w-full p-2 px-4 flex flex-row border-b border-dashed border-gray-100 hover:bg-gray-100">
                            <div class="font-semibold text-lg">Блюда в заказе</div>
                        </div> -->
                        <div class="w-full p-2 px-4 flex flex-row border-b border-dashed border-gray-100 text-sm">
                            <div class="w-3/6">Блюда в заказе</div>
                            <div class="w-1/6">Количество</div>
                            <div class="w-1/6">Цена</div>
                            <div class="w-1/6">Бонус</div>
                        </div>
                        <div v-for="item, index in order.items" :key="index">
                            <div class="w-full p-2 px-4 flex flex-row border-b border-dashed border-gray-100 hover:bg-gray-100">
                                <div class="w-3/6">
                                    <details>
                                        <summary>{{ item.name }} {{ item.details }}</summary>
                                        <div class="w-full p-2">
                                            <input v-model="order.items[index].details" type="text" class="px-1 w-11/12 outline-none border-b" placeholder="детали блюда: без оливок, без помидоров, поджаристее края"/>
                                        </div>

                                    </details>
                                </div>
                                <div class="w-1/6"><input v-model="order.items[index].amount" type="number" class="text-right outline-none" @change="counBasketTotalSumms()" min="1" max="99" /></div>
                                <div class="w-1/6">x{{ item.price }}</div>
                                <div class="w-1/6 flex">
                                    <div class="w-2/3 flex items-center">
                                        <input v-model="order.items[index].is_bonus" :value="item.is_bonus" type="checkbox" @change="counBasketTotalSumms()" />
                                        <div class="pl-2">бонус</div>
                                    </div>
                                    <div class="w-1/3 flex items-center justify-center">
                                        <div class="w-6 rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-red-500 " @click="removeItemFromOrder(index)">x</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full p-2 px-4 flex flex-row border-b border-gray-300 text-sm">
                            <div class="w-3/6"></div>
                            <div class="w-1/6">Итого:</div>
                            <div class="w-1/6">{{ order.payment.total_meals }}</div>
                            <div class="w-1/6">{{ order.payment.total_bonuses }}</div>
                        </div>
                        <div class="w-full p-2 px-4 flex flex-row items-center border-b border-dashed border-gray-100 text-sm">
                            <div class="w-1/6">Скидка:</div>
                            <div class="w-2/6">
                                <my-numeric-input v-model="order.payment.discount" min=0 :max="order.payment.total_to_pay" @change="counBasketTotalSumms()"/>
                            </div>
                            <div class="w-3/6">
                                <input class="w-full outline-none border-b p-2" v-model="order.payment.discount_comment" placeholder="Комментарий скидки"/>
                            </div>
                        </div>
                        <div class="w-full p-2 px-4 flex flex-row items-center border-b border-dashed border-gray-100 text-sm">
                            <div class="w-1/6">Надбавка:</div>
                            <div class="w-2/6">
                                <my-numeric-input v-model="order.payment.upcount" min=0 @change="counBasketTotalSumms()"/>
                            </div>
                            <div class="w-3/6">
                                <input class="w-full outline-none border-b p-2" v-model="order.payment.upcount_comment" placeholder="Комментарий надбавки"/>
                            </div>
                        </div>
                    </div>
                    <div class="w-full h-full flex items-center justify-center" v-else>
                        <div class="p-4 text-gray-300"><i>В заказе нет блюд</i></div>
                    </div>
                </div>
                <div class="w-full flex border-t">
                    <div class="w-full flex">
                        <div class="w-full relative">
                            <input class="m-2 p-2 border-b outline-none w-5/6 " type="text" v-model="mealSearchName" @keyup="getMealByName" placeholder="+ добавить блюда по названию" />
                            <div class="absolute bg-yellow-100 py-2 w-full shadow-md rounded-md" style="max-height: 450px; overflow-y: auto; z-index: 2" v-if="mealSuggestions.length > 0 && mealSearchName.length >= 2 && editDeliveryItems == false">
                                <div class="py-2 px-4 cursor-pointer hover:bg-yellow-300" v-for="suggested_meal in mealSuggestions" :key="suggested_meal.id" @click="addItemToOrder(suggested_meal)">
                                    <div>{{ suggested_meal.name }} {{ suggested_meal.is_combo == 1 ? ' - комбо' : ''}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full p-4 cursor-pointer hover:bg-yellow-300"  @click="openDeliveryItemsDialog">+ добавить блюда меню</div>
                    </div>
                    <div v-if="order.items.length > 0" class="w-1/4 cursor-pointer hover:bg-red-500 p-4 text-center" @click="clearItems()"> x очистить</div>
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full md:w-1/3 text-xs md:text-base">
            <div class="flex flex-col w-full border-dashed border-t-2 border-gray-100">
                <div class="flex flex-row items-center border-dashed border-b border-r">
                    <div class="w-1/3 py-4 px-4 font-semibold">По чеку:</div>
                    <div class="px-2">{{ order.payment.total_to_pay }}</div>
                </div>
                <div class="flex flex-row items-center border-dashed border-b border-r">
                    <div class="w-1/3 py-4 px-4 font-semibold">Доставка:</div>
                    <div class="w-2/3"><my-numeric-input v-model="order.payment.delivery_cost" min=0  @change="counBasketTotalSumms()"/></div>
                </div>
                <div class="flex flex-row items-center border-dashed border-b border-r">
                    <div class="w-1/3 py-4 px-4 font-semibold">Перевод:</div>
                    <div class="w-2/3"><my-numeric-input v-model="order.payment.transfer" min=0 :max="order.payment.total_to_pay" @change="countBasketTotalToPayCash()" /></div>
                </div>
                <div class="flex flex-col border-dashed border-b border-r">
                    <div class="w-full flex items-center">
                        <div class="w-1/3 py-4 px-4 font-semibold">Промокод:</div>
                        <div class="w-2/3"><input v-model="order.payment.promocode" @keyup.enter="getPromocodeInfo"  class="border-b p-2 w-5/6 outline-none focus:border-b-black"/></div>
                    </div>
                    <div v-if="promocodeInfo" class="w-full text-center text-xs ">{{ promocodeInfo }}</div>
                </div>
                <div class="flex flex-row items-center border-dashed border-b border-r">
                    <div class="w-1/3 py-2 pl-4">К оплате нал.:</div>
                    <div class="px-2">{{ order.payment.total_to_pay_cash }}</div>
                </div>
                <div class="border-dashed border-r border-b">
                    <div class="flex flex-row items-center">
                        <div class="w-1/5 py-4 px-4 font-semibold">Нал.:</div>
                        <div class="w-4/5"><my-numeric-input v-model="order.payment.cash" min=0 :possibleValues="possibleCash" :placeholder="order.payment.total_to_pay_cash"/></div>
                    </div>
                </div>
                <div class="flex flex-row w-full items-center justify-center pt-2 px-2">
                    <div @click="sendOrder()" class="py-3 pl-6 pr-5 w-5/6 text-center rounded-lg rounded-tr-none rounded-br-none bg-gray-100 cursor-pointer hover:bg-green-500"><b>Оформить заказ</b></div>
                    <div @click="clearForm()" class="py-3 pl-2 pr-3 w-1/6 text-center rounded-lg rounded-tl-none rounded-bl-none bg-gray-100 cursor-pointer hover:bg-red-500">X</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            order: {
                customer: {
                    id: '',
                    phone: '',
                    name: '',
                    avatar: 'https://t3.ftcdn.net/jpg/02/43/51/48/360_F_243514868_XDIMJHNNJYKLRST05XnnTj0MBpC4hdT5.jpg'
                },
                address: {
                    id: 0,
                    coords: '',
                    delivery_cost: 0,
                    delivery_cost_save: false,
                    country: {
                        id: 1,
                        name: 'Казахстан'
                    },
                    city: {
                        id: 1,
                        name: 'Павлодар'
                    },
                    street: {
                        id: 0,
                        name: ''
                    },
                    building: {
                        id: 0,
                        number: '',
                        coords: '',
                        type: null
                    },
                    appartment_number: '',
                    entrance: '',
                    floor: '',
                    has_intercom: 0,
                    label: null,
                    details: ''
                },
                date: {
                    date: 'Сегодня',
                    time: 'Сейчас',
                    time_est: '60:80'
                },
                items: [],
                payment: {
                    discount: 0,
                    discount_comment: '',
                    upcount: 0,
                    upcount_comment: '',
                    delivery_cost: 0,
                    transfer: 0,
                    cash: 0,
                    total_meals: 0,
                    total_bonuses: 0,
                    total_summ: 0,
                    total_to_pay: 0,
                    total_to_pay_cash: 0,
                    promocode: ''
                }
            },
            promocodeInfo: null,
            isSendingOrder: false,
            searchForCustomer: false,
            searchForCustomerNumpad: false,
            addressSearch: '',
            addressSearchResults: '',
            suggestedAddresses: [],
            editDeliveryAddress: false,
            editDeliveryTime: false,
            editDeliveryItems: false,
            customerSuggestions: [],
            customerLabels: [],
            mealSearchName: '',
            mealsAll: [],
            mealsTags: [],
            mealsSubTags: [],
            mealSuggestions: [],
            latestOrder: null,
            phoneCodes: [
                '8700',
                '8701',
                '8702',
                '8705',
                '8707',
                '8708',
                '8747',
                '8771',
                '8775',
                '8776',
                '8777',
                '87182',
                '871837'
            ],
            possibleCash: [
                { title: '2k', value: 5000 },
                { title: '5k', value: 5000 },
                { title: '10k', value: 10000 }
            ],
            cities: [
                { id: 1, name: 'Павлодар' },
                { id: 2, name: 'Аксу' }
            ],
            streets: [],
            streetsSuggestions: [],
            buildingsSuggestions: [],
            customerbyAddressSuggestions: [],
            phoneInputTimeout: null,
            searchingForCustomer: false

            // sortOptions: [
            //     { value:'title', name: 'РїРѕ РЅР°Р·РІР°РЅРёСЋ'},
            //     { value:'body', name: 'РїРѕ РѕРїРёСЃР°РЅРёСЋ'}
            // ],
            // params.items                         = $('textarea[name=items_raw]').val();

            // params.payment.summ_meals_in_list    = $('input[name=summ_meals_in_list]').val();
            // params.payment.summ_discounted_meals = $('input[name=summ_discounted_meals]').val();
            // params.payment.bonuses_to_minus      = $('input[name=bonuses_to_minus]').val();
            // params.payment.bonuses_to_add        = $('input[name=bonuses_to_add]').val();
            // params.payment.discount              = $('input[name=discount]').val();
            // params.payment.discount_comment      = $('input[name=discount_comment]').val();
            // params.payment.upcount               = $('input[name=upcount]').val();
            // params.payment.upcount_comment       = $('input[name=upcount_comment]').val();
            // params.payment.delivery_cost         = $('input[name=delivery_cost]').val();
            // params.payment.transfer              = $('input[name=transfer]').val();
            // params.payment.cash                  = $('input[name=cash]').val();
        }
    },
    methods: {
        getCombos () {
            this.mealSuggestions = []
            this.getServerData('combos_all')
                .then((res) => {
                    res.data.forEach((item) => {
                        const suggested_meal = {
                            id: item.id,
                            name: item.name,
                            price: item.price,
                            // images: item.images,
                            images: [{ url: 'https://static.omelette.kz//images/meals/combo/4221347[1].png_medium.png'}],
                            is_combo: 1,
                            is_bonus: 0,
                            tags: [],
                            description: item.description,
                            contents: item.meals
                        }

                        this.mealSuggestions.push(suggested_meal)
                    })
                })
                .catch((error) => console.log(error))
        },
        getMealByTag (tag) {
            const tempArr = this.mealsAll.filter(item => item.tags_list && item.tags_list.length > 0 && item.tags_list.includes(tag.id)
                // item.tags?.forEach((tag) => {
                //     if (tag.id === tag_id) {
                //         return true
                //     }
                // })
                // return false
            )
            this.mealSuggestions = tempArr
        },
        getMealByName () {
            if (this.mealSearchName.length >= 2) {
                // this.mealSuggestions = this.mealsAll.filter((item) => item.name.toLowerCase().includes(this.mealSearchName.toLowerCase()));
                // axios
                //     .get(`${api_routes.meals_by_name}/${this.mealSearchName}`)
                //     .then((res)=>{
                //         this.mealSuggestions = res.data
                //     })
                //     .catch((error)=>{})
                    this.mealSuggestions = []
                    this.getServerData('meals_by_name', { meal_name: this.mealSearchName })
                        .then((res) => {
                            this.mealSuggestions = []

                            const temp_array = []
                            res.data.forEach((item) => {
                                const suggested_meal = {
                                    id: item.id,
                                    name: item.name,
                                    price: item.price,
                                    // images: item.images,
                                    images: [{ url: 'https://static.omelette.kz//images/meals/combo/4221347[1].png_medium.png'}],
                                    is_combo: 0,
                                    is_bonus: 0,
                                    tags: item.tags,
                                    description: item.description,
                                    contents: item.ingredients
                                }
                                temp_array.push(suggested_meal)
                            })
                            this.mealSuggestions = temp_array
                        })
                        .catch(() => {})

                    this.getServerData('combos_by_name', { combo_name: this.mealSearchName })
                        .then((res) => {
                            res.data.forEach((item) => {
                                const suggested_meal = {
                                    id: item.id,
                                    name: item.name,
                                    price: item.price,
                                    // images: item.images,
                                    images: [{ url: 'https://static.omelette.kz//images/meals/combo/4221347[1].png_medium.png'}],
                                    is_combo: 1,
                                    is_bonus: 0,
                                    tags: [],
                                    description: item.description,
                                    contents: item.meals
                                }

                                this.mealSuggestions.push(suggested_meal)
                            })
                        })
                        .catch((error) => console.log(error))
                }
        },
        setAddress (_address) {
            const address = {
                    id: _address.id,
                    coords: _address.coords,
                    // delivery_cost: _address.delivery_cost,
                    label: _address.label,
                    country: {
                        id: _address.country.id,
                        name: _address.country.name
                    },
                    city: {
                        id: _address.city.id,
                        name: _address.city.name
                    },
                    street: {
                        id: _address.street.id,
                        name: _address.street.name
                    },
                    building: {
                        id: _address.building.id,
                        number: _address.building.number,
                        coords: _address.building.coords,
                        type: null
                    }
                }
            this.order.address = address
        },
        getAddressByLabel () {
            this.streetsSuggestions = []
            this.buildingsSuggestions = []
            this.customerbyAddressSuggestions = []
            this.addressSearchResults = '...'
            this.getServerData('address_by_label', { label: this.addressSearch })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.suggestedAddresses = res.data.collection
                        this.addressSearchResults = ''
                    } else {
                        this.addressSearchResults = res.data.message
                    }
                })
        },
        getStreetByName () {
            this.addressSearchResults = ''
            this.suggestedAddresses = []
            this.streetsSuggestions = []
            this.buildingsSuggestions = []
            this.customerbyAddressSuggestions = []
            this.order.address.label = ''

            if (this.order.address.street.name.length >= 3) {
                this.streetsSuggestions = this.streets.filter((item) => item.name.toLowerCase().includes(this.order.address.street.name.toLowerCase()))
            }
        },
        selectStreetFromSuggestions (street) {
            const building = {
                        id: 0,
                        number: '',
                        coords: '',
                        type: null
                    }
            this.order.address.building = building

            this.order.address.street.id = street.id
            this.order.address.street.name = street.name

            // this.streetsSuggestions = []

            this.buildingsSuggestions = street.buildings
        },
        selectBuildingFromSuggestions (building) {
            this.order.address.building.id = building.id
            this.order.address.building.number = building.number
            this.order.address.building.coords = building.coords
            // this.order.address.building.delivery_cost = building.delivery_cost
            this.order.address.building.type = building.type

            // this.order.payment.delivery_cost = building.delivery_cost

            // this.buildingsSuggestions = []
            this.getAddressByCityStreetBuilding()
        },
        encodeAddress () {
            const country_name = this.order.address?.country?.name
            const city_name = this.order.address?.city?.name
            const street_name = this.order.address?.street?.name
            const building_number = this.order.address?.building?.number

            this.order.address.label = ''
            this.order.address.details = ''

            this.postServerData('address_encode', { data: { country_name, city_name, street_name, building_number } })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.order.address.id = res.data.item.id
                        this.order.address.delivery_cost = res.data.item?.branches[0]?.delivery_cost || 0
                        this.order.address.label = res.data.item?.label
                    } else {
                        this.order.address.id = 0
                    }
                })
                .catch(() => {})
        },
        storeAddress () {
            const country_name = this.order.address?.country?.name
            const city_name = this.order.address?.city?.name
            const street_name = this.order.address?.street?.name
            const building_number = this.order.address?.building?.number
            const address_label = this.order.address?.label
            const delivery_cost = this.order.address?.delivery_cost
            const delivery_cost_save = this.order.address?.delivery_cost_save

            if (country_name && city_name && street_name && building_number) {
                this.postServerData('address_store', { data: { country_name, city_name, street_name, building_number, address_label, delivery_cost_save, delivery_cost } })
                    .then((res) => {
                        this.encodeAddress()
                    })
                    .catch(() => {})
            }
        },
        updateAddressInfo (info_index, new_value) {
            const address_id = this.order.address?.id

            if (address_id && address_id !== 0) {
                this.postServerData('address_update_info', { data: { address_id, info_index, new_value } })
                    .then((res) => {
                    })
                    .catch(() => {})
            }
        },
        getAddressByCityStreetBuilding () {
            const city_id = this.order.address?.city?.id
            const street_id = this.order.address?.street?.id
            const building_id = this.order.address?.building?.id

            this.getServerData('address_by_city_street_building', { city_id, street_id, building_id })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.order.address.id = res.data.item.id
                        this.getCustomerByAddress()
                    } else {
                        this.order.address.id = 0
                    }
                })
                .catch(() => {})
        },
        setOrderDeliveryCost (delivery_cost) {
            this.order.payment.delivery_cost = delivery_cost
        },
        getCustomerByAddress () {
            if (this.order.address?.id !== '' && !this.order.customer?.id) {
                const address_id = this.order.address.id
                this.getServerData('customer_by_address', { address_id })
                    .then((res) => {
                        console.log(res.data)
                        if (res.data.code === 200) {
                            this.customerbyAddressSuggestions = res.data.items
                        }
                        // this.customerSuggestions = res.data
                    })
                    .catch(() => {})
            }
        },
        getCustomerByPhoneInput () {
            clearTimeout(this.phoneInputTimeout)
            this.phoneInputTimeout = setTimeout(this.getCustomerByPhone, 250)
        },
        getCustomerByPhone () {
            if (this.order.customer.phone.toString().length > 6) {
                this.searchingForCustomer = true
                this.getServerData('customer_by_phone', { phone_number: this.order.customer.phone })
                    .then((res) => {
                        this.customerSuggestions = res.data
                    })
                    .catch(() => {})
                    .finally(this.searchingForCustomer = false)
            }
        },
        openCustomerSearchDialog () {
            this.searchForCustomer = true
            if (this.customerLabels.length === 0) {
                this.getCustomerLabels()
            }
        },
        getCustomerLabels () {
            // if(this.order.customer.phone.toString().length > 6){
                this.getServerData('customer_labels')
                    .then((res) => {
                        this.customerLabels = res.data
                    })
                    .catch((error) => { console.log(error) })
            // }
        },
        attachCustomerLabel (customer, label) {
            if (!customer.labels.includes(label)) {
                customer.labels.push(label)
                this.postServerData('customer_attach_label', { data: { customer_id: customer.id, label_id: label.id } })
                    .then((res) => {})
                    .catch((e) => console.log(e))
            }
        },
        detachCustomerLabel (customer, label) {
            if (customer.labels.includes(label)) {
                customer.labels.splice(customer.labels.indexOf(label), 1)
                this.postServerData('customer_detach_label', { data: { customer_id: customer.id, label_id: label.id } })
                    .then((res) => {})
                    .catch((e) => console.log(e))
            }
        },
        updateCustomerAddress () {
            if (this.order.customer.id !== 0 && this.order.address.id !== 0) {
                const customer_id = this.order.customer.id
                const address_id = this.order.address.id

                const appartment = {
                    appartment_number: this.order.address.appartment_number,
                    entrance: this.order.address.entrance,
                    floor: this.order.address.floor,
                    has_intercom: this.order.address.has_intercom,
                    details: this.order.address.details
                }

                this.postServerData('customer_update_or_create_address', { data: { customer_id, address_id, appartment } })
                    .then((res) => console.log(res))
            }
        },
        addItemToOrder (_item) {
            const new_item = {
                meal_id: _item.id,
                name: _item.name,
                is_combo: _item.is_combo,
                is_bonus: _item.is_bonus || false,
                amount: _item.amount ? _item.amount : 1,
                details: _item.details ? _item.details : '',
                price: _item.price
                // status: 0,
            }
            let item_already_added = false
            this.order.items.forEach((item, index) => {
                if (item.meal_id === new_item.meal_id && item.is_bonus === new_item.is_bonus) {
                    item_already_added = true
                    this.order.items[index].amount += 1
                }
            })
            if (!item_already_added) {
                this.order.items.push(new_item)
            }

            this.mealSearchName = ''

            this.counBasketTotalSumms()
        },
        removeItemFromOrder (item_index) {
            this.order.items.splice(item_index, 1)
            this.counBasketTotalSumms()
        },
        clearItems () {
            this.order.items = []
            this.counBasketTotalSumms()
        },
        selectCustomerWithNewAddress (_customer) {
            const customer = {
                    id: _customer.customer_id,
                    phone: _customer.number,
                    name: '',
                    avatar: 'https://t3.ftcdn.net/jpg/02/43/51/48/360_F_243514868_XDIMJHNNJYKLRST05XnnTj0MBpC4hdT5.jpg'
                }

            this.order.customer = customer
            this.searchForCustomer = false
            this.customerSuggestions = []

            this.getCustomerGifts(customer.id)
        },
        selectCustomerWithAddress (_customer, delivery_address) {
            const customer = {
                    id: _customer.customer_id,
                    phone: _customer.number,
                    name: '',
                    avatar: 'https://t3.ftcdn.net/jpg/02/43/51/48/360_F_243514868_XDIMJHNNJYKLRST05XnnTj0MBpC4hdT5.jpg'
                }

            this.order.customer = customer

            const address = {
                    id: delivery_address.id,
                    coords: delivery_address.coords,
                    // delivery_cost: delivery_address.delivery_cost,
                    country: {
                        id: delivery_address.country?.id,
                        name: delivery_address.country?.name
                    },
                    city: {
                        id: delivery_address.city?.id,
                        name: delivery_address.city?.name
                    },
                    street: {
                        id: delivery_address.street?.id,
                        name: delivery_address.street?.name
                    },
                    building: {
                        id: delivery_address.building?.id,
                        number: delivery_address.building?.number,
                        coords: delivery_address.building?.coords,
                        // delivery_cost: delivery_address.building?.delivery_cost,
                        type: delivery_address.building?.type
                    },
                    appartment_number: delivery_address.pivot?.appartment_number || null,
                    entrance: delivery_address.pivot?.entrance || null,
                    floor: delivery_address.pivot?.floor || null,
                    has_intercom: delivery_address.pivot?.has_intercom || false,
                    label: delivery_address.pivot?.label || delivery_address.label,
                    details: delivery_address.label || null
                }
            this.order.address = address
            this.searchForCustomer = false
            this.customerSuggestions = []

            this.getCustomerGifts(customer.id)
        },
        counBasketTotalSumms () {
            this.order.payment.total_meals = '...'
            this.order.payment.total_bonuses = '...'
            this.order.payment.total_summ = '...'
            this.order.payment.total_to_pay = '...'
            this.order.payment.total_to_pay_cash = '...'

            setTimeout(() => {
                this.order.payment.total_meals = 0
                this.order.payment.total_bonuses = 0
                this.order.items.forEach((item, index) => {
                    this.order.payment.total_meals += item.amount * item.price
                    if (item.is_bonus) {
                        this.order.payment.total_bonuses += item.amount * item.price
                    }
                })

                this.order.payment.total_summ = this.order.payment.total_meals - this.order.payment.total_bonuses
                this.order.payment.total_to_pay = this.order.payment.total_summ + this.order.payment.upcount - this.order.payment.discount + this.order.payment.delivery_cost
                this.order.payment.transfer = 0
                this.order.payment.cash = 0

                this.countBasketTotalToPayCash()
            }, 100)
        },
        countBasketTotalToPayCash () {
            this.order.payment.total_to_pay_cash = this.order.payment.total_to_pay - this.order.payment.transfer
            this.possibleCash[0] = { title: 'БС', value: this.order.payment.total_to_pay_cash }

            this.order.payment.cash = 0
        },
        clearForm () {
            this.order =
            {
                customer: {
                    id: 0,
                    phone: '',
                    name: '',
                    avatar: 'https://t3.ftcdn.net/jpg/02/43/51/48/360_F_243514868_XDIMJHNNJYKLRST05XnnTj0MBpC4hdT5.jpg'
                },
                address: JSON.parse(JSON.stringify(this.$store.getters.currentUser.account?.branch.address)),
                date: {
                    date: 'Сегодня',
                    time: 'Сейчас',
                    time_est: '60:80'
                },
                items: [],
                payment: {
                    discount: 0,
                    discount_comment: '',
                    upcount: 0,
                    upcount_comment: '',
                    delivery_cost: 0,
                    transfer: 0,
                    cash: 0,
                    total_meals: 0,
                    total_bonuses: 0,
                    total_summ: 0,
                    total_to_pay: 0,
                    total_to_pay_cash: 0
                }
            }
            this.counBasketTotalSumms()
        },
        formValidated () {
            // validate form or send notification
            if (this.order.customer.phone === '') {
                this.$root.notify('Добавьте номер телефона клиента', 'Ошибка')
                return false
            }
            if (this.order.address.street.name === '') {
                this.$root.notify('Улица доставки', 'Ошибка')
                return false
            }
            if (this.order.items.length <= 0) {
                this.$root.notify('Добавьте блюда к заказу', 'Ошибка')
                return false
            }

            return true
        },
        sendOrder () {
            if (this.formValidated()) {
                this.isSendingOrder = true
                this.postServerData('order_store', { data: this.order })
                    .then((res) => {
                        console.log(res)
                        if (res.data.code === 200) {
                            this.clearForm()
                            this.$parent.$refs.aol.getActiveOrdersList()
                            // this.$root.notify('Заказ оформлен', 'Ура!')
                        } else {
                            this.$root.notify(res.data.message, 'Ошибка')
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$root.notify('Заказ не оформлен', 'Ошибка')
                    })
                    .finally(() => { this.isSendingOrder = false })
            }
        },
        openDeliveryItemsDialog () {
            this.editDeliveryItems = true
            this.getServerData('meals_tags')
                    .then((res) => {
                        this.mealsTags = res.data
                    })
                    .catch(() => {})

            this.getServerData('meals_all', { with: 'images,tag,tags' })
                .then((res) => {
                    res.data.data.forEach((item) => {
                        const single_meal = {
                            id: item.id,
                            name: item.name,
                            price: item.price,
                            // images: item.images,
                            images: [{ url: 'https://static.omelette.kz//images/meals/combo/4221347[1].png_medium.png'}],
                            is_combo: 0,
                            is_bonus: 0,
                            tags: item.tags,
                            tags_list: item.tags_list,
                            description: item.description,
                            contents: item.ingredients
                        }
                        this.mealsAll.push(single_meal)
                    })
                })
        },
        openDeliveryAddressDialog () {
            this.editDeliveryAddress = true

            if (this.streets.length > 0) return

            this.getServerData('streets_my_city')
                    .then((res) => {
                        function compare (a, b) {
                            if (a.number < b.number) {
                                return -1
                            }
                            if (a.number > b.number) {
                                return 1
                            }
                            return 0
                        }

                        res.data.forEach((item) => {
                            item.buildings.sort(compare)
                        })

                        this.streets = res.data
                        // this.streetsSuggestions = res.data
                    })
                    .catch(() => {})
        },
        storeNewPhone () {
            if (this.order.customer.phone) {
                this.postServerData('phone_store', { data: { phone: this.order.customer.phone } })
                    .then((res) => {
                        this.getCustomerByPhone(this.order.customer.phone)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        storeNewAddress () {
            if (this.order.address) {
                this.postServerData('address_store', { data: { address: this.order.address, customer: this.order.customer } })
                    .then((res) => {
                        console.log(res.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        deletePhone (customer_phone) {
            const phone_id = customer_phone.id
            if (confirm('Удалить номер ' + customer_phone.number + ' из базы?')) {
                this.postServerData('phone_delete', { data: { phone_id } })
                    .then((res) => {
                        this.getCustomerByPhone()
                    })
                    .catch((error) => console.log(error))
            }
        },
        detachAddressFromCustomer (customer_phone, address) {
            const customer_id = customer_phone.customer.id
            const address_id = address.id

            if (confirm('Удалить адрес у пользователя?')) {
                this.postServerData('customer_detach_address', { data: { customer_id, address_id } })
                    .then((res) => {
                        this.getCustomerByPhone()
                    })
                    .catch((error) => console.log(error))
            }
        },
        createCustomerByPhone (customer_phone) {
            const phone_id = customer_phone.id

            if (phone_id) {
                this.postServerData('customer_create_by_phone', { data: { phone_id } })
                    .then((res) => {
                        this.getCustomerByPhone()
                    })
                    .catch((error) => console.log(error))
            }
        },
        getCustomerLatestOrder () {
            const customer_id = this.order.customer.id

            if (customer_id) {
                this.getServerData('customer_latest_order', { customer_id })
                    .then((res) => {
                        console.log(res.data)
                        this.latestOrder = res.data
                    })
                    .catch(errors => console.log(errors))
            }
        },
        getPromocodeInfo () {
            this.getServerData('promocode_by_slug', { slug: this.order.payment.promocode })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.promocodeInfo = res.data.item.title + ': ' + res.data.item.active_quantity + 'шт, до ' + res.data.item.expires_at + ', порог: ' + res.data.item.min_sum
                    } else {
                        this.promocodeInfo = res.data.message
                    }
                })
        },
        getCustomerGifts () {
            if (this.order.customer?.id) {
                this.getServerData('customer_gifts', { customer_id: this.order.customer?.id })
                    .then((res) => { this.order.customer.gifts = res.data })
            }
        }
    },
    mounted () {
        const branch_address = this.$store.getters.currentUser.account.branch?.address
        const user_address = {
            id: branch_address?.id,
            coords: branch_address?.coords,
            // delivery_cost: branch_address.delivery_cost,
            country: {
                id: branch_address?.country?.id,
                name: branch_address?.country?.name || 'Казахстан'
            },
            city: {
                id: branch_address?.city?.id,
                name: branch_address?.city?.name || 'Астана'
            },
            street: {
                id: branch_address?.street?.id,
                name: branch_address?.street?.name
            },
            building: {
                id: branch_address?.building?.id,
                number: branch_address?.building?.number,
                coords: branch_address?.building?.coords,
                // delivery_cost: branch_address.building?.delivery_cost,
                type: null
            },
            appartment_number: '',
            entrance: '',
            floor: '',
            has_intercom: 0,
            delivery_cost: 0,
            label: 'Самовывоз',
            details: ''
        }

        this.order.address = user_address
        // console.log(this.order.address)
        // this.order.address.city.id = this.$store.getters.currentUser.branch.city_id
        // this.order.address.city.name = this.$store.getters.currentUser.branch_city.name
    },
    computed: {
        address_str () {
            let address_string = ''
            if (this.order.address?.city?.name !== '') address_string += this.order.address.city.name
            if (this.order.address?.street?.name !== '') address_string += ', ' + this.order.address.street.name
            if (this.order.address?.building?.number !== '') address_string += ', ' + this.order.address.building.number
            if (this.order.address?.appartment_number) address_string += ' - ' + this.order.address.appartment_number
            if (this.order.address?.floor) address_string += ' э' + this.order.address.floor
            if (this.order.address?.entrance) address_string += ' п' + this.order.address.entrance
            if (this.order.address?.label) address_string += ' ' + this.order.address.label
            if (this.order.address?.details) address_string += ' ' + this.order.address.details
            // if( this.order.address.has_intercom != '') address_string += ' ' + this.order.address.has_intercom
            if (address_string === '') {
                address_string = 'Адрес доставки'
            }
            return address_string
        }
    }

}
</script>
<style scoped>

</style>
