<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="p-4">
                    <h1 class="text-xl lg:text-2xl text-gray-800 font-semibold pt-2 min-h-4">{{ item.name }} </h1>
                </div>
                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-8/12 flex flex-col">
                                    <label class="px-2">Наименование</label>
                                    <text-input v-model="item.name" @change="updateItemInfo('name', item.name)" type="text" placeholder="Наименование" ref="name" validate="min:4|max:255"/>
                                </div>
                                <div class="w-4/12 flex flex-col">
                                    <label class="px-2">Цена</label>
                                    <text-input v-model="item.price" @change="updateItemInfo('price', item.price)" type="number" placeholder="Цена" ref="price" validate="number|max:99999" silent/>
                                </div>
                            </div>
                            <div class="w-full flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Описание</label>
                                    <textarea v-model="item.description" @change="updateItemInfo('description', item.description)" class="w-full p-2 border outline-none h-36" placeholder="Описание"></textarea>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Рецепт</label>
                                    <textarea v-model="item.recipe" @change="updateItemInfo('recipe', item.recipe)" class="w-full  p-2 border outline-none h-36"  placeholder="Рецепт"></textarea>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Дополнительная информация</h3>
                        <div class="border rounded-md">
                            <div class="w-full flex flex-col p-4">
                                <label class="px-2">Слаг - уникальное название латиницей</label>
                                <text-input v-model="item.slug" @change="updateItemInfo('slug', item.slug)" type="text" placeholder="Слаг" ref="slug" validate="min:4|max:255"/>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Приоритет</label>
                                    <text-input v-model="item.weight" @change="updateItemInfo('weight', item.weight)" type="number" placeholder="Приоритет" ref="weight" validate="number|max:99999" silent/>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Порция</label>
                                    <text-input v-model="item.portion" @change="updateItemInfo('portion', item.portion)" type="number" placeholder="Порция" ref="portion" validate="number|max:9" silent/>
                                </div>
                            </div>
                            <div class="w-full flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Активно</label>
                                    <toggle-button v-model="item.is_active" :values="['1:Да','0:Нет']" @change="updateItemInfo('is_active', item.is_active)"/>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Видимо</label>
                                    <toggle-button v-model="item.is_seen" :values="['1:Да','0:Нет']" @change="updateItemInfo('is_seen', item.is_seen)"/>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Продвигаем</label>
                                    <toggle-button v-model="item.is_recomended" :values="['1:Да','0:Нет']" @change="updateItemInfo('is_recomended', item.is_recomended)"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Ингредиенты</h3>
                        <div class=" border rounded-md">
                            <div class="w-full flex flex-row p-2 items-center">
                                <div v-if="item.ingredients?.length > 0" class="flex flex-col w-full text-xs">
                                    <div v-for="ingredient, index in item.ingredients" class="w-full hover:bg-indigo-50 p-2 rounded-md flex justify-between items-center border-b">
                                        <div class="w-full">
                                            <div class="w-full flex text-base pb-1">
                                                <router-link v-if="can('edit_ingredients')" :to="{ name: 'IngredientsEdit', params: { id: ingredient.id }}">{{ ingredient.name }}</router-link>
                                                <div v-else>{{ ingredient.name }}</div>
                                            </div>
                                            <div class="w-full flex flex-wrap">
                                                <input v-model="item.ingredients[index].pivot.amount" @change="updateMealIngredient(ingredient)" class="py-2 px-2 border rounded-md outline-none" />
                                                <select v-model="item.ingredients[index].pivot.is_seen" @change="updateMealIngredient(ingredient)" class="py-2 px-2 border rounded-md outline-none">
                                                    <option value="0">Скрыт</option>
                                                    <option value="1">Видим</option>
                                                </select>
                                                <select v-model="item.ingredients[index].pivot.is_optional" @change="updateMealIngredient(ingredient)" class="py-2 px-2 border rounded-md outline-none">
                                                    <option value="0">Обязательный</option>
                                                    <option value="1">Опциональный</option>
                                                </select>
                                                <select v-model="item.ingredients[index].pivot.is_auto" @change="updateMealIngredient(ingredient)" class="py-2 px-2 border rounded-md outline-none">
                                                    <option value="0">---</option>
                                                    <option value="1">Списывать</option>
                                                </select>
                                            </div>
                                        </div>
                                        <default-button @confirm="detachIngredient(ingredient.id)" type="cancel" pressconfirm>убрать</default-button>
                                    </div>
                                </div>
                                <div v-else class="p-4 border-b w-full">
                                    <i>В составе нет ингредиентов</i>
                                </div>
                            </div>

                            <div class="w-full p-4 flex flex-wrap justify-between items-end">
                                <div class="w-7/12 flex flex-col">
                                    <label class="px-2">Добавить ингредиент:</label>
                                    <select v-model="selected_ingredient" class="py-2 px-4 outline-none border rounded-md">
                                        <option value='' disabed selected>Добавить ингредиент</option>
                                        <option v-for="ingredient in ingredients" :value="ingredient.id" :key="`kk_${ingredient.id}`">{{ ingredient.name }}</option>
                                    </select>
                                </div>
                                <div class="w-5/12 flex justify-center">
                                    <default-button @click="attachIngredient()">добавить</default-button>
                                </div>
                            </div>

                            <div v-if="can('see_item_costs')" class="py-2 px-4 border-t text-right">
                                Сумма стоимости ингредиентов: {{ item.ingredients_cost }}тг
                            </div>
                        </div>
                    </section>

                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Тэги</h3>
                        <div class="flex flex-col p-2 border rounded-md items-start">
                            <div class="p-2 flex flex-col border-b w-full">
                                <label class="px-2">Основная категория</label>
                                <select v-model="item.tag_id" @change="updateItemInfo('tag_id', item.tag_id)" class="py-2 px-4 outline-none border rounded-md">
                                    <option v-for="main_tag in tags" :value="main_tag.id" :key="main_tag.id">{{ main_tag.name }}</option>
                                </select>
                            </div>
                            <div class="p-2 flex flex-col w-full">
                                <label class="px-2">Дополнительные тэги</label>
                                <div v-if="item.tags?.length > 0" class="flex flex-row py-2">
                                    <div v-for="tag in item.tags" class="mr-4 py-1 px-6 flex flex-row items-center hover:bg-gray-100 rounded-md">
                                        {{ tag.name }}
                                        <div @click="detachTag(tag.id)" class="ml-2 w-6 rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-red-500">x</div>
                                    </div>
                                </div>
                                <div v-else class=" p-4">
                                    <i>Нет привязанных тэгов</i>
                                </div>

                                <div class="w-full py-2 flex flex-wrap justify-between items-end border-t">
                                    <div class="w-7/12 flex flex-col">
                                        <label class="px-2">Добавить тэг:</label>
                                        <select v-model="selected_tag" class="py-2 px-4 outline-none border rounded-md">
                                            <option value='' disabed selected>Добавить тэг</option>
                                            <optgroup v-for="main_tag in tags" :label="main_tag.name" >
                                                <option v-for="tag in main_tag.children" :value="tag.id" :key="`kk_${tag.id}`">{{ tag.name }}</option>
                                            </optgroup>
                                        </select>
                                    </div>
                                    <div class="w-5/12 flex justify-center">
                                        <default-button @click="attachTag()">добавить</default-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Фотографии</h3>
                        <div class="border rounded-md">
                            <div class="w-full flex flex-row items-center">
                                <div v-if="item.images?.length > 0" class="flex flex-row p-4 w-full overflow-x-auto">
                                    <!-- <div class="py-2 px-4 text-center">
                                        <input />
                                    </div> -->
                                    <div v-for="image, index in item.images" class="py-2 px-4 text-center relative hover:bg-gray-100 rounded-lg">
                                        <img :src="image.url" style="height: 256px; width: 256px; max-width: 256px"/>
                                        <select v-model="item.images[index].type" @change="updateImage(image, 'type', item.images[index].type)">
                                            <option value="">неизвестно</option>
                                            <option value="big">оригинал</option>
                                            <option value="medium">средний</option>
                                            <option value="small">предпросмотр</option>
                                        </select>
                                        <!-- <div>{{ image.type || 'unknown'}}</div> -->
                                        <div @click="detachImage(image)" class="w-6 absolute top-2 right-2  rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-red-500">x</div>
                                    </div>
                                </div>
                                <div v-else class=" p-4">
                                    <i>Нет привязанных фотографий</i>
                                </div>
                            </div>
                            <div class="w-full p-4 flex justify-end">
                                <default-button @click="openImagesDialog()">Добавить фотографии</default-button>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Дети</h3>
                        <div class="border rounded-md">
                            <div v-if="item.parent_id != 0" class="px-4 pb-4 flex flex-col border-r">
                                <label class="py-2">Родитель</label>
                                <div >
                                    <!-- <router-link :to="{name: 'ItemsEdit', params: { id: item.parent_id}}" class="py-2 px-4 bg-gray-100 rounded-md cursor-pointer hover:bg-yellow-300">{{ item.parent?.name }}</router-link> -->
                                </div>
                            </div>
                            <div class="w-full">
                                <div v-if="item.children?.length > 0" class="flex flex-row p-4">
                                    <div v-for="child in item.children">
                                        <!-- <router-link :to="{name: 'ItemsEdit', params: { id: child.id }}"  class="py-2 px-4 bg-gray-100 rounded-md cursor-pointer hover:bg-yellow-300 mr-4">{{ child.name }}</router-link> -->
                                    </div>
                                    <!-- {{ item.children }} -->
                                </div>
                                <div v-else class=" p-4">
                                    <i>Нет дочерних блюд</i>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Участвует в комбо</h3>
                        <div class=" border rounded-md">
                            <div v-if="item.combos?.length > 0" class="flex flex-row p-4">
                                <div v-for="combo in item.combos" class="py-2 px-4">{{ combo.name }}</div>
                            </div>
                            <div v-else class=" p-4">
                                <i>Не участвует в комбо</i>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Рекомендации</h3>
                        <div class="border rounded-md">
                            <div class="w-full flex flex-row p-2 items-center">
                                <div v-if="item.recommendations?.length > 0" class="flex flex-col w-full text-xs">
                                    <div v-for="recommended_meal, index in item.recommendations" class="w-full hover:bg-indigo-50 p-2 rounded-md flex justify-between items-center border-b">
                                        <div class="w-full">
                                            {{ recommended_meal.name }}
                                        </div>
                                        <default-button @confirm="detachRecommendation(recommended_meal.id)" type="cancel" pressconfirm>убрать</default-button>
                                    </div>
                                </div>
                                <div v-else class="p-4 border-b w-full">
                                    <i>Рекомендаций пока нет</i>
                                </div>
                            </div>

                            <div class="w-full p-4 flex flex-wrap justify-between items-end">
                                <div class="w-7/12 flex flex-col">
                                    <label class="px-2">Добавить рекомендацию:</label>
                                    <select v-model="selected_recommendation" class="py-2 px-4 outline-none border rounded-md">
                                        <option value=0 disabed selected>Выберите блюдо</option>
                                        <option v-for="meal in meals" :value="meal.id" :key="`rm_${meal.id}`">{{ meal.name }}</option>
                                    </select>
                                </div>
                                <div class="w-5/12 flex justify-center">
                                    <default-button @click="attachRecommendation()">добавить</default-button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section v-if="can('delete_items')" class="flex flex-col pb-6 px-4">
                    <div class="w-full flex flex-col items-end">
                        <default-button @confirm="deleteItem()" pressconfirm type="delete">Удалить блюдо</default-button>
                    </div>
                </section>

            </section>
        </div>
    </div>
    <my-dialog v-model:show="isOpenImagesDialog">
        <template #header>Фотографии на сервере</template>
        <template #default>
            <div class="w-full px-4 flex border-t justify-center items-center">
                <div class="w-1/2 flex justify-center items-center">
                    <label class="p-2">Загрузить фотографию</label>
                    <input @change="uploadImage()" class="p-2 border outline-none rounded-lg" type="file" ref="file" />
                    <div @click="submitUploadedFile()" class="p-2 flex rounded-lg text-white bg-gray-300 text-center cursor-pointer hover:bg-green-500">загрузить</div>
                </div>
                <div class="w-1/2 flex justify-center items-center">
                    <div @click="server_photo_size_modifier > 1 ? server_photo_size_modifier-- : 1" class="p-2 rounded-md bg-gray-300 font-semibold select-none cursor-pointer w-16 text-center">zoom-</div>
                    <div @click="server_photo_size_modifier < 6 ? server_photo_size_modifier++ : 6" class="p-2 rounded-md bg-gray-300 font-semibold select-none cursor-pointer w-16 text-center">zoom+</div>
                </div>
            </div>
            <div class="border rounded-md">
                <div v-if="server_images.length > 0" class=" flex flex-row flex-wrap">
                    <div v-for="image in server_images" class="flex flex-col p-2 hover:bg-yellow-300 cursor-pointer rounded-md" :class="server_photo_size" @click="attachImage(`${images_server}/${image.dir}/${image.file}`)">
                        <img :src="`${images_server}/${image.dir}/${image.file}`" style="width: 100%"/>
                        <div class="hidden lg:flex text-center text-xs">{{ image.file }}</div>
                        <div class="text-center">{{ image.meta[0] }}x{{ image.meta[1] }}</div>
                    </div>
                </div>
                <div v-else class="p-6">
                    <i>На сервере еще нет фотографий в этой категории. Загрузите через форму загрузки.</i>
                </div>
            </div>
        </template>
    </my-dialog>
    <confirm-alert ref="confirmAlert"></confirm-alert>
</template>
<script>
import axios from 'axios'

export default {
    data: () => {
        return {
            item: {
                id: 0,
                parent_id: 0
            },
            selected_tag: '',
            tags: [],
            selected_ingredient: '',
            meals: [],
            selected_recommendation: 0,
            ingredients: [],
            images_server: 'https://static.breaktime.kz/',
            server_images: [],
            isOpenImagesDialog: false,
            server_photo_size_modifier: 1,
        }
    },
    methods: {
        uploadImage () {
            this.images = this.$refs.file.files[0]
        },
        submitUploadedFile () {
            const formData = new FormData()
            formData.append('upload_file', 'true')
            formData.append('dir', 'omelette.meals')
            formData.append('userfile', this.images)
            const headers = { 'Content-Type': 'multipart/form-data' }
            axios
                .post(this.images_server + 'uploader', formData, { headers })
                .then((res) => {
                    // res.data.files // binary representation of the file
                    // res.status // HTTP status
                    // if (res.data.code == 200) {}
                    this.getImages()
                })
        },
        openImagesDialog () {
            this.getImages()
            this.isOpenImagesDialog = true
        },
        getMealById () {
            this.getServerData('meal_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data
                })
                .catch((error) => console.log(error))
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('meal_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {
                    console.log(res)
                })
                .catch((error) => console.log(error))
        },
        async detachIngredient (ingredient_id) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Убрать ингредиент?',
                description: '',
                okButton: 'Убрать'
            })
            if (confirmdel) {
                this.postServerData('meal_detach_ingredient', { data: { item_id: this.item.id, ingredient_id } })
                    .then((res) => {
                        this.getMealById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        attachIngredient () {
            if (this.selected_ingredient !== '') {
                this.postServerData('meal_attach_ingredient', { data: { item_id: this.item.id, ingredient_id: this.selected_ingredient } })
                    .then((res) => {
                        this.selected_ingredient = ''
                        this.getMealById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        async detachRecommendation (recommended_meal_id) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Убрать блюдо?',
                description: 'Убрать блюдо из рекомендованных',
                okButton: 'Убрать'
            })
            if (confirmdel) {
               this.postServerData('meal_detach_recommendation', { data: { item_id: this.item.id, recommended_meal_id } })
                    .then((res) => {
                        this.getMealById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        attachRecommendation () {
            if (this.selected_recommendation !== '') {
                this.postServerData('meal_attach_recommendation', { data: { item_id: this.item.id, recommended_meal_id: this.selected_recommendation } })
                    .then((res) => {
                        this.selected_recommendation = 0
                        this.getMealById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        async detachTag (tag_id) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Убрать тэг?',
                description: '',
                okButton: 'Убрать'
            })
            if (confirmdel) {
                this.postServerData('meal_detach_tag', { data: { item_id: this.item.id, tag_id } })
                    .then((res) => {
                        this.getMealById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        attachTag () {
            if (this.selected_tag !== '') {
                this.postServerData('meal_attach_tag', { data: { item_id: this.item.id, tag_id: this.selected_tag } })
                    .then((res) => {
                        this.selected_tag = ''
                        this.getMealById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        attachImage (image_url) {
            if (image_url !== '') {
                this.postServerData('meal_attach_image', { data: { item_id: this.item.id, image_url, image_type: '' } })
                    .then((res) => {
                        this.getMealById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        updateImage (image, info_index, new_value) {
            if (image) {
                this.postServerData('image_update', { data: { id: image.id, info_index, new_value } })
                    .then((res) => {
                        this.getMealById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        detachImage (image) {
            if (image) {
                this.postServerData('meal_detach_image', { data: { item_id: this.item.id, image_id: image.id } })
                    .then((res) => {
                        this.getMealById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        getTags () {
           this.getServerData('meals_tags')
                .then((res) => {
                    this.tags = res.data
                })
                .catch(() => {})
        },
        getMeals () {
            this.getServerData('meals_all')
                .then((res) => {
                    this.meals = res.data.data
                })
                .catch(() => {})
        },
        getIngredients () {
            this.getServerData('ingredients_all')
                .then((res) => {
                    this.ingredients = res.data
                })
                .catch(() => {})
        },
        updateMealIngredient (ingredient) {
            this.postServerData('meal_update_ingredient', { data: ingredient.pivot })
                .then((res) => {
                    console.log(res)
                    // this.selected_ingredient = ''
                    // this.getMealById()
                })
                .catch((error) => console.log(error))
        },
        getImages () {
            this.getExternalServerData('get_images', { dir: 'omelette.meals' })
                .then((res) => {
                    this.server_images = res.data
                })
                .catch((errors) => console.log(errors))
        },
        async deleteItem () {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Удалить блюдо?',
                description: 'Удалить блюдо безвозвратно',
                okButton: 'Удалить'
            })
            if (confirmdel) {
                this.postServerData('meal_delete', { data: { id: this.item.id } })
                    .then((res) => {
                        if (res.data.code === 200) {
                            // this.$router.back()
                            this.$router.push({ name: 'Items' })
                        }
                    })
                    .catch((error) => console.log(error))
            }
        }
    },
    computed: {
        server_photo_size () {
            return 'w-' + this.server_photo_size_modifier + '/12'
        }
    },
    watch: {
        $route (to, from) {
            if (to.name === 'ItemsEdit') {
                this.item.id = to.params.id
                this.getMealById()
            }
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getMealById()
        this.getTags()
        this.getMeals()
        this.getIngredients()
    }
}
</script>
<style lang="">

</style>
