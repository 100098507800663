<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="p-4">
                    <h1 class="text-xl lg:text-2xl text-gray-800 font-semibold pt-2 min-h-4">Создать тэг</h1>
                </div>
                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                        <div class=" border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Наименование</label>
                                    <text-input v-model="item.name" @update="generateSlug" type="text" placeholder="Наименование" ref="name" validate="min:4|max:255"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Описание</label>
                                    <textarea v-model="item.description" class="p-2 border outline-none h-36"></textarea>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Дополнительная информация</h3>
                        <div class=" border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-7/12 flex flex-col">
                                    <label class="px-2">Короткое описание</label>
                                    <input v-model="item.name_short" class="p-2 border outline-none" type="text"/>
                                </div>
                                <div class="w-5/12 flex flex-col">
                                    <label class="px-2">Эмодзи</label>
                                    <select v-model="item.emoji" class="p-2 border outline-none">
                                        <option value="🍜">🍜 Блюда</option>
                                        <option value="🍲">🍲 Супы</option>
                                        <option value="☕">☕ Напитки</option>
                                        <option value="🍔">🍔 Фастфуд</option>
                                        <option value="🥗">🥗 Салат</option>
                                        <option value="🍕">🍕 Пицца</option>
                                        <option value="🍣">🍣 Суши</option>
                                        <option value="🍢">🍢 Барбекю</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Слаг</label>
                                    <text-input v-model="item.slug" type="text" placeholder="Слаг" ref="slug" validate="min:4|max:255"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4 flex-wrap">
                                <div class="w-6/12 lg:w-3/12 flex flex-col">
                                    <label class="px-2">Родитель</label>
                                    <select v-model="item.parent_id" class="p-2 border outline-none">
                                        <option value="0" selected>Нет</option>
                                        <option v-for="tag in tags" :value="tag.id">{{ tag.name }}</option>
                                    </select>
                                </div>
                                <div class="w-6/12 lg:w-3/12 flex flex-col">
                                    <label class="px-2">Приоритет</label>
                                    <text-input v-model="item.weight" type="number" placeholder="Приоритет" ref="weight" validate="number|max:9999"/>
                                </div>
                                <div class="w-6/12 lg:w-3/12 py-4 lg:py-0 flex flex-col">
                                    <label class="px-2">Активно</label>
                                    <toggle-button v-model="item.is_active" :values="['1:Да','0:Нет']"/>
                                </div>
                                <div class="w-6/12 lg:w-3/12 py-4 lg:py-0 flex flex-col">
                                    <label class="px-2">Видимо</label>
                                    <toggle-button v-model="item.is_seen" :values="['1:Да','0:Нет']"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section class="flex flex-col p-4 items-end lg:items-start">
                    <div class="flex flex-row pb-6">
                        <div class="w-full flex items-end">
                            <div class="pr-2">
                                <default-button type="cancel" @confirm="resetForm()" pressconfirm>Отмена</default-button>
                            </div>
                            <div class="pr-2">
                                <default-button @click="storeItem()" type="confirm">Сохранить</default-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="formErrors.length > 0" class="py-4 flex">
                        <div class="py-2 px-8 rounded-md border border-yellow-500">
                            <ul class="text-yellow-500">
                                <li v-for="error in formErrors">{{ error }}</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { api_route } from '../../../config/api_routes';

export default {
    data: () => {
        return {
            item : {
                name: '',
                slug: '',
                name_short: '',
                emoji: '',
                weight: 0,
                is_active: 0,
                is_seen: 0,
                description: '',
                parent_id: 0,
            },
            tags: [],
            formErrors: []
        }
    },
    methods: {
        generateSlug(value){
            if(this.item.name.length == 0) return this.item.slug = ""

            const cyrilic = ['а','б','в','г','д','е','ё','ж','з','и','й','к','л','м','н','о','п','р','с','т','у','ф','х','ц','ч','ш','щ','ъ','ы','ь','э','ю','я',' ','+'];
            const latin   = ['a','b','v','g','d','e','e','j','z','i','i','k','l','m','n','o','p','r','s','t','u','f','h','c','ch','sh','sh','','i','','e','yu','ya','_','_'];

            const eng = "abcdefghijklmnopqrstuvwxyz";

            const nameArray = this.item.name.split('')
            const slugArray = nameArray.map(transliterate)

            function transliterate(letter){
                if(isNumber(letter) || isIng(letter)){
                    return letter
                } else {
                    return latin[cyrilic.indexOf(letter.toLowerCase())]
                }
            }
            function isNumber(n){
                return parseInt(n) == n;
            }
            function isIng(l){
                return eng.indexOf(l) !== -1;
            }
            
            const gs = slugArray.join('')
            this.item.slug = gs
            return gs 
        },
        validateForm(){
            this.formErrors = []

            if(!this.$refs.name.isValid){
                this.formErrors.push("Наименование не подходящее");
                this.$refs.name.validationErrors.forEach(error => this.formErrors.push(error))
                this.$refs.name.$refs.input.focus(); 
                return false;
            }
            if(!this.$refs.slug.isValid){
                this.formErrors.push("Слаг не подходящий");
                this.$refs.slug.validationErrors.forEach(error => this.formErrors.push(error))
                this.$refs.slug.$refs.input.focus(); 
                return false;
            }
            return true;
        },
        resetForm(){
            let item = {
                name: '',
                slug: '',
                name_short: '',
                emoji: '',
                weight: 0,
                is_active: 0,
                is_seen: 0,
                description: '',
                parent_id: 0,
            }
            this.item = item
        },
        getTags(){
            axios
                .get(api_route('tags_all'))
                .then((res)=>{
                    this.tags = res.data
                })
                .catch((error)=>console.log(error))
        },
        storeItem(){
            if(!this.validateForm()) return;
            axios
                .post(api_route('tag_store'), this.item)
                .then((res)=>{
                    if(res.data?.code == 200){
                        this.$router.push({ name: 'TagsEdit', params: { id: res.data.item_id } })
                    }
                })
                .catch((error)=>console.log(error))
        },
    },
    mounted(){
        this.getTags()
    },
    computed: {
        generated_slug: {
            get(){
                const cyrilic = ['а','б','в','г','д','е','ё','ж','з','и','й','к','л','м','н','о','п','р','с','т','у','ф','х','ц','ч','ш','щ','ъ','ы','ь','э','ю','я',' ','+'];
                const latin   = ['a','b','v','g','d','e','e','j','z','i','i','k','l','m','n','o','p','r','s','t','u','f','h','c','ch','sh','sh','','i','','e','yu','ya','_','_'];

                const nameArray = this.item.name.split('')
                const slugArray = nameArray.map(transliterate)

                function transliterate(letter){
                    if(isNumber(letter) || isLatin(letter)){
                        return letter
                    } else {
                        return latin[cyrilic.indexOf(letter.toLowerCase())]
                    }
                }
                function isNumber(n){
                    return parseInt(n) == n;
                }
                function isLatin(l){
                    return latin.indexOf(l) !== -1;
                }
                
                const gs = slugArray.join('')
                this.item.slug = gs
                return gs 
            },
            set(newValue){
                this.item.slug = newValue
            }
        }
    }
}
</script>
<style lang="">
    
</style>