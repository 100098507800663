<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-end pt-6  pb-4">
                    <div class="w-2/3 flex flex-col justify-around pl-2 ">
                        <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-2">Список телефонов</h1>
                        <span class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}}</span>
                    </div>
                    <div class="w-1/3 flex flex-row justify-end">
                        <!-- <div v-if="can('create_roles') || can('debug')">
                            <router-link :to="{name: 'RolesCreate' }" class="py-2 px-6 rounded-md bg-gray-100 cursor-pointer hover:bg-yellow-300"> Создать </router-link>
                        </div>                         -->
                    </div>
                </div>
                <div class="w-full rounded-lg border-2 border-gray-100">
                    <div class="items_list">
                        <div class="w-full flex flex-row bg-gray-100">
                            <div class="w-1/12 p-2"></div>
                            <div class="w-6/12 p-2" >Номер</div>
                            <div class="w-5/12 p-2" >Клиент</div>
                        </div>
                        <div v-for="item, index in collection" :key="index">
                            <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed">
                                <div class="w-full flex flex-row hover:bg-gray-100 items-center">
                                    <div class="w-1/12 flex justify-center">
                                        <!-- <img :src="item.images[0]?.url" style="height: 40px; border-radius: 50%"/> -->
                                    </div>
                                    <div class="w-6/12 p-2 text-lg flex flex-col">
                                        <div v-if="can('edit_phones')">
                                            <router-link :to="{name: 'PhonesEdit', params: { id: item.id  }}">{{ item.formated || 'no number' }}</router-link>
                                            <!-- <div>{{ item.number }}</div> -->
                                        </div>
                                        <div v-else>{{ item.formated }}</div>
                                        <div class=" flex flex-row text-sm text-gray-300">
                                            <!-- | {{ item.slug }}
                                            | {{ item.description }}   -->
                                        </div>
                                    </div>
                                    <div class="w-5/12 p-2 flex flex-row flex-wrap">
                                        {{ item.orders_count}} |
                                        {{ item.customer?.id}} | {{ item.customer?.first_name}}
                                    </div>
                                    <div class="w-1/12 p-2 flex flex-row flex-wrap">
                                        <div v-if="!item.customer && item.number.length != 11">
                                            <div @click="deletePhone(item)" class="w-8 h-8 flex justify-center items-center bg-gray-100 rounded-full cursor-pointer hover:bg-red-100 text-gray-700 font-semibold">x</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>
           <section>
                <div class="flex flex-row justify-between items-center pt-6 pb-24">
                    <div class="w-1/2 flex flex-col justify-around pl-2">
                        <span class="text-base">Показаны {{ meta.from }} - {{ meta.to}} из {{ meta.total}}</span>
                    </div>
                    <div v-if="meta.links.length > 3" class="w-1/2 flex flex-row justify-end">
                        <div v-for="link, index in meta.links" :key="index">
                            <div
                                v-if="index != 0 && index != meta.links.length-1"
                                class="py-2 px-4 bg-gray-100 rounded-md"
                                :class="[
                                        meta.current_page == parseInt(link.label) ? 'bg-yellow-300 font-semibold' : '',
                                        link.url !== null ? 'hover:bg-yellow-300 cursor-pointer' : ''
                                    ]"
                                @click="()=>{ if(link.url != null ){ changePage(parseInt(link.label)) }}"
                                >{{ link.label }}
                            </div>
                        </div>
                    </div>
                </div>
           </section>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            meta: {
                current_page: 0,
                per_page: 50,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0
            },
            sort_by: 'number',
            sort_dir: 'ASC',
            collection: []
            // showEditItemDialog: false,
        }
    },
    methods: {
        getCollection () {
            this.getServerData('phones_list', { per_page: this.meta.per_page, sort_by: this.sort_by, sort_dir: this.sort_dir, current_page: this.meta.current_page })
                .then((res) => {
                    this.meta = res.data.meta
                    this.collection = res.data.data
                })
                .catch(() => {})
        },
        deletePhone (item) {
            this.postServerData('phone_delete', { data: { phone_id: item.id } })
            this.getCollection()
        },
        changePage (page) {
            this.meta.current_page = page
            this.getCollection()
        }
    },
    mounted () {
        this.getCollection()
    },
    watch: {
        $route (to, from) {
            if (to.name === this.$route.name) {
                this.meta.current_page = to.params.page
                this.getCollection()
            }
        }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
