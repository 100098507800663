<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">{{ order.id || '...'}} {{ order.address_full || '...'}}</h1>
                    <!-- <router-link :to="{ name: 'ActiveOrders' }">&larr; назад</router-link> -->
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>

                <section class="flex w-full flex-wrap">
                    <section class="flex w-full lg:w-1/2 flex-col pr-2">

                        <section class="flex flex-col py-4 w-full">
                            <h3 class="text-md font-semibold pb-4 pl-4">Блюда</h3>
                            <div class="flex flex-col p-2 border rounded-md items-start w-full">
                                <div v-for="item in order.items" class="flex items-center w-full border-b">
                                    <div class="p-2 w-8/12">
                                        <div v-if="item.meal_id">
                                        <router-link :to="{ name: 'ItemsEdit', params: { id: item.meal_id}}">{{ item.name }}</router-link>
                                        </div>
                                        <div v-else>
                                            {{ item.name }}
                                        </div>
                                    </div>
                                    <div class="p-2 w-1/12">x{{ item.amount }}</div>
                                    <div class="p-2 w-1/12">{{ item.price }}</div>
                                    <div class="p-2 w-1/12">{{ item.is_combo == 1 ? '🍱' : ''}}</div>
                                    <div class="p-2 w-1/12">{{ item.is_bonus == 1 ? '🎁' : ''}}</div>
                                </div>
                                <div class="w-full flex">
                                    <div class="w-1/2 flex ">
                                        <div class="p-2 w-1/2">Итого:</div>
                                        <div class="p-2 w-1/2">{{ order.total_meals }}</div>
                                    </div>
                                    <div class="w-1/2 flex ">
                                        <div class="p-2 w-1/2">Бонусных:</div>
                                        <div class="p-2 w-1/2">{{ order.total_bonuses }}</div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="flex flex-col py-4 w-full">
                            <h3 class="text-md font-semibold pb-4 pl-4">Автосписаниния со склада</h3>
                            <div class="flex flex-col border rounded-md items-center">
                                <div class="flex flex-wrap w-full" v-if="order.ingredient_stock_changes && order.ingredient_stock_changes.length > 0">
                                    <div v-for="item, index in order.ingredient_stock_changes" class="flex items-center w-full border-b border-r hover-bg-gray-50">
                                        <div class="w-1/12 p-2">{{ item.change_type == 'consumption' ? '➖' : '' }}</div>
                                        <div class="w-2/12 p-2">{{ item.change_amount }}{{ item.ingredient.unit }}</div>
                                        <div class="w-5/12 p-2">{{ item.ingredient.name }}</div>
                                        <div class="w-2/12 p-2">{{ item.change_price }}</div>
                                        <div class="w-2/12 p-2"><button @click="orderItemsRemoveSpecificStockChange(item.id)" class="py-1 px-2 rounded-md bg-gray-100 hover:bg-red-300 cursor-pointer">удалить</button></div>
                                    </div>
                                </div>
                                <div v-else class="p-4">
                                    <i>Списания еще не производились</i>
                                </div>
                            </div>
                            <div class="flex w-full justify-around items-center py-2">
                                <button @click="orderItemsAddToStockChanges" class="py-2 px-4 rounded-md bg-gray-100 hover:bg-yellow-300 cursor-pointer">Списать со склада</button>
                                <button @click="orderItemsRemoveStockChanges" class="py-2 px-4 rounded-md bg-gray-100 hover:bg-red-300 cursor-pointer" >Убрать списания со склада</button>
                            </div>
                        </section>
                    </section>

                    <section class="flex w-full lg:w-1/2 flex-col pl-2">
                        <section class="flex flex-col py-4 w-full">
                            <h3 class="text-md font-semibold pb-4 pl-4">Время</h3>
                            <div class="flex flex-col p-2 border rounded-md w-full">
                                <div v-if="order.times" class="flex flex-col w-full">
                                    <div class="border-b flex w-full ">
                                        <div class="p-2 w-6/12">С момента оформления:</div>
                                        <div class="p-2 w-3/12">{{ order.times.created_to_closed.raw }} мин</div>
                                        <div class="p-2 w-3/12">{{ order.times.created_to_closed.formatted }} мин</div>
                                    </div>
                                    <div class="border-b flex w-full">
                                        <div class="p-2 w-6/12">До кухни:</div>
                                        <div class="p-2 w-3/12">{{ order.times.created_to_kitchen.raw }} мин</div>
                                        <div class="p-2 w-3/12">{{ order.times.created_to_kitchen.formatted }} мин</div>
                                    </div>
                                    <div class="border-b flex w-full">
                                        <div class="p-2 w-6/12">Кухня до доставки:</div>
                                        <div class="p-2 w-3/12">{{ order.times.kitchen_to_delivery.raw }} мин</div>
                                        <div class="p-2 w-3/12">{{ order.times.kitchen_to_delivery.formatted }} мин</div>
                                    </div>
                                    <div class="flex w-full">
                                        <div class="p-2 w-6/12">Доставка до закрытия:</div>
                                        <div class="p-2 w-3/12">{{ order.times.delivery_to_closed.raw }} мин</div>
                                        <div class="p-2 w-3/12">{{ order.times.delivery_to_closed.formatted }} мин</div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary class="text-md font-semibold pb-4 pl-4">Адрес : {{ order.address_full }}</summary>
                                <div class="flex flex-col p-2 border rounded-md items-center">
                                    <div v-for="item, index in order.address" class="flex items-center">
                                        <div class="p-2">{{ index }}</div>
                                        <div class="p-2">{{ item }}</div>
                                    </div>
                                </div>
                            </details>
                        </section>

                        <section class="flex flex-col py-4">
                            <h3 class="text-md font-semibold pb-4 pl-4">Оплаты : {{ order.total_summ }}</h3>
                            <div class="flex flex-col p-2 border rounded-md items-center">
                                <div class="border-b flex w-full">
                                    <div class="p-2 w-3/12">Скидка:</div>
                                    <div class="p-2 w-3/12">{{ order.payment?.discount }}</div>
                                    <div class="p-2 w-6/12">{{ order.payment?.discount_comment }}</div>
                                </div>
                                <div class="border-b flex w-full">
                                    <div class="p-2 w-3/12">Надбавка:</div>
                                    <div class="p-2 w-3/12">{{ order.payment?.upcount }}</div>
                                    <div class="p-2 w-6/12">{{ order.payment?.upcount_comment }}</div>
                                </div>
                                <div class="flex w-full">
                                    <div class="p-2 w-4/12">
                                        <div class="w-full">Доставка:</div>
                                        <div class="p-2 w-full">{{ order.payment?.delivery_cost }}</div>
                                    </div>
                                    <div class="p-2 w-4/12">
                                        <div class="w-full">К оплате нал.:</div>
                                        <div class="p-2 w-full">{{ order.total_to_pay_cash }}</div>
                                    </div>
                                    <div class="p-2 w-4/12">
                                        <div class="w-full">Наличные:</div>
                                        <div class="p-2 w-full">{{ order.payment?.cash }}</div>
                                    </div>
                                    <div class="p-2 w-4/12">
                                        <div class="w-full">Перевод:</div>
                                        <div class="p-2 w-full">{{ order.payment?.transfer }}</div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary class="text-md font-semibold pb-4 pl-4">Промокод : {{ order.promocode || 'нет'}}</summary>
                                <div class="flex flex-col p-2 border rounded-md items-center">
                                    <div v-for="item, index in order.promocode" class="flex items-center">
                                        <div class="p-2">{{ index }}</div>
                                        <div class="p-2">{{ item }}</div>
                                    </div>
                                </div>
                            </details>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary class="text-md font-semibold pb-4 pl-4">Рейтинг : {{ order.is_rated ? '+' : '-'}}</summary>
                                <div class="flex flex-col p-2 border rounded-md items-center">
                                    <div v-for="item, index in order.rating" class="flex items-center">
                                        <div class="p-2">{{ index }}</div>
                                        <div class="p-2">{{ item }}</div>
                                    </div>
                                </div>
                            </details>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary class="text-md font-semibold pb-4 pl-4">Статус : {{ order.current_status?.emoji }}</summary>
                                <div class="flex flex-col p-2 border rounded-md items-center">
                                    <div class="p-2">{{ order.current_status?.name }}</div>
                                    <div class="p-2">{{ order.current_status?.emoji }}</div>
                                </div>
                            </details>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary class="text-md font-semibold pb-4 pl-4">Курьер : {{ order.courier?.first_name }}</summary>
                                <div class="flex p-2 border rounded-md items-center">
                                    <div class="w-1/5">
                                        <img :src="order.courier?.avatar_image?.url" class="rounded-full w-full" />
                                    </div>
                                    <div class="w-4/5 pl-4">
                                        <div class="w-full flex items-center">
                                            <div class="p-2 w-full">{{ order.courier?.last_name }}</div>
                                        </div>
                                        <div class="w-full flex items-center">
                                            <div class="p-2 w-full">{{ order.courier?.first_name }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="flex flex-col p-2 border rounded-md items-center">
                                    <div v-for="item, index in order.courier" class="flex items-center">
                                        <div class="p-2">{{ index }}</div>
                                        <div class="p-2">{{ item }}</div>
                                    </div>
                                </div> -->
                            </details>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary  class="text-md font-semibold pb-4 pl-4">Оператор : {{ order.operator?.first_name }}</summary>
                                <div class="flex p-2 border rounded-md items-center">
                                    <div class="w-1/5">
                                        <img :src="order.operator?.avatar_image?.url" class="rounded-full w-full" />
                                    </div>
                                    <div class="w-4/5 pl-4">
                                        <div class="w-full flex items-center">
                                            <div class="p-2 w-full">{{ order.operator?.last_name }}</div>
                                        </div>
                                        <div class="w-full flex items-center">
                                            <div class="p-2 w-full">{{ order.operator?.first_name }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="flex flex-col p-2 border rounded-md items-center">
                                    <div v-for="item, index in order.operator" class="flex items-center">
                                        <div class="p-2">{{ index }}</div>
                                        <div class="p-2">{{ item }}</div>
                                    </div>
                                </div> -->
                            </details>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary class="text-md font-semibold pb-4 pl-4">Клиент : {{ order.customer?.formated_phone}}</summary>
                                <div class="flex flex-col p-2 border rounded-md items-center">
                                    <div v-if="order.customer" class="flex flex-col">
                                        <div v-if="order.customer.customer_id">
                                            <router-link :to="{ name: 'CustomersEdit', params: { id: order.customer.customer_id } }">
                                                {{ order.customer.customer_id }}
                                            </router-link>
                                        </div>
                                        <div v-if="order.customer.phone">
                                            {{ order.customer.formated_phone }}
                                        </div>
                                    </div>
                                    <!-- <div v-for="item, index in order.customer" :key="index" class="flex items-center">
                                        <div class="p-2">{{ index }}</div>
                                        <div class="p-2">{{ item }}</div>
                                    </div> -->
                                </div>
                            </details>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary class="text-md font-semibold pb-4 pl-4">История статусов</summary>
                                <div class="flex flex-col p-2 border rounded-md items-center">
                                    <div v-for="item, index in order.statuses_history" class="flex items-center">
                                        <div class="p-2">{{ index }}</div>
                                        <div class="p-2">{{ item }}</div>
                                    </div>
                                </div>
                            </details>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary class="text-md font-semibold pb-4 pl-4">Доп. инфо</summary>
                                <div class="flex p-2 border rounded-md items-center">
                                    <div class="p-2 w-4/12">
                                        <div class="w-full">Источник заказа:</div>
                                        <div class="p-2 w-full">{{ order.extra_info?.order_source }}</div>
                                    </div>
                                    <div class="p-2 w-4/12">
                                        <div class="w-full">Чек клиенту:</div>
                                        <div class="p-2 w-full">{{ order.extra_info?.is_customer_check}}</div>
                                    </div>
                                    <div class="p-2 w-4/12">
                                        <div class="w-full">Чек кухне:</div>
                                        <div class="p-2 w-full">{{ order.extra_info?.is_kitchen_check }}</div>
                                    </div>
                                    <div class="p-2 w-4/12">
                                        <div class="w-full">Оплачен:</div>
                                        <div class="p-2 w-full">{{ order.extra_info?.is_payed }}</div>
                                    </div>
                                </div>
                            </details>
                        </section>

                        <section class="flex flex-col py-4">
                            <details>
                                <summary class="text-md font-semibold pb-4 pl-4">Общая информация</summary>
                                <div class="flex flex-col p-2 border rounded-md items-center">
                                    <div class="flex items-center">
                                        <div class="p-2">Предзаказ?:</div>
                                        <div class="p-2">{{ order.is_preorder }}</div>
                                    </div>
                                    <div class="flex items-center">
                                        <div class="p-2">Предзаказ на:</div>
                                        <div class="p-2">{{ order.desired_delivery_at }}</div>
                                    </div>
                                    <div class="flex items-center">
                                        <div class="p-2">Готовность:</div>
                                        <div class="p-2">{{ order.readiness_percent }}</div>
                                    </div>
                                    <div class="flex items-center">
                                        <div class="p-2">Последние обновления:</div>
                                        <div class="p-2">{{ order.updated_at }}</div>
                                    </div>
                                    <div class="flex items-center">
                                        <div class="p-2">Заказ оформлен:</div>
                                        <div class="p-2">{{ order.created_at }}</div>
                                    </div>
                                </div>
                            </details>
                        </section>

                    </section>
                </section>
           </section>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            order: {
                id: 0
            }
        }
    },
    methods: {
        getOrderById () {
           this.getServerData('order_by_id', { id: this.order.id })
                .then((res) => {
                    this.order = res.data
                })
                .catch(() => {})
        },
        orderItemsRemoveSpecificStockChange (item_id) {
            if (confirm('Удалить запись со склада?')) {
                this.getServerData('order_items_remove_stock_changes_by_id', { item_id })
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.getOrderById()
                        }
                    })
                    .catch(() => {})
            }
        },
        orderItemsRemoveStockChanges () {
            if (confirm('Удалить записи со склада?')) {
                this.getServerData('order_items_remove_stock_changes', { order_id: this.order.id })
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.getOrderById()
                        }
                    })
                    .catch(() => {})
            }
        },
        orderItemsAddToStockChanges () {
            this.getServerData('order_items_add_to_stock_changes', { order_id: this.order.id })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.getOrderById()
                    }
                })
                .catch(() => {})
        }
    },
    mounted () {
        this.order.id = this.$route.params.id
        document.title = this.order.id + ' | ' + document.title

        this.getOrderById()
    },
    watch: {
        // $route(to, from) {
        //     console.log(this.$route)
        //     console.log(this.$route.query)
        //     console.log(this.$route.hash)
        // }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
