<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="w-full lg:container lg:mx-auto bg-white">
          <section>
            <div class="flex flex-wrap">
                <div v-for="nav, index in navs" class="w-full lg:w-1/3 p-4" :key="index">
                    <div>{{ nav.title }}</div>
                    <div v-for="ni, index in nav.items" :key="index">
                        <div v-if="ni.inactive" class="flex flex-col px-8 py-16 mb-4 rounded-md bg-gray-50 border opacity-100" active-class="bg-lime-300">
                            <div class="text-xl text-center h-7"></div>
                        </div>
                        <router-link v-else :to="ni.to" class="flex flex-col px-8 py-16 mb-4 items-center justify-center hover:bg-lime-300 cursor-pointer rounded-md bg-white border shadow-md main-nav h-40" active-class="bg-lime-300">
                            <div class="text-xl text-center ">{{ ni.title }}</div>
                            <div class="text-sm text-center text-gray-500">{{ ni.description }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
          </section>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            navs: [
                {
                    // category
                    title: '',
                    items:
                    [
                        {
                            title: 'Список',
                            to: { name: 'CustomersList' }
                        },
                        {
                            inactive: true
                        },
                        {
                            inactive: true
                        }
                    ]
                },
                {
                    // category
                    title: '',
                    items:
                    [
                        {
                            title: 'Поиск',
                            to: { name: 'CustomersSearchResults' }
                        },
                        {
                            inactive: true
                        },
                        {
                            inactive: true
                        }
                    ]
                },
                {
                    // category
                    title: '',
                    items:
                    [
                        {
                            title: 'Телефоны',
                            to: { name: 'PhonesList' }
                        },
                        {
                            inactive: true
                        },
                        {
                            inactive: true
                        }
                    ]
                }
            ]
        }
    },
    methods: {
    },
    watch: {
    },
    mounted () {
    },
    components: {
    }
}
</script>
<style scoped>
.main-nav{
    background-image: url('https://img.icons8.com/nolan/256/1A6DFF/C822FF/box.png');
    background-repeat: no-repeat;
    background-size: 90px;
    background-position: -45px center;
    /* text-shadow: 1px 1px 0px #fff, -1px -1px 0px #fff, -1px 1px 0px #fff, 1px -1px 0px #fff; */
}
</style>
