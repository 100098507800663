<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center pb-12">
       <div class="bg-white" :class="[ view == 'full' ? 'w-full px-2' : 'container mx-auto']">
           <section>
                <!-- <div class="flex flex-row justify-between items-end pt-6  ">
                    <div class="w-2/3 flex flex-col justify-around pl-2 ">
                        <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-2">Список в работе на кухне</h1>
                        <span class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}}</span>
                    </div>
                    <div class="w-1/3 flex flex-row justify-end">

                    </div>
                </div> -->
                <div class="w-full rounded-lg border-2 border-gray-100 mt-4">
                    <div class="w-full flex flex-row items-center bg-gray-100">
                        <div class="w-full p-2 text-md font-semibold">В работе на кухне</div>
                        <div class="w-full p-2 flex items-center justify-end">
                            <div class="h-8 w-8 bg-gray-300 mr-4 p-2 rounded-full cursor-pointer hover:bg-yellow-300" @click="getKitchenMeals()">
                                <img src="https://cdn-icons-png.flaticon.com/512/61/61444.png" class="w-full" />
                            </div>
                            <div class="pr-2">Вид:</div>
                            <select v-model="view" class="p-2 outline-none rounded-md">
                                <option value="container">стандарт</option>
                                <option value="full">на весь экран</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="w-full mt-4">
                    <div v-if="orders.length > 0" class="items_list">
                        <div class="w-full flex px-4 text-gray-500">
                            <div class="w-3/12">Блюдо</div>
                            <div class="w-3/12">Особенности</div>
                            <div class="w-1/12 flex justify-center">Заказ</div>
                            <div class="w-1/12 flex justify-center">Готово</div>
                            <div class="w-4/12 flex justify-center">Отметить сделано</div>
                        </div>
                        <div v-for="order, order_index in orders" class="w-full rounded-lg border-2 border-gray-100 mt-4" :key="order_index">
                            <details :open="order.readiness_percent != 100">
                                <summary class="w-full flex justify-left p-2"
                                    :class="[ order.readiness_percent == 100 ? 'bg-green-100' : 'bg-yellow-100' ]"
                                    >
                                    <div>Заказ №{{ order.id }}
                                        {{ order.is_preorder == 1 ? 'предзаказ на ' + order.desired_delivery_at : null}}
                                        {{ order.address?.label == 'Зал' ? ' ( подать в ЗАЛ) ' : null}}</div>
                                </summary>
                                <div class="w-full flex flex-row text-sm">
                                    <div class="w-full flex flex-row items-center">
                                        <div class="w-full p-2 flex flex-col">
                                            <div v-for="item, item_index in order.items" class="w-full flex flex-row items-center border-t"  :class="[ view == 'full' ? 'text-2xl' : 'text-lg']" :key="item_index">
                                                <div class="w-3/12">
                                                    <div class="py-2">
                                                        <details v-if="item.is_combo" open>
                                                            <summary class="py-2">{{ item.name }}</summary>
                                                            <div class="pl-4" :class="[ view == 'full' ? 'text-lg' : 'text-base']">
                                                                <div v-for="sub_item, sub_item_index in item.contents" :key="sub_item_index">
                                                                    {{ sub_item.name }} x
                                                                    {{ sub_item.pivot.amount }}
                                                                </div>
                                                            </div>
                                                        </details>
                                                        <div v-else>
                                                            {{ item.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w-3/12">
                                                    {{ order.items[item_index].details }}
                                                </div>
                                                <div class="w-1/12 text-center">
                                                    {{ order.items[item_index].amount }}
                                                </div>
                                                <div class="w-1/12 text-center">
                                                    {{ order.items[item_index].ready_meals }}
                                                </div>
                                                <div class="w-4/12 text-md ">
                                                    <!-- <input v-model="order.items[index].ready_meals" class="py-2 px-4 border-b outline-none" /> -->
                                                    <div v-if=" order.items[item_index].amount != 1" class="w-full flex overflow-x-auto">
                                                        <div v-if="order.items[item_index].ready_meals > 0" class="m-2">
                                                            <button @click="updateOrderItemInfo(orders[order_index].items[item_index].id, 'ready_meals', 0)" class="flex w-full items-center justify-center px-6 py-3 border-b outline-none bg-gray-100 cursor-pointer hover:bg-green-300 rounded-md" >0</button>
                                                        </div>
                                                        <div v-for="item, index in (order.items[item_index].amount)" class=" m-2" :key="index">
                                                            <button @click="updateOrderItemInfo(orders[order_index].items[item_index].id, 'ready_meals', item)" class="flex w-full items-center justify-center px-6 py-3 border-b outline-none bg-gray-100 cursor-pointer hover:bg-green-300 rounded-md" >{{ item }}</button>
                                                        </div>
                                                    </div>
                                                    <div v-else class="flex">
                                                        <button v-if="order.items[item_index].ready_meals == 1" @click="updateOrderItemInfo(orders[order_index].items[item_index].id, 'ready_meals', 0)" class="w-1/2 py-2 px-2 m-2 border-b outline-none bg-gray-100 cursor-pointer hover:bg-red-300 rounded-md" >Не готово</button>
                                                        <button v-else @click="updateOrderItemInfo(orders[order_index].items[item_index].id, 'ready_meals', 1)" class="w-1/2 py-2 px-2 m-2 border-b outline-none bg-gray-100 cursor-pointer hover:bg-green-300 rounded-md" >Готово</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </details>
                        </div>
                    </div>
                </div>
           </section>
           <div class="py-4"></div>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            polling: null,
            is_loading: false,
            update_interval: 10000,
            view: 'container',
            meta: {
                current_page: 0,
                per_page: 20,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0
            },
            orders: []
            // showEditItemDialog: false,
        }
    },
    methods: {
        pollData () {
            this.polling = setInterval(() => {
                this.getKitchenMeals()
            }, this.update_interval)
        },
        getKitchenMeals () {
            this.is_loading = true
            this.getServerData('order_items_in_progress')
                .then((res) => {
                    this.meta.total = res.data.length
                    this.meta.from = 1
                    this.meta.to = res.data.length
                    this.orders = res.data
                })
                .catch(() => {})
                .finally(() => { this.is_loading = false })
        },
        updateOrderItemInfo (item_id, info_index, new_value) {
            console.log(item_id)
            this.postServerData('order_item_update_info', { data: { item_id, info_index, new_value } })
                .then((res) => {
                    this.getKitchenMeals()
                })
                .catch((error) => console.log(error))
        }
    },
    beforeUnmount () {
        clearInterval(this.polling)
    },
    created () {
        this.pollData()
    },
    mounted () {
        this.getKitchenMeals()
    },
    components: {
    }
}
</script>
<style lang="">

</style>
