<template lang="">
    <router-view></router-view>
</template>
<script>
export default {
    data: () => {
        return {
        }
    },
    methods: {
    },
    watch: {
    },
    mounted () {
    },
    components: {
    }
}
</script>
<style scoped>
</style>
