<template>
    <div class="w-full">
        <div class="w-full py-4 px-4 rounded-md flex flex-col" 
            :class="[stat.data ? stat.color ? `${stat.color}-100` : 'bg-indigo-100' : 'bg-gray-100' ]">
            <div class="pt-2 text-lg font-semibold flex justify-between items-center">
                <div>
                    {{ stat.title }}
                </div>
                <div class="cursor-pointer" @click="statsSeen = !statsSeen">
                    <div :class="[statsSeen ? 'masked' : 'unmasked']" class="h-4 w-4 transition-all"></div>
                </div>
            </div>
            <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
            <div v-if="stat.data" class="pb-2 flex w-full flex flex-col relative">
                <div v-for="data, index in stat.data" class="w-full">
                    <div class="w-full" style="height: 1px"  :class="`${stat.color}-300`"></div>
                    <div class="py-2 flex justify-start items-center">
                        <!-- <div class="w-1/12 font-semibold text-sm">#{{ index + 1 }}</div> -->
                        <div class="w-5/12 text-md pl-2">
                            <router-link v-if="data.link" :to="data.link">{{ data.label }}</router-link>
                            <div v-else>{{ data.label }}</div>
                        </div>
                        <div class="w-7/12 flex justify-end text-md relative">
                            <!-- <div class="relative z-10 pr-1 w-full">
                                 
                            </div> -->
                            <div v-if="stat.meta.max" class="overflow-visible px-1 flex justify-end rounded-md h-full transition-all" 
                                :class="[ statsSeen ? `${stat.color}-200` : `${stat.color}-300` ]"
                                :style="{'width': data.amount / stat.meta.max * 100 + '%'}">
                                <div class="transition-all" :class="[ statsSeen ? 'opacity-100': 'opacity-0' ]">{{ data.amount }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="flex flex-col items-center justify-center bg-gray-300 rounded-md">
                <statistics-placeholder>Информации пока нет</statistics-placeholder>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'collection-label-data',
    data: () => {
        return {
            statsSeen: false, 
        }
    },
    props: {
        stat: {
            type: Object,
            required: true
        }
    },
}
</script>
<style scoped>
    .masked
    {
        background-image: url('https://img.icons8.com/ios-glyphs/256/visible.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .unmasked
    {
        background-image: url('https://img.icons8.com/windows/256/hide.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>