import { createApp } from 'vue'
import App from './App'
import router from './router'
import store from './store'
import components from './components/UI'
import directives from './directives'
import mixins from './mixins'
import './index.css'

const app = createApp(App)

components.forEach(component => {
    app.component(component.name, component)
})

directives.forEach(directive => {
    app.directive(directive.name, directive)
})

mixins.forEach(component => {
    app.mixin(component)
})

app
    .use(store)
    .use(router)
    .mount('#app')
