<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
        <div class="container mx-auto b1g-white">
            <div class="pt-6 md:pl-4 flex items-center">
                <img :src="this.$store.getters.currentUser?.account?.avatar?.url" style="width: 128px" class="w-32 h-32 rounded-full shadow-sm" />
                <div class="flex px-4 text-md w-full">
                    <div class="flex justify-between w-full">
                        <div class="flex flex-col">
                            <div class="font-semibold flex items-center text-lg">
                                Салем, {{ this.$store.getters.currentUser?.account?.first_name }}
                                <!-- <button @click.prevent="logout" class="flex px-2 bg-gray-50 rounded-md ml-2 text-black">&#x21AA;</button> -->
                            </div>
                            <div class="flex items-center text-sm pt-1">{{ $store.getters.currentUser?.account?.role?.title }}</div>
                            <div class="flex items-center text-xs text-gray-700 pt-1">Последний визит {{ $store.getters.currentUser?.account?.last_visit_at }}</div>
                        </div>
                        <div class="flex items-center justify-between">
                            <div class="flex flex-col items-end">
                                <div class="text-lg text-gray-700">
                                    <router-link to="/firm/edit">
                                        {{ this.$store.getters.currentUser?.account?.firm?.title }}
                                    </router-link>
                                    <router-link to="/firm/branch" class="pl-1">
                                        {{ this.$store.getters.currentUser?.account?.branch?.title }}
                                    </router-link>
                                </div>
                                <div class="text-sm text-gray-700">Тарифный план <b>{{ this.$store.getters.currentUser?.account?.firm?.plan?.title || '...' }}</b></div>
                                <div class="text-md text-gray-700">Истекает <b>{{ this.$store.getters.currentUser?.account?.firm?.plan_expires_at || '...' }}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-1/2 md:w-1/3 lg:w-1/5 bg-rose-100 bg-rose-300 bg-rose-500 bg-red-300 bg-red-500  bg-yellow-300 bg-yellow-500"></div>
            <div class="w-full md:w-1/2 lg:w-1/2 bg-amber-100 bg-amber-300 bg-amber-500  bg-lime-300 bg-lime-500"></div>
            <div v-if="stats.length > 0" class="py-6 flex flex-wrap w-full">
                <div v-for="stat, index in stats" class="p-2" :class="[stat.width ? stat.width : 'w-full']" :key="index">
                    <NumberBlock v-if="stat.type == 'number'" :stat="stat"/>
                    <SimpleGraph v-if="stat.type == 'collection-linear-plot'" :stat="stat"/>
                    <MultiGraph v-if="stat.type == 'collection-linear-plot-multiple'" :stat="stat"/>

                    <collection-calendar v-if="stat.type == 'collection-calendar'" :stat="stat"/>

                    <div v-if="stat.type == 'collection-piechart'" class="w-full">
                        <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                            <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                            <div class="pt-2 pb-2 text-sm text-gray-700">{{ stat.description }}</div>
                            <div class="flex w-full overflow-hidden">
                                <div v-for="data, index in stat.data" :style="{'width': (data.point/stat.meta.max)*100 + '%'}" class="" :key="index">
                                    <div class="w-full h-4 flex flex-col justify-center items-center" :class="`${bg_colors[index]}`"></div>
                                </div>
                            </div>
                            <div class="w-full flex flex-col overflow-y-auto justify-center" style="height: 4.8rem">
                                <div v-for="data, index in stat.data" class="w-full flex flex-col" :key="index">
                                    <div class="flex items-center text-sm">
                                        <div class="w-1/12 font-semibold pr-2">
                                            <div class="w-2 h-2 rounded-full" :class="`${bg_colors[index]}`"></div>
                                        </div>
                                        <div class="w-2/12 font-semibold pr-2">{{ data.point }}</div>
                                        <div class="w-2/12 pr-2">{{ Math.round(data.point/stat.meta.max*100) || '...' }}%</div>
                                        <div class="w-7/12 ">{{ data.label }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="stat.type == 'collection-numbers'" class="w-full">
                        <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                            <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                            <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                            <div class="pb-2 flex w-full">
                                <div v-for="data, index in stat.data" class="w-full" :key="index">
                                    <div class="py-2 flex flex-col justify-center items-center">
                                        <div class="text-3xl font-semibold">{{ data.point }}</div>
                                        <div>{{ data.label }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="stat.type == 'collection-reviews'" class="w-full">
                        <div class="w-full pt-4 pb-8 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                            <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                            <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                            <div class="pb-0 flex w-full flex flex-col h-80 overflow-y-auto">
                                <div v-for="data, index in stat.data" class="w-full" :key="index">
                                    <div class="py-4 pr-4 flex flex-col justify-start items-start border-t">
                                        <div class="flex w-full justify-between items-center pb-2">
                                            <router-link :to="{ 'name': 'OrderEdit', 'params': { id: data.order_id } }" class=" text-lg font-semibold">Заказ #{{ data.order_id }}</router-link>
                                            <div class="text-xs text-gray-500">{{ data.created_at }}</div>
                                        </div>
                                        <div class="flex justify-center items-center w-full">
                                            <div class="w-full flex justify-around text-xs">
                                                <div class="pr-2 text-center">Доставка:
                                                    <div class="flex items-center">
                                                        <div v-for="star, index in data.courier_rating" :key="index">⭐</div>
                                                    </div>
                                                </div>
                                                <div class="pr-2 text-center">Кухня:
                                                    <div class="flex items-center">
                                                        <div v-for="star, index in data.kitchen_rating" :key="index">⭐</div>
                                                    </div>
                                                </div>
                                                <div class=" text-center">Оператор:
                                                    <div class="flex items-center">
                                                        <div v-for="star, index in data.operator_rating" :key="index">⭐</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="data.body" class="mt-4 py-2 px-4 italic text-sm hover:text-base transition-all text-left bg-green-50 rounded-md">{{ data.body }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="stat.type == 'collection-top-customers'" class="w-full">
                        <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                            <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                            <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                            <div class="pb-2 flex w-full flex flex-col">
                                <div v-for="data, index in stat.data" class="w-full" :key="index">
                                    <div class="py-2 flex justify-start items-center border-t border-gray-500 text-xs md:text-base">
                                        <div class="w-1/12 font-semibold">#{{ index + 1 }}</div>
                                        <div class="w-7/12 ">{{ data.label }}</div>
                                        <div class="w-4/12 text-right">{{ data.amount }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="stat.type == 'collection-stock-ingredients'" class="w-full">
                        <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.data ? stat.bg ? stat.bg : 'bg-indigo-100' : 'bg-gray-100' ]">
                            <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                            <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                            <div v-if="stat.data"  class="pb-2 flex w-full flex flex-col">
                                <div v-for="data, index in stat.data" class="w-full" :key="index">
                                    <div class="py-2 flex justify-start items-center border-t border-gray-500 text-xs md:text-base">
                                        <div class="w-5/12 flex items-center">
                                            <div class="font-semibold pr-2">#{{ index + 1 }}</div>
                                            {{ data.label }}
                                        </div>
                                        <div class="w-7/12 text-right flex items-center">
                                            <div v-for="inner, index in data.collection" class="w-full" :key="index">{{ inner }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="py-2">
                                <div class="h-64 pt-8 flex justify-center items-center">
                                    <i class="text-gray-700">Не достаточно информации</i>
                                </div>
                                <div class="w-full py-8"></div>
                            </div>
                        </div>
                    </div>

                    <div v-if="stat.type == 'debug'" class="w-full">
                        <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                            <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                            <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                            <div class="pb-2 flex w-full flex flex-col">
                                {{ stat.data }}
                            </div>
                        </div>
                    </div>

                    <div v-if="stat.type == 'notification'" class="w-full dismissable">
                        <div class="w-full py-52 px-4 rounded-md flex flex-col text-center" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                            <div class="pt-2 text-2xl font-semibold">{{ stat.title }}</div>
                            <!-- <div class="pt-2 pb-2 text-sm text-gray-700">{{ stat.description }}</div> -->
                            <div class="py-4 flex w-full flex flex-col text-xl">
                                {{ stat.data }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-else class="py-6 flex flex-wrap w-full">
                <div class="w-1/5"><div class="bg-gray-100 rounded-md h-48 w-11/12"></div></div>
                <div class="w-1/5"><div class="bg-gray-100 rounded-md h-48 w-11/12"></div></div>
                <div class="w-1/5"><div class="bg-gray-100 rounded-md h-48 w-11/12"></div></div>
                <div class="w-1/5"><div class="bg-gray-100 rounded-md h-48 w-11/12"></div></div>
                <div class="w-1/5"><div class="bg-gray-100 rounded-md h-48 w-12/12"></div></div>
                <div class="w-1/3"><div class="bg-gray-100 rounded-md h-48 w-11/12 mt-4"></div></div>
                <div class="w-2/3"><div class="bg-gray-100 rounded-md h-48 w-12/12 mt-4"></div></div>
                <div class="w-1/3"><div class="bg-gray-100 rounded-md h-48 w-11/12 mt-4"></div></div>
                <div class="w-1/3"><div class="bg-gray-100 rounded-md h-48 w-11/12 mt-4"></div></div>
                <div class="w-1/3"><div class="bg-gray-100 rounded-md h-48 w-12/12 mt-4"></div></div>
            </div>
        </div>
    </div>
</template>
<script>
import NumberBlock from '../components/UI/Dash/NumberBlock'
import SimpleGraph from '../components/UI/Dash/SingleLayerGraphBlock'
import MultiGraph from '../components/UI/Dash/MultiLayerGraph'
import CollectionCalendar from '../components/UI/Dash/CollectionCalendar'

export default {
    data: () => {
        return {
            stats: [],
            bg_colors: ['bg-green-300', 'bg-yellow-300', 'bg-red-300', 'bg-indigo-300']
        }
    },
    methods: {
        // logout () {
        //     this.$store.dispatch('logoutUser')
        // },
        getStats () {
            this.getServerData('report_stats').then((res) => {
                    this.stats = res.data
                    // console.log(res.data)
                }
            )
        }
    },
    mounted () {
        this.getStats()
    },
    components: {
        NumberBlock,
        SimpleGraph,
        MultiGraph,
        CollectionCalendar
    }
}
</script>
<style scoped>
</style>
