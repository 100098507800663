import { createRouter, createWebHistory } from 'vue-router'
import store from './store'

import NotFound from './pages/ErrorPages/404'

import RegisterPage from './pages/User/Register'
import LoginPage from './pages/User/Login'

import HomePage from './pages/Home'
import ActiveOrdersPage from './pages/Orders/Active'
import OrderEditPage from './pages/Orders/Edit'

import CustomersWrapPage from './pages/Customers/Wrap'
import CustomersHomePage from './pages/Customers/Home'
import CustomersPage from './pages/Customers/Items/List'
import CustomersSearchResultsPage from './pages/Customers/SearchResults/List'
import CustomersEditPage from './pages/Customers/Items/Edit'
import PhonesPage from './pages/Customers/Phones/List'
import PhonesEditPage from './pages/Customers/Phones/Edit'

import KitchenProgressPage from './pages/Kitchen/ProgressList'

// Reports
import ReportsWrapPage from './pages/Reports/Wrap'
import ReportsHomePage from './pages/Reports/Home'

import DailyReportWrapPage from './pages/Reports/Daily/Wrap'
import DailyReportHomePage from './pages/Reports/Daily/Home'
import DailyReportTotalsWrapPage from './pages/Reports/Daily/Totals/Wrap'
import DailyReportTotalsHomePage from './pages/Reports/Daily/Totals/Home'
import DailyReportOrdersWrapPage from './pages/Reports/Daily/Orders/Wrap'
import DailyReportOrdersHomePage from './pages/Reports/Daily/Orders/Home'

import MonthlyReportWrapPage from './pages/Reports/Monthly/Wrap'
import MonthlyReportHomePage from './pages/Reports/Monthly/Home'

import FilteredOrdersWrapPage from './pages/Reports/Filtered/Wrap'
import FilteredOrdersHomePage from './pages/Reports/Filtered/Home'

import FilteredOrdersByIdWrapPage from './pages/Reports/ByID/Wrap'
import FilteredOrdersByIdHomePage from './pages/Reports/ByID/Home'

import WeeklyReportPage from './pages/Reports/Weekly'

// My Firm
import MyFirmWrapPage from './pages/MyFirm/Wrap'
import MyFirmHomePage from './pages/MyFirm/Home'
import MyFirmEditPage from './pages/MyFirm/Edit'
import MyStaffWrapPage from './pages/MyFirm/Staff/Wrap'
import MyStaffListPage from './pages/MyFirm/Staff/List'
import MyStaffCreatePage from './pages/MyFirm/Staff/Create'
import MyStaffEditPage from './pages/MyFirm/Staff/Edit'
import MyStaffSalariesPage from './pages/MyFirm/Staff/Salaries'
import MyBranchEditPage from './pages/MyFirm/Branch/Edit'

import FirmsPage from './pages/Admin/Firms/List'
import FirmsEditPage from './pages/Admin/Firms/Edit'
import FirmsCreatePage from './pages/Admin/Firms/Create'
import BranchesPage from './pages/Admin/Branches/List'
import BranchesCreatePage from './pages/Admin/Branches/Create'
import BranchesEditPage from './pages/Admin/Branches/Edit'
import StaffPage from './pages/Staff/List'
import StaffEditPage from './pages/Staff/Edit'
import MyAccountPage from './pages/Staff/MyAccount'
import StaffCreatePage from './pages/Staff/Create'

// Menu
import MenuWrapPage from './pages/Menu/Wrap'
import MenuHomePage from './pages/Menu/Home'

import ItemsWrapPage from './pages/Menu/Items/Wrap'
import ItemsListPage from './pages/Menu/Items/List'
import ItemsEditPage from './pages/Menu/Items/Edit'
import ItemsCreatePage from './pages/Menu/Items/Create'

import TagsWrapPage from './pages/Menu/Tags/Wrap'
import TagsListPage from './pages/Menu/Tags/List'
import TagsEditPage from './pages/Menu/Tags/Edit'
import TagsCreatePage from './pages/Menu/Tags/Create'

import CombosWrapPage from './pages/Menu/Combos/Wrap'
import CombosListPage from './pages/Menu/Combos/List'
import CombosEditPage from './pages/Menu/Combos/Edit'
import CombosCreatePage from './pages/Menu/Combos/Create'

// PR
import PublicRelationsWrapPage from './pages/PublicRelations/Wrap'
import PublicRelationsHomePage from './pages/PublicRelations/Home'
import NewsPage from './pages/PublicRelations/News/List'
import NewsEditPage from './pages/PublicRelations/News/Edit'
import NewsCreatePage from './pages/PublicRelations/News/Create'

import SalesPage from './pages/PublicRelations/Sales/List'
import SalesEditPage from './pages/PublicRelations/Sales/Edit'
import SalesCreatePage from './pages/PublicRelations/Sales/Create'

import PromocodesPage from './pages/PublicRelations/Promocodes/List'
import PromocodesEditPage from './pages/PublicRelations/Promocodes/Edit'
import PromocodesCreatePage from './pages/PublicRelations/Promocodes/Create'

// Kacca
import FundsWrapPage from './pages/Funds/Wrap'
import FundsHomePage from './pages/Funds/Home'
import ExpensesPage from './pages/Funds/Expenses/List'
import IncomesPage from './pages/Funds/Incomes/List'

// Склад
import WarehouseWrapPage from './pages/Warehouse/Wrap'
import WarehouseHomePage from './pages/Warehouse/Home'

import IngredientsWrapPage from './pages/Warehouse/Ingredients/Wrap'
import IngredientsListPage from './pages/Warehouse/Ingredients/List'
import IngredientsPriorityListPage from './pages/Warehouse/Ingredients/PriorityList'
import IngredientsCreatePage from './pages/Warehouse/Ingredients/Create'
import IngredientsEditPage from './pages/Warehouse/Ingredients/Edit'
import IngredientsRefillPage from './pages/Warehouse/IngredientsRefill'

import StockChangesWrapPage from './pages/Warehouse/StockChanges/Wrap'
import StockChangesListPage from './pages/Warehouse/StockChanges/List'
import StockChangesEditPage from './pages/Warehouse/StockChanges/Edit'

// Debug
import RolesPage from './pages/Staff/Roles/List'
import RolesEditPage from './pages/Staff/Roles/Edit'
import RolesCreatePage from './pages/Staff/Roles/Create'
import PermissionsPage from './pages/Staff/Permissions/List'
import PermissionsEditPage from './pages/Staff/Permissions/Edit'
import PermissionsCreatePage from './pages/Staff/Permissions/Create'

import AddressesWrapPage from './pages/Addresses/Wrap'
import AddressesHomePage from './pages/Addresses/Home'
import AddressesListPage from './pages/Addresses/List'
import AddressesEditPage from './pages/Addresses/Edit'
import StreetsPage from './pages/Addresses/Streets/List'
import StreetsEditPage from './pages/Addresses/Streets/Edit'
import BuildingsEditPage from './pages/Addresses/Buildings/Edit'

import DepartmentsPage from './pages/Staff/Departments/List'

const routes = [
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: '/login'
    // },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: {
            title: 'Вход | Breaktime CRM'
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterPage,
        meta: {
            title: 'Регистрация | Breaktime CRM'
        }
    },

    { // Home Page
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: {
            title: 'Breaktime CRM'
        }
    },

    // Orders
    {
        path: '/orders/active',
        name: 'ActiveOrders',
        component: ActiveOrdersPage,
        meta: {
            title: 'Прием заказов'
        }
    },
    {
        path: '/orders/:id/edit',
        name: 'OrderEdit',
        component: OrderEditPage,
        meta: {
            title: 'Информация о заказе'
        }
    },

    // Reports
    {
        path: '/report/',
        // name: 'Reports',
        component: ReportsWrapPage,
        children: [
            {
                path: '',
                name: 'Reports',
                component: ReportsHomePage,
                meta: {
                    title: 'Отчеты | Breaktime CRM'
                }
            },
            {
                path: 'daily/',
                component: DailyReportWrapPage,
                children: [
                    {
                        path: '',
                        name: 'DailyReport',
                        component: DailyReportHomePage,
                        meta: {
                            title: 'Отчет дневной | Breaktime CRM'
                        }
                    },
                    {
                        path: ':day/totals/',
                        component: DailyReportTotalsWrapPage,
                        children: [
                            {
                                path: '',
                                // path: ':sector?/:show_criteria?/:show_value?/:sort_criteria?/:sort_direction?',
                                name: 'DailyReportTotals',
                                component: DailyReportTotalsHomePage,
                                meta: {
                                    title: 'Итоги | Дневной | Отчет | Breaktime CRM',
                                    parent: 'Reports'
                                }
                            }
                        ]
                    },
                    {
                        path: ':day/orders/',
                        component: DailyReportOrdersWrapPage,
                        children: [
                            {
                                path: ':sector?/:show_criteria?/:show_value?/:sort_criteria?/:sort_direction?',
                                name: 'DailyReportOrders',
                                component: DailyReportOrdersHomePage,
                                meta: {
                                    title: 'Заказы | Дневной | Отчет | Breaktime CRM',
                                    parent: 'Reports'
                                }
                            }
                        ]
                    }
                    // {
                    //     path: ':day?/:sector?/:show_criteria?/:show_value?/:sort_criteria?/:sort_direction?',
                    //     name: 'DailyReport',
                    //     component: DailyReportHomePage,
                    //     meta: {
                    //         title: 'Отчет дневной | Breaktime CRM',
                    //     }
                    // },
                ]
            },
            {
                path: 'monthly/',
                component: MonthlyReportWrapPage,
                children: [
                    {
                        path: '',
                        name: 'MonthlyReport',
                        component: MonthlyReportHomePage,
                        meta: {
                            title: 'Отчет за месяц | Breaktime CRM'
                        }
                    }
                ]
            },
            {
                path: 'filtered/',
                component: FilteredOrdersWrapPage,
                children: [
                    {
                        path: '',
                        name: 'FilteredOrders',
                        component: FilteredOrdersHomePage,
                        meta: {
                            title: 'Поиск заказов | Breaktime CRM'
                        }
                    }
                ]
            },
            {
                path: 'byid/',
                component: FilteredOrdersByIdWrapPage,
                children: [
                    {
                        path: '',
                        name: 'FilteredOrdersById',
                        component: FilteredOrdersByIdHomePage,
                        meta: {
                            title: 'Поиск заказов по номеру | Breaktime CRM'
                        }
                    }
                ]
            }

                    // {
            //     path: 'weekly',
            //     name: 'WeeklyReport',
            //     component: WeeklyReportPage,
            //     meta: {
            //         title: 'Отчет недельный | Breaktime CRM',
            //         parent: '/report',
            //     }
            // },
            // {
            //     path: 'monthly',
            //     name: 'MonthlyReport',
            //     component: MonthlyReportPage,
            //     meta: {
            //         title: 'Отчет месяц | Breaktime CRM',
            //         parent: '/report',
            //     }
            // },

        ]

    },

    // My Firm
    {
        path: '/firm/',
        component: MyFirmWrapPage,
        children: [
            {
                path: '',
                name: 'MyFirm',
                component: MyFirmHomePage,
                meta: {
                    title: 'Моя фирма | Breaktime CRM'
                }
            },
            {
                path: 'edit',
                name: 'MyFirmEdit',
                component: MyFirmEditPage,
                meta: {
                    title: 'Моя фирма | Breaktime CRM'
                }
            },
            {
                path: 'branch',
                name: 'MyBranchEdit',
                component: MyBranchEditPage
            },
            {
                path: 'staff',
                component: MyStaffWrapPage,
                children: [
                    {
                        path: '',
                        name: 'MyStaff',
                        component: MyStaffListPage,
                        meta: {
                            title: 'Сотрудники | Breaktime CRM'
                        }
                    },
                    {
                        path: ':id/salaries',
                        name: 'MyStaffSalaries',
                        component: MyStaffSalariesPage,
                        meta: {
                            title: 'Зарплаты | Breaktime CRM'
                        }
                    },
                    {
                        path: ':id/edit',
                        name: 'MyStaffEdit',
                        component: MyStaffEditPage,
                        meta: {
                            title: 'Инфо сотрудника | Breaktime CRM'
                        }
                    },
                    {
                        path: 'create',
                        name: 'MyStaffCreate',
                        component: MyStaffCreatePage,
                        meta: {
                            title: 'Создать сотрудника | Breaktime CRM'
                        }
                    }
                ]
            }
        ]

    },
    {
        path: '/firms',
        name: 'Firms',
        component: FirmsPage
    },
    {
        path: '/firms/:id/edit',
        name: 'FirmsEdit',
        component: FirmsEditPage
    },
    {
        path: '/firms/create',
        name: 'FirmsCreate',
        component: FirmsCreatePage,
        meta: {
            title: 'Создать фирму | Breaktime CRM'
        }
    },
    {
        path: '/branches',
        name: 'Branches',
        component: BranchesPage
    },
    {
        path: '/branches/create',
        name: 'BranchesCreate',
        component: BranchesCreatePage,
        meta: {
            title: 'Создать фирму | Breaktime CRM'
        }
    },
    {
        path: '/branches/:id/edit',
        name: 'BranchesEdit',
        component: BranchesEditPage
    },
    {
        path: '/departments',
        name: 'Departments',
        component: DepartmentsPage
    },
    {
        path: '/staff',
        name: 'Staff',
        component: StaffPage
    },
    {
        path: '/staff/:id/edit',
        name: 'StaffEdit',
        component: StaffEditPage
    },
    {
        path: '/my_account',
        name: 'MyAccount',
        component: MyAccountPage,
        meta: {
            title: 'Мой профиль | Breaktime CRM'
        }
    },
    {
        path: '/staff/create',
        name: 'StaffCreate',
        component: StaffCreatePage,
        meta: {
            title: 'Создать сотрудника | Breaktime CRM'
        }
    },

    // customers
    {
        path: '/customers/',
        component: CustomersWrapPage,
        children:
        [
            {
                path: '',
                name: 'Customers',
                component: CustomersHomePage,
                meta: {
                    title: 'Клиенты | Breaktime CRM'
                }
            },
            {
                path: 'list/:page?',
                name: 'CustomersList',
                component: CustomersPage,
                meta: {
                    title: 'Клиенты | Breaktime CRM'
                }
            },
            {
                path: ':id/edit',
                name: 'CustomersEdit',
                component: CustomersEditPage
            },
            {
                path: 'search/:q?',
                name: 'CustomersSearchResults',
                component: CustomersSearchResultsPage,
                meta: {
                    title: 'Клиенты | Breaktime CRM'
                }
            },
            {
                path: 'phones/list/:page?',
                name: 'PhonesList',
                component: PhonesPage,
                meta: {
                    title: 'Клиенты | Breaktime CRM'
                }
            },
            {
                path: 'phones/:id/edit',
                name: 'PhonesEdit',
                component: PhonesEditPage,
                meta: {
                    title: 'Клиенты | Breaktime CRM'
                }
            }
        ]
    },

    // очередь на кухне
    {
        path: '/kitchen_progress',
        name: 'KitchenProgress',
        component: KitchenProgressPage
    },

    // addresses
    {
        path: '/addresses/',
        component: AddressesWrapPage,
        children: [
            {
                path: '',
                name: 'Addresses',
                component: AddressesHomePage
            },
            {
                path: ':page?',
                name: 'AddressesList',
                component: AddressesListPage
            },
            {
                path: ':id/edit',
                name: 'AddressesEdit',
                component: AddressesEditPage
            },
            // streets
            {
                path: 'streets/:page?',
                name: 'Streets',
                component: StreetsPage
            },
            {
                path: 'streets/:id/edit',
                name: 'StreetsEdit',
                component: StreetsEditPage
            },
            // buildings
            {
                path: 'buildings/:id/edit',
                name: 'BuildingsEdit',
                component: BuildingsEditPage
            }
        ]
    },

    // Menu | Меню
    {
        path: '/menu/',
        component: MenuWrapPage,
        children:
        [
            {
                path: '',
                name: 'Menu',
                component: MenuHomePage,
                meta: {
                    title: 'Меню | Breaktime CRM'
                }
            },

            // tags
            {
                path: 'tags/',
                component: TagsWrapPage,
                children: [
                    {
                        path: '',
                        name: 'Tags',
                        component: TagsListPage
                    },
                    {
                        path: ':id/edit',
                        name: 'TagsEdit',
                        component: TagsEditPage
                    },
                    {
                        path: 'create',
                        name: 'TagsCreate',
                        component: TagsCreatePage
                    }
                ]
            },

            // combos
            {
                path: 'combos/',
                component: CombosWrapPage,
                children: [
                    {
                        path: '',
                        name: 'Combos',
                        component: CombosListPage
                    },
                    {
                        path: ':id/edit',
                        name: 'CombosEdit',
                        component: CombosEditPage
                    },
                    {
                        path: 'create',
                        name: 'CombosCreate',
                        component: CombosCreatePage
                    }

                ]
            },

            // items meals
            {
                path: 'items/',
                component: ItemsWrapPage,
                children: [
                    {
                        path: '',
                        name: 'Items',
                        component: ItemsListPage,
                        meta: {
                            title: 'Блюда | Breaktime CRM'
                        }
                    },
                    {
                        path: 'p/:page(\\d+)?',
                        name: 'ItemsPaged',
                        component: ItemsListPage,
                        meta: {
                            title: 'Блюда | Breaktime CRM'
                        }
                    },
                    {
                        path: ':id(\\d+)/edit',
                        name: 'ItemsEdit',
                        component: ItemsEditPage,
                        meta: {
                            title: 'Информация о блюде | Breaktime CRM'
                        }
                    },
                    {
                        path: 'create',
                        name: 'ItemsCreate',
                        component: ItemsCreatePage,
                        meta: {
                            title: 'Создать блюдо | Breaktime CRM'
                        }
                    }
                    // {
                    //     path: '?sb=:sort_by&sd=:sort_dir&p=:page&tid=:tag_id',
                    //     name: 'ItemsExact',
                    //     component: ItemsListPage,
                    //     meta: {
                    //         title: 'Блюда | Breaktime CRM'
                    //     }
                    // },

                ]
            }
        ]
    },

    // Warehouse | Склад
    {
        path: '/warehouse/',
        component: WarehouseWrapPage,
        children:
        [
            {
                path: '',
                name: 'Warehouse',
                component: WarehouseHomePage,
                meta: {
                    title: 'Склад | Breaktime CRM'
                }
            },

            // ingredients
            {
                path: 'ingredients/',
                component: IngredientsWrapPage,
                children: [
                    {
                        path: '',
                        // path: ':page(\\d+)?',
                        // alias: '',
                        name: 'Ingredients',
                        component: IngredientsListPage,
                        meta: {
                            title: 'Склад | Ингредиенты | Breaktime CRM'
                        }
                    },
                    {
                        path: 'priority',
                        name: 'IngredientsPriority',
                        component: IngredientsPriorityListPage,
                        meta: {
                            title: 'Склад | Приоритетные Ингредиенты | Breaktime CRM'
                        }
                    },
                    {
                        path: ':id/edit',
                        name: 'IngredientsEdit',
                        component: IngredientsEditPage
                    },
                    {
                        path: 'create',
                        name: 'IngredientsCreate',
                        component: IngredientsCreatePage
                    },
                    {
                        path: 'refill',
                        name: 'IngredientsRefill',
                        component: IngredientsRefillPage
                    }
                ]
            },

            // stock changes
            {
                path: 'stock_changes/',
                component: StockChangesWrapPage,
                children: [
                    {
                        path: '',
                        // path: ':criteria?/:period?',
                        name: 'StockChanges',
                        component: StockChangesListPage
                    },
                    {
                        path: ':id/edit',
                        name: 'StockChangesEdit',
                        component: StockChangesEditPage
                    }
                ]
            }
        ]
    },

    // Funds | касса
    {
        path: '/funds/',
        component: FundsWrapPage,
        children:
        [
            // {
            //     path: '',
            //     name: 'Funds',
            //     component: FundsHomePage,
            //     meta: {
            //         title: 'Касса | Breaktime CRM'
            //     }
            // },

            {
                path: '',
                redirect: '/funds/incomes'
            },
            {
                path: 'incomes',
                name: 'Incomes',
                component: IncomesPage,
                meta: {
                    title: 'Доп Дотации | Breaktime CRM'
                }
            },
            {
                path: 'expenses',
                name: 'Expenses',
                component: ExpensesPage,
                meta: {
                    title: 'Доп Расходы | Breaktime CRM'
                }
            }
        ]
    },

    // debug roles permissions
    {
        path: '/roles',
        name: 'Roles',
        component: RolesPage
    },
    {
        path: '/roles/:id/edit',
        name: 'RolesEdit',
        component: RolesEditPage
    },
    {
        path: '/roles/create',
        name: 'RolesCreate',
        component: RolesCreatePage
    },
    {
        path: '/permissions',
        name: 'Permissions',
        component: PermissionsPage
    },
    {
        path: '/permissions/:id/edit',
        name: 'PermissionsEdit',
        component: PermissionsEditPage
    },
    {
        path: '/permissions/create',
        name: 'PermissionsCreate',
        component: PermissionsCreatePage
    },

    // Public Relations
    {
        path: '/pr/',
        component: PublicRelationsWrapPage,
        children:
        [
            {
                path: '',
                name: 'PublicRelations',
                component: PublicRelationsHomePage
            },
            {
                path: '/news',
                name: 'News',
                component: NewsPage
            },
            {
                path: '/news/:id/edit',
                name: 'NewsEdit',
                component: NewsEditPage
            },
            {
                path: '/news/create',
                name: 'NewsCreate',
                component: NewsCreatePage
            },

            // sales
            {
                path: '/sales',
                name: 'Sales',
                component: SalesPage
            },
            {
                path: '/sales/:id/edit',
                name: 'SalesEdit',
                component: SalesEditPage
            },
            {
                path: '/sales/create',
                name: 'SalesCreate',
                component: SalesCreatePage
            },

            // promocodes
            {
                path: '/promocodes',
                name: 'Promocodes',
                component: PromocodesPage
            },
            {
                path: '/promocodes/:id/edit',
                name: 'PromocodesEdit',
                component: PromocodesEditPage
            },
            {
                path: '/promocodes/create',
                name: 'PromocodesCreate',
                component: PromocodesCreatePage
            }
        ]
    }
]

const router = createRouter({
    routes,
    linkActiveClass: 'font-semibold',
    history: createWebHistory()
})

router.beforeEach(async (to, from, next) => {
    const title = to.meta.title
    if (title) { document.title = title }


    const loggedIn = store.getters.loggedIn
    if(loggedIn !== undefined){
        store.dispatch('getUser')
        if(to.name === 'Login' || to.name === 'Register') return next({ name: 'Home' })
        return next()
    } else {
        if (to.name === 'Login') {
            return next()
        } else
        if (to.name === 'Register') {
            return next()
        } else {
            return next({ name: 'Login' })
        }
    }
})

export default router
