<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">{{ item.street?.name }}, {{ item.number }} </h1>
                    <router-link :to="{ name: 'Streets' }">&larr; назад</router-link>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class="border rounded-md flex">
                        <div class="flex flex-row p-4 w-2/12">
                            <div class="w-full flex flex-col">
                                <label class="px-2">Номер</label>
                                <input v-model="item.number" @change="updateItemInfo('number', item.number)" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>

                        <div class="flex flex-row p-4 w-2/12">
                            <div class="w-full flex flex-col">
                                <label class="px-2">Тип</label>
                                <select v-model="item.type" @change="updateItemInfo('type', item.type)" class="p-2 border outline-none">
                                    <option value="office">Офис</option>
                                    <option value="condo">Кондо</option>
                                    <option value="house">Частный</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-row p-4 w-2/12">
                            <div class="w-full flex flex-col">
                                <label class="px-2">Координаты</label>
                                <input v-model="item.coords" @change="updateItemInfo('coords', item.coords)" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4 w-2/12">
                            <div class="w-full flex flex-col">
                                <label class="px-2">Доставка</label>
                                <input v-model="item.delivery_cost" @change="updateItemInfo('delivery_cost', item.delivery_cost)" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4 w-2/12">
                            <div class="w-full flex flex-col">
                                <label class="px-2">Улица</label>
                                <select v-model="item.street_id" @change="updateItemInfo('street_id', item.street_id)" class="p-2 border outline-none">
                                    <option v-for="street in streets" :value="street.id">{{ street.id }} {{ street.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </section>

                <!-- <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Филиалы</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="flex flex-row p-4 w-full">
                            <div v-for="branch in item.branches" class="w-3/12 flex flex-col rounded-md hover:bg-yellow-300 p-4">
                                <router-link :to="{ name: 'BranchesEdit', params: { id: branch.id }}">
                                    <label class="pb-2">{{ branch.title}}</label>
                                    <div class="pb-2">{{ branch.city?.name }}</div>
                                    <div class="pb-2">{{ branch.description }}</div>
                                    <div class="pb-2">{{ branch.main_phone }}</div>
                                    <div class="pb-2">{{ branch.status == 1 ? 'Активен' : 'Не активен'}}</div>
                                    <div class="">{{ branch.shift_begins_at}} - {{ branch.shift_ends_at}}</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </section> -->

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Информация</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="flex flex-row p-4 w-full">
                            <div class="w-3/12 flex flex-col">
                                <label class="pb-2">Последнее обновление</label>
                                {{ item.updated_at }}
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="pb-2">Создан</label>
                                {{ item.created_at }}
                            </div>
                        </div>
                    </div>
                </section>
                <section v-if="can('delete_buildings')" class="flex flex-col pb-6">
                    <div class="w-full flex flex-col items-end">
                        <button @click="deleteItem()" class="py-3 px-6 outline-none rounded-md bg-red-100 hover:bg-red-300">Удалить</button>
                    </div>
                </section>

            </section>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                id: 0
            },
            streets: []
        }
    },
    methods: {
        getAddressById () {
            this.getServerData('building_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data
                })
                .catch((error) => console.log(error))
        },
        getStreetsAll () {
            this.getServerData('streets_all')
                .then((res) => {
                    this.streets = res.data
                })
                .catch(() => {})
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('building_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {

                })
                .catch((error) => console.log(error))
        },
        deleteItem () {
            this.postServerData('building_delete', { data: { id: this.item.id } })
                .then((res) => {
                    if (res.data.code === 200) {
                        // this.$router.push({ name: 'Streets' })
                        this.$router.back()
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    watch: {
        $route (to, from) {
            if (to.name === 'BuildingsEdit') {
                this.item.id = to.params.id
                this.getAddressById()
            }
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getAddressById()
        this.getStreetsAll()
    }
}
</script>
<style lang="">

</style>
