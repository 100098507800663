<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
        <div class="w-full lg:container lg:mx-auto bg-white">
            <div>
                <div class="select-none text-2xl pt-4 pb-2 pl-3 cursor-pointer border-b-2 border-yellow-300 font-semibold flex items-center">
                    <div class="pl-4 pb-1">Прием заказов</div>
                </div>
                <div :class="[createOrdersFormsCount > 0 ? 'h-120 mb-8 opacity-100' : 'h-0 overflow-hidden mb-0 opacity-0' ]" class="transition-all">
                    <create-order-form v-for="form,index in createOrdersFormsCount" :key="index"></create-order-form>
                </div>
                <div class="select-none text-sm pt-2 pb-2 pl-3 cursor-pointer border-t-2 border-yellow-300 font-semibold flex items-center bg-gray-50">
                    <div v-show="createOrdersFormsCount < 3" @click="createNewForm" class="flex items-center mr-4 py-2 px-4 rounded-md hover:bg-yellow-300 active:bg-yellow-500">
                        <img src="https://static.breaktime.kz/uploads/images/breaktime/icons/chevron-down-96-light.png" class="h-6"/>
                        <div class="pl-4 pb-1">Создать форму</div>
                    </div>
                    <div v-show="createOrdersFormsCount > 0" @click="closeForm" class="flex items-center py-2 px-4 rounded-md hover:bg-yellow-300 active:bg-yellow-500">
                        <img src="https://static.breaktime.kz/uploads/images/breaktime/icons/chevron-up-96-light.png" class="h-6"/>
                        <div class="pl-4 pb-1">Закрыть форму</div>
                    </div>
                </div>
            </div>
            <section>
                <active-orders-list ref="aol"></active-orders-list>
            </section>
            <section>
                <div class="h-36 border-t mt-16"></div>
            </section>
        </div>
    </div>
</template>
<script>
import CreateOrderForm from '../../components/CreateOrderForm'
import ActiveOrdersList from '../../components/ActiveOrdersList'

export default {
    data: () => {
        return {
            isCreateOrderFormExpanded: false,
            createOrdersFormsCount: 0
        }
    },
    methods: {
        createNewForm () {
            this.createOrdersFormsCount++
        },
        closeForm () {
            if (this.createOrdersFormsCount > 0) this.createOrdersFormsCount--
        }
    },
    mounted () {
        // this.$refs.nc.notify('Well!', 'Hi there')
    },
    components: {
        ActiveOrdersList,
        CreateOrderForm
    }
}
</script>
<style scoped>
    .h-120 {}
</style>
