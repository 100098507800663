<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="p-4">
                    <h1 class="text-xl lg:text-2xl text-gray-800 font-semibold pt-2 min-h-4">Зарплаты</h1>
                </div>
                <details class="w-full flex flex-col py-2">
                    <summary class="text-md font-semibold pb-4 pl-4">Добавить запись</summary>
                    <div class="flex flex-col p-2 border rounded-md items-center">
                        <div class="flex flex-wrap items-end w-full">
                            <div class="w-full flex flex-col">
                                <label class="px-2">Количество</label>
                                <text-input v-model="salary.salary_amount" placeholder="Количество" type="number"/>
                            </div>
                            <div class="w-7/12 flex flex-col">
                                <label class="px-2">Дата</label>
                                <text-input v-model="salary.date" type="text" placeholder="Дата: ДД.ММ.ГГГГ" />
                            </div>
                            <div class="w-5/12 flex flex-col">
                                <label class="px-2"></label>
                                <default-button @click="storeSalary">добавить</default-button>
                            </div>
                        </div>
                    </div>
                </details>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Зарплаты</h3>
                    <div class="flex flex-col p-2 border rounded-md items-center">
                        <div v-if="item.salaries && item.salaries.length > 0" class="w-full px-4">
                            <div class="w-full flex py-2">
                                <div class="w-1/3">Дата</div>
                                <div class="w-1/3">Сумма</div>
                            </div>
                            <div v-for="staff_salary in item.salaries" class="w-full flex py-2 border-t">
                                <div class="w-1/3">{{ staff_salary.date }}</div>
                                <div class="w-1/3">{{ staff_salary.salary_amount }}</div>
                                <div class="w-1/3 flex flex-col">
                                    <button @click="deleteSalary(staff_salary.id)" class="py-2 px-4 rounded-md cursor-pointer bg-gray-100 hover:bg-red-300 w-11/12">- удалить</button>
                                </div>
                            </div>
                        </div>
                        <div v-else class="p-8">
                            <i>Информации пока нет</i>
                        </div>
                    </div>
                </section>

            </section>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data: () => {
        return {
            item: {
                id: 0
            },
            salary: {
                salary_amount: '',
                date: 'сегодня'
            }
        }
    },
    methods: {
        getStaffById () {
            this.getServerData('staff_salaries', { staff_id: this.item.id })
                .then((res) => {
                    this.item = res.data
                })
                .catch((error) => console.log(error))
        },
        storeSalary () {
           this.postServerData('staff_salary_store', { data: { staff_id: this.item.id, salary_amount: this.salary.salary_amount, date: this.salary.date } })
                .then((res) => {
                    this.getStaffById()
                })
                .catch((error) => console.log(error))
        },
        deleteSalary (salary_id) {
            this.postServerData('staff_salary_delete', { data: { salary_id } })
                .then((res) => {
                    this.getStaffById()
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getStaffById()
    }
}
</script>
<style scoped>
    input:focus{
        border-bottom: 1px solid #333;
    }
    input:invalid {
        border-bottom: 1px solid red;
    }
</style>
