<template>
    <!-- <section v-if="$store.getters.loggedIn && $route.name != 'Paycheck'">
        <navbar></navbar>
    </section> -->
    <section v-if="$store.getters.loggedIn && $route.name != 'Paycheck'">
        <navbar></navbar>
    </section>
    <main :class="mainClass">
        <router-view></router-view>
    </main>
    <section v-if="$store.getters.loggedIn" class="fixed bottom-10 right-10 flex flex-col">
        <chat-app ref="chat"></chat-app>
    </section>
</template>
<script>
import ChatApp from './components/ChatApp'
import Navbar from './components/Navbar'

export default {
    data: () => {
        return {
            theme: 'light'
        }
    },
    components: {
        ChatApp,
        Navbar
    },
    methods: {
        notify (body, header = '', urgency = 0) {
            this.$refs.chat.$refs.nc.notify(body, header, urgency)
        }
    },
    computed: {
        mainClass () {
            let classes = ''
            if (!this.$store.getters.loggedIn) {
                classes += 'h-full'
            } else {
                classes += 'lg:pl-20 lg:pr-2'
            }
            if (this.theme === 'dark') classes += ' bg-gray-900'
            return classes
        }
    },
    mounted () {
        // counter = 100
    }
}
</script>
<style>
    .text-2xs{
        font-size: .75rem;
        line-height: 1.25;
    }
    /* body {font-size: 16px} */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* div::-webkit-scrollbar{
        width: 15px !important
    } */
    /* width */
    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        border-radius: 3px;
        opacity: .1;
        transition: all .3s;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: rgb(250, 250, 250);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(165, 180, 252);
        background: rgb(131, 131, 131);
        border-radius: 3px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(153, 170, 253);
        opacity: 1
    }
</style>
