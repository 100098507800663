<template lang="">
    <div class="notification-center">
        <div class="notifications-center">
            <div class="telegram-notifications-content"></div>
            <div class="callback-notifications-content">
                <div v-for="notification, index in notifications"
                    class="notification-single drop-shadow-lg border"
                    :key="index">
                    <div class="notification-close-btn" @click="cancelNotification(index)">x</div>
                    <div class="notification-header">{{ notification.header }}</div>
                    <div class="notification-content">{{ notification.body }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            polling: null,
            notifications: [],
            hasUnread: false
        }
    },
    methods: {
        notify (body, header = '', emergency = 0) {
            const notification = { body, header, emergency }
            this.notifications.push(notification)
        },
        cancelNotification (_index) {
            this.notifications.splice(_index, 1)
        },
        getNotifications () {

        },
        pollData () {
            this.polling = setInterval(() => {
                // this.notify('welll!', 'Hello')
                // this.$store.dispatch('RETRIEVE_DATA_FROM_BACKEND')
            }, 3000)
        }
    },
    computed: {
        urgencyClass: function (urgency) {
            return 'urgency-' + urgency
        }
    },
    beforeUnmount () {
        clearInterval(this.polling)
    },
    created () {
        this.pollData()
    },
    mounted () {
        // this.notify('Цент уведомлений подключился', 'Ура!')
    }
}
</script>
<style scoped>
    .notification-center{
    }
    .callback-notifications-content{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        max-height: 450px;
        overflow-y: auto;
    }
    .notification-single{
        position: relative;
        /* box-shadow: 2px 2px 8px #aaa; */
        border-radius: 6px;
        width: 350px;
        padding: 6px;
        background-color: white;
        margin-top: 12px;
    }
    .urgency-0{
        background-color: white;
    }
    .urgency-1{
        background-color: rgb(179, 255, 199);
    }
    .notification-close-btn{
        position: absolute;
        top: 0;
        right: 0;
        height: 28px;
        width: 28px;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
        border-radius: 0 6px 0 6px;
        background-color: #eee;
    }
    .notification-close-btn:hover{
        background-color: #aaa;
    }
    .notification-header{
        font-size: 18px;
        font-weight: 700;
        border-bottom: 1px solid #eee;
        padding: 6px
    }
    .notification-content{
        padding: 12px 6px
    }
</style>
