<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">Создать ингредиент</h1>
                    <router-link :to="{ name: 'Ingredients' }">&larr; назад</router-link>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md">
                        <div class="flex flex-row p-4">
                            <div class="w-5/12 flex flex-col">
                                <label class="px-2">Наименование</label>
                                <input v-model="item.name" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-5/12 flex flex-col">
                                <label class="px-2">Единица</label>
                                <select v-model="item.unit" class="p-2 border outline-none">
                                    <option value="шт" selected>шт</option>
                                    <option value="кг">кг</option>
                                    <option value="л">л</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-row pb-6">
                            <div class="w-1/2 flex flex-col items-end">
                                <button @click="storeItem()" class="py-3 px-6 outline-none rounded-md bg-green-100 hover:bg-green-300">Сохранить</button>
                            </div>
                        </div>
                    </div>
                </section>
           </section>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                name: '',
                unit: 'шт'
            },
            ingredients: []
            // showEditItemDialog: false,
        }
    },
    methods: {
        storeItem () {
            this.postServerData('ingredient_store', { data: this.item })
                .then((res) => {
                    if (res.data?.code == 200) {
                        this.$router.push({ name: 'IngredientsEdit', params: { id: res.data.item_id } })
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
    },
    watch: {
        // $route(to, from) {
        //     console.log(this.$route)
        //     console.log(this.$route.query)
        //     console.log(this.$route.hash)
        // }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
