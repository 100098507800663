<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 min-h-4">{{ item.title || '...' }}</h1>
                    <router-link :to="{ name: 'Promocodes' }">&larr; назад</router-link>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md">
                        <div class="flex flex-row p-4">
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Заголовок</label>
                                <input v-model="item.title" @change="updateItemInfo('title', item.title)" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Slug</label>
                                <input v-model="item.slug" @change="updateItemInfo('slug', item.slug)" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Количество</label>
                                <input v-model="item.quantity" @change="updateItemInfo('quantity', item.quantity)" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Активно до</label>
                                <input v-model="item.expires_at" @change="updateItemInfo('expires_at', item.expires_at)"  class="p-2 border outline-none" type="datetime-local"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Тип</label>
                                <div class="w-full flex">
                                    <select v-model="promo.type" @change="updatePromoCodeType()" class="w-1/2 p-2 border outline-none">
                                        <option value="percent" selected>Процент</option>
                                        <option value="fixed">Фиксировано</option>
                                        <option value="meal">Блюдо</option>
                                    </select>
                                    <div v-if="promo.type != 'meal'" class="w-1/2">
                                        <input v-model="promo.value" @change="updatePromoCodeType()" class="p-2 border outline-none" type="text"/>
                                    </div>
                                </div>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Минимальная сумма</label>
                                <input v-model="item.min_sum" @change="updateItemInfo('min_sum', item.min_sum)" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4">
                            <div class="w-1/2 flex flex-col">
                                <label class="px-2">Описание</label>
                                <textarea v-model="item.body" @change="updateItemInfo('body', item.body)" class="p-2 border outline-none h-36"></textarea>
                            </div>
                        </div>
                    </div>
                </section>

                <section v-if="can('delete_promocodes')" class="flex flex-col pb-6">
                    <div class="w-1/2 flex flex-col items-end">
                        <button @click="deleteItem()" class="py-3 px-6 outline-none rounded-md bg-red-100 hover:bg-red-300">Удалить</button>
                    </div>
                </section>
            </section>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                id: 0
            },
            promo: {
                type: 'percent',
                value: 0
            }
        }
    },
    methods: {
        getItemById () {
           this.getServerData('promocodes_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data

                    this.promo.type = this.item.type.split(':')[0]
                    this.promo.value = this.item.type.split(':')[1]
                })
                .catch((error) => console.log(error))
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('promocodes_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {

                })
                .catch((error) => console.log(error))
        },
        updatePromoCodeType () {
            const promo_type = this.promo.type
            const promo_value = this.promo.value

            const item_type = promo_type + ':' + promo_value
            this.updateItemInfo('type', item_type)
        },
        deleteItem () {
            this.postServerData('promocodes_delete', { data: { item_id: this.item.id } })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.$router.push({ name: 'Promocodes' })
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getItemById()
    }
}
</script>
<style lang="">

</style>
