<template lang="">
    <button class="min-w-fit outline-none rounded-full shadow-xs text-base flex justify-center items-center group transition-all relative overflow-hidden select-none"
        :class="[style, iconOnly ? '' : '' ]"
        :disabled="disabled">
        <div v-if="pressconfirm" class="absolute top-0 left-0 h-full w-full flex justify-start z-0">
            <div class="h-full w-1/2 bg-red-400" :style="{'width': (pressTime / pressTimeLimit * 100)+'%'}"></div>
        </div>
        <div class="flex flex-col relative z-100 h-full w-full justify-center items-center py-2 px-2 lg:py-2"
            :class="[ iconOnly ? '' : 'pr-4 lg:px-4']">
            <div class="flex flex-row items-center">
                <img class="w-4 h-4 rotate-0 m-1 lg:m-0 group-hover:rotate-180 transition-all drop-shadow-md select-none"
                    :src="icon"
                    :class="[ iconOnly ? '' : 'lg:mr-2']"
                />
                <slot class="" v-if="!iconOnly"></slot>
            </div>
        </div>
    </button>
</template>
<script>
export default {
    name: 'default-button',
    data () {
        return {
            pressTimer: null,
            pressTime: 0,
            pressInterval: null,
            pressTimeLimit: 50
        }
    },
    props: {
        type: {
            type: String,
            default: 'default'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        pressconfirm: {
            type: Boolean,
            default: false
        },
        iconOnly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['confirm'],
    methods: {
        startTimer (e) {
            if (e.type === 'click' && e.button !== 0) {
                return
            }

            // if (this.pressTimer === null) {
            //     this.pressTimer = setTimeout(() => {
            //         this.handler(e)
            //     }, 1000)
            // }
            if (this.pressInterval === null) {
                this.pressInterval = setInterval(() => {
                    if (this.pressTime < this.pressTimeLimit) {
                        this.pressTime++
                    } else {
                        this.handler(e)
                    }
                }, 10)
            }
        },
        cancelTimer () {
            // if (this.pressTimer !== null) {
            //     clearTimeout(this.pressTimer)
            //     this.pressTimer = null
            // }
            if (this.pressInterval !== null) {
                clearInterval(this.pressInterval)
                this.pressInterval = null
                this.pressTime = 0
            }
        },
        handler (e) {
            this.$emit('confirm')
        }
    },
    computed: {
        style () {
            if (this.disabled) return 'bg-gray-500 text-gray-100 opacity-70'
            switch (this.type) {
                case 'delete':
                    return 'bg-red-200 hover:bg-rose-300 active:bg-rose-500 text-gray-700 drow-shadow-md border-rose-500 hover:border-rose-600'
                case 'cancel':
                    return 'bg-gray-50 hover:bg-gray-100 active:bg-gray-200 text-indigo-500 drow-shadow-md'
                case 'confirm':
                    return 'bg-lime-300 hover:bg-lime-400 active:bg-lime-600 text-indigo-700 drow-shadow-md border-green-500 hover:border-lime-600'
                default:
                    return 'bg-yellow-100 hover:bg-yellow-200 active:bg-yellow-300 text-yellow-700'
            }
        },
        icon () {
            if (this.disabled) return 'https://img.icons8.com/dotty/256/add.png'
            switch (this.type) {
                case 'delete':
                    return 'https://img.icons8.com/?size=256&id=NLT5nBYxPRCV&format=png'
                case 'cancel':
                    return 'https://img.icons8.com/?size=256&id=NLT5nBYxPRCV&format=png'
                case 'confirm':
                    return 'https://img.icons8.com/?size=256&id=FCFeqPfNH4BT&format=png'
                default:
                    return 'https://img.icons8.com/dotty/256/add.png'
            }
        }
    },
    mounted () {
        this.$el.addEventListener('mousedown', this.startTimer)
        this.$el.addEventListener('touchstart', this.startTimer)
        this.$el.addEventListener('click', this.cancelTimer)
        this.$el.addEventListener('mouseout', this.cancelTimer)
        this.$el.addEventListener('touchend', this.cancelTimer)
        this.$el.addEventListener('touchcancel', this.cancelTimer)
    },
    unmounted () {
        this.$el.removeEventListener('mousedown', this.cancelTimer)
        this.$el.removeEventListener('touchstart', this.cancelTimer)
        this.$el.removeEventListener('click', this.cancelTimer)
        this.$el.removeEventListener('mouseout', this.cancelTimer)
        this.$el.removeEventListener('touchend', this.cancelTimer)
        this.$el.removeEventListener('touchcancel', this.cancelTimer)
    }
}
</script>
<style scoped>
    button {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
        -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
        outline: none !important;
    }
    image {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
        -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
        outline: none !important;
    }
</style>
