<template lang="">
    <div class="flex flex-row flex-wrap w-full items-center">
        <div class="w-1/4">
            <input :value="modelValue" ref="inpt" @input="updateInput" type="number" :min="min" :max="max" class="input outline-none" />
        </div>
        <div class="flex flex-row inpt_btns w-3/4" v-if="!possibleValues">
            <div class="input_btn bg-gray-100" v-if="modelValue != min" @click="setValue(parseInt(min))">min</div>    
            <div class="input_btn bg-gray-100" v-if="max != null && max != 0 && modelValue != max" @click="setValue(max)">max</div>
            <div class="clear_btn bg-gray-100" v-if="modelValue > 0" @click="setValue(0)">x</div>
        </div>
        <div class="flex flex-row inpt_btns w-3/4 justify-center" v-else>
            <div v-for="possibleValue in possibleValues" @click="setValue(parseInt(possibleValue.value))" class="input_btn bg-gray-100" :key="possibleValue.title">{{possibleValue.title}}</div>
            <div class="clear_btn bg-gray-100" v-if="modelValue > 0" @click="setValue(0)">x</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'my-numeric-input',
    props: {
        modelValue: [Number],
        possibleValues: [Array],
        min: 0,
        max: null,
        emits: ['update:modelValue']
    },
    methods: {
        updateInput(event) {
            let value = parseInt(event.target.value);
            if(this.max && value > this.max ) value = this.max
            //if( value.length > 1 && value[0] == 0 ){ value = value.substr(1) }
            if(!parseInt(event.target.value) || !event.target.value){ value = 0 }
            this.$emit('update:modelValue', value)
        },
        setValue(value){
            this.$emit('update:modelValue', value)
            this.$emit('change')
        }
    },
    computed: {
        // formattedValue: {
        //     get() {
        //         return this.modelValue;
        //     },
        //     set(newValue) {
        //         console.log(newValue)
        //         if (newValue.length > 2) {
        //             newValue = newValue.replace(".", "");
        //             this.modelValue =
        //             newValue.substr(0, newValue.length - 2) +
        //             "." +
        //             newValue.substr(newValue.length - 2);
        //         } else {
        //             this.modelValue = newValue;
        //         }
        //     }
        // }
    }
}
</script>
<style scoped>
    .input{
        /* min-width: 156px */
        padding-left: 0.5rem;
        width: 90%;
        border-bottom: 1px solid #aaa
    }
    .input:focus {
        border-bottom: 1px solid #333
    }
    .inpt_btns div:first-child{
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
    }
    .inpt_btns div:last-child{
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
    }
    .input_btn {
        /* height: 24px;  */
        padding: 2px 10px;
        line-height: 24px;
        /* font-weight: bold; */
        font-size: 18px;
        text-align: center;
        cursor: pointer;
        color: rgb(50, 50, 50)
    }
    .input_btn:hover {
        background: #333;
        color: white
    }
    .clear_btn {
        padding: 2px 10px;
        line-height: 24px;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
        background: rgb(243, 244, 246);
        /* border-radius: 14px */
    }
    .clear_btn:hover {
        background: rgb(140, 15, 15);
        color: white;
    }
</style>