<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="w-full lg:container lg:mx-auto bg-white">
            <section>
                <div class="pt-2 pb-1 border-b-4 border-yellow-300">
                    <div class="text-gray-800 font-semibold flex items-center justify-start">
                        <router-link 
                            to='/funds'>   
                            <div class="text-lg lg:text-2xl pt-2 pb-2 px-4">Касса</div>
                        </router-link>
                        <div class="w-full overflow-x-auto flex items-center">
                            <router-link 
                                to='/funds/incomes'
                                class="px-6 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 whitespace-nowrap"
                                active-class="bg-yellow-300">
                                Дотации в кассу
                            </router-link>
                            <router-link 
                                    to='/funds/expenses'
                                    class="px-6 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 whitespace-nowrap"
                                    active-class="bg-yellow-300">
                                    Расходы из кассы
                            </router-link>
                        </div>
                    </div>
                </div>
            </section>
       </div>
       <router-view></router-view>
    </div>
</template>
<script>
export default {
    data: () => {
        return{
        }
    },
    methods: {
    },
    watch: {
    },
    mounted(){
    },
    components: {
    }
}
</script>
<style scoped>
</style>