<template lang="">
    <!-- mobile -->
    <div class="w-full flex lg:hidden border-b-2 p-2 border-indigo-100">
        <div class="flex w-full justify-around items-center">
            <div class="flex w-full justify-start items-center" @click="collapsed = true">
                <img src='https://static.breaktime.kz/uploads/images/breaktime/playstore.png' class="w-8 rounded-md" />
            </div>
            <div class="flex w-full justify-center items-center">
                <div class="whitespace-nowrap overflow-hidden text-xl font-semibold">Breaktime</div>
            </div>
            <div class="flex w-full justify-end items-center">

            </div>
        </div>
    </div>
    <div class="fixed left-0 top-0 z-[100] overflow-hidden transition-all h-full"
        :class="[collapsed ? 'w-48' : 'w-0']">
        <div class="h-full w-full bg-gray-100 group-hover/main-menu:bg-white flex flex-col items-start justify-between shadow-lg">
            <div class="w-full flex justify-between items-center overflow-hidden p-4 border-b-4 border-indigo-100">
                <div class="flex w-full justify-start items-center">
                    <img src='https://static.breaktime.kz/uploads/images/breaktime/playstore.png' class="w-8 rounded-md"  @click="collapsed = false"/>
                    <div class="pl-4 whitespace-nowrap overflow-hidden text-md font-semibold">Breaktime</div>
                </div>
                <div class="p-1 w-6 h-6 rounded-full bg-indigo-100 flex items-center justify-center">
                    <img src="https://img.icons8.com/nolan/256/1A6DFF/C822FF/delete-sign.png" class="w-full" @click="collapsed = false"/>
                </div>
            </div>
            <div class="h-full w-full">

                <router-link
                    @click="collapsed = false"
                    class="w-full hover:bg-gray-50 hover:font-semibold hover:border-r-4 flex justify-start items-center"
                    to="/"
                    exact
                    active-class="border-r-4 border-indigo-300 bg-gray-200 group-hover/main-menu:bg-gray-100 text-indigo-900">
                    <img src='https://static.breaktime.kz/uploads/images/breaktime/icons/dash-64-bicolor.png' class="rounded-md py-2 px-4 box-content" style="width: 28px" />
                    <div class="w-full pl-2 whitespace-nowrap overflow-hidden text-md">Сводка</div>
                </router-link>

                <div v-for="nav, index in navMenu" class="w-full" :key="index">
                    <router-link
                        @click="collapsed = false"
                        class="w-full hover:bg-gray-50 hover:font-semibold hover:border-r-4 flex justify-start items-center"
                        :to="nav.to"
                        active-class="border-r-4 border-indigo-300 bg-gray-200 group-hover/main-menu:bg-gray-100 text-indigo-900">
                        <img :src='nav.icon' class="rounded-md py-2 px-4 box-content" style="width: 28px" />
                        <div class=" w-full pl-2 whitespace-nowrap overflow-hidden transition-all text-md">{{ nav.title }}</div>
                    </router-link>
                </div>

                <div v-if="user?.firm?.plan.id && this.user?.firm?.plan.id == 4" class="p-2 overflow-hidden">
                    В демо версии доступна только основная сводка
                </div>
            </div>

            <!-- User account -->
            <div class="w-full">
                <div class="w-full hover:bg-gray-50 hover:font-semibold hover:border-r-4 flex justify-start items-center" >
                    <img
                    :src="user?.avatar?.url !== undefined ? user.avatar.url : `https://img.icons8.com/nolan/256/1A6DFF/C822FF/user-male-circle.png`"
                    class="rounded-md py-2 px-4 box-content"
                    style="width: 32px" />
                    <div class=" w-full pl-2 whitespace-nowrap overflow-hidden transition-all flex justify-between items-center">
                        <div class="w-full">
                            <router-link class="hover:font-semibold" to="/my_account">
                                {{ user?.first_name }}
                            </router-link>
                        </div>
                        <!-- <div><router-link class="hover:font-semibold" to="/settings">Настройки</router-link></div> -->
                    </div>
                </div>
                <div class="w-full flex justify-center items-center border-t-4 border-indigo-100 p-1">
                    <button @click.prevent="logout" class="cursor-pointer py-1 px-4 rounded-full flex items-center justify-center hover:bg-rose-700 hover:text-white active:bg-rose-300  text-md">
                        <div class="">&larr;</div>
                        <div class="ml-2 ">Выйти</div>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- desktop -->
    <div class="fixed left-0 top-0 z-[100] group/main-menu w-16 hover:w-48 transition-all h-full hidden lg:flex">
        <div class="h-full w-full bg-gray-100 group-hover/main-menu:bg-white flex flex-col items-start justify-between shadow-lg">
            <div class="w-full flex justify-start overflow-hidden p-4 border-b-4 border-stone-700">
                <div class="flex w-full justify-start items-center">
                    <img src='https://static.breaktime.kz/uploads/images/breaktime/playstore.png' class="w-8 rounded-md"  @click="collapsed = false"/>
                    <div class="hidden opacity-0 pl-4 group-hover/main-menu:flex group-hover/main-menu:opacity-100 whitespace-nowrap overflow-hidden transition-all text-md font-semibold">Breaktime</div>
                </div>
            </div>
            <div class="h-full w-full">

                <router-link
                    class="w-full hover:bg-stone-50 hover:font-semibold hover:border-r-4 flex justify-start items-center"
                    to="/"
                    exact
                    active-class="border-r-4 border-lime-300 bg-stone-50 group-hover/main-menu:bg-stone-100 text-stone-900 font-semibold">
                    <!-- <img src='https://img.icons8.com/nolan/256/combo-chart.png' class="rounded-md py-2 px-4 box-content" style="width: 28px" /> -->
                    <img src='https://static.breaktime.kz/uploads/images/breaktime/icons/dash-64-bicolor.png' class="rounded-md py-2 px-4 box-content" style="width: 28px" />
                    <div class="hidden opacity-0 w-full pl-2 group-hover/main-menu:flex group-hover/main-menu:opacity-100 whitespace-nowrap overflow-hidden transition-all text-md">Сводка</div>
                </router-link>

                <div v-for="nav, index in navMenu" class="w-full" :key="index">
                    <router-link
                        v-if="nav.is_active != false"
                        class="w-full hover:bg-stone-50 hover:font-semibold hover:border-r-4 flex justify-start items-center"
                        :to="nav.to"
                        active-class="border-r-4 border-lime-300 bg-stone-50 group-hover/main-menu:bg-stone-100 text-stone-900 font-semibold">
                        <img :src='nav.icon' class="rounded-md py-2 px-4 box-content" style="width: 28px" />
                        <div class="hidden opacity-0 w-full pl-2 group-hover/main-menu:flex group-hover/main-menu:opacity-100 whitespace-nowrap overflow-hidden transition-all text-md">{{ nav.title }}</div>
                    </router-link>

                    <div
                        v-else
                        class="w-full hover:bg-stone-50 hover:font-semibold hover:border-r-4 flex justify-start items-center"
                        active-class="border-r-4 border-lime-300 bg-stone-50 group-hover/main-menu:bg-stone-100 text-stone-900 font-semibold">
                        <img :src='nav.icon' class="rounded-md py-2 px-4 box-content" style="width: 28px" />
                        <div class="hidden opacity-0 w-full pl-2 group-hover/main-menu:flex group-hover/main-menu:opacity-100 whitespace-nowrap overflow-hidden transition-all text-md">
                            <div class>
                                <div class="text-md">{{ nav.title }}</div>
                                <div class="text-xs text-gray-500">не доступно в демо</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- User account -->
            <div class="w-full">
                <div class="border-t-4 border-stone-300"></div>
                <router-link class="w-full hover:bg-stone-50 hover:font-semibold hover:border-r-4 flex justify-start items-center border-lime-300"
                        to="/firm/staff/">
                        <img
                            src="https://static.breaktime.kz/uploads/images/breaktime/icons/settings-64-bicolor.png"
                            class="rounded-full py-2 px-4 box-content aspect-square"
                            style="width: 32px" />
                        <div class="hidden opacity-0 w-full pl-2 group-hover/main-menu:flex group-hover/main-menu:opacity-100 whitespace-nowrap overflow-hidden transition-all flex justify-between items-center">
                            <div class="w-full">
                                Аккаунт
                            </div>
                            <!-- <div><router-link class="hover:font-semibold" to="/settings">Настройки</router-link></div> -->
                        </div>
                </router-link>
                <div class="border-t-4 border-stone-300"></div>
                <button @click.prevent="logout" class="w-full hover:bg-yellow-100 hover:border-r-4 hover:font-semibold flex justify-start items-center border-yellow-300 py-1">
                    <img
                    src="https://static.breaktime.kz/uploads/images/breaktime/icons/logout-64-bicolor.png"
                    class="rounded-full py-2 px-4 box-content aspect-square"
                    style="width: 32px" />
                    <div class="hidden opacity-0 w-full pl-2 group-hover/main-menu:flex group-hover/main-menu:opacity-100 whitespace-nowrap overflow-hidden transition-all flex justify-between items-center">Выйти</div>
                </button>
                <div class="border-t-4 border-stone-700"></div>
            </div>
        </div>
    </div>
</template>
<script>
// import GuardedLink from './GuardedLink';
export default {
    name: 'navbar',
    data: () => {
        return {
            user: undefined,
            collapsed: false
        }
    },
    methods: {
        logout () {
            this.$store.dispatch('logoutUser')
        },
        can (permission_name) {
            return this.user?.account?.role?.permissions_list?.includes(permission_name) || this.user?.account?.role?.permissions_list?.includes('debug')
        },
        canhave: function (permission_name) {
            return this.user?.firm_plan_features?.includes(permission_name)
        }
    },
    computed: {
        navMenu () {
            const navMenu = []

            // + Сводка
            // + Заказы
            // + Кухня только очередь
            // + Меню   -> блюда комбо категории
            // + Склад  -> ингредиенты пополнения+списание закуп консигнации
            // + Касса  -> дотации расходы
            // + Отчеты -> дневной неделя месяц
            // + Фирма  -> филиал сотрудники зарплаты

            // Адреса -> улицы+дома адреса доставки
            // PR -> Новости Акции Промокоды
            // Клиенты телефоны

            // Аккаунт выход

            if (!this.has('firm')) {
                navMenu.push({
                    to: { name: 'FirmsCreate' },
                    title: '+ Фирма',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/firm-64-bicolor.png'
                })

                return navMenu
            }

            if (!this.has('branch')) {
                navMenu.push({
                    to: { name: 'MyFirm' },
                    title: 'Фирма',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/firm-64-bicolor.png'
                })
                navMenu.push({
                    to: { name: 'BranchesCreate' },
                    title: '+ Филиал',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/firm-64-bicolor.png'
                })

                return navMenu
            }

            if (this.can('see_orders')) {
                navMenu.push({
                    to: { name: 'ActiveOrders' },
                    title: 'Заказы',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/orders-64-bicolor.png'
                })
            }

                navMenu.push({
                    to: '/kitchen_progress',
                    title: 'Кухня',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/kitchen-64-bicolor.png'
                })
                navMenu.push({
                    to: '/menu',
                    title: 'Меню',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/clipboard-menu-64-bicolor.png'
                })

            if (this.can('see_warehouse')) { // canhave('report_ingredients_consumption') && can('see_warehouse')
                navMenu.push({
                    to: '/warehouse',
                    title: 'Склад',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/warehouse-2-64-bicolor.png'
                })
            }

                // касса
                navMenu.push({
                    to: '/funds',
                    title: 'Касса',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/wallet-64-bicolor.png'
                })

            if (this.can('see_reports')) {
                navMenu.push({
                    to: { name: 'Reports' },
                    title: 'Отчеты',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/stats-3-64-bicolor.png'
                })
                navMenu.push({
                    to: { name: 'Customers' },
                    title: 'Клиенты',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/customers-64-bicolor.png'
                    // icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/user-64-bicolor.png'
                })
                navMenu.push({
                    to: { name: 'PublicRelations' },
                    title: 'PR',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/feedback-64-bicolor.png'
                    // icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/user-64-bicolor.png'
                })
            }
            if (this.can('see_my_firm')) {
                navMenu.push({
                    to: { name: 'MyFirm' },
                    title: 'Фирма',
                    icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/firm-64-bicolor.png'
                })
            }

            navMenu.push({
                to: { name: 'Addresses' },
                title: 'Адреса',
                icon: 'https://static.breaktime.kz/uploads/images/breaktime/icons/delivery-location-64-bicolor.png'
            })

            // if(this.can('debug')){
            //     navMenu.push({
            //         title: "Debug",
            //         icon: "https://img.icons8.com/nolan/256/1A6DFF/C822FF/graph-report.png",
            //         subs:[
            //             {
            //                 to: '/roles',
            //                 title: "Расходы",
            //                 icon: "https://img.icons8.com/nolan/256/1A6DFF/C822FF/graph-report.png"
            //             },
            //             {
            //                 to: '/permissions',
            //                 title: "Расходы",
            //                 icon: "https://img.icons8.com/nolan/256/1A6DFF/C822FF/graph-report.png"
            //             },
            //         ]
            //     });
            // }

            return navMenu
        }

    },
    mounted () {
        this.user = this.$store.state.user
    },
    watch: {
        '$store.state.user': function () {
            this.user = this.$store.state.user
        }
    }
}
</script>
<style scoped>
    .main-menu{
        position: relative;
    }
    .main-menu .sub-menu{
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        background-color: white;
        padding: 1rem;
        z-index: 1;
    }
    .main-menu .sub-menu-left{
        display: none;
        position: absolute;
        right: 0;
        top: 100%;
        background-color: white;
        padding: 1rem;
        z-index: 1;
    }
    .main-menu .sub-menu div,
    .main-menu .sub-menu-left div{
        padding: .25rem 0
    }
    .main-menu:hover .sub-menu,
    .main-menu:hover .sub-menu-left{
        display: block;
    }

</style>
