<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6 pl-4 md:pl-0">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">Редактировать {{ item.ingredient?.name }} {{ item.date || '' }}</h1>
                    <div class="flex w-full">
                        <router-link :to="{ name: 'StockChanges' }">&larr; весь список</router-link>
                    </div>
                </div>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Ингредиент</h3>
                    <div class="border rounded-md">
                        <!-- <div v-show="stock_changes && stock_changes.length > 0"> -->
                        <div class="hidden md:flex border-b p-2 w-full items-center font-semibold">
                            <div class="w-full flex items-center">
                                Наименование
                            </div>
                            <div class="w-full flex items-center">
                                Количество на складе
                            </div>
                        </div>
                        <div class="border-b flex w-full items-center flex-col">
                            <div class="w-full flex items-center p-2">
                                <div class="w-full">{{ item.ingredient?.name }}</div>
                                <div class="w-full">{{ item.ingredient?.in_stock }}</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Пополнение / списание</h3>
                    <div class="border rounded-md">
                        <div class="hidden md:flex border-b p-2 w-full items-center font-semibold">
                            <div class="w-3/12 flex items-center px-2">
                                Тип изменения
                            </div>
                            <div class="w-2/12 flex items-center px-2">
                                Количество
                            </div>
                            <div class="w-2/12 flex items-center px-2">
                                Цена
                            </div>
                            <div class="w-2/12 flex items-center px-2">
                                Поставщик
                            </div>
                            <div class="w-3/12 flex items-center px-2">
                                Сумма
                            </div>
                        </div>
                        <div class="w-full flex item-center p-2">
                            <div class="w-3/12">
                                <select class="py-2 px-2 w-full outline-none border-b" @change="updateStockChange('change_type', item.change_type)" v-model="item.change_type" >
                                    <option value="refill">Пополнение</option>
                                    <option value='writeoff'>Списание</option>
                                    <option value="correction">Корректировка</option>
                                </select>
                            </div>
                            <div class="w-2/12">
                                <input class="py-2 px-2 w-11/12 outline-none border-b" @change="updateStockChange('change_amount', item.change_amount)" v-model="item.change_amount" />
                            </div>
                            <div class="w-2/12">
                                <input class="py-2 px-2 w-11/12 outline-none border-b" @change="updateStockChange('change_price', item.change_price)" v-model="item.change_price" />
                            </div>
                            <div class="w-2/12">
                                <select class="py-2 px-2 w-11/12 outline-none border-b" @change="updateStockChange('supplier_id', item.supplier_id)" v-model="item.supplier_id" >
                                    <option value="0" selected>Склад</option>
                                    <option value='1'>Поставщик</option>
                                </select>
                            </div>
                            <div class="w-3/12 flex justify-start items-center py-2 px-2">
                                {{ Math.round(item.change_amount * item.change_price) }}
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Оплата</h3>
                    <div class="border rounded-md">
                        <div class="hidden md:flex border-b p-2 w-full items-center font-semibold">
                            <div class="w-1/2 flex items-center px-2">
                                Оплачено
                            </div>
                            <div class="w-1/2 flex items-center px-2">
                                Источник
                            </div>
                        </div>
                        <div class="w-full flex item-center p-2">
                            <div class="w-1/2">
                                <input class="py-2 px-2 w-11/12 outline-none border-b" @change="updateStockChange('payed_amount', item.payed_amount)" v-model="item.payed_amount" />
                            </div>
                            <div class="w-1/2">
                                <select class="py-2 px-2 w-11/12 outline-none border-b" @change="updateStockChange('payed_from', item.payed_from)" v-model="item.payed_from" >
                                    <option value="1" selected>Касса</option>
                                    <option value='2'>Депозит</option>
                                    <option value='0'>Другое</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Пользователь и Дата</h3>
                    <div class="border rounded-md flex items-center">
                        <div class="w-1/2 flex flex-wrap item-center p-2 pl-4">
                            <div class="w-full">
                                {{ item.user?.first_name }}
                            </div>
                        </div>
                        <div class="w-1/2 flex items-center p-2">
                            <input class="w-11/12 py-2 px-2 w-8/12 outline-none border-b" @change="updateStockChange('date', item.date)" v-model="item.date" />
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4 items-end">
                    <button @click="removeStockChange(item.id)" class="py-2 px-4 flex justify-center items-center rounded-md bg-red-100 hover:bg-red-300 active:bg-red-500">удалить запись</button>
                </section>
           </section>
       </div>
    </div>
</template>
<script>
import axios from 'axios'
import { api_route } from '../../../config/api_routes'

export default {
    data: () => {
        return {
            item: {
                id: 0
            }
            // showEditItemDialog: false,
        }
    },
    methods: {
        getStockChange () {
            this.getServerData('stock_change_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data
                })
                .catch((error) => {})
        },
        updateStockChange (info_index, new_value) {
            this.postServerData('ingredient_stock_change_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {})
                .catch((error) => {})
        },
        removeStockChange (change_id) {
            if (confirm('Удалить запись со склада?')) {
                this.getServerData('order_items_remove_stock_changes_by_id', { change_id })
                    .then((res) => {
                        if (res.data.code == 200) {
                            this.$router.push({ name: 'StockChanges' })
                        }
                    })
                    .catch((error) => {})
            }
        }
    },
    mounted () {
        this.item.id = this.$route.params.id

        this.getStockChange()
    },
    watch: {
        $route (to, from) {
        }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
