export default {
    data: () => {
        return {
        }
    },
    methods: {
        has: function (trait) {
            return this.$store.getters.currentUser?.account[trait] || false
        },
        can: function (permissionName) {
            if (this.$store.getters.currentUser?.account?.role?.permissions_list.includes('debug')) return true
            return this.$store.getters.currentUser?.account?.role?.permissions_list?.includes(permissionName)
        },
        canhave: function (permissionName) {
            return this.firm_plan_features?.includes(permissionName) || this.permissions?.includes('debug')
        }
    }
}
