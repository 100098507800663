<template lang="">
    <div class="text-gray-800 font-semibold pt-8 pb-4 pl-2 flex items-center justify-between">
        <!-- <h1 class="text-2xl ">Список заказов</h1> -->
        <div class="flex flex-col md:flex-row w-full justify-between text-xs md:text-base">
            <div class="flex w-5/12 md:w-5/12 md:flex-row justify-between items-center">
                <div class="flex items-center pr-2">
                    <img src="https://static.breaktime.kz/uploads/images/breaktime/icons/filter-64-bicolor.png" class="h-8 mr-2"/>
                    <select v-model="criteria" class="py-2 px-2 outline-none border-b bg-white" @change="getActiveOrdersList()">
                        <option value="pending">Ожидающие</option>
                        <option value="kitchen">На кухне</option>
                        <option value="delivery">На доставке</option>
                        <option value="delivered">Доставленные</option>
                        <option value="active">Активные заказы</option>
                        <option value="preorders">Предзаказы</option>
                        <option value="cancelled">Отмененные заказы</option>
                        <option value="declined">Отказавшиеся заказы</option>
                    </select>
                </div>
                <div class="flex items-center pr-2">
                    <select v-model="sort_by" class="py-2 px-2 outline-none border-b bg-white"  @change="getActiveOrdersList()">
                        <option value="created_at">По времени</option>
                        <option value="priority">По приоритету</option>
                        <option value="courier_id">По Курьерам</option>
                    </select>
                </div>
                <div class="flex items-center pr-2">
                    <select v-model="sort_dir" class="py-2 px-2 outline-none border-b bg-white"  @change="getActiveOrdersList()">
                        <option value="ASC">А-Я</option>
                        <option value="DESC">Я-А</option>
                    </select>
                </div>
            </div>
            <div class="flex w-6/12 md:w-6/12 justify-between">
                <div class="flex items-center pr-2">
                    <img src="https://static.breaktime.kz/uploads/images/breaktime/icons/notification-bell-64-bicolor.png" class="h-8 mr-2" @click="playSound"/>
                    <select class="py-2 px-2 outline-none border-b bg-white" v-model="sound">
                        <option value="notification-light">Звук тихий</option>
                        <option value="notification-medium">Звук средний</option>
                        <option value="notification-loud">Звук громкий</option>
                        <option value="off">Звук отключен</option>
                    </select>
                </div>
                <div class="flex items-center">
                    <img src="https://static.breaktime.kz/uploads/images/breaktime/icons/stopwatch-64-bicolor.png" class="h-8 mr-2"/>
                    <div class="pr-2">
                        Автообновление:
                    </div>
                    <select v-model="update_interval" class="py-2 outline-none border-b bg-white">
                        <option value="5000">5 секунд</option>
                        <option value="10000">10 секунд</option>
                        <option value="30000">30 секунд</option>
                        <option value="60000">60 секунд</option>
                        <option value="300000">5 минут</option>
                        <option value="1800000">30 минут</option>
                        <option value="3600000">60 минут</option>
                    </select>
                </div>
                <div class="h-8 w-8 bg-gray-100 mr-2 p-2 rounded-full flex items-center justify-center cursor-pointer hover:bg-yellow-300"
                    @click="getActiveOrdersList()">
                    <img src="https://cdn-icons-png.flaticon.com/512/61/61444.png" :class="[ is_loading_orders ? 'animate-spin' : 'animate-none']" class="w-full" />
                </div>
            </div>
        </div>
    </div>
    <div v-if="orders.length > 0" class="table w-full rounded-lg border-2 border-gray-100  overflow-x-auto">
        <div class="orders_list w-full">
            <div class="w-full flex flex-row bg-gray-100 text-sm">
                <div class="px-2 md:w-8"></div>
                <div class="w-2/12 md:w-1/12 flex justify-center items-center">ID</div>
                <div class="w-4/12 md:w-1/12 p-2 flex justify-center items-center">Время</div>
                <div class="w-4/12 md:w-4/12 p-2">Клиент & Адрес</div>
                <div class="hidden md:flex w-4/12 p-2">Блюда</div>
                <div class="w-4/12 md:w-2/12 p-2 text-right">Суммы</div>
                <div class="hidden md:flex w-2/12 p-2 flex justify-center items-center">Курьер</div>
                <div class="hidden md:flex w-5/12 p-2"></div>
            </div>
            <div v-for="order, index in orders" :key="order.id" class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed"
                :class="[ order.status.id == 2 ? 'bg-yellow-100' : '']"
                >
                <div v-if="order.status?.id != 6" class="hidden md:flex flex-col justify-between items-stretch">
                    <div class="px-2 cursor-pointer hover:bg-yellow-300 h-1/2 flex items-end hastooltip" @click="updateOrderInfo( order.id, 'priority', order.priority + 1)">
                        <div class="wrapper">
                            <div class="text">повысить приоритет</div>
                        </div>
                        <img src="https://static.breaktime.kz/uploads/images/breaktime/icons/arrow-up-96-dark.png" class="w-8 rounded-full" />
                    </div>
                    <div class="flex justify-center">
                        {{ order.priority }}
                    </div>
                    <div class="px-2 cursor-pointer hover:bg-yellow-300 h-1/2 flex items-start hastooltip" @click="updateOrderInfo( order.id, 'priority', order.priority - 1)">
                        <div class="wrapper">
                            <div class="text">понизить приоритет</div>
                        </div>
                        <img src="https://static.breaktime.kz/uploads/images/breaktime/icons/arrow-down-96-dark.png" class="w-8 rounded-full" />
                    </div>
                </div>
                <div v-else class="hidden md:flex w-12"></div>
                <div class="w-2/12 md:w-1/12 flex flex-col justify-center items-center">
                    <div class="w-full flex flex-col justify-center items-center">
                        <div v-if="can('edit_orders')">
                            <router-link :to="`/orders/${order.id}/edit`">{{ order.id }}</router-link>
                        </div>
                        <div v-else>
                            {{ order.id }}
                        </div>
                        {{ order.status.emoji }}
                    </div>
                </div>
                <div class="w-2/12 md:w-1/12 flex flex-col justify-center items-center">
                    <div class="flex flex-col justify-center items-center">
                        <div v-if="order.is_preorder == 1" class="flex justify-center items-center">
                            <div @click="editOrderInfo(index, 'date')" class="cursor-pointer hover:bg-yellow-300 text-center px-1 rounded-md">
                                <div>{{ order.desired_delivery_at.split(' ')[1] }}</div>
                                <div class="text-xs" style="font-size: 10px">{{ order.desired_delivery_at.split(' ')[0] }}</div>
                            </div>
                        </div>
                        <div v-else>
                            <div @click="editOrderInfo(index, 'date')" class="cursor-pointer hover:bg-yellow-300 px-1 rounded-md">{{ order.times?.created_to_closed.formatted }}</div>
                        </div>
                    </div>
                    <div v-if="order.is_preorder != 1 && order.status.id != 6" class="flex items-center mt-1 rounded-md">
                        <div class="h-4 w-8 flex justify-center rounded-lg text-xs" :class="[ order.readiness_percent == 100 ? 'bg-green-300' : 'bg-yellow-300']">
                            {{ order.readiness_percent }}
                        </div>
                    </div>
                </div>
                <div class="w-4/12 md:w-4/12 p-2 flex flex-col justify-center items-start">
                    <div @click="editOrderInfo(index, 'customer')" class="cursor-pointer hover:bg-yellow-300 flex items-center px-1 rounded-md">
                        <div class="h-2 w-2 mr-1 rounded-full" :class="[ order.customer?.customer_id ? 'bg-green-100' : 'bg-yellow-100']"></div>
                        {{ order.customer?.formated_phone }}
                    </div>
                    <div @click="editOrderInfo(index, 'address')" class="cursor-pointer hover:bg-yellow-300 flex items-center px-1 rounded-md">
                        <div class="h-2 w-2 mr-1 rounded-full" :class="[ order.address?.address_id ? 'bg-green-100' : 'bg-yellow-100']"></div>
                        {{ order.address?.street_appartment }}
                        {{ order.address?.label }}
                        {{ order.address?.details }}
                    </div>
                </div>
                <div class="hidden md:flex md:flex-col w-4/12 py-2 cursor-pointer hover:bg-yellow-300" @click="editOrderInfo(index, 'items')">
                    <div v-for="item, index in order.items" :key="index" class="flex flex-row w-full hover:bg-gray-200 items-center justify-between">
                        <div class="flex items-center text-xs">
                            <div class="h-2 w-2 m-2 rounded-full" :class="[ item.ready_meals == item.amount ? 'bg-green-100' : 'bg-yellow-100']"></div>
                            {{ item.name }} {{ item.is_combo == 1 ? '🍱' : ''}} {{ item.is_bonus ? '🎁' : ''}}
                        </div>
                        <div class="w-2/6 flex justify-end items-center text-xs">
                            <div class="flex justify-end">
                                {{ item.ready_meals && item.ready_meals != item.amount ? '|' + item.ready_meals + '|' : '' }} {{ item.amount }}x
                            </div>
                            <div class="flex justify-start pr-2">{{ item.price }}</div>
                        </div>
                    </div>
                </div>
                <div class="w-4/12 md:w-2/12 p-2 flex flex-col justify-center items-end cursor-pointer hover:bg-yellow-300" @click="editOrderInfo(index, 'payment')">
                    <div :class="[order.extra_info?.is_payed == 1 ? 'text-green-900' : '']">Итого: {{ order.total_summ }}</div>
                    <div v-if="order.payment?.transfer > 0" class="text-xs">перевод: {{ order.payment?.transfer }}</div>
                    <div v-if="order.payment?.delivery_cost > 0" class="text-xs">доставка: {{ order.payment?.delivery_cost }}</div>
                    <div v-if="order.promocode" class="text-xs">{{ order.promocode.title }}</div>
                </div>
                <div class="hidden md:flex w-2/12 p-2 justify-center items-center  cursor-pointer hover:bg-yellow-300" @click="openAssignCourierDialog(order)">
                    <div v-if="order.courier">{{ order.courier.first_name }}</div>
                    <div v-else><i class="text-xs">Не назначен</i></div>
                </div>
                <!-- <div class="w-1/12 p-2 flex justify-center items-center text-sm">
                    <div style="font-size: 14px">📞</div>
                </div> -->
                <div class="hidden md:flex w-5/12 p-2 justify-start items-center">
                    <div class="flex actions-block">
                        <span v-if="order.status.id == 6 || order.status.id == 7" @click="updateOrderInfo( order.id, 'status', 2)" class="cursor-pointer py-1 px-4 bg-gray-100 hover:bg-yellow-300 text-center flex items-center">Вернуть</span>

                        <span v-if="order.status.id == 1" @click="updateOrderInfo( order.id, 'status', 2)" class="cursor-pointer py-3 px-6 bg-yellow-200 hover:bg-yellow-300 text-center flex items-center">В обработку</span>

                        <span v-if="order.status.id == 2" @click="updateOrderInfo( order.id, 'status', 3)" class="cursor-pointer py-3 px-6 bg-yellow-200 hover:bg-yellow-300 text-center hastooltip">
                            <div class="wrapper">
                                <div class="text">&rarr; кухне</div>
                            </div>
                            <img src="https://img.icons8.com/plasticine/344/kitchen.png" class="icon">
                        </span>

                        <span v-if="order.status.id == 3" @click="updateOrderInfo( order.id, 'status', 4)" class="cursor-pointer py-3 px-6 bg-yellow-200 hover:bg-yellow-300 text-center flex items-center hastooltip">
                            <div class="wrapper">
                                <div class="text">Отправить</div>
                            </div>
                            <div v-if="order.extra_info.is_kitchen_check == 1 && order.extra_info.is_customer_check == 1 && order.readiness_percent == 100">Отправить</div>
                            <div>🚀</div>
                        </span>

                        <span v-if="order.status.id == 4" @click="updateOrderInfo( order.id, 'status', 3)" class="cursor-pointer py-3 px-2 bg-gray-200 hover:bg-yellow-300 text-center"><img src="https://img.icons8.com/plasticine/344/kitchen.png" class="icon"></span>
                        <span v-if="order.status.id == 4" @click="updateOrderInfo( order.id, 'status', 5)" class="cursor-pointer py-3 px-2 bg-yellow-200 hover:bg-yellow-300 text-center flex items-center">Доставлен 🍳</span>
                        <span v-if="order.status.id == 4" @click="updateOrderInfo( order.id, 'status', 7)" class="cursor-pointer p-3 bg-gray-200 hover:bg-red-300 text-center hastooltip">
                            <div class="wrapper">
                                <div class="text">Отказались</div>
                            </div>
                            <img src="https://img.icons8.com/dotty/344/cancel.png" class="icon"></span>

                        <span v-if="order.status.id == 5" @click="updateOrderInfo( order.id, 'status', 3)" class="cursor-pointer py-3 px-6 bg-gray-100 hover:bg-yellow-300 text-center"><img src="https://img.icons8.com/plasticine/344/kitchen.png" class="icon"></span>
                        <span
                            v-if="order.status.id == 1 || order.status.id == 2 || order.status.id == 3"
                            @click="cancelOrder(order.id)"
                            class="cursor-pointer p-3 bg-red-100 hover:bg-red-300 text-center hastooltip">
                            <div class="wrapper">
                                <div class="text">Отменить</div>
                            </div>
                            <img src="https://img.icons8.com/dotty/344/cancel.png" class="icon">
                        </span>
                        <span v-if="order.extra_info.is_kitchen_check == 0 && order.status.id == 3" @click="showPaycheck('kitchen', order.id, !order.extra_info.is_kitchen_check)" class="cursor-pointer p-3 bg-gray-100 hover:bg-yellow-300  text-center hastooltip">
                            <div class="wrapper">
                                <div class="text">Чек для кухни</div>
                            </div>
                            <img src="https://img.icons8.com/plasticine/344/transaction-list.png" class="icon">
                        </span>
                        <span v-if="order.extra_info.is_customer_check == 0 && order.status.id == 3" @click="showPaycheck('customer', order.id, !order.extra_info.is_customer_check)" class="cursor-pointer p-3 bg-gray-100 hover:bg-yellow-300 text-center hastooltip">
                            <div class="wrapper">
                                <div class="text">Чек для клиента</div>
                            </div>
                            <img src="https://img.icons8.com/plasticine/344/check--v1.png" class="icon">
                        </span>
                        <span v-if="order.extra_info.is_payed == 0 && order.status.id != 6" @click="updateOrderInfo( order.id, 'extra_info.is_payed', 1)" class="cursor-pointer p-3 bg-gray-100 hover:bg-yellow-300 text-center hastooltip">
                            <div class="wrapper">
                                <div class="text">Оплачен</div>
                            </div>
                            <img src="https://img.icons8.com/plasticine/344/coins.png" class="icon">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="w-full flex items-center justify-center py-8">
        <div class="p-16 bg-gray-100 rounded-lg text-center flex items-center justify-center w-full">
            <img src="https://static.breaktime.kz/uploads/images/breaktime/icons/alert-icon-box-64-bicolor.png" class="h-8"/>
            <i class="text-2xl pl-4">Список пуст</i>
        </div>
    </div>
    <my-dialog v-model:show="showEditOrderAddressDialog">
        <template #header>Обновить информацию о заказе #{{ orders[selected_order].id || '...' }}</template>
        <template #default>
            <section v-if="orders[selected_order].status.id == 1">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 6">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 7">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <div v-else class="container">
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold">Адрес в системе {{ orders[selected_order].address?.address_id == -1 ? 'не найден' : orders[selected_order].address?.address_id}}</h3>
                    <i>Изменение приведет к потере связи с адресом в базе</i>
                    <div class="flex flex-row py-4" :class="[ orders[selected_order].address?.address_id > 0 ? 'text-green-500' : '' ]">
                        <div class="w-2/12 flex flex-col">
                            <label class="px-2">Страна</label>
                            <input :value="orders[selected_order].address.raw.country" disabled class="p-2 border-b"/>
                        </div>
                        <div class="w-2/12 flex flex-col">
                            <label class="px-2">Город</label>
                            <input v-model="orders[selected_order].address.raw.city" @change="updateOrderInfo(orders[selected_order].id, 'address.city', orders[selected_order].address.raw.city)" class="p-2 border-b outline-none"/>
                        </div>
                        <div class="w-2/12 flex flex-col">
                            <label class="px-2">Улица</label>
                            <input v-model="orders[selected_order].address.raw.street" @change="updateOrderInfo(orders[selected_order].id, 'address.street', orders[selected_order].address.raw.street)" class="p-2 border-b outline-none"/>
                        </div>
                        <div class="w-1/12 flex flex-col">
                            <label class="px-2">Дом</label>
                            <input v-model="orders[selected_order].address.raw.building" @change="updateOrderInfo(orders[selected_order].id, 'address.building', orders[selected_order].address.raw.building)" class="p-2 border-b outline-none"/>
                        </div>
                    </div>
                    <div>
                        <!-- <div v-if="!orders[selected_order].address.address_id || orders[selected_order].address.address_id == 0" class="w-3/12 flex flex-col"> -->
                        <div class="w-3/12 flex flex-col">
                            <div @click="encodeAddress" class="py-2 px-4 cursor-pointer hover:bg-yellow-300 rounded-md text-center">Найти адрес в базе</div>
                        </div>
                        <div v-if="orders[selected_order].address.address_id == -1" class="w-3/12 flex flex-col">
                            <div @click="storeAddress" class="py-2 px-4 cursor-pointer hover:bg-yellow-300 rounded-md text-center">Создать адрес</div>
                        </div>
                    </div>
                </section>
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold">Адрес клиента</h3>
                    <div class="flex flex-row py-4">
                        <div class="w-2/12 flex flex-col">
                            <label class="px-2">Квартира</label>
                            <input v-model="orders[selected_order].address.raw.appartment" @change="updateOrderInfo(orders[selected_order].id, 'address.appartment', orders[selected_order].address.raw.appartment)" class="p-2 border-b outline-none"/>
                        </div>
                        <div class="w-2/12 flex flex-col">
                            <label class="px-2">Подъезд</label>
                            <input v-model="orders[selected_order].address.raw.entrance" @change="updateOrderInfo(orders[selected_order].id, 'address.entrance', orders[selected_order].address.raw.entrance)" class="p-2 border-b outline-none"/>
                        </div>
                        <div class="w-2/12 flex flex-col">
                            <label class="px-2">Этаж</label>
                            <input v-model="orders[selected_order].address.raw.floor" @change="updateOrderInfo(orders[selected_order].id, 'address.floor', orders[selected_order].address.raw.floor)" class="p-2 border-b outline-none"/>
                        </div>
                        <div class="w-4/12 flex flex-col">
                            <label class="px-2">Домофон.</label>
                            <div class="w-full flex flex-row px-2">
                                <div class="w-1/6 flex flex-row justify-center">
                                    <input v-model="orders[selected_order].address.raw.has_intercom" @change="updateOrderInfo(orders[selected_order].id, 'address.has_intercom', orders[selected_order].address.raw.has_intercom)" type="radio" value="1" class="p-2 border-b"/>
                                </div>
                                <div class="w-5/6 flex flex-row text-sm">
                                    Работает
                                </div>
                            </div>
                            <div class="w-full flex flex-row px-2">
                                <div class="w-1/6 flex flex-row justify-center">
                                    <input v-model="orders[selected_order].address.raw.has_intercom" @change="updateOrderInfo(orders[selected_order].id, 'address.has_intercom', orders[selected_order].address.raw.has_intercom)" type="radio" value="0" class="p-2 border-b"/>
                                </div>
                                <div class="w-5/6 flex flex-row text-sm">
                                    По звонку
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row py-4">
                        <div class="w-4/12 flex flex-col">
                            <label class="px-2">Лейбл</label>
                            <input v-model="orders[selected_order].address.raw.label" @change="updateOrderInfo(orders[selected_order].id, 'address.label', orders[selected_order].address.raw.label)" class="p-2 border-b outline-none" placeholder="Дом/Работа/Временно/Тайное убежище"/>
                        </div>
                        <div class="w-8/12 flex flex-col">
                            <label class="px-2">Детали</label>
                            <input v-model="orders[selected_order].address.raw.details" @change="updateOrderInfo(orders[selected_order].id, 'address.details', orders[selected_order].address.raw.details)" class="p-2 border-b outline-none" placeholder="Вход с торца/Черная дверь/Злая собака"/>
                        </div>
                    </div>
                    <div v-if="orders[selected_order].customer.customer_id && orders[selected_order].address.address_id" class="flex flex-row py-4">
                        <div @click="updateCustomerAddress(orders[selected_order].customer.customer_id, orders[selected_order].address.address_id)" class="py-2 px-4 cursor-pointer hover:bg-yellow-300 rounded-md text-center">Обновить адрес клиента</div>
                    </div>
                </section>
            </div>
        </template>
    </my-dialog>
    <my-dialog v-model:show="showEditOrderItemsDialog">
        <template #header>Обновить информацию о заказе</template>
        <template #default>
            <section v-if="orders[selected_order].status.id == 1">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 6">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 7">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <div v-else class="container">
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold">Состав заказа</h3>
                    <div class="flex flex-row py-4">
                        <div class="w-full h-full">
                            <div class="w-full p-2 px-4 flex flex-row border-b border-dashed border-gray-100 text-sm">
                                <div class="w-3/6">Блюда в заказе</div>
                                <div class="w-1/6">Количество</div>
                                <div class="w-1/6">Цена</div>
                                <div class="w-1/6">Бонус</div>
                            </div>
                            <div v-for="item, index in orders[selected_order].items" :key="index">
                                <div class="w-full p-2 px-4 flex flex-row border-b border-dashed border-gray-100 hover:bg-gray-100">
                                    <div class="w-3/6">
                                        <details>
                                            <summary>{{ item.name }}</summary>
                                            <div class="p-2">
                                                <input v-model="orders[selected_order].items[index].details" @change="updateOrderInfo(orders[selected_order].id, 'item.details', item.id + ':' + orders[selected_order].items[index].details)" class="border-b px-2 outline-none"
                                                placeholder="детали блюда">
                                            </div>
                                        </details></div>
                                    <div class="w-1/6">
                                        <input v-model="orders[selected_order].items[index].amount" @change="updateOrderInfo(orders[selected_order].id, 'item.amount', item.id + ':' + orders[selected_order].items[index].amount)" type="number" class="text-right outline-none" min="1" max="99">
                                    </div>
                                    <div class="w-1/6">x{{ item.price }}</div>
                                    <div class="w-1/6 flex">
                                        <div class="w-2/3 flex items-center justify-center">
                                            <input v-model="orders[selected_order].items[index].is_bonus" @change="updateOrderInfo(orders[selected_order].id, 'item.is_bonus', item.id + ':' + orders[selected_order].items[index].is_bonus)" type="checkbox">
                                        </div>
                                        <div class="w-1/3 flex items-center justify-center">
                                            <div class="w-6 rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-red-500 " @click="removeItemFromOrder(index)">x</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div v-if="selected_order_customer_gifts" class="w-full p-2 px-4 flex flex-col border-b border-dashed border-gray-100">
                                    <div class="py-2">Подарки клиента:</div>
                                    <div v-for="meal, index in selected_order_customer_gifts" @click="selected_order_add_item(meal)" class="hover:bg-yellow-100 cursor-pointer"
                                    :key="index">
                                        {{ meal.name }}
                                        {{ meal.price }}
                                        {{ meal.is_combo == 1 ? 'set' : '' }}
                                    </div>
                                </div>
                                <div class="w-full p-2 px-4 flex flex-row border-b border-dashed border-gray-100 hover:bg-gray-100">
                                    <div class="w-3/6">
                                        <input class="border-b p-2 w-full" v-model="selected_order_meal_suggestion" @keyup="selected_order_meal_suggest()"/>
                                    </div>
                                </div>
                                <div class="w-full p-2 px-4 flex flex-col border-b border-dashed border-gray-100 hover:bg-gray-100">
                                    <div v-for="meal, index in selected_order_meal_suggestions" @click="selected_order_add_item(meal)" :key="index">
                                        {{ meal.name }}
                                        {{ meal.price }}
                                        {{ meal.is_combo == 1 ? 'set' : '' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="flex justify-between">
                        <span @click="showPaycheck('kitchen', orders[selected_order].id, !orders[selected_order].extra_info.is_kitchen_check)" class="flex items-center px-8 rounded-md cursor-pointer bg-yellow-200 hover:bg-yellow-300 mr-4">
                            <div class="pr-2 font-semibold">Чек для кухни</div>
                            <img src="https://img.icons8.com/plasticine/344/transaction-list.png" class="w-16">
                        </span>
                        <span @click="showPaycheck('customer', orders[selected_order].id, !orders[selected_order].extra_info.is_customer_check)" class="flex items-center px-8 rounded-md cursor-pointer bg-yellow-200 hover:bg-yellow-300 mr-4">
                            <div class="pr-2 font-semibold">Чек для клиента</div>
                            <img src="https://img.icons8.com/plasticine/344/check--v1.png" class="w-16">
                        </span>
                    </div>
                </section>
            </div>
        </template>
    </my-dialog>
    <my-dialog v-model:show="showEditOrderDateDialog">
        <template #header>Обновить информацию о заказе #{{ orders[selected_order].id || '...' }}</template>
        <template #default>
            <section v-if="orders[selected_order].status.id == 1">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 6">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 7">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <div v-else class="container">
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold">Дата процессинга</h3>
                    <div class="flex flex-row py-4">
                        <div class="w-1/2 flex flex-col">
                            <label class="px-2">Дата</label>
                            <input v-model="orders[selected_order].created_at.date" @change="updateOrderInfo(orders[selected_order].id, 'created_at.date', orders[selected_order].created_at.date)" class="p-2 border-b outline-none"
                            type="date"
                            />
                        </div>
                        <div class="w-1/2 flex flex-col">
                            <label class="px-2">Время</label>
                            <input v-model="orders[selected_order].created_at.time" @change="updateOrderInfo(orders[selected_order].id, 'created_at.time', orders[selected_order].created_at.time)" class="p-2 border-b outline-none"
                            type="time"
                            />
                        </div>
                    </div>
                </section>
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold">Желаемое время доставки</h3>
                    <div class="flex flex-row py-4">
                        <div class="w-1/2 flex flex-col">
                            <label class="px-2">Дата и время</label>
                            <input v-model="orders[selected_order].desired_delivery_at" @change="updateOrderInfo(orders[selected_order].id, 'desired_delivery_at', orders[selected_order].desired_delivery_at)" class="p-2 border-b outline-none"
                            type="datetime-local"
                            />
                        </div>
                    </div>
                </section>
            </div>
        </template>
    </my-dialog>
    <my-dialog v-model:show="showEditOrderCustomerDialog">
        <template #header>Клиент заказа #{{ orders[selected_order].id || '...' }}</template>
        <template #default>
            <section v-if="orders[selected_order].status.id == 1">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 6">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 7">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <div v-else class="container">
                <section class="flex flex-col">
                    <h3 class="text-md font-semibold">Номер телефона клиента</h3>
                    <div class="flex flex-row py-4">
                        <div class="w-full flex flex-col">
                            <label class="px-2">Номер телефона</label>
                            <my-phone-input v-model="orders[selected_order].customer.phone" @keyup.enter="updateOrderInfo(orders[selected_order].id, 'customer.phone', orders[selected_order].customer.phone)" class="p-2 border-b outline-none"
                            type="number"
                            />
                            <div v-if="!orders[selected_order].customer.customer_id" class="my-2 flex justify-start">
                                <div @click="getCustomerByPhone(orders[selected_order].customer.phone)" class="py-2 px-4 rounded-md cursor-pointer hover:bg-yellow-300 bg-gray-100">найти клиента</div>
                            </div>
                        </div>
                        <div v-if="orders[selected_order].customer.customer_id" class="m-2 flex flex-col">
                            <router-link :to="{ name: 'CustomersEdit', params: { id: orders[selected_order].customer.customer_id} }">
                                <div class="py-2 px-4 rounded-md cursor-pointer hover:bg-yellow-300 bg-gray-100 text-center">посмотреть клиента</div>
                            </router-link>
                        </div>
                    </div>
                    <div class="flex flex-row py-2">
                        <div v-if="orders[this.selected_order].customerSuggestions">
                            <div class="pb-2 text-md">Результаты поиска по номеру телефона:</div>
                            <div v-if="orders[this.selected_order].customerSuggestions.length > 0"  class="w-full flex">
                                <div v-for="phone, index in orders[this.selected_order].customerSuggestions" :key="index">
                                    <div @click="updateOrderInfo(orders[selected_order].id, 'customer.customer_id', phone.customer_id)" class="px-4 rounded-md cursor-pointer hover:bg-yellow-300 flex items-center bg-gray-50">
                                        <div class="py-2">
                                            {{ phone.customer_id || 'без номера' }}
                                            {{ phone.customer?.first_name }}
                                            {{ phone.customer?.last_name }}
                                        </div>
                                        <div v-if="!phone.customer_id" @click="storeCustomerByPhone(phone.id)" class="ml-2 px-2 h-6 bg-gray-100 rounded-full flex items-center justify-center text-gray-700 font-semibold hover:bg-green-100">+ создать</div>
                                        <div v-if="!phone.customer_id" @click="deletePhone(phone.id)" class="ml-2 w-6 h-6 bg-gray-100 rounded-full flex items-center justify-center text-gray-700 font-semibold hover:bg-red-100">x</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="flex flex-col">
                                <div class="px-2 py-4 text-base">
                                    <i>Номер телефона не найден в базе</i>
                                </div>
                                <div @click="storePhone(orders[selected_order].customer.phone)" class="py-2 px-4 rounded-md cursor-pointer hover:bg-yellow-300 bg-gray-100 text-center">сохранить в базу</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col py-2" v-if="orders[selected_order].customer.data" >
                        <!-- <div class="flex flex-row py-2">
                            <div class="w-1/2">
                                Дата регистрации:
                            </div>
                            <div class="w-1/2">
                                {{ orders[selected_order].customer.data.created_at }}
                            </div>
                        </div> -->
                        <div class="flex flex-row py-2">
                            <div class="w-1/2">
                                Всего заказов:
                            </div>
                            <div class="w-1/2">
                                {{ orders[selected_order].customer.data.orders_count }}
                            </div>
                        </div>
                        <div class="flex flex-row py-2">
                            <div class="w-1/2">
                                Дата первого заказа:
                            </div>
                            <div class="w-1/2">
                                {{ orders[selected_order].customer.data.first_delivery_at }}
                            </div>
                        </div>
                        <div class="flex flex-row py-2">
                            <div class="w-1/2">
                                Дата последнего заказа:
                            </div>
                            <div class="w-1/2">
                                {{ orders[selected_order].customer.data.latest_delivery_at }}
                            </div>
                        </div>
                        <div class="flex flex-row py-2">
                            <div class="w-1/2">
                                Доставлено заказов:
                            </div>
                            <div class="w-1/2">
                                {{ orders[selected_order].customer.data.orders_delivered_numb }}
                            </div>
                        </div>
                        <div class="flex flex-row py-2">
                            <div class="w-1/2">
                                Отменено заказов:
                            </div>
                            <div class="w-1/2">
                                {{ orders[selected_order].customer.data.orders_cancelled_numb }}
                            </div>
                        </div>
                        <div class="flex flex-row py-2">
                            <div class="w-1/2">
                                Отказались от заказов:
                            </div>
                            <div class="w-1/2">
                                {{ orders[selected_order].customer.data.orders_declined_numb }}
                            </div>
                        </div>
                        <div class="flex flex-row py-2">
                            <div class="w-1/2">
                                Всего адресов:
                            </div>
                            <div class="w-1/2">
                                {{ orders[selected_order].customer.data.addresses_count }}
                            </div>
                        </div>
                        <!-- <div>
                            {{ orders[selected_order].customer.data }}
                        </div> -->
                    </div>
                </section>
            </div>
        </template>
    </my-dialog>
    <my-dialog v-model:show="showEditOrderPaymentDialog" contentclass="w-1/3">
        <template #header>Оплата заказа #{{ orders[selected_order].id || '...' }}</template>
        <template #default>
            <section v-if="orders[selected_order].status.id == 1">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 6">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <section v-else-if="orders[selected_order].status.id == 7">
                <h3>У этого заказа нельзя менять информацию</h3>
            </section>
            <div v-else class="container">
                <section class="flex flex-col py-4">
                    <!-- <h3 class="text-md font-semibold">Оплаты</h3> -->
                    <div class="flex flex-row py-4">
                        <div class="w-1/2 flex flex-col">
                            <label class="px-2">Наличные</label>
                            <input v-model="orders[selected_order].payment.cash" @change="updateOrderInfo(orders[selected_order].id, 'payment.cash', orders[selected_order].payment.cash)" class="w-5/6 p-2 border-b outline-none"
                            type="number"
                            />
                        </div>
                        <div class="w-1/2 flex flex-col">
                            <label class="px-2">Перевод</label>
                            <my-numeric-input
                                class="w-full pt-3"
                                v-model="orders[selected_order].payment.transfer" @change="updateOrderInfo(orders[selected_order].id, 'payment.transfer', orders[selected_order].payment.transfer)"
                                :min="0" :max="orders[selected_order].total_summ"
                            />
                        </div>
                    </div>
                    <div class="flex w-full  py-4">
                        <div class="w-1/2 flex flex-col">
                            <label class="px-2">Доставка</label>
                            <input v-model="orders[selected_order].payment.delivery_cost" @change="updateOrderInfo(orders[selected_order].id, 'payment.delivery_cost', orders[selected_order].payment.delivery_cost)" class="w-5/6 p-2 border-b outline-none"
                            type="number"
                            />
                        </div>
                    </div>
                    <div class="flex w-full py-4">
                        <div class="w-1/2 flex flex-col pr-4">
                            <label class="px-2">Надбавка</label>
                            <div class="w-full flex flex-col">
                                <input v-model="orders[selected_order].payment.upcount" @change="updateOrderInfo(orders[selected_order].id, 'payment.upcount', orders[selected_order].payment.upcount)" class="w-full p-2 border-b outline-none"
                                type="number"
                                />
                                <input v-model="orders[selected_order].payment.upcount_comment" @change="updateOrderInfo(orders[selected_order].id, 'payment.upcount_comment', orders[selected_order].payment.upcount_comment)" class="w-full p-2 border-b outline-none"
                                type="text" placeholder="Комментарий"
                                />
                            </div>
                        </div>
                        <div class="w-1/2 flex flex-col">
                            <label class="px-2">Скидка</label>
                            <div class="w-full flex flex-col">
                                <input v-model="orders[selected_order].payment.discount" @change="updateOrderInfo(orders[selected_order].id, 'payment.discount', orders[selected_order].payment.discount)" class="w-full p-2 border-b outline-none"
                                type="number"
                                />
                                <input v-model="orders[selected_order].payment.discount_comment" @change="updateOrderInfo(orders[selected_order].id, 'payment.discount_comment', orders[selected_order].payment.discount_comment)" class="w-full p-2 border-b outline-none"
                                type="text" placeholder="Комментарий"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold">Статус оплаты: {{ orders[selected_order].extra_info.is_payed == 1 ? 'оплачен' : 'не оплачен' }}</h3>
                    <div class="flex flex-row py-4">
                        <div class="w-full flex flex-col">
                            <div class="flex justify-between">
                                <div @click="updateOrderInfo( orders[selected_order].id, 'extra_info.is_payed', 1)" class="flex items-center px-8 rounded-md cursor-pointer bg-yellow-200 hover:bg-yellow-300 mr-4">
                                    <div class="pr-2 font-semibold">Оплачен</div>
                                    <img src="https://img.icons8.com/plasticine/344/coins.png" class="w-16">
                                </div>
                                <div @click="updateOrderInfo( orders[selected_order].id, 'extra_info.is_payed', 0)" class="flex items-center px-8 rounded-md cursor-pointer bg-red-200 hover:bg-red-300">
                                    <div class="pr-2 font-semibold">Не оплачен</div>
                                    <img src="https://img.icons8.com/plasticine/344/coins.png" class="w-16">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </template>
    </my-dialog>
    <my-dialog v-model:show="showAssignCourierDialog" contentclass="w-1/3">
        <template #header>Назначить курьера</template>
        <template #default>
            <section class="flex flex-col py-4">
                <h3 class="text-md font-semibold">Курьеры</h3>
                <div class="flex flex-col py-4">
                    <div v-for="courier, index in activeCouriers" class="w-full flex flex-row items-center cursor-pointer hover:bg-yellow-300 rounded-lg" @click="assignCourier(courier)" :key="index">
                        <div class="p-4">
                            <img :src="courier.avatar?.url" class="w-16 rounded-full bg-gray-100"/>
                        </div>
                        <div class="">
                            <div class="text-lg font-semibold pr-4">{{ courier.first_name }} {{ courier.last_name }}</div>
                            <div class="text-base">{{ courier.status == 1 ? 'Работает' : 'Не работает' }}</div>
                            <!-- {{ courier }} -->
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </my-dialog>
    <!-- {{ orders[selected_order] }} -->
    <audio id="notification-light"><source src="https://static.omelette.kz/_files/sound-light.mp3" type="audio/mpeg"></audio>
    <audio id="notification-medium"><source src="https://static.breaktime.kz/uploads/tones/breaktime/alem_cut_4.mp3" type="audio/mpeg"></audio>
    <audio id="notification-loud"><source src="https://static.omelette.kz/_files/sound-loud.mp3" type="audio/mpeg"></audio>
    <confirm-alert ref="confirmAlert"></confirm-alert>
</template>
<script>
export default {
    data: () => {
        return {
            polling: null,
            update_interval: 30000,
            is_loading_orders: false,
            criteria: 'active',
            sort_by: 'created_at',
            sort_dir: 'DESC',
            sound: 'notification-medium',
            orders: [],
            showEditOrderCustomerDialog: false,
            showEditOrderDateDialog: false,
            showEditOrderAddressDialog: false,
            showEditOrderItemsDialog: false,
            showEditOrderPaymentDialog: false,
            showAssignCourierDialog: false,
            sorder: null,
            selected_order: null,
            selected_order_meal_suggestion: '',
            selected_order_meal_suggestions: [],
            selected_order_customer_gifts: [],
            activeCouriers: []
        }
    },
    methods: {
        getCustomerByIdDash (customer_id) {
            this.getServerData('customer_by_id_dash', { id: customer_id })
                    .then((res) => {
                        this.orders[this.selected_order].customer.data = res.data
                    })
                    .catch(() => {})
        },
        getCustomerById (customer_id) {
            this.getServerData('customer_by_id', { id: customer_id })
                    .then((res) => {
                        console.log(res)
                    })
                    .catch(() => {})
        },
        getCustomerByPhone (customer_phone, customer_id) {
            if (customer_phone && !customer_id) {
                this.getServerData('customer_by_phone', { phone_number: customer_phone })
                    .then((res) => {
                        this.orders[this.selected_order].customerSuggestions = res.data
                    })
                    .catch(() => {})
            }
        },
        storeCustomerByPhone (phone_id) {
            this.postServerData('customer_create_by_phone', { data: { phone_id } }).then((res) => {
                // this.orders[this.selected_order].customer.id = res.data.item_id
                this.getCustomerByPhone(this.orders[this.selected_order].customer.phone)
            })
        },
        storePhone (phone) {
            this.postServerData('phone_store', { data: { phone } }).then(() => {
                this.getCustomerByPhone(this.orders[this.selected_order].customer.phone)
            })
        },
        deletePhone (phone_id) {
            this.postServerData('phone_delete', { data: { phone_id } }).then(() => {
                this.getCustomerByPhone(this.orders[this.selected_order].customer.phone)
            })
        },
        storeAddress () {
            const country_name = this.orders[this.selected_order].address?.raw?.country
            const city_name = this.orders[this.selected_order].address?.raw?.city
            const street_name = this.orders[this.selected_order].address?.raw?.street
            const building_number = this.orders[this.selected_order].address?.raw?.building

            this.postServerData('address_store', { data: { country_name, city_name, street_name, building_number } })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.orders[this.selected_order].address.address_id = res.data.item.id
                        this.updateOrderInfo(this.orders[this.selected_order].id, 'address.address_id', res.data.item.id)
                    } else {
                        this.orders[this.selected_order].address.address_id = -1
                        // this.orders[this.selected_order].address.id = -1
                    }
                })
                .catch(() => {})
        },
        encodeAddress () {
            const country_name = this.orders[this.selected_order].address?.raw?.country
            const city_name = this.orders[this.selected_order].address?.raw?.city
            const street_name = this.orders[this.selected_order].address?.raw?.street
            const building_number = this.orders[this.selected_order].address?.raw?.building

            this.postServerData('address_encode', { data: { country_name, city_name, street_name, building_number } })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.orders[this.selected_order].address.address_id = res.data.item.id
                        this.updateOrderInfo(this.orders[this.selected_order].id, 'address.address_id', res.data.item.id)
                    } else {
                        this.orders[this.selected_order].address.address_id = -1
                        // this.orders[this.selected_order].address.id = -1
                    }
                })
                .catch(() => {})
        },
        updateCustomerAddress (customer_id, address_id) {
            const appartment = {
                appartment_number: this.orders[this.selected_order].address?.raw.appartment,
                entrance: this.orders[this.selected_order].address?.raw.entrance,
                floor: this.orders[this.selected_order].address?.raw.floor,
                has_intercom: this.orders[this.selected_order].address?.raw.has_intercom,
                label: this.orders[this.selected_order].address?.raw.label,
                details: this.orders[this.selected_order].address?.raw.details
            }
            this.postServerData('customer_update_or_create_address', { data: { customer_id, address_id, appartment } })
                .then((res) => { console.log(res) })
                .catch((error) => console.log(error))
        },
        selected_order_meal_suggest () {
            if (this.selected_order_meal_suggestion.length >= 2) {
                this.selected_order_meal_suggestions = []
                this.getServerData('meals_by_name', { meal_name: this.selected_order_meal_suggestion })
                    .then((res) => {
                        res.data.forEach((item) => {
                            const suggested_meal = {
                                id: item.id,
                                name: item.name,
                                price: item.price,
                                images: item.images,
                                is_combo: 0,
                                is_bonus: 0,
                                tags: item.tags,
                                description: item.description,
                                contents: item.ingredients
                            }

                            this.selected_order_meal_suggestions.push(suggested_meal)
                        })
                    })
                    .catch(() => {})

            this.getServerData('combos_by_name', { combo_name: this.selected_order_meal_suggestion })
                .then((res) => {
                    res.data.forEach((item) => {
                        const suggested_meal = {
                            id: item.id,
                            name: item.name,
                            price: item.price,
                            images: item.images,
                            is_combo: 1,
                            is_bonus: 0,
                            tags: [],
                            description: item.description,
                            contents: item.meals
                        }

                        this.selected_order_meal_suggestions.push(suggested_meal)
                    })
                })
                .catch((error) => console.log(error))
            }
        },
        selected_order_add_item (_item) {
            const new_item = {
                meal_id: _item.id,
                name: _item.name,
                is_combo: _item.is_combo,
                is_bonus: _item.is_bonus || false,
                amount: _item.amount ? _item.amount : 1,
                price: _item.price,
                details: _item.details ? _item.details : ''
                // status: 0,
            }
            let item_already_added = false
            this.orders[this.selected_order].items.forEach((item, index) => {
                if (item.meal_id === new_item.meal_id && item.is_bonus === new_item.is_bonus) {
                    item_already_added = true
                    this.orders[this.selected_order].items[index].amount += 1
                    this.updateOrderInfo(this.orders[this.selected_order].id, 'item.amount', item.id + ':' + this.orders[this.selected_order].items[index].amount)
                }
            })
            if (!item_already_added) {
                this.orders[this.selected_order].items.push(new_item)
                this.updateOrderInfo(this.orders[this.selected_order].id, 'item.add', new_item.meal_id + ':' + new_item.is_combo)
            }

            this.selected_order_meal_suggestions = []
            this.selected_order_meal_suggestion = ''
        },
        removeItemFromOrder (item_index) {
            const item_id = this.orders[this.selected_order].items[item_index].id
            const order_id = this.orders[this.selected_order].id

            this.updateOrderInfo(order_id, 'item.delete', item_id)
            this.orders[this.selected_order].items.splice(item_index, 1)
        },
        pollData () {
            this.polling = setInterval(() => {
                if (this.is_updatable) {
                    this.getActiveOrdersList()
                }
            }, this.update_interval)
        },
        getActiveOrdersList () {
            this.is_loading_orders = true
            this.getServerData('orders_active', { criteria: this.criteria, sort_by: this.sort_by, sort_dir: this.sort_dir })
                .then((res) => {
                    this.orders = res.data.data
                    document.title = '[' + res.data.data.length + '] Breaktime CRM '

                    if (this.sound !== 'off') {
                        this.orders.every(element => {
                            if (element.status.id === 2) {
                                // const loud_notification = document.getElementById('notification-loud')
                               this.playSound()
                                return false
                            }
                            return true
                        })
                    }
                })
                .catch((error) => console.log(error))
                .finally(this.is_loading_orders = false)
        },
        updateOrderInfo (order_id, info_index, new_value) {
            if (info_index === 'address.street' || info_index === 'address.building' || info_index === 'address.city') {
                this.orders[this.selected_order].address.address_id = null
            }

            this.postServerData('order_update_info', { data: { order_id, info_index, new_value } })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.getActiveOrdersList()
                        // this.orders[order_index].$info_index = new_value;
                    }
                })
                .catch(() => {
                    const notification = {
                        body: 'Ошибка',
                        header: 'Не удалось обновить заказ'
                    }
                    this.$root.notify(notification.body, notification.header)
                })
                .finally(() => {})
        },
        showPaycheck (type, order_id) {
            const url = this.api_route('paycheck_show', { order_id, type })

            const width = 350
             const height = 500
            window.open(url, '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=' + width + ',height=' + height)
        },
        editOrderInfo (order_index, type) {
            switch (type) {
                case 'customer':
                    this.selected_order = order_index
                    this.showEditOrderCustomerDialog = true
                    this.getCustomerByIdDash(this.orders[this.selected_order].customer.customer_id)
                    break
                case 'address':
                    this.selected_order = order_index
                    this.showEditOrderAddressDialog = true
                    break
                case 'date':
                    this.selected_order = order_index
                    this.showEditOrderDateDialog = true
                    break
                case 'items':
                    this.selected_order = order_index
                    this.showEditOrderItemsDialog = true
                    this.selected_order_customer_gifts = []
                    this.getCustomerGifts()
                    break
                case 'payment':
                    this.selected_order = order_index
                    this.showEditOrderPaymentDialog = true
                    break
            }
        },
        getActiveCouriersList () {
            this.getServerData('couriers_active')
                .then((res) => {
                    this.activeCouriers = res.data.data
                })
                .catch((error) => console.log(error))
        },
        openAssignCourierDialog (order) {
            this.sorder = order
            this.showAssignCourierDialog = true
        },
        assignCourier (courier) {
            this.sorder.courier = courier
            this.updateOrderInfo(this.sorder.id, 'courier', courier.id)
            this.showAssignCourierDialog = false
        },
        getCustomerGifts () {
            if (this.orders[this.selected_order].customer?.customer_id) {
                this.getServerData('customer_gifts', { customer_id: this.orders[this.selected_order].customer.customer_id })
                    .then((res) => { this.selected_order_customer_gifts = res.data })
            }
        },
        playSound () {
            const loud_notification = document.getElementById(this.sound)
            loud_notification.play().catch((e) => {})
        },
        async cancelOrder (order_id) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Отменить заказ?',
                description: '',
                okButton: 'Отменить'
            })
            if (confirmdel) {
                this.updateOrderInfo(order_id, 'status', 6)
            }
        }
    },
    beforeUnmount () {
        clearInterval(this.polling)
    },
    // created () {
    // },
    computed: {
        is_updatable () {
            return !this.showEditOrderCustomerDialog &&
                    !this.showEditOrderDateDialog &&
                    !this.showEditOrderAddressDialog &&
                    !this.showEditOrderItemsDialog &&
                    !this.showEditOrderPaymentDialog &&
                    !this.showAssignCourierDialog
        }
    },
    mounted () {
        this.pollData()
        this.getActiveOrdersList()
        this.getActiveCouriersList()
        // this.notify('Цент уведомлений подключился', 'Ура!')
    }
}
</script>
<style scoped>
    .actions-block{}
    .actions-block span:first-child{
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .actions-block span:last-child{
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .actions-block span{
        height: 48px;
    }
    .actions-block span img{
        height: 28px;
    }
    .hastooltip {
        position: relative;
    }
    .hastooltip:hover .wrapper{
        transform: translate(-50%, 0%);
        opacity: 1;
        display: flex;
    }
    .hastooltip .wrapper{
        position: absolute;
        bottom: 135%;
        left: 50%;
        transform: translate(-50%, 50%);
        height: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all .3s;
        opacity: 0;
        display: none;
    }
    .hastooltip .wrapper .text{
        background: #000;
        color: white;
        padding: 3px 6px;
        border-radius: 3px;
        white-space: nowrap;
    }
    .hastooltip .wrapper .text::after{
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        display: block;
        height: 13px;
        width: 20px;
        background-image: url(https://cdn-icons-png.flaticon.com/512/60/60995.png);
        background-size: cover;
        transform: translate(-50%, 52%);
    }
</style>
