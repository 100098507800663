<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-center p-4">
                    <div class="w-full flex flex-col justify-around ">
                        <h1 class="text-lg lg:text-2xl text-gray-800 font-semibold pt-2 pb-2">Дополнительные дотации</h1>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                    </div>
                </div>
                <details class="flex flex-col py-2">
                    <summary class="text-md font-semibold pb-4 pl-4 cursor-pointer">Добавить запись</summary>
                        <div class="flex flex-col p-2 border rounded-md items-center">
                            <div class="flex items-end w-full flex-wrap">
                                <div class="w-full md:w-3/12 flex flex-col">
                                    <label class="px-2">Сумма</label>
                                    <text-input v-model="income.amount" placeholder="Сумма" type="number" validate="number"/>
                                </div>
                                <div class="w-full md:w-3/12 flex flex-col">
                                    <label class="px-2">Пометка</label>
                                    <text-input v-model="income.comment" placeholder="Цель/Источник" type="text" />
                                </div>
                                <div class="w-6/12 md:w-3/12 flex flex-col">
                                    <label class="px-2">Дата</label>
                                    <text-input v-model="income.date" placeholder="Дата: ДД.ММ.ГГГГ" type="date" />
                                </div>
                                <div class="w-6/12 md:w-3/12 flex flex-col px-2">
                                    <label class="px-2"></label>
                                    <default-button @click="storeIncome" type="confirm">Добавить</default-button>
                                </div>
                            </div>
                        </div>
                </details>

                <section class="flex flex-col py-2 transition-all">
                    <h3 class="text-md font-semibold pb-4 pl-4">Дополнительные доходы</h3>
                    <div class="flex flex-col border rounded-md items-center text-sm">
                        <div v-if="incomes && incomes.length > 0" class="w-full">
                            <div class="w-full flex p-2 bg-indigo-100 text-indigo-700">
                                <div class="w-3/12">Дата</div>
                                <div class="w-3/12">Сумма</div>
                                <div class="w-5/12">Пометка</div>
                                <div class="w-1/12"></div>
                            </div>
                            <div v-for="item, index in incomes" class="w-full flex p-2 border-t flex items-center" :key="index">
                                <div class="w-3/12">{{ item.date }}</div>
                                <div class="w-3/12">{{ item.amount }}</div>
                                <div class="w-5/12">{{ item.comment }}</div>
                                <div class="w-1/12">
                                    <default-button @confirm="deleteIncome(item.id)" type="cancel" pressconfirm icon-only>&times;</default-button>
                                </div>
                            </div>
                        </div>
                        <div v-else class="p-4">
                            <i>Информации пока нет</i>
                        </div>
                    </div>
                </section>

            </section>
        </div>
    </div>
    <confirm-alert ref="confirmAlert"></confirm-alert>
</template>
<script>
export default {
    data: () => {
        return {

            income: {
                amount: null,
                date: 'сегодня',
                comment: ''
            },
            incomes: []
        }
    },
    methods: {
        resetForm () {
            this.income = {
                amount: null,
                date: 'сегодня',
                comment: ''
            }
        },
        getIncomes () {
            this.getServerData('incomes_all')
                .then((res) => {
                    this.incomes = res.data
                })
                .catch((error) => console.log(error))
        },
        storeIncome () {
            this.postServerData('income_store', { data: this.income })
                .then((res) => {
                    this.getIncomes()
                })
                .catch((error) => console.log(error))
                .finally(() => this.resetForm())
        },
        async deleteIncome (item_id) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Удалить запись?',
                description: 'Удалить запись безвозвратно',
                okButton: 'Удалить'
            })
            if (confirmdel) {
            this.postServerData('income_delete', { data: { item_id } })
                .then((res) => {
                    this.getIncomes()
                })
                .catch((error) => console.log(error))
            }
        }
    },
    mounted () {
        this.getIncomes()
    }
}
</script>
<style scoped>
    input:focus{
        border-bottom: 1px solid #333;
    }
    input:invalid {
        border-bottom: 1px solid red;
    }
</style>
