<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-center p-4">
                    <div class="w-full flex flex-col justify-around ">
                        <h1 class="text-lg lg:text-2xl text-gray-800 font-semibold pt-2 pb-2">Список комбо</h1>
                    </div>
                    <div class="w-full flex flex-row justify-end">
                        <div v-if="can('create_combos')">
                            <router-link :to="{name: 'CombosCreate' }">
                                <default-button type="confirm">Комбо</default-button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="w-full rounded-lg border-2 border-indigo-100">
                    <!-- mobile -->
                    <div class="w-full flex lg:hidden py-2 px-2 flex-col">
                        <div class="w-full flex mb-2 items-center">
                            <text-input icon-url="https://img.icons8.com/nolan/256/1A6DFF/C822FF/search.png" type="text" input-class="text-sm p-0" container-class="rounded-full" placeholder="Поиск" v-model="name_like" @keyup.enter="getCombos"/>
                            <div class="pl-2">
                                <toggle-button v-model="listView" :values="['table:Таблицей','cards:Списком']" @change="getCombos"></toggle-button>
                            </div>
                        </div>
                        <div class="w-full flex mb-2 items-center justify-between">
                            <div class="text-gray-500 text-xs">Показаны {{ meta.to}} из {{ meta.total}}</div>
                            <toggle-button v-model="meta.per_page" :values="['10:10','25:25', '50:50']" @change="()=>{ meta.current_page = 1; getCombos()}"></toggle-button>
                        </div>
                    </div>

                    <!-- desktop -->
                    <div class="w-full hidden lg:flex py-2 px-2 justify-between items-center">
                        <div class="flex items-center">
                            <div class="pl-2">
                                <toggle-button v-model="listView" :values="['table:Таблицей','cards:Списком']" @change="getCombos"></toggle-button>
                            </div>
                            <div class="pl-4">
                                <toggle-button v-model="sort_by" :values="['name:По наименованию','price:По цене']" @change="getCombos"></toggle-button>
                            </div>
                            <div class="pl-4">
                                <toggle-button v-model="sort_dir" :values="['ASC:А-Я','DESC:Я-А']" @change="getCombos"></toggle-button>
                            </div>
                            <div class="pl-4">
                                <toggle-button v-model="meta.per_page" :values="['10:10','25:25', '50:50']" @change="()=>{ meta.current_page = 1; getCombos()}"></toggle-button>
                            </div>
                        </div>
                        <text-input icon-url="https://img.icons8.com/nolan/256/1A6DFF/C822FF/search.png" type="text" input-class="text-sm p-0" container-class="rounded-full" placeholder="Поиск" v-model="name_like" @keyup.enter="getCombos"/>
                        <div class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}}</div>
                    </div>

                    <div v-if="combos.length > 0"  :class="{'opacity-50':isLoadingData}">
                        <div v-if="listView == 'table'">
                            <div class="w-full flex flex-row bg-indigo-100 text-indigo-700 select-none">
                                <div class="w-1/12 p-2"></div>
                                <div class="w-8/12 p-2 cursor-pointer transition-all"
                                    @click="changeOrderByDir('name')"
                                    :class="[sort_by == 'name' ? 'font-semibold' : '',
                                            sort_by == 'name' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'name' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]"
                                    >Наименование</div>
                                <div class="w-3/12 p-2 cursor-pointer transition-all"
                                    @click="changeOrderByDir('price')"
                                    :class="[sort_by == 'price' ? 'font-semibold' : '',
                                            sort_by == 'price' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'price' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]"
                                    >Цена</div>
                                <div class="hidden lg:flex w-1/12 p-2 cursor-pointer transition-all"
                                    @click="changeOrderByDir('is_active')"
                                    :class="[sort_by == 'is_active' ? 'font-semibold' : '',
                                            sort_by == 'is_active' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'is_active' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]"
                                    >Активно</div>
                                <div class="hidden lg:flex w-1/12 p-2 cursor-pointer transition-all"
                                    @click="changeOrderByDir('is_seen')"
                                    :class="[sort_by == 'is_seen' ? 'font-semibold' : '',
                                            sort_by == 'is_seen' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'is_seen' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]"
                                    >Видим</div>
                                <!-- <div class="w-1/12 p-2 cursor-pointer transition-all"
                                    @click="changeOrderByDir('is_recomended')"
                                    :class="[sort_by == 'is_recomended' ? 'font-semibold' : '',
                                            sort_by == 'is_recomended' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'is_recomended' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]"
                                    >Продвигаем</div> -->
                            </div>
                            <div class="w-full">
                                <div v-for="item in combos" :key="item.id">
                                    <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed #aaa">
                                        <div class="w-full flex flex-row hover:bg-gray-100 items-center">
                                            <div class="w-1/12 flex justify-center">
                                                <img :src="item.images[0]?.url" style="height: 40px; border-radius: 50%; background-color: #eee"/>
                                            </div>
                                            <div class="w-8/12 p-2 text-md flex flex-col"
                                                :class="[item.is_seen == 0 ? 'text-gray-500' : '', item.is_active == 0 ? 'italic' : '']">
                                                <div v-if="can('edit_combos')">
                                                    <router-link :to="{name: 'CombosEdit', params: { id: item.id  }}">{{ item.name }}</router-link>
                                                </div>
                                                <div v-else>{{ item.name }}</div>
                                            </div>
                                            <div class="w-3/12 px-2">{{ item.price }}</div>
                                            <div class="hidden lg:flex w-1/12 px-2">
                                                {{ item.is_active ? 'Активно' : 'Не активно' }}
                                                <!-- <toggle-button v-model="item.is_active" :values="['1:Да','0:Нет']" @change="updateCombo"/> -->
                                            </div>
                                            <div class="hidden lg:flex w-1/12 px-2">{{ item.is_seen ? 'Видимо' : 'Скрыто' }}</div>
                                            <!-- <div class="w-1/12 px-2">{{ item.is_recomended }}</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="listView == 'cards'">
                            <div class="w-full flex flex-wrap" :class="{'opacity-50':isLoadingData}">
                                <div v-for="item in combos" :key="item.id" class="w-6/12 md:5/12 xl:w-4/12 2xl:w-3/12 p-2">
                                    <div class="w-full flex flex-col text-sm hover:bg-gray-100 rounded-md border ">
                                        <div class="w-full flex flex-col hover:bg-gray-100 items-center">
                                            <div class="w-full py-2 px-4 text-md flex flex-col">
                                                <div v-if="can('edit_combos')">
                                                    <router-link :to="{name: 'CombosEdit', params: { id: item.id  }}">{{ item.name }}</router-link>
                                                </div>
                                                <div v-else>{{ item.name }}</div>
                                            </div>
                                            <div class="w-full flex justify-center">
                                                <img :src="item.thumb?.url" style="height: 140px;"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section>
                            <div class="flex flex-row justify-between items-center p-4 border-t text-xs">
                                <div class="w-1/2 flex flex-col justify-around pl-2">
                                    <span class="text-xs">Показаны {{ meta.from }} - {{ meta.to}} из {{ meta.total}}</span>
                                </div>
                                <div v-if="meta.links.length > 3" class="w-1/2 flex flex-row justify-end">
                                    <div v-for="link, index in meta.links" :key="index">
                                        <div
                                            v-if="index != 0 && index != meta.links.length-1"
                                            class="py-2 px-4 bg-gray-100 rounded-md"
                                            :class="[
                                                    meta.current_page == parseInt(link.label) ? 'bg-yellow-300 font-semibold' : '',
                                                    link.url !== null ? 'hover:bg-yellow-300 cursor-pointer' : ''
                                                ]"
                                            @click="()=>{ if(link.url != null ){ changePage(parseInt(link.label)) }}"
                                            >{{ link.label }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                    <div v-else>
                        <div class="w-full py-16 text-center">
                            Список пуст
                        </div>
                    </div>
                </div>
           </section>
           <!-- <div class="py-4"></div> -->
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            meta: {
                current_page: 1,
                per_page: 10,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0
            },
            sort_by: 'name',
            sort_dir: 'ASC',
            name_like: '',
            selected_tag: '',
            items: [],
            combos: [],
            // showEditItemDialog: false,
            isLoadingData: false,
            listView: 'cards'
        }
    },
    methods: {
        getCombos () {
            this.isLoadingData = true
            this.getServerData('combos_list', { per_page: this.meta.per_page, sort_by: this.sort_by, sort_dir: this.sort_dir, current_page: this.meta.current_page, name_like: this.name_like })
                .then((res) => {
                    this.meta.total = res.data.total
                    this.meta.from = res.data.from
                    this.meta.to = res.data.to
                    this.meta.links = res.data.links
                    this.combos = res.data.data
                })
                .catch(() => {})
                .finally(() => { this.isLoadingData = false })
        },
        updateCombo () {

        },
        changeOrderByDir (criteria) {
            if (this.sort_by !== criteria) {
                this.sort_by = criteria
                this.sort_dir = 'ASC'
            } else {
                if (this.sort_dir === 'ASC') {
                    this.sort_dir = 'DESC'
                } else {
                    this.sort_dir = 'ASC'
                }
            }
            this.meta.current_page = 1

            this.getCombos()
        },
        changePage (page) {
            this.meta.current_page = page
            this.getCombos()
        }
    },
    mounted () {
        this.getCombos()
    },
    watch: {
        // $route(to, from) {
        //     console.log(this.$route)
        //     console.log(this.$route.query)
        //     console.log(this.$route.hash)
        // }
    },
    components: {
    }
}
</script>
<style scoped>
    .sort-asc {
        background-image: url('https://img.icons8.com/nolan/256/1A6DFF/C822FF/sort-up--v2.png');
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 1.2rem;
    }
    .sort-desc{
        background-image: url('https://img.icons8.com/nolan/256/1A6DFF/C822FF/sort-down--v1.png');
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 1.2rem;
    }
</style>
