<template>
    <div class="h-20 md:h-48 flex flex-col items-center justify-end">
        <div :style="{'height': (current_percent != 0 ? current_percent : 0.01)*100 + '%', 'transition-duration': index * 50 + 100 + 'ms', 'background-color': `rgb(${189 - 189 * (current_percent)}, 224, 56)`}" class="w-2/12 rounded-full transition-all"></div>
    </div>
    <!-- :class="`bg-${points_color}-500 hover:bg-${points_color}-300`" -->
</template>
<script>
export default {
    name: 'simple-linear-graph-single-block',
    data () {
        return {
            current_percent: 0,
            incTimeout: null
        }
    },
    props: {
        percent: {
            type: Number,
            default: 1
        },
        points_color: {
            type: String,
            default: 'lime'
        },
        index: {
            type: Number,
            default: 300
        }

    },
    methods: {
        incNumber () {
            if (this.current_percent < this.percent) {
                this.current_percent++
            }
            if (this.current_percent >= this.percent) {
                this.current_percent = this.percent
                clearInterval(this.incTimeout)
            }
        }
    },
    mounted () {
        this.incTimeout = setInterval(this.incNumber, 300)
    }
}
</script>
<style scoped>

</style>
