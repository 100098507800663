<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
            <div class="pt-6">
                <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">Поиск заказов</h1>
            </div>
            <div class="pt-2 flex items-center">
                <div class="flex flex-col">
                    <label>Дата с:</label>
                    <h1 class="text-lg lg:text-2xl text-gray-800 font-semibold pt-1 pb-2 px-4 flex items-center whitespace-nowrap">
                        <div @click="()=> { pickDate('date_from')}" class="flex text-base lg:text-xl items-center p-1 px-4 rounded-md bg-yellow-100 hover:bg-yellow-200 cursor-pointer">
                            {{ date_from == 'today' ? 'сегодня' : date_from}}
                            <img src="https://img.icons8.com/nolan/256/1A6DFF/C822FF/tear-off-calendar.png" class="h-6 w-6 ml-2"/>
                        </div>
                    </h1>
                </div>
                <div class="flex flex-col">
                    <label>Дата по:</label>
                    <h1 class="text-lg lg:text-2xl text-gray-800 font-semibold pt-1 pb-2 px-4 flex items-center whitespace-nowrap">
                        <div @click="()=> { pickDate('date_to')}" class="flex text-base lg:text-xl items-center p-1 px-4 rounded-md bg-yellow-100 hover:bg-yellow-200 cursor-pointer">
                            {{ date_to == 'today' ? 'сегодня' : date_to}}
                            <img src="https://img.icons8.com/nolan/256/1A6DFF/C822FF/tear-off-calendar.png" class="h-6 w-6 ml-2"/>
                        </div>
                    </h1>
                </div>
                <div class="flex flex-col">
                    <label>Сумма с:</label>
                    <input type="text" v-model="summ_from"  @change="getStats" class="p-2 border outline-none"/>
                </div>
                <div class="flex flex-col">
                    <label>Сумма по:</label>
                    <input type="text" v-model="summ_to"  @change="getStats"   class="p-2 border outline-none"/>
                </div>
                <div class="flex flex-col">
                    <label>Статусы:</label>
                    <select v-model="statuses"  @change="getStats" class="p-2 border outline-none">
                        <option value='2'>Ожидающие</option>
                        <option value='3'>На Кухне</option>
                        <option value='4'>На доставке</option>
                        <option value='5'>Доставленные</option>
                        <option value='6'>Отмененные</option>
                        <option value='7'>Отказавшиеся</option>
                        <option value='1,2,3,4,5,6,7,8,9'>Все</option>
                    </select>
                </div>
            </div>
            <section v-if="!is_loading">

                <div class="py-6 flex flex-wrap">
                    <div v-for="stat in stats" class="py-2" :class="[stat.width ? stat.width : 'w-full']">
                       <div v-if="stat.type == 'collection-orders'" class="w-full">
                           <div class="w-full px-4 rounded-md flex flex-col border">
                               <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                               <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                               <div v-if="stat.data && stat.data.length > 0" class="pb-2 flex w-full flex flex-col">
                                   <div v-for="order, index in stat.data" class="w-full even:bg-gray-50 border-t flex flex-wrap  text-xs md:text-base">
                                       <div class="w-full md:w-6/12 flex justify-between py-2">
                                           <div class="w-full flex flex-row justify-between">
                                               <div class="w-full flex flex-col px-2">
                                                   <div :class="[order.times.created_to_closed.raw > 60 ? 'text-red-500 font-semibold' : '']">
                                                       <router-link :to="`/orders/${order.id}/edit`">{{ order.id }}</router-link>
                                                   </div>
                                                   <div class="flex flex-row items-center whitespace-nowrap">{{ order.current_status?.emoji || '' }}</div>
                                                   <div>{{ order.times.created_to_closed.formatted }}</div>
                                               </div>
                                               <div class="w-full flex flex-col">
                                                   <div v-for="status in order.statuses_history" class="flex items-center text-xs md:text-sm">
                                                       <div>{{ status?.created_at }}</div>
                                                       <div class="pl-2 hidden md:flex">{{ status.status?.name }}</div>
                                                       <div class="pl-2 flex md:hidden">{{ status.status?.emoji }}</div>
                                                   </div>
                                               </div>
                                           </div>
                                           <div class="w-full flex flex-col">
                                               <div>{{ order.customer?.phone }} | {{ order.customer?.customer_id }}</div>
                                               <div>{{ order.address?.street_appartment }}</div>
                                               <div>{{ order.extra_info?.order_source }}</div>
                                               <!-- <div>{{ order.address.full_address }}</div> -->
                                           </div>
                                       </div>
                                       <div class="w-full md:w-3/12 flex justify-between py-2">
                                           <div class="w-full flex flex-col">
                                               <div v-for="item in order.items" class="flex items-center text-xs md:text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div class="w-7/12 flex items-center">
                                                       <div v-if="item.meal_id">
                                                           <div v-if="item.is_combo == 1">
                                                               <router-link :to="{ name: 'CombosEdit', params: { id: item.meal_id } }">{{ item.name }}</router-link>
                                                           </div>
                                                           <div v-if="item.is_combo == 0">
                                                               <router-link :to="{ name: 'ItemsEdit', params: { id: item.meal_id } }">{{ item.name }}</router-link>
                                                           </div>
                                                       </div>
                                                       <div v-else>{{ item.name }}</div>
                                                       <div class="">{{ item.is_combo ? '🍱' : ''  }}</div>
                                                   </div>
                                                   <div class="pl-2">{{ item.amount }} x </div>
                                                   <div>{{ item.price }}</div>
                                                   <div class="">{{ item.is_bonus ? '🎁' : ''  }}</div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="w-full md:w-3/12 flex justify-between py-2">
                                           <div class="w-full flex flex-col text-xs md:text-sm">
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Итого</div>
                                                   <div>{{ order.total_summ }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Перевод</div>
                                                   <div>{{ order.payment?.transfer }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Бонусных</div>
                                                   <div>{{ order.total_bonuses }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Доставка</div>
                                                   <div>{{ order.payment?.delivery_cost }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Скидка</div>
                                                   <div>{{ order.payment?.discount }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Надбавка</div>
                                                   <div>{{ order.payment?.upcount }}</div>
                                               </div><!-- <div>{{ order.address.full_address }}</div> -->
                                           </div>
                                       </div>
                                       <div v-if="order.rating" class="w-full flex justify-between p-4 border-t">
                                           <div class="w-3/12 flex flex-col">
                                               <div class="flex">
                                                   <div class="w-1/2">Оператор:</div>
                                                   <div class="flex">
                                                       <img v-for="index in order.rating?.operator_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                                                   </div>
                                               </div>
                                               <div class="flex">
                                                   <div class="w-1/2">Кухня:</div>
                                                   <div class="flex">
                                                       <img v-for="index in order.rating?.kitchen_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                                                   </div>
                                               </div>
                                               <div class="flex">
                                                   <div class="w-1/2">Доставка:</div>
                                                   <div class="flex">
                                                       <img v-for="index in order.rating?.courier_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                                                   </div>
                                               </div>
                                           </div>
                                           <div class="w-9/12 flex">
                                               <div v-if="order.rating?.body" class="bg-gray-100 rounded-md p-4"><i>{{ order.rating?.body }}</i></div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div v-else class="p-8">
                                   <i>Список пуст</i>
                               </div>
                           </div>
                       </div>

                    </div>
                </div>

            </section>
            <section v-else>
                <div>Загрузка...</div>
            </section>
        </div>
    </div>
   <datepicker-alert ref="datePickerAlert"></datepicker-alert>
</template>
<script>
export default {
    data: () => {
        return {
            is_loading: false,
            stats: [],
            bg_colors: ['bg-green-300', 'bg-yellow-300', 'bg-red-300', 'bg-indigo-300'],
            date_from: 'today',
            date_to: 'today',
            summ_from: 0,
            summ_to: 999999,
            statuses: '5'
        }
    },
    methods: {
       async pickDate (param) {
           const confirm = await this.$refs.datePickerAlert.show({
               title: 'Выбрать дату',
               description: 'Выбериате дату',
               okButton: 'Удалить'
           })
           if (confirm) {
               this[param] = confirm.date
               this.getStats()
           }
       },
        getStats () {
            this.is_loading = true
            this.getServerData('report_filtered', { date_from: this.date_from, date_to: this.date_to, summ_from: this.summ_from, summ_to: this.summ_to, statuses: this.statuses })
                .then((res) => { this.stats = res.data })
                .finally(() => { this.is_loading = false })
        }
    },
    mounted () {
        // this.user = this.$store.getters.currentUser
        this.getStats()
    }
}
</script>
<style scoped>
    .h-104 {
        height: 26rem;
    }
    .h-112 {
        height: 28rem
    }
    .h-120 {
        height: 30rem
    }
    .max-h-104 {
        max-height: 26rem;
    }
    .max-h-112 {
        max-height: 28rem
    }
    .max-h-120 {
        max-height: 30rem
    }
</style>
