const api_routes = {
    base_url: 'https://api.breaktime.kz/api/v2/',
    meals_all: '/api/crm/v1/meals/all',
    // 'meals_list'   : '/api/crm/v1/meals/list',    // page - perpage
    meals_list: '/api/crm/v1/meals/list/{$0}/{$1}/{$2}?page={$3}&name={$4}&tag_id={$5}',
    // api_routes.meals_list}/${this.meta.per_page}/${this.sort_by}/${this.sort_dir}?page=${this.meta.current_page}&name=${this.name_like}&tag_id=${this.selected_tag}
    meals_by_name: '/api/crm/v1/meals/by_name/{$0}', // используется при добавлении блюда в оформленный заказ
    meals_by_tag: '/api/crm/v1/meals/by_tag', // not used yet
    meal_store: '/api/crm/v1/meals', // post
    meal_delete: '/api/crm/v1/meals/delete', // post id

    meal_by_id: '/api/crm/v1/meal/{$0}',
    meal_update_info: '/api/crm/v1/meal/update/info',
    meal_attach_tag: '/api/crm/v1/meal/attach/tag',
    meal_detach_tag: '/api/crm/v1/meal/detach/tag',

    meal_attach_recommendation: '/api/crm/v1/meal/attach/recommendation',
    meal_detach_recommendation: '/api/crm/v1/meal/detach/recommendation',

    meal_attach_ingredient: '/api/crm/v1/meal/attach/ingredient',
    meal_update_ingredient: '/api/crm/v1/meal/update/ingredient',
    meal_detach_ingredient: '/api/crm/v1/meal/detach/ingredient',

    meal_attach_image: '/api/crm/v1/meal/attach/image',
    meal_detach_image: '/api/crm/v1/meal/detach/image',

    meals_tags: '/api/crm/v1/meals/tags',
    tags_all: '/api/crm/v1/tags/all',
    tag_by_id: '/api/crm/v1/tags/{$0}',
    tag_update_info: '/api/crm/v1/tags/update/info', // post
    tag_store: '/api/crm/v1/tags', // post
    tag_delete: '/api/crm/v1/tags/delete', // post

    ingredients_all: '/api/crm/v1/ingredients/all',
    ingredients_list: '/api/crm/v1/ingredients/list/{$0}/{$1}/{$2}?page={$3}&name={$4}',
    ingredient_store: '/api/crm/v1/ingredients', // post
    ingredient_by_id: '/api/crm/v1/ingredients/{$0}', // get
    ingredient_update_info: '/api/crm/v1/ingredients/update/info', // post
    ingredients_stock_changes_all: '/api/crm/v1/ingredients/stock_changes/all/{$0}/{$1}', // get criteria period
    ingredients_delete: '/api/crm/v1/ingredients/delete',

    news_all: '/api/crm/v1/news/all', // get
    news_by_id: '/api/crm/v1/news/{$0}', // {id}
    news_store: '/api/crm/v1/news', // post
    news_attach_image: '/api/crm/v1/news/attach/image',
    news_detach_image: '/api/crm/v1/news/detach/image',
    news_update_info: '/api/crm/v1/news/update/info', // post
    news_delete: '/api/crm/v1/news/delete', // post item_id

    sales_all: '/api/crm/v1/sales/all', // get
    sales_by_id: '/api/crm/v1/sales/{$0}', // {id}
    sales_store: '/api/crm/v1/sales', // post
    sales_attach_image: '/api/crm/v1/sales/attach/image',
    sales_detach_image: '/api/crm/v1/sales/detach/image',
    sales_update_info: '/api/crm/v1/sales/update/info', // post
    sales_delete: '/api/crm/v1/sales/delete', // post item_id

    promocodes_all: '/api/crm/v1/promocodes/all', // get
    promocodes_by_id: '/api/crm/v1/promocodes/{$0}', // {id}
    promocode_by_slug: '/api/crm/v1/promocodes/by_slug/{$0}', // get
    promocodes_store: '/api/crm/v1/promocodes', // post
    promocodes_update_info: '/api/crm/v1/promocodes/update/info', // post
    promocodes_delete: '/api/crm/v1/promocodes/delete', // post item_id

    combos_all: '/api/crm/v1/combos/all',
    combos_list: '/api/crm/v1/combos/list/{$0}/{$1}/{$2}?page={$3}&name={$4}',
    combos_by_name: '/api/crm/v1/combos/by_name/{$0}', // используется при добавлении блюда в оформленный заказ
    combo_by_id: '/api/crm/v1/combos/{$0}', // {id}
    combo_store: '/api/crm/v1/combos', // post
    combo_update_info: '/api/crm/v1/combos/update/info', // post
    combo_attach_image: '/api/crm/v1/combos/attach/image',
    combo_detach_image: '/api/crm/v1/combos/detach/image',
    combo_delete: '/api/crm/v1/combos/delete', // post item_id
    combo_attach_meal: '/api/crm/v1/combos/attach/meal', // post combo_id meal_id amount
    combo_detach_meal: '/api/crm/v1/combos/detach/meal', // post combo_id meal_id

    staff_all: '/api/crm/v1/staff/all',
    staff_by_my_branch: '/api/crm/v1/staff/by_branch/my',
    staff_by_id: '/api/crm/v1/staff/{$0}', // {id}
    staff_store: '/api/crm/v1/staff', // post
    staff_update_info: '/api/crm/v1/staff/update/info', // post
    staff_attach_avatar: '/api/crm/v1/staff/attach/avatar', // post
    staff_detach_avatar: '/api/crm/v1/staff/detach/avatar', // post
    staff_salaries: '/api/crm/v1/staff/{$0}/salaries', // get id
    staff_salary_store: '/api/crm/v1/staff/salaries', // post staff_id salary_amount date
    staff_salary_delete: '/api/crm/v1/staff/salaries/delete', // post salary_id

    customers_all: '/api/crm/v1/customers/all',
    customers_list: '/api/crm/v1/customers/list/{$0}?page={$1}', // per_page
    customer_by_id: '/api/crm/v1/customers/{$0}', // id
    customer_update_info: '/api/crm/v1/customers/update/info', // post
    customer_by_phone: '/api/crm/v1/customers/by_phone/{$0}',
    customer_by_address: '/api/crm/v1/customers/by_address/{$0}',
    customer_store: '/api/crm/v1/customers', // post
    customer_gifts: '/api/crm/v1/customers/{$0}/gifts', // get
    customer_create_by_phone: '/api/crm/v1/customers/create/by_phone', // post
    customer_attach_address: '/api/crm/v1/customers/attach/address', // post
    customer_update_address: '/api/crm/v1/customers/update/address', // post
    customer_detach_address: '/api/crm/v1/customers/detach/address', // post
    customer_update_or_create_address: '/api/crm/v1/customers/update/create/address', // post
    customer_latest_order: '/api/crm/v1/customers/{$0}/order/latest', // get by cus id
    customer_labels: '/api/crm/v1/customers/labels', // get
    customer_attach_phone: '/api/crm/v1/customers/attach/phone', // post customer_id phone_id
    customer_detach_phone: '/api/crm/v1/customers/detach/phone', // post customer_id phone_id
    customer_attach_label: '/api/crm/v1/customers/attach/label', // post customer_id label_id
    customer_detach_label: '/api/crm/v1/customers/detach/label', // post customer_id label_id
    customer_attach_gift: '/api/crm/v1/customers/attach/gift', // post customer_id gift_type gift_id
    customer_update_gift: '/api/crm/v1/customers/update/gift', // post gift_id
    customer_detach_gift: '/api/crm/v1/customers/detach/gift', // post gift_id
    customer_delete: '/api/crm/v1/customers/delete', // post customer_id

    get_current_user: '/api/user',

    addresses_all: '/api/crm/v1/addresses/all',
    addresses_list: '/api/crm/v1/addresses/list/{$0}/{$1}/{$2}?page={$3}',
    address_store: '/api/crm/v1/addresses', // post
    address_by_id: '/api/crm/v1/addresses/{$0}',
    address_by_label: '/api/crm/v1/addresses/by_label/{$0}',
    address_update_info: '/api/crm/v1/addresses/update/info', // post
    address_by_city_street_building: '/api/crm/v1/addresses/by_city_street_building/{$0}/{$1}/{$2}',
    address_encode: '/api/crm/v1/addresses/encode', // post country_name city_name street_name building_number
    address_delete: '/api/crm/v1/addresses/delete', // post id

    streets_all: '/api/crm/v1/streets/all',
    streets_list: '/api/crm/v1/streets/list/{$0}/{$1}/{$2}?page={$3}&name={$4}',
    street_by_id: '/api/crm/v1/streets/{$0}',
    street_delete: '/api/crm/v1/streets/delete', // post id
    street_update_info: '/api/crm/v1/streets/update/info', // post
    streets_my_city: '/api/crm/v1/streets/city/my',
    building_by_id: '/api/crm/v1/buildings/{$0}',
    building_update_info: '/api/crm/v1/buildings/update/info', // post
    building_delete: '/api/crm/v1/buildings/delete', // post id

    phones_list: '/api/crm/v1/phones/list/{$0}/{$1}/{$2}?page={$3}',
    phone_store: '/api/crm/v1/phones', // post
    phone_by_id: '/api/crm/v1/phones/{$0}', // get
    phone_by_number: '/api/crm/v1/phones/by_number/{$0}', // post
    phone_update_info: '/api/crm/v1/phones/update/info', // post
    phone_delete: '/api/crm/v1/phones/delete', // post
    phone_history: '/api/crm/v1/phones/{$0}/history',

    orders_active: '/api/crm/v1/orders/active/{$0}/{$1}/{$2}',
    order_store: '/api/crm/v1/orders',
    order_update_info: '/api/crm/v1/orders/update/info', // update single specific info
    order_by_id: '/api/crm/v1/orders/{$0}', // get

    order_items_in_progress: '/api/crm/v1/orders/items/in_progress',
    order_item_update_info: '/api/crm/v1/orders/items/update/info', // post
    order_items_add_to_stock_changes: '/api/crm/v1/orders/{$0}/items/stock_changes/store', // get order_id
    order_items_remove_stock_changes: '/api/crm/v1/orders/{$0}/items/stock_changes/delete', // get order_id
    order_items_remove_stock_changes_by_id: '/api/crm/v1/orders/items/stock_changes/{$0}/delete', // get item_id

    couriers_active: '/api/crm/v1/couriers/active',

    roles_all: '/api/crm/v1/roles/all',
    roles_list: '/api/crm/v1/roles/list/{$0}/{$1}/{$2}?page={$3}',
    role_by_id: '/api/crm/v1/roles/{$0}', // {id}
    role_update_info: '/api/crm/v1/roles/update/info', // post
    role_store: '/api/crm/v1/roles/store', // post
    role_delete: '/api/crm/v1/roles/delete',

    permissions_all: '/api/crm/v1/permissions/all',
    permissions_list: '/api/crm/v1/permissions/list/{$0}/{$1}/{$2}?page={$3}',
    permission_by_id: '/api/crm/v1/permissions/{$0}', // {id}
    permission_update_info: '/api/crm/v1/permissions/update/info', // post
    permission_store: '/api/crm/v1/permissions/store', // post

    permission_attach_role: '/api/crm/v1/permissions/attach/role',
    permission_detach_role: '/api/crm/v1/permissions/detach/role',
    permission_delete: '/api/crm/v1/permissions/delete',

    firms_all: '/api/crm/v1/firms/all',
    firm_by_id: '/api/crm/v1/firms/{$0}',
    firm_update_info: '/api/crm/v1/firms/update/info', // post

    branches_all: '/api/crm/v1/branches/all',
    branch_by_id: '/api/crm/v1/branches/{$0}',
    branch_update_info: '/api/crm/v1/branches/update/info', // post

    departments_all: '/api/crm/v1/departments/all',
    chats_all: '/api/crm/v1/chats/all',
    messages_with: '/api/crm/v1/messages/with/{$0}/{$1}',
    messages_post: '/api/crm/v1/messages/send',

    paycheck_show: '/api/crm/v1/orders/paycheck/{$0}/{$1}',

    get_images: 'https://static.omelette.kz/?get_images&it={$0}&st={$1}',
    image_update: '/api/crm/v1/images/update/info', // post

    report_stats: '/api/crm/v1/reports/stats', // get
    report_day: '/api/crm/v1/reports/day/{$0}/{$1}/{$2}/{$3}/{$4}/{$5}', // get
    // 'report_day_orders' : '/api/crm/v1/reports/day/orders/{$0}', //get
    report_month: '/api/crm/v1/reports/month/{$0}', // get
    report_week: '/api/crm/v1/reports/week/{$0}', // get
    report_filtered: '/api/crm/v1/reports/filtered/{$0}/{$1}/{$2}/{$3}', // get

    ingredient_attach_child: '/api/crm/v1/ingredients/attach/child', // post parent_id child_id amount
    ingredient_detach_child: '/api/crm/v1/ingredients/detach/child', // post parent_id child_id
    ingredient_child_update_info: '/api/crm/v1/ingredients/child/update/info', // post parent_id child_id info_index new_value
    ingredient_stock_change_store: '/api/crm/v1/ingredients/stock_change/store', // post change.ingredient_id change.amount change.type chane.issued_at?
    ingredient_stock_change_update_info: '/api/crm/v1/ingredients/stock_change/update/info', // post item_id info_index new_value
    ingredient_set_to_zero: '/api/crm/v1/warehouse/stock_changes/set_to_zero', // post ingredient object
    ingredient_set_in_stock: '/api/crm/v1/warehouse/stock_changes/set_in_stock', // post ingredient object

    expenses_all: '/api/crm/v1/expenses/all', // get
    expense_store: '/api/crm/v1/expenses', // post
    expense_delete: '/api/crm/v1/expenses/delete', // post
    incomes_all: '/api/crm/v1/incomes/all', // get
    income_store: '/api/crm/v1/incomes', // post
    income_delete: '/api/crm/v1/incomes/delete', // post

    stock_change_by_id: '/api/crm/v1/ingredients/stock_changes/{$0}'

}

export function api_route (routeName, params = null) {
    let routeStr = api_routes[routeName]
    if (params) {
        params.forEach((value, index) => {
            routeStr = routeStr.replace('{$' + index.toString() + '}', value)
        })
    }
    return routeStr
}

export default api_routes
