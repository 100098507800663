<template lang="">
    <!-- чтобы хром не пытался их автозаполнить -->
    <input type='email' style='display: block; width: 0px'/>
    <input type='password' style='display: block; width: 0px' />
    <!-- -->
    <div class="flex flex-wrap w-full h-full justify-center items-center">
        <div class="flex flex-wrap flex-col max-w-xl m-auto rounded-md w-10/12 md:w-3/12 xl:w-3/12 2xl:w-3/12">
            <div class="flex justify-center text-3xl py-8">
                <h1><span class="font-semibold">Breaktime</span> CRM</h1>
            </div>
            <div class="flex flex-wrap flex-col rounded-md w-full border shadow-lg bg-white p-2 px-4 "
                :class="[errors.length != 0 ? 'shake' : '']">
                <div class="pt-4 pb-2 text-gray-800 text-center"><h1>Регистрация в системе</h1></div>
                <div class="pt-0 pb-4 text-gray-800 text-center"><h1>Шаг {{ step }} из 2</h1></div>
                <div v-show="step === 1 ">
                    <div class="p-2">
                        <label class="w-full">Email</label>
                        <text-input
                            v-model="form.email"
                            iconUrl="https://img.icons8.com/ios-glyphs/256/user.png"
                            placeholder="Email"
                            type="email"
                            validate="email"
                            required
                            ref="emailInput"
                            />
                    </div>
                    <div class="p-2">
                        <label class="w-full">Пароль</label>
                        <text-input
                            v-model="form.password"
                            iconUrl="https://img.icons8.com/ios-filled/256/lock-2.png"
                            placeholder="Пароль"
                            type="password"
                            validate="min:4|max:25"
                            ref="passwordInput1"
                            />
                    </div>
                    <div class="p-2">
                        <label class="w-full">Повторите Пароль</label>
                        <text-input
                            v-model="form.password_confirmation"
                            iconUrl="https://img.icons8.com/ios-filled/256/lock-2.png"
                            placeholder="Пароль"
                            type="password"
                            validate="min:4|max:25"
                            ref="passwordInput2"
                            />
                    </div>
                    <div class="p-4 w-full flex-col justify-center items-center mt-2 select-none">
                        <div class="w-full flex flex-col justify-center items-center">
                            <div @click="gotoStep(2)" class="w-3/5 text-center cursor-pointer text-black border py-2 px-4 focus:outline-none hover:bg-lime-300 active:bg-lime-900 rounded-full">Продолжить &rarr;</div>
                        </div>
                    </div>
                </div>
                <div v-show="step === 2 ">
                    <div class="p-2">
                        <label class="w-full">Фамилия</label>
                        <text-input
                            v-model="form.last_name"
                            iconUrl="https://img.icons8.com/ios-glyphs/256/user.png"
                            placeholder="Фамилия"
                            type="text"
                            validate="text|min:2|max:255"
                            required
                            />
                    </div>
                    <div class="p-2">
                        <label class="w-full">Имя</label>
                        <text-input
                            v-model="form.first_name"
                            iconUrl="https://img.icons8.com/ios-glyphs/256/user.png"
                            placeholder="Имя"
                            type="text"
                            validate="text|min:2|max:255"
                            required
                            />
                    </div>
                    <div class="p-4 flex items-center justify-between bg-gray-100 mt-4 mx-2 rounded-md">
                        <input type="checkbox" v-model="form.agreed" class="ml-2 mr-6"/>
                        <div>Я прочитал и согласен с условиями пользовательского соглашения</div>
                    </div>
                    <div class="p-4 w-full flex-col justify-center items-center my-2 select-none">
                        <div class="w-full flex flex-col justify-center items-center">
                            <button @click.prevent="saveForm" type="submit" class="w-3/5 text-center text-white bg-lime-500 border-0 py-2 px-4 focus:outline-none hover:bg-lime-600 active:bg-lime-900 rounded-full">Зарегистрироваться</button>
                        </div>
                    </div>
                </div>
                <div class="w-full py-4 mt-0 border-t">
                    <router-link :to="{ name: 'Login' }">
                        <div class="text-md text-center cursor-pointer hover:font-semibold hover:bg-lime-100 py-4 rounded-md">&larr; Войти</div>
                    </router-link>
                </div>
            </div>
            <div class="flex justify-center pb-32">
                <div class="p-4 w-full text-rose-500 text-center bg-red-100 rounded-br-md rounded-bl-md" v-if="errors.length > 0">
                    <div v-for="error, index in errors" :key="index">{{ error }}</div>
                </div>
                <div v-else class="p-4">&nbsp;</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            form: {
                email: 'aj_pktl@mail.ru',
                password: '341105aj',
                password_confirmation: '341105aj',
                first_name: 'Altz',
                last_name: 'Zh',
                agreed: false
            },
            step: 1,
            errors: []
        }
    },
    methods: {
        gotoStep (step) {
            this.errors = []
            switch (step) {
                case 1:
                    this.step = 1
                    break
                case 2:
                    if (!this.$refs.emailInput.isValid) this.errors = this.$refs.emailInput.validationErrors
                    else if (!this.$refs.passwordInput1.isValid) this.errors = this.$refs.passwordInput1.validationErrors
                    else if (!this.$refs.passwordInput2.isValid) this.errors = this.$refs.passwordInput2.validationErrors
                    else if (this.form.password !== this.form.password_confirmation) this.errors = ['Пароли не совпадают']
                    else this.step = 2
            }
        },
        saveForm () {
            if (!this.form.agreed) this.errors = ['Условия пользовательского соглашения']
            this.$store
                    .dispatch('registerUser', this.form)
                    .then()
                    .catch((errors) => { this.errors = errors; this.step = 1; this.form.agreed = false })
                    .finally(() => setTimeout(() => { this.isLogginIn = false }, 300))
        }
    }

}
</script>
<style scoped>

    .shake {
        /* animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both; */
        animation: shake 0.62s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
    }

    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }
</style>
