<template lang="">
    <div class="chat-app" v-show="false">
        <div>
            <div v-show="showNotifications">
                <notification-center ref="nc"></notification-center>
            </div>
        </div>
        <div class="flex flex-row justify-end ml-4 items-end">
            <div v-if="showChats">
                <div class="flex flex-col rounded-lg bg-white border drop-shadow-lg">
                    <div class="flex flex-row justify-between border-b">
                        <div class="flex text-md font-semibold">
                            <div v-if="selected_reciever_id != 0" @click="deselectChat()" class="text-md font-semibold px-1 cursor-pointer hover:bg-yellow-300 bg-gray-300 rounded-tl-lg flex  items-center">
                                <img src="https://cdn0.iconfinder.com/data/icons/web-seo-and-advertising-media-1/512/218_Arrow_Arrows_Back-512.png" class="w-6"/>
                            </div>
                            <div class="py-2 px-4">Chats</div>
                        </div>
                        <div class="text-md font-semibold px-2 cursor-pointer hover:bg-yellow-300 bg-gray-300 rounded-bl-lg rounded-tr-lg flex  items-center" @click="showChats = false">x</div>
                    </div>
                    <div class="flex flex-col" style="width: 250px">
                        <div v-if="selected_reciever_id != 0" class="selected-chat flex  flex-col items-end">
                            <div v-if="messages.length > 0"  ref='messagesWrapper' class="w-full p-2 overflow-x-hidden overflow-y-auto flex flex-col items-end" style="min-height: 350px; max-height: 450px; ">
                                <!-- <div ref='messagesInner' class="w-full overflow-x-hidden"> -->
                                    <div v-for="message in messages" class="w-full mb-2">
                                        <div v-if="message.author_id == user.id">
                                            <div class="w-full flex justify-end">
                                                <div class="bg-green-100 p-2 text-sm rounded-md text-right" style="max-width: 85%">{{ message.body }}</div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="w-full flex justify-start">
                                                <div class="bg-blue-100 p-2 text-sm rounded-md text-left" style="max-width: 85%">{{ message.body }}</div>
                                            </div>
                                        </div>
                                    </div>                             
                                <!-- </div>                              -->
                            </div>
                            <div v-else class="p-8 flex justify-center">
                                <i>Сообщений в чате пока нет</i>
                            </div>
                            <div class=" border-t w-full">
                                <input class="py-2 pl-4 w-full outline-none" type="text" v-model="new_message.body" @keyup.enter="sendMessage()"/>
                            </div>
                        </div>
                        <div v-else class="chats-list">
                            <div v-for="chat in chats" class="flex items-center p-2 cursor-pointer hover:bg-yellow-300"  @click="selectChat('user',chat.id)">
                                <div class="p-1 bg-gray-100 rounded-full">
                                    <img :src="chat.avatar_url?.url" style="height: 24px; width: 24px"/>
                                </div>
                                <div class="text-sm ml-2 w-full flex justify-between items-center">
                                    <div>{{ chat.first_name }}</div>
                                    <div v-if="chat.unread_messages > 0" class="text-xs p-1 rounded-full bg-gray-100">{{ chat.unread_messages }}</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div @click="toggleChat()" class="flex bg-gray-100 rounded-full drop-shadow-md rounded-lg p-1 cursor-pointer">
                <img src="https://cdn-icons-png.flaticon.com/512/1380/1380370.png" class="w-6 h-6"/>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { api_route } from '../config/api_routes';
import NotificationCenter from './NotificationCenter';

export default {
    data: () => {
        return {
            user: {
                id: 0
            },
            selected_reciever_type: '',
            selected_reciever_id: 0,
            is_getting_messages: false,
            showChats: false,
            showNotifications: true,
            chats: [],
            messages: [],
            new_message: {
                body : ''
            },
            polling_chats: null,
            polling_messages: null,
            update_interval: 5000,
        }
    },
    methods: {
        pollMessages () {
            this.polling_messages = setInterval(() => {
                this.getChatMessages()
            }, this.update_interval)
        },
        pollChats () {
            this.polling_chats = setInterval(() => {
                this.getChats()
            }, this.update_interval)
        },
        getChatMessages(){
            if(!this.is_getting_messages){
                this.is_getting_messages = true
                axios
                    .get(api_route('messages_with', [this.selected_reciever_type, this.selected_reciever_id]))
                    .then((res)=>{
                        this.messages = res.data
                    })
                    .catch((errors)=> console.log(errors))
                    .finally(()=>this.is_getting_messages = false)
            }
        },
        getChats(){
            axios
                .get(api_route('chats_all'))
                .then((res)=>{
                    this.chats = res.data
                })
                .catch((errors)=> console.log(errors))
        },
        toggleChat(){
            if(this.showChats == false){
                this.pollChats()
            } else {
                clearInterval(this.polling_chats)
                clearInterval(this.polling_messages)
            }
            this.showChats = !this.showChats
        },
        selectChat(chat_type, chat_id){
            this.selected_reciever_type = chat_type,
            this.selected_reciever_id = chat_id

            this.pollMessages()
            this.getChatMessages()
        },
        deselectChat(){
            this.selected_reciever_type = '',
            this.selected_reciever_id = 0

            clearInterval(this.polling_messages)
        },
        sendMessage(){
            axios
                .post(api_route('messages_post'), {
                    reciever_type: this.selected_reciever_type,
                    reciever_id: this.selected_reciever_id,
                    message_body: this.new_message.body,
                })
                .then((res)=>{
                    this.new_message.body = ''
                    this.getChatMessages()
                })
                .catch();
        }
    },
    mounted(){
        // offline for now
        // this.user = this.$store.getters.currentUser
        // this.getChats()
    },
    components: {
        NotificationCenter,
    },
    beforeDestroy () {
        clearInterval(this.polling_chats)
        clearInterval(this.polling_messages)
    },
    created () {
        
    },
    updated(){
        if(this.selected_reciever_id > 0){
            var messagesWrapper = this.$refs.messagesWrapper;
            if(messagesWrapper){
                messagesWrapper.scrollTop = messagesWrapper.scrollHeight
            }
            // console.log(messageDisplay.$el)
            // messageDisplay.scrollTop = messageDisplay.scrollHeight;
        }
    }
}
</script>
<style scoped>
    .chat-app{
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 999;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
    }
    /* width */
    ::-webkit-scrollbar {
    width: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }
</style>