<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-end pt-6  pb-4">
                    <div class="w-1/2 flex flex-col justify-around pl-2 ">
                        <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-2">Склад ингредиентов</h1>
                        <span class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}}</span>
                    </div>
                    <div class="w-1/2 flex flex-row justify-end items-center">
                        <!-- <div v-if="can('create_ingredients')">
                            <router-link :to="{name: 'WarehouseIngredientsCreate' }" class="py-2 px-6 rounded-md bg-gray-100 cursor-pointer hover:bg-green-300"> + Ингредиент </router-link>
                        </div>
                        <div v-if="can('refill_ingredients')">
                            <router-link :to="{name: 'WarehouseIngredientsRefill' }" class="py-2 px-6 rounded-md bg-gray-100 cursor-pointer hover:bg-green-300"> Пополнение | Списание </router-link>
                        </div> -->
                    </div>
                </div>
                <div class="w-full rounded-lg border-2 border-gray-100">
                    <div class="items_list">
                        <div class="w-full flex flex-row bg-gray-100">
                            <div class="w-1/12 p-2"></div>
                            <div class="w-7/12 p-2" >Наименование</div>
                            <!-- <div class="w-2/12 p-2 text-right" >Пополнения</div>
                            <div class="w-2/12 p-2 border-r text-right" >Списания</div>
                            <div class="w-2/12 p-2 text-right " >Потребления</div>
                            <div class="w-2/12 p-2 text-right " >Корректировки</div> -->
                            <div class="w-4/12 p-2 text-right " >Остатки</div>
                            <!-- <div class="w-2/12 p-2 text-right " >Порог</div>
                            <div class="w-2/12 p-2 text-right " >Net</div> -->
                        </div>
                        <div v-for="item, index in ingredients" :key="item.id">
                            <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed">
                                <div class="w-full flex flex-row hover:bg-gray-100 items-center">
                                    <div class="w-1/12 flex justify-center">
                                        <router-link :to="{ name: 'IngredientsEdit', params: {id: item.id}}">{{ index + 1 }}</router-link>
                                    </div>
                                    <div class="w-7/12 p-2 flex flex-col" :class="[item.summ <=0 ? 'text-red-300' : '']">
                                        <div>
                                            {{ item.name }}
                                        </div>
                                        <!-- <router-link :to="{name: 'ItemsEdit', params: { id: item.id  }}">{{ item.name }}</router-link> -->
                                        <div class=" flex flex-row text-sm text-gray-300">
                                            <!-- | {{ item.name_short }}
                                            | {{ item.description }}
                                            | {{ item.emoji }}
                                            | {{ item.slug }}  -->
                                        </div>
                                    </div>
                                    <!-- <div class="w-2/12 px-2 text-right">
                                        <select v-model="item.priority" @change="updateIngredientInfo(item, 'priority', item.priority)" class="py-2 px-2 border-b outline-none w-11/12 text-right">
                                            <option value="100">Очень высокий</option>
                                            <option value="50">Высокий</option>
                                            <option value="20">Выше среднего</option>
                                            <option value="10">Средний</option>
                                            <option value="5">Ниже среднего</option>
                                            <option value="2">Низкий</option>
                                            <option value="1">Очень низкий</option>
                                        </select>
                                    </div> -->
                                    <!-- <div class="w-2/12 px-2 text-right">
                                        {{ item.refills || 0 }}
                                    </div>
                                    <div class="w-2/12 px-2 text-right border-r">
                                        {{ item.writeoffs || 0 }}
                                    </div>
                                    <div class="w-2/12 px-2 text-right border-r">
                                        {{ item.consumptions || 0 }}
                                    </div>
                                    <div class="w-2/12 px-2 text-right border-r">
                                        {{ item.corrections || 0 }}
                                    </div> -->
                                    <div class="w-4/12 px-2 text-right border-r">
                                        {{ item.summ || 0 }}
                                    </div>
                                    <!-- <div class="w-2/12 px-2 text-right border-r">
                                        {{ item.notify_at || 0 }}
                                    </div>
                                    <div class="w-2/12 px-2 text-right border-r">
                                        {{ item.net || 0 }}
                                    </div> -->
                                    <!--
                                    <div class="w-1/12 px-2">{{ item.weight }}</div>
                                    <div class="w-1/12 px-2 text-sm">{{ item.is_active ? 'Активно' : 'Не активно' }}</div>
                                    <div class="w-1/12 px-2 text-sm">{{ item.is_seen ? 'Видимо' : 'Скрыто' }}</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            meta: {
                current_page: 0,
                per_page: 20,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0
            },
            sort_by: 'priority',
            sort_dir: 'ASC',
            name_like: '',
            selected_tag: '',
            items: [],
            ingredients: []
            // showEditItemDialog: false,
        }
    },
    methods: {
        getIngredients () {
            this.getServerData('ingredients_list', { per_page: this.meta.per_page, sort_by: this.sort_by, sort_dir: this.sort_dir, current_page: this.meta.current_page, name_like: this.name_like })
                .then((res) => {
                    // this.meta.current_page = res.data.current_page
                    // this.meta.per_page = res.data.per_page
                    // this.meta.last_page = res.data.last_page
                    // this.meta.links = res.data.links
                    // this.meta.total = res.data.total
                    // this.meta.from = res.data.from
                    // this.meta.to = res.data.to
                    this.ingredients = res.data
                    console.log(res)
                })
                .catch((error) => {})
        },
        changePage (page) {
            this.$router.push({ name: this.$route.name, params: { page } })
        },
        updateIngredientInfo (item, info_index, new_value) {
            this.postServerData('ingredient_update_info', { data: { item_id: item.id, info_index, new_value } })
                .then((res) => {
                    this.getIngredients()
                })
                .catch((error) => {})
        }
    },
    mounted () {
        if (this.$route.params.page) {
            this.meta.current_page = this.$route.params.page
        }
        this.getIngredients()
    },
    watch: {
        $route (to, from) {
            if (to.name == this.$route.name) {
                this.meta.current_page = to.params.page
                this.getIngredients()
            }
        }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
