import { createStore } from 'vuex'
import router from '../router'
import axios from 'axios'

const User = {
    id: null,
    branch_id: null,
    email: null,
    email_verified_at: null,
    name: null,
    created_at: null,
    updated_at: null
}

export default createStore({
    state: {
        user: undefined,
        at: undefined
    },
    getters: {
        loggedIn: (state) => {
            if(state.at != undefined) return state.at

            let cookieToken = ''
            let name = "refreshToken=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    cookieToken = c.substring(name.length, c.length);
                }
            }

            if (cookieToken != ''){
                state.at = cookieToken
                return cookieToken
            }

            return undefined
        },
        currentUserAT: (state) => {
            return state.at
        },
        currentUser: (state) => {
            return state.user
        }
    },
    mutations: {
        setAt (state, at) {
            state.at = at
        },
        setUser (state, user) {
            state.user = user
        },
        clearUser (state) {
            state.user = undefined
            state.at = undefined
        }
    },
    actions: {
        // функции с мутациями
        // например, запрос на сервер, а потом мутация для сохранения состояния
        getUser ({ commit }) {
            if (this.getters.loggedIn === undefined) return
            return axios
                    .get('https://api.breaktime.kz/api/v2/me', {
                        headers: {
                            Authorization: this.getters.loggedIn
                        }
                    })
                    .then((res) => {
                        if (res.data.code === 200) {
                            commit('setUser', res.data.data)
                            return true
                        } else
                        if (res.data.code !== 200) {
                            document.cookie = "refreshToken=;"
                            commit('clearUser')

                            router.push({ name: 'Login' })
                            return false
                        }
                    })
                    .catch((error) => {
                        document.cookie = "refreshToken=;"
                        commit('clearUser')

                        router.push({ name: 'Login' })
                        return false
                    })
        },
        loginUser ({ commit, mutations }, user) {
            // axios.get('/sanctum/csrf-cookie').then(response => {
            return new Promise((resolve, reject) => {
                    axios
                        .post('https://api.breaktime.kz/api/v2/login', {
                            email: user.email,
                            password: user.password
                        })
                        .then((res) => {
                            if (res.data.code === 200) {
                                commit('setUser', res.data.data.user)
                                commit('setAt', res.data.data.at)
                                document.cookie = "refreshToken=" + res.data.data.at

                                router.push({ name: 'Home' })
                                resolve('Пользователь найден')
                            } else {
                                reject('Пользователь не найден')
                            }
                        })
                        .catch(() => {
                            // reject(error.message)
                            reject('Пользователь не найден')
                        })
                })
            // });
        },
        registerUser ({ commit, mutations }, user) {
            // axios.get('/sanctum/csrf-cookie').then(response => {
            return new Promise((resolve, reject) => {
                    axios
                        .post('https://api.breaktime.kz/api/v2/register', user)
                        .then((res) => {
                            if (res.data.code === 200) {
                                commit('setUser', res.data.data.user)
                                commit('setAt', res.data.data.at)
                                document.cookie = "refreshToken=" + res.data.data.at
                                router.push({ name: 'Home' })

                                resolve('Пользователь найден')
                            } else {
                                reject('Пользователь не найден')
                            }
                        })
                        .catch((errors) => {
                            console.log(errors.response?.data?.errors)
                            const server_errors = ['Регистрация не удалась']
                            Object.values(errors.response?.data?.errors).map((se) => se.map((item) => server_errors.push(item)))
                            reject(server_errors)
                        })
                })
            // });
        },
        logoutUser ({ commit }) {
            axios
                .get('https://api.breaktime.kz/api/v2/logout', {
                    headers: {
                        Authorization: this.getters.loggedIn
                    }
                })
                .finally(() => {
                    document.cookie = "refreshToken=;"
                    commit('clearUser')

                    router.push({ name: 'Login' })
                })
        }
    },
    modules: {
        // весь код можно поменять на части
        // post: PostModule
    }
})
