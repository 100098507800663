<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="p-4">
                    <h1 class="text-xl lg:text-2xl text-gray-800 font-semibold pt-2 min-h-4">{{ item.name || '...' }} </h1>
                </div>
                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                        <div class=" border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Наименование</label>
                                    <text-input v-model="item.name" @change="updateItemInfo('name', item.name)"  type="text" placeholder="Наименование" ref="name" validate="min:4|max:255"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Описание</label>
                                    <textarea v-model="item.description" @change="updateItemInfo('description', item.description)" class="p-2 border outline-none h-36"></textarea>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Дополнительная информация</h3>
                        <div class=" border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-7/12 flex flex-col">
                                    <label class="px-2">Короткое описание</label>
                                    <input v-model="item.name_short" @change="updateItemInfo('name_short', item.name_short)" class="p-2 border outline-none" type="text"/>
                                </div>
                                <div class="w-5/12 flex flex-col">
                                    <label class="px-2">Эмодзи</label>
                                    <select v-model="item.emoji" @change="updateItemInfo('emoji', item.emoji)" class="p-2 border outline-none">
                                        <option value="🍜">🍜 Блюда</option>
                                        <option value="🍲">🍲 Супы</option>
                                        <option value="☕">☕ Напитки</option>
                                        <option value="🍔">🍔 Фастфуд</option>
                                        <option value="🥗">🥗 Салат</option>
                                        <option value="🍕">🍕 Пицца</option>
                                        <option value="🍣">🍣 Суши</option>
                                        <option value="🍢">🍢 Барбекю</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Слаг</label>
                                    <text-input v-model="item.slug" @change="updateItemInfo('slug', item.slug)" type="text" placeholder="Слаг" ref="slug" validate="min:4|max:255"/>
                                </div>
                            </div>
                            <div class="flex flex-row flex-wrap p-4">
                                <div class="w-6/12 lg:w-3/12 flex flex-col">
                                    <label class="px-2">Родитель</label>
                                    <select v-model="item.parent_id" @change="updateItemInfo('parent_id', item.parent_id)" class="p-2 border outline-none">
                                        <option value="0" selected>Нет</option>
                                        <option v-for="tag in tags" :value="tag.id">{{ tag.name }}</option>
                                    </select>
                                </div>
                                <div class="w-6/12 lg:w-3/12 flex flex-col">
                                    <label class="px-2">Приоритет</label>
                                    <text-input v-model="item.weight" @change="updateItemInfo('weight', item.weight)" type="number" placeholder="Приоритет" ref="weight" validate="number|max:9999"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Активно</label>
                                    <toggle-button v-model="item.is_active" @change="updateItemInfo('is_active', item.is_active)" :values="['1:Да','0:Нет']"/>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Видимо</label>
                                    <toggle-button v-model="item.is_seen" @change="updateItemInfo('is_seen', item.is_seen)" :values="['1:Да','0:Нет']"/>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Отслеживать</label>
                                    <toggle-button v-model="item.is_instats" @change="updateItemInfo('is_instats', item.is_instats)" :values="['1:Да','0:Нет']"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section v-if="can('delete_tags')" class="flex flex-col pb-6 px-4">
                    <div class="w-full flex flex-col items-end">
                        <default-button @confirm="deleteItem()" pressconfirm type="delete">Удалить категорию</default-button>
                    </div>
                </section>
            </section>
        </div>
    </div>
    <confirm-alert ref="confirmAlert"></confirm-alert>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                id: 0,
                parent_id: 0
            },
            tags: []
        }
    },
    methods: {
        getTagById () {
            this.getServerData('tag_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data
                })
                .catch((error) => console.log(error))
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('tag_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {

                })
                .catch((error) => console.log(error))
        },
        getTags () {
           this.getServerData('tags_all')
                .then((res) => {
                    this.tags = res.data
                })
                .catch((error) => console.log(error))
        },
        async deleteItem () {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Удалить категорию?',
                description: 'Удалить категорию безвозвратно',
                okButton: 'Удалить'
            })
            if (confirmdel) {
                this.postServerData('tag_delete', { item_id: this.item.id })
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.$router.push({ name: 'Tags' })
                        }
                    })
                    .catch((error) => console.log(error))
            }
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getTagById()
        this.getTags()
    }
}
</script>
<style lang="">

</style>
