<template>
    <select  @change="changeOptions">
        <option selected disabled value="">Select Value</option>
        <option v-for="option in options" :key="option.value" :value="option.value">{{ option.name }}</option>
    </select>
</template>
<script>
export default {
    name: 'my-select',
    props: {
        options: {
            type: Array,
            default: () => []
        }
    }, 
    methods : {
        changeOptions(event) {
            this.$emit('update:modelValue', event.target.value)
        }
    }
}
</script>
<style scoped>
    
</style>