<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="p-4">
                    <h1 class="text-xl lg:text-2xl text-gray-800 font-semibold pt-2 min-h-4">{{ item.name || '...'}} {{ item.in_stock || ''}}</h1>
                </div>
                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-col lg:flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Наименование</label>
                                    <text-input v-model="item.name" @change="updateItemInfo('name', item.name)" type="text" placeholder="Наименование" ref="name" validate="min:4|max:255"/>
                                </div>
                                <div class="w-full flex flex-col py-4 lg:p-0 lg:pl-2">
                                    <label class="px-2">Приоритет</label>
                                    <div class="flex justify-start py-1">
                                        <toggle-button v-model="item.priority"
                                                :values="['100:⬆️ 7','50:6','20:5','10:4','5:3','2:2','1:1 ⬇️']"
                                                @change="updateItemInfo('priority', item.priority)" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col lg:flex-row p-4">
                                <!-- <div class="w-1/3 flex flex-col">
                                    <label class="px-2">Погрешность</label>
                                    <input v-model="item.error" @change="updateItemInfo('error', item.error)"  class="p-2 border outline-none" type="text"/>
                                </div> -->
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Единица</label>
                                    <div class="flex justify-start py-1">
                                        <toggle-button v-model="item.unit"
                                                :values="['шт:штук','кг:килограмм','гр:грамм','л:литр',':нет']"
                                                @change="updateItemInfo('unit', item.unit)" />
                                    </div>
                                </div>
                                <div class="w-full py-4 lg:p-0 lg:pl-2 flex flex-col">
                                    <label class="px-2">Порог уведомления</label>
                                    <text-input v-model="item.notify_at" @change="updateItemInfo('notify_at', item.notify_at)" type="number" placeholder="Порог" ref="notify_at" validate="number|max:9999"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <details>
                            <summary class="text-md font-semibold pb-4 pl-4">Входит в состав блюд - {{ item.meals?.length }}</summary>
                            <div class="border rounded-md">
                                <div class="w-full flex flex-row p-2 items-center">
                                    <div v-if="item.meals?.length > 0" class="w-full flex flex-col w-full">
                                        <div v-for="meal in item.meals" class="w-full hover:bg-indigo-50 p-2 rounded-md flex justify-between items-center border-b">
                                            <div class="w-2/3 flex">
                                                <router-link :to="{ name: 'ItemsEdit', params: { id: meal.id }}">
                                                    <div class="py-2 px-4 rounded-md cursor-pointer hover:bg-yellow-300 text-sm">{{ meal.name }}</div>
                                                </router-link>
                                            </div>
                                            <div class="w-1/3 flex">
                                                {{ meal.pivot.amount }}{{ item.unit}}
                                            </div>
                                            <div class="w-1/3 flex">
                                                <default-button @confirm="detachIngredient(meal.id)" type="cancel" pressconfirm>убрать</default-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="p-4 w-full">
                                        <i>Ингредиент не входит в состав блюд</i>
                                    </div>
                                </div>
                            </div>
                        </details>
                    </section>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <details>
                            <summary class="text-md font-semibold pb-4 pl-4">Ингредиенты в составе - {{ item.children?.length }}</summary>
                            <div class="border rounded-md">
                                <div  v-if="item.children && item.children.length > 0" class="w-full flex flex-col w-full">
                                    <div v-for="ingredient in item.children" class="w-full hover:bg-indigo-50 p-2 rounded-md flex justify-between items-center border-b">
                                        <div class="w-1/3 flex">
                                            <router-link :to="{ name: 'IngredientsEdit', params: { id: ingredient.id }}">
                                                <div class="py-2 px-4 rounded-md cursor-pointer hover:bg-yellow-300 text-sm">{{ ingredient.name }}</div>
                                            </router-link>
                                        </div>
                                        <div class="w-1/3 flex">
                                            <input class="py-2 px-2 w-1/3 outline-none border-b text-sm text-right" @change="updateIngredientChildInfo(ingredient.id, 'pivot_amount', ingredient.pivot.amount)" v-model="ingredient.pivot.amount" />
                                        </div>
                                        <div class="w-1/3 flex">
                                            <default-button @confirm="removeChildFromIngredient(ingredient.id)" type="cancel" pressconfirm>убрать</default-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-center flex-col md:flex-row">
                                    <div class="px-4 py-2 font-semibold">
                                        Добавить в состав:
                                    </div>
                                    <div class="p-2 flex items-center">
                                        <select v-model="child_id" class="py-2 px-4 w-4/5 border bg-white">
                                            <option v-for="ingredient in ingredients" :value="ingredient.id">{{ ingredient.name }}</option>
                                        </select>
                                        <input v-model="pivot_amount" class="py-2 px-2 w-1/5 outline-none border bg-white text-right" />
                                    </div>
                                    <div class="p-2">
                                        <default-button @click="addChildToIngredient()" class="">добавить</default-button>
                                    </div>
                                </div>
                            </div>
                        </details>
                    </section>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <details>
                            <summary class="text-md font-semibold pb-4 pl-4">Пополнения и списания - {{ item.stock_changes?.length }}</summary>
                            <div class="border rounded-md">
                                <div v-if="item.stock_changes && item.stock_changes.length > 0" class="flex flex-col w-full text-xs">
                                    <div v-for="change in item.stock_changes" class="w-full hover:bg-indigo-50 p-2 rounded-md flex justify-between items-center border-b">
                                        <div class="w-full flex items-center">
                                            <div class="w-2/12 flex items-center">
                                                <div class="flex flex-col">
                                                    <label class="px-2">#</label>
                                                    <div class="w-full flex p-2">
                                                        <router-link :to="{ name: 'StockChangesEdit', params: { id: change.id }}">{{ change.id }}</router-link>
                                                    </div>
                                                </div>
                                                <div class="flex flex-col">
                                                    <label class="px-2">Тип</label>
                                                    <div class="w-full flex p-2">
                                                        {{ change.change_type == 'refill' ? '⬆️' : (change.change_type == 'consumption' ? '⬇️' : '➡️')}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-10/12 flex flex">
                                                <div class="w-1/3 flex flex-col">
                                                    <label class="px-2">Количество</label>
                                                    <text-input @change="updateStockChange(change, 'change_amount', change.change_amount)" v-model="change.change_amount" requestsEnterKey />
                                                </div>
                                                <div class="w-1/3 flex flex-col">
                                                    <label class="px-2">Цена за ед.</label>
                                                    <text-input @change="updateStockChange(change, 'change_price', change.change_price)" v-model="change.change_price" requestsEnterKey />
                                                </div>
                                                <div class="w-1/3 flex flex-col items-center">
                                                    <label class="px-2">Источник</label>
                                                    <toggle-button v-model="change.supplier_id" @change="updateStockChange(change, 'supplier_id', change.supplier_id)" :values="['0:Склад', '1:Поставщик']"/>
                                                    <!-- <select class="py-2 px-2 w-11/12 outline-none border-b" @change="updateStockChange(change, 'supplier_id', change.supplier_id)" v-model="change.supplier_id" >
                                                        <option value="" selected>Склад</option>
                                                        <option value='1'>Поставщик</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                            <div class="w-1/12 text-xs">
                                                <div class="py-2 flex justify-center">
                                                    {{ change.user?.first_name }}
                                                    <div v-if="change.order_id">
                                                        <router-link :to="`/orders/${change.order_id}/edit`">{{ change.order_id }}</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="w-1/12">{{ change.updated_at }}</div> -->
                                            <div class="w-1/12 text-xs">
                                                <div class="py-2">{{ change.created_at }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="p-4">
                                        <i>Пока нет информации</i>
                                    </div>
                                </div>
                            </div>
                        </details>
                    </section>
                </div>

                <section v-if="can('delete_ingredients') && item.meals && item.meals.length == 0" class="flex flex-col pb-6">
                    <div class="w-full flex flex-col items-end">
                        <default-button @confirm="deleteItem()" type="delete" pressconfirm>Удалить</default-button>
                    </div>
                </section>
           </section>
       </div>
    </div>
    <confirm-alert ref="confirmAlert"></confirm-alert>
</template>
<script>
import axios from 'axios'
import check_permissions from '../../../mixins/CheckPermissions'
import { api_route } from '../../../config/api_routes'

export default {
    data: () => {
        return {
            item: {
                id: 0
            },
            ingredients: [],
            child_id: '',
            pivot_amount: 0
            // showEditItemDialog: false,
        }
    },
    mixins: [check_permissions],
    methods: {
        getIngredientById () {
            this.getServerData('ingredient_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data

                    this.getIngredientsAll()
                })
                .catch((error) => {})
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('ingredient_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {

                })
                .catch((error) => console.log(error))
        },
        updateStockChange (change, info_index, new_value) {
            this.postServerData('ingredient_stock_change_update_info', { data: { item_id: change.id, info_index, new_value } })
                .then((res) => {})
                .catch((error) => {})
        },
        updateIngredientChildInfo (child_id, info_index, new_value) {
            this.postServerData('ingredient_child_update_info', { data: { parent_id: this.item.id, child_id, info_index, new_value } })
                .then((res) => {})
                .catch((error) => {})
        },
        updateIngredientInfo (info_index, new_value) {
            this.postServerData('ingredient_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {})
                .catch((error) => {})
        },
        getIngredientsAll () {
            this.getServerData('ingredients_all')
                .then((res) => {
                    this.ingredients = res.data
                })
                .catch((error) => {})
        },
        addChildToIngredient () {
            const child_id = this.child_id
            const amount = this.pivot_amount

            this.postServerData('ingredient_attach_child', { data: { parent_id: this.item.id, child_id, amount } })
                .then((res) => {
                    this.getIngredientById()
                })
                .catch((error) => {})
        },
        async removeChildFromIngredient (child_id) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Убрать из состава?',
                description: 'Убрать ингредиент из состава',
                okButton: 'Убрать'
            })
            if (confirmdel) {
                this.postServerData('ingredient_detach_child', { data: { parent_id: this.item.id, child_id } })
                .then((res) => {
                    this.getIngredientById()
                })
                .catch((error) => {})
            }
        },
        async detachIngredient (item_id) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Убрать ингредиент?',
                description: '',
                okButton: 'Убрать'
            })
            if (confirmdel) {
                this.postServerData('meal_detach_ingredient', { data: { item_id, ingredient_id: this.item.id } })
                    .then((res) => {
                        this.getIngredientById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        async deleteItem () {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Удалить ингредиент?',
                description: 'Удаление произойдет навсегда',
                okButton: 'Удалить'
            })
            if (confirmdel) {
                this.postServerData('ingredients_delete', { data: { item_id: this.item.id } })
                    .then((res) => {
                        if (res.data.code == 200) {
                            this.$router.push({ name: 'Ingredients' })
                        }
                    })
                    .catch((error) => console.log(error))
            }
        }
    },
    mounted () {
        this.item.id = this.$route.params.id

        this.getIngredientById()
    },
    watch: {
        // $route(to, from) {
        //     console.log(this.$route)
        //     console.log(this.$route.query)
        //     console.log(this.$route.hash)
        // }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
