<template lang="">
    <button class="outline-none text-base flex items-center group relative overflow-hidden select-none"
        :disabled="disabled">
        <div class="flex flex-col relative h-full w-full justify-center items-center">
            <div class="flex flex-row items-center">
                <!-- <div class="rounded-tl-full rounded-bl-full pl-4 border-2 border-gray-100 h-10">
                    <img :src="icon" class="w-4 h-4 mr-2 rotate-0 group-hover:rotate-180 transition-all drop-shadow-md" />
                </div> -->
                <div v-for="value, index in values" :key="index"
                    class="px-2 py-1 border-l hover:bg-lime-300 cursor-pointer first:pl-3 first:rounded-tl-md first:rounded-bl-md last:pr-3 last:rounded-tr-md last:rounded-br-md  border border-stone-200 transition-all text-sm"
                    :class="[value.split(':')[0] == modelValue ? 'bg-lime-200 text-yellow-900' : 'text-yellow-700']"
                    @click="updateValue(value.split(':')[0])"
                    >
                    {{value.split(':')[1]}}
                </div>
            </div>
        </div>
    </button>
</template>
<script>
export default {
    name: 'toggle-button',
    data () {
        return {
        }
    },
    props: {
        modelValue: [Number, String],
        type: {
            type: String,
            default: 'default'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        values: {
            type: Array,
            default: []
        }
    },
    emits: ['change', 'update:modelValue'],
    methods: {
        updateValue (nevValue) {
            this.$emit('update:modelValue', nevValue)
            this.$emit('change', nevValue)
        }
    },
    computed: {
        style () {
            if (this.disabled) return 'bg-gray-500 text-gray-100 opacity-70'
            switch (this.type) {
                case 'cancel':
                    return 'bg-gray-50 hover:bg-gray-100 active:bg-gray-200 text-indigo-500 drow-shadow-md'
                case 'confirm':
                    return 'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white drow-shadow-md border-green-600 hover:border-green-700'
                default:
                    return 'bg-gray-50 hover:bg-gray-100 active:bg-gray-200 text-gray-100'
            }
        },
        icon () {
            if (this.disabled) return 'https://img.icons8.com/dotty/256/add.png'
            switch (this.type) {
                case 'cancel':
                    return 'https://img.icons8.com/nolan/256/1A6DFF/C822FF/delete-sign.png'
                case 'confirm':
                    return 'https://img.icons8.com/nolan/256/1A6DFF/C822FF/plus-math.png'
                default:
                    return 'https://img.icons8.com/dotty/256/add.png'
            }
        }
    },
    mounted () {

    }
}
</script>
<style scoped>
    button {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
        -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
        outline: none !important;
    }
</style>
