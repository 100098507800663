<template>
    <div class="w-full">
        <div class="pb-4 pt-1 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
            <div class="pt-0 lg:pt-2 text-sm md:text-base lg:text-lg font-semibold">
                <div v-if="stat.link"><router-link :to="stat.link">{{ stat.title }} &rarr;</router-link></div>
                <div v-else>{{ stat.title }}</div>
            </div>
            <div class="hidden lg:flex pt-0 lg:pt-2 pb-1 lg:pb-4 text-2xs lg:text-sm text-gray-700">{{ stat.description }}</div>
            <div class="pb-0 lg:pb-2 text-3xl lg:text-5xl font-semibold transition-all flex justify-end lg:justify-start" :class="[current_number != parseInt(this.stat.data) ? 'opacity-50' : 'opacity-100']">
                <div v-if="stat.link"><router-link :to="stat.link">{{ current_number_text }}</router-link></div>
                <div v-else>{{ current_number_text }}</div>
            </div>
            <div class="flex items-center text-gray-700 text-xs">
                <div v-if="stat.comparison" class="flex items-center">
                    <img :src="comparison_icon" class="h-4 w-4 mr-2"/>
                    {{ comparison_difftype }}
                </div>
                <div v-else>
                    ...
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'number-block',
    data () {
        return {
            current_number: 0,
            current_number_text: 0,
            incTimeout: null
        }
    },
    props: {
        stat: {
            type: Object
            // default:,
        }

    },
    methods: {
        incNumber () {
            const target_number = parseInt(this.stat.data)

            if (this.current_number < target_number) {
                if (target_number - this.current_number > 100000) this.current_number += 100000
                if (target_number - this.current_number <= 100000 && target_number - this.current_number > 10000) this.current_number += 13333
                if (target_number - this.current_number <= 10000 && target_number - this.current_number > 1000) this.current_number += 1333
                if (target_number - this.current_number <= 1000 && target_number - this.current_number > 100) this.current_number += 133
                if (target_number - this.current_number <= 100 && target_number - this.current_number > 10) this.current_number += 13
                if (target_number - this.current_number <= 10) this.current_number++
            }
            if (this.current_number >= target_number) {
                this.current_number = target_number
                clearInterval(this.incTimeout)
            }
            this.current_number_text = this.current_number.toLocaleString('fr-FR')
        }
    },
    computed: {
        comparison_icon () {
            switch (this.stat.comparison?.diff_type) {
                case 'raise':
                    return 'https://img.icons8.com/fluency-systems-filled/256/chart-arrow-rise.png'
                case 'fall':
                    return 'https://img.icons8.com/fluency-systems-filled/256/chart-arrow-descent.png'
                case 'even':
                default:
                    return 'https://img.icons8.com/?size=256&id=85563&format=png'
            }
        },
        comparison_difftype () {
            switch (this.stat.comparison?.diff_type) {
                case 'raise':
                    return 'на ' + this.stat.comparison.diff_amount + '% больше'
                case 'fall':
                    return 'на ' + this.stat.comparison.diff_amount + '% меньше'
                case 'even':
                default:
                    return '0% изменения'
            }
        }
    },
    mounted () {
        this.incTimeout = setInterval(this.incNumber, 10)
    }
}
</script>
<style scoped>

</style>
