<template>
    <div class="flex items-center">
        <slot></slot>
        <img @click="toggle()" src="https://icons-for-free.com/iconfiles/png/512/create+new+plus+icon-1320183284524393487.png" class="w-8 h-8 rounded-full hover:bg-yellow-300 active:animate-spin"/>
    </div>
    <div :class="[ hidden ? 'hidden' : '' ]">
        <slot name="hiddentext"></slot>
    </div>
</template>
<script>
export default {
    name: 'my-hider',
    props: {},
    data: () =>{
        return{ 
            hidden: true
        }
    },
    methods : {
        toggle(){
            this.hidden = !this.hidden
        }
    },
    mounted(){

    }
}
</script>
<style scoped>
    
</style>