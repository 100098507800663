<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-center p-4">
                    <div class="w-full flex flex-col justify-around ">
                        <h1 class="text-lg lg:text-2xl text-gray-800 font-semibold pt-2 pb-2">Все изменения списком</h1>
                        <!-- <div class="flex w-full">
                            <router-link :to="{ name: 'StockChanges', params: { criteria: 'all', period: 'today' } }"><div class="px-2">За сегодня</div></router-link>
                            <router-link :to="{ name: 'StockChanges', params: { criteria: 'all', period: 'week' } }"><div class="px-2">За неделю</div></router-link>
                            <router-link :to="{ name: 'StockChanges', params: { criteria: 'all', period: 'month' } }"><div class="px-2">За месяц</div></router-link>
                        </div> -->
                    </div>
                </div>

                <section class="flex flex-col py-2">
                    <details>
                        <summary class="text-md font-semibold pb-4 pl-4">Пополнить склад</summary>
                        <div class="border rounded-md w-full flex flex-wrap">
                            <div class="flex flex-row p-4 flex-wrap">
                                <div class="flex flex-row w-full md:w-6/12 p-4">
                                    <div class="w-6/12 flex flex-col">
                                        <label class="px-2">Ингредиент</label>
                                        <select v-model="stockchange.ingredient_id" class="p-2 border outline-none w-full">
                                            <option v-for="ingredient in ingredients" :value="ingredient.id">{{ ingredient.name }}</option>
                                        </select>
                                    </div>
                                    <div class="w-6/12 flex flex-col">
                                        <label class="px-2">Изменение</label>
                                        <select v-model="stockchange.type" class="p-2 border outline-none w-full">
                                            <option value="refill">Пополнение</option>
                                            <option value="writeoff">Списание</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex flex-row p-4 w-full md:w-6/12">
                                    <div class="w-6/12 flex flex-col">
                                        <label class="px-2">Поставщик</label>
                                        <select v-model="stockchange.supplier_id" class="p-2 border outline-none w-full">
                                            <option value="">Склад</option>
                                            <option value="1">Поставщик</option>
                                        </select>
                                    </div>
                                    <div class="w-6/12 flex flex-col">
                                        <label class="px-2">Дата</label>
                                        <input v-model="stockchange.date" class="p-2 border outline-none w-full" type="text"/>
                                    </div>
                                </div>
                                <div class="flex flex-row p-4 w-full md:w-6/12">
                                    <div class="w-3/12 flex flex-col">
                                        <label class="px-2">Количество</label>
                                        <input v-model="stockchange.amount" class="p-2 border outline-none w-11/12" type="number"/>
                                    </div>
                                    <div class="w-3/12 flex flex-col">
                                        <label class="px-2">Множитель</label>
                                        <input v-model="stockchange.amount_multiplier" class="p-2 border outline-none w-11/12" type="number"/>
                                    </div>
                                    <div class="w-3/12 flex flex-col">
                                        <label class="px-2">Сумма</label>
                                        <input v-model="stockchange.price" class="p-2 border outline-none w-11/12" type="number"/>
                                    </div>
                                    <div class="w-3/12 flex flex-col">
                                        <label class="px-2">Оплачено</label>
                                        <input v-model="stockchange.payed_amount" class="p-2 border outline-none w-full" type="text"/>
                                    </div>
                                </div>
                                <div class="flex flex-row p-4 w-full md:w-6/12 items-end">
                                    <div class="w-8/12 flex flex-col">
                                        <label class="px-2">Источник</label>
                                        <select v-model="stockchange.payed_from" class="py-2 px-2 w-full outline-none border bg-white" >
                                            <option value="1" selected>Касса</option>
                                            <option value='2'>Депозит</option>
                                            <option value='3'>Другое</option>
                                        </select>
                                    </div>
                                    <div class="w-4/12 flex flex-col items-end">
                                        <button @click="storeItem()" class="py-2 px-6 outline-none rounded-md bg-green-100 hover:bg-green-300">Сохранить</button>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col m-4 p-4 rounded-md bg-gray-100 text-gray-700">
                                <h3>Информация</h3>
                                <div class="flex w-full text-xs flex-wrap">
                                    <div class="w-1/3 md:w-1/5 pb-2">
                                        <div>Количество:</div>
                                        <div>Количество единиц поступающей продукции.</div>
                                        <div>12 бутылок / 40 упаковок</div>
                                    </div>
                                    <div class="w-1/3 md:w-1/5 pb-2">
                                        <div>Множитель:</div>
                                        <div>Количество гр/л/шт в одной единице</div>
                                        <div>0.750кг в бутылке / 50штук в упаковке</div>
                                    </div>
                                    <div class="w-1/3 md:w-1/5 pb-2">
                                        <div>Сумма:</div>
                                        <div>Итоговая сумма по чеку</div>
                                        <div>9600тг / 27000тг</div>
                                    </div>
                                    <div class="w-1/3 md:w-1/5 pb-2">
                                        <div>Оплачено:</div>
                                        <div>Сколько от итоговой суммы оплачено</div>
                                        <div>все / 9000тг / 27000тг</div>
                                    </div>
                                    <div class="w-1/3 md:w-1/5 pb-2">
                                        <div>Источник:</div>
                                        <div>Откуда была оплата</div>
                                        <div>Из кассы / С дотаций / Другое</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </details>
                </section>

                <section class="flex flex-col py-2">
                    <h3 class="text-md font-semibold pb-4 pl-4">Пополнения и списания</h3>
                    <div class="border rounded-md">
                        <!-- <div v-show="stock_changes && stock_changes.length > 0"> -->
                            <div class="hidden md:flex items-center bg-indigo-100 text-indigo-700 select-none">
                                <div class="w-2/12 flex pl-2">
                                    Ингредиент
                                </div>
                                <div class="w-1/12 p-2">
                                    Тип
                                </div>
                                <div class="w-3/12 flex items-center p-2">
                                    <div class="w-1/2">
                                        Кол-во
                                    </div>
                                    <div class="w-1/2">
                                        Цена
                                    </div>
                                </div>
                                <div class="w-6/12 flex items-center p-2">
                                    <div class="w-3/12">
                                        Сумма
                                    </div>
                                    <div class="w-3/12">
                                        Оплачено
                                    </div>
                                    <div class="w-3/12">
                                        Дата
                                    </div>
                                    <div class="w-3/12">
                                    </div>
                                </div>
                            </div>
                            <div v-for="change in stock_changes" >
                                <div class="w-full flex flex-row items-center text-sm hover:bg-gray-100" style="border-top: 1px dashed #aaa">
                                    <div class="w-full md:w-2/12 flex items-center p-2">
                                        <div class="w-full">
                                            <router-link :to="{ name: 'StockChangesEdit', params: {id: change.id}}">
                                                {{ change.ingredient?.name }}
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/12 p-2">
                                    {{ change.change_type }}
                                    </div>
                                    <div class="w-full md:w-3/12 flex items-center p-2">
                                        <div class="w-1/2">
                                            {{  change.change_amount }}
                                        </div>
                                        <div class="w-1/2">
                                            {{ change.change_price }}
                                        </div>
                                    </div>
                                    <div class="w-full md:w-6/12 flex flex-wrap items-center p-2">
                                        <div class="w-3/12">
                                            {{ Math.round(change.change_amount * change.change_price) }}
                                        </div>
                                        <div class="w-3/12">
                                            {{ change.payed_amount }}
                                        </div>
                                        <div class="w-3/12 flex items-center">
                                            {{ change.date }}
                                        </div>
                                        <div class="w-3/12 flex items-center">
                                            <default-button @confirm="removeStockChange(change.id)" type="cancel" pressconfirm>удалить</default-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- </div>
                        <div v-else>
                            <div class="p-8">
                                <i>Пока нет информации</i>
                            </div>
                        </div> -->
                    </div>
                </section>
           </section>
       </div>
    </div>
    <confirm-alert ref="confirmAlert"></confirm-alert>
</template>
<script>
import axios from 'axios'
import { api_route } from '../../../config/api_routes'

export default {
    data: () => {
        return {
            stockchange: {
                type: 'refill',
                amount: 0,
                amount_multiplier: 1,
                price: 0,
                ingredient_id: 0,
                supplier_id: '',
                payed_amount: 'все',
                payed_from: 1,
                date: 'сегодня'
            },
            stock_changes: [],
            ingredients: [],
            criteria: 'all',
            period: 'today'
            // showEditItemDialog: false,
        }
    },
    methods: {
        getStockChanges () {
            this.getServerData('ingredients_stock_changes_all', { criteria: this.criteria, period: this.period })
                .then((res) => {
                    this.stock_changes = res.data
                })
                .catch((error) => {})
        },
        async removeStockChange (change_id) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Удалить изменение?',
                description: 'Удалить навсегда изменение',
                okButton: 'Удалить'
            })
            if (confirmdel) {
                this.getServerData('order_items_remove_stock_changes_by_id', { change_id })
                    .then((res) => {
                        if (res.data.code == 200) {
                            this.getStockChanges()
                        }
                    })
                    .catch((error) => {})
            }
        },
        updateStockChange (change, info_index, new_value) {
            this.postServerData('ingredient_stock_change_update_info', { data: { item_id: change.id, info_index, new_value } })
                .then((res) => {})
                .catch((error) => {})
        },
        getIngredients () {
            this.getServerData('ingredients_all')
                .then((res) => {
                    this.ingredients = res.data
                })
                .catch((error) => {})
        },
        storeItem () {
            this.postServerData('ingredient_stock_change_store', { data: this.stockchange })
                .then((res) => {
                    if (res.data?.code == 200) {
                        this.getStockChanges()
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        if (this.$route.params.criteria) {
            this.criteria = this.$route.params.criteria
        }
        if (this.$route.params.period) {
            this.period = this.$route.params.period
        }

        this.getStockChanges()
        this.getIngredients()
    },
    watch: {
        $route (to, from) {
            if (to.name == this.$route.name) {
                if (to.params.criteria) {
                    this.criteria = to.params.criteria
                }
                if (to.params.period) {
                    this.period = to.params.period
                }

                this.getStockChanges()
            }
        }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
