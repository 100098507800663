<template>
    <div class="flex items-center border border-gray-200 relative overflow-hidden" :class="containerClass">
        <div v-if="hasIcon" class="w-8 h-8 input-icon" :style="{ backgroundImage: 'url('+ iconUrl +')'}"></div>
        <input
            class="w-full p-2 outline-none focus:border-indigo-500"
            :class="[type == 'password' || hasValidation || requestsEnterKey ? 'pr-8' : '', inputClass]"
            :value="modelValue"
            @input="updateInput"
            @keypress.enter="keyPressInput"
            @keyup="keyUpInput"
            @change="changeInput"

            :placeholder="placeholder"
            :type="currentType"
            :required="required"
            :autocomplete="autocomplete"
            ref="input"
        />
        <div class="absolute z-10 right-0 top-0 h-full flex justify-center items-center">
            <div
                v-if="requestsEnterKey"
                class="w-8 h-full input-icon bg-white rounded-tr-md rounded-br-md"
                :class="[keyPressEnter ? 'enter-key-pressed' : 'enter-key-unpressed']"
                ></div>
            <div
                v-if="hasValidation"
                class="w-8 h-full input-icon bg-white rounded-tr-md rounded-br-md"
                :class="[isValid ? 'valid' : 'invalid']"
                ></div>
            <div
                v-if="type == 'password'"
                class="w-8 h-full input-icon bg-white rounded-tr-md rounded-br-md"
                :class="[masked ? 'masked' : 'unmasked']"
                @click="togglePassword"
                ></div>
        </div>
    </div>
    <div v-if="validationErrors.length > 0 && modelValue != '' && modelValue != null && !silent">
        <ul class="list-disc pl-6 pt-2 text-yellow-500">
            <li v-for="error,index in validationErrors" class="text-xs" :key="index">
                {{ error }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'text-input',
    data () {
        return {
            initialType: this.type,
            currentType: this.type,
            masked: this.type === 'password',
            validationErrors: [],
            keyPressEnter: false
        }
    },
    props: {
        modelValue: [Number, String],
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        iconUrl: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: 'off'
        },
        ref: String,
        validate: String,
        silent: {
            type: Boolean,
            default: false
        },
        inputClass: String,
        containerClass: String,
        requestsEnterKey: {
            type: Boolean,
            default: false
        }

    },
    computed: {
        hasIcon () {
            if (this.iconUrl !== '') return true
            return false
        },
        hasValidation () {
            if (this.validate === undefined) return false
            if (this.validate === '') return false
            return true
        },
        isValid () {
            if (!this.hasValidation) return true

            const validationOptions = this.validate.split('|')
            let result = true
            const errors = []

            validationOptions.forEach(option => {
                const optArr = option.split(':')
                const name = optArr[0]
                const val = optArr[1] || ''

                switch (name) {
                    case 'number':
                        if (parseInt(this.modelValue) != this.modelValue) {
                            result = false
                            errors.push('Неподходящая цифра')
                        }
                    break
                    case 'email':
                        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.modelValue))) {
                            result = false
                            errors.push('Неподходящий email')
                        }
                    break
                    case 'phone':
                        if (parseInt(this.modelValue) != this.modelValue) {
                            result = false
                            errors.push('Неподходящий телефон')
                        }
                    break
                    case 'min':
                        if (this.type === 'number') {
                            if (parseInt(this.modelValue) < parseInt(val)) {
                                result = false
                                errors.push('Слишком мало. Мин: ' + val + '')
                            }
                        } else {
                            if (this.modelValue?.length < parseInt(val)) {
                                result = false
                                errors.push('Слишком коротко. Минимальная длина: ' + val + '')
                            }
                        }
                    break
                    case 'max':
                        if (this.type === 'number') {
                            if (parseInt(this.modelValue) >= parseInt(val)) {
                                result = false
                                errors.push('Слишком много. Макс: ' + val + '')
                            }
                        } else {
                            if (this.modelValue?.length >= parseInt(val)) {
                                result = false
                                errors.push('Слишком длинно. Максимальная длина: ' + val + '')
                            }
                        }
                    break
                }
            })
            this.validationErrors = errors

            return result

            // switch(this.initialType){
            //     case 'email':
            //         if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.modelValue)) return true
            //         else return false;
            //     break;
            //     default:
            //         if(this.modelValue.length > 3) return true
            //         else return false
            //         break;
            // }
        }
    },
    emits: ['update', 'update:modelValue', 'keyup', 'change'],
    methods: {
        changeInput () {
            this.$emit('change', this.modelValue)
        },
        keyPressInput (event) {
            this.$emit('keyup', event)
            this.keyPressEnter = true
        },
        keyUpInput (event) {
            this.$emit('keyup', event)
            this.keyPressEnter = false
        },
        updateInput (event) {
            this.$emit('update:modelValue', event.target.value)
            this.$emit('update', event.target.value)
        },
        togglePassword () {
            if (this.initialType === 'password') {
                if (this.currentType !== 'password') {
                    this.currentType = 'password'
                    this.masked = true
                } else {
                    this.masked = false
                    this.currentType = 'text'
                }
            }
        }
    }
}
</script>
<style scoped>
    div {
        transition: all .3s
    }
    .input-icon{
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: .8rem center;
    }
    .masked
    {
        background-image: url('https://img.icons8.com/ios-glyphs/256/visible.png');
    }
    .unmasked
    {
        background-image: url('https://img.icons8.com/windows/256/hide.png');
    }

    .valid
    {
        background-image: url('https://img.icons8.com/color/256/in-progress--v1.png');
        opacity: 1;
    }
    .invalid
    {
        background-image: url('https://img.icons8.com/small/256/in-progress.png');
        opacity: .5;
    }

    .enter-key-pressed
    {
        background-image: url('https://static.breaktime.kz/uploads/images/breaktime/icons/enter-key-96-light.png');
        opacity: 1;
    }
    .enter-key-unpressed
    {
        background-image: url('https://static.breaktime.kz/uploads/images/breaktime/icons/enter-key-96-dark.png');
        opacity: .5;
    }

    .validatable:invalid
    {
        /*
        background-image: url('https://img.icons8.com/pulsar-line/256/checkmark.png');
        background-size: 2rem;
        background-repeat: no-repeat;
        background-position: 95% center;
        */
        color: rgb(239 68 68 / 1);
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    input[type=date]{
        padding-top: 0.35rem
    }
</style>
