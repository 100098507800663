<template lang="">
   <div class="w-full py-4 px-4 pb-5 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
        <div class="pt-2 text-lg font-semibold flex justify-between items-center">
            <div>
                {{ stat.title }}
            </div>
            <div class="cursor-pointer" @click="statsSeen = !statsSeen">
                <div :class="[statsSeen ? 'masked' : 'unmasked']" class="h-4 w-4 transition-all"></div>
            </div>
        </div>
        <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
        <div class="flex flex-wrap justify-between items-center text-sm h-64 pb-2">
            <div v-for="dn in daynames" class="w-1/7 flex justify-center items-center pb-1">{{ dn }}</div>
            <div v-for="(pd, index) in parseInt(stat.meta.first_day_index)" :key="index" class="w-1/7 flex justify-center items-center"></div>
            <div v-for="data, index in stat.data" class="w-1/7 flex justify-center items-center" style="padding: 1px">
                <!-- {{ data }} -->
                <div class="w-12 h-10 rounded-md flex items-center justify-center"
                :class="dotColor(data)">
                    <div class="transition-all text-center flex flex-col items-center">
                        <div class="text-sm font-semibold transition-all overflow-hidden"  :class="[statsSeen ? 'opacity-100 h-full' : 'opacity-0 h-0']">{{ data.label }}</div>
                        <div class="text-xs text-green-700 opacity-70">{{ data.description }}</div>
                    </div>
                </div>
            </div>
            <div v-for="(pd2, i2) in (7 - parseInt(stat.meta.first_day_index))" :key="i2" class="w-1/7 flex justify-center items-center"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'collection-calendar',
    data: () => {
        return {
            daynames: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
            statsSeen: false
        }
    },
    props: {
        stat: {
            type: Object,
            required: true
        }
    },
    methods: {
        dotColor (data) {
            if (data.point >= this.stat.meta.avg) {
                if (data.point > this.stat.meta.max - this.stat.meta.max * 0.1) return 'bg-green-500'
                return 'bg-green-400'
            } else
            if (data.point < this.stat.meta.avg) {
                if (data.point <= this.stat.meta.min + this.stat.meta.min * 0.1) return 'bg-green-100'
                return 'bg-green-200'
            } else {
                return 'bg-green-300'
            }
        }
    }

}
</script>
<style scoped>
    .w-1\/7
    {
        width: 14.2857142857%
    }
    .masked
    {
        background-image: url('https://img.icons8.com/ios-glyphs/256/visible.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .unmasked
    {
        background-image: url('https://img.icons8.com/windows/256/hide.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

</style>
