<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="p-4">
                    <h1 class="text-xl lg:text-2xl text-gray-800 font-semibold pt-2 min-h-4">Создать блюдо</h1>
                </div>
                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Наименование</label>
                                    <text-input v-model="item.name" @update="generateSlug" type="text" placeholder="Наименование" ref="name" validate="min:4|max:255"/>
                                </div>
                            </div>

                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Описание</label>
                                    <textarea v-model="item.description" class="p-2 border outline-none h-36" placeholder="Описание"></textarea>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Дополнительная информация</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-3/12 flex flex-col">
                                    <label class="px-2">Цена</label>
                                    <text-input v-model="item.price" type="number" placeholder="Цена" ref="price" validate="number|max:99999" silent/>
                                </div>
                                <div class="w-9/12 flex flex-col">
                                    <label class="px-2">Slug</label>
                                    <text-input v-model="item.slug" type="text" placeholder="Слаг" ref="slug" validate="min:4|max:255"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section class="flex flex-col p-4 items-end lg:items-start">
                    <div class="flex flex-row pb-6">
                        <div class="w-full flex items-end">
                            <div class="pr-2">
                                <default-button type="cancel" @confirm="resetForm()" pressconfirm>Отмена</default-button>
                            </div>
                            <div class="pr-2">
                                <default-button @click="storeItem()" type="confirm">Сохранить</default-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="formErrors.length > 0" class="py-4 flex">
                        <div class="py-2 px-8 rounded-md border border-yellow-500">
                            <ul class="text-yellow-500">
                                <li v-for="error in formErrors">{{ error }}</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { api_route } from '../../../config/api_routes'

export default {
    data: () => {
        return {
            item: {
                name: '',
                slug: '',
                description: '',
                recipe: '',
                price: null,
                sub_type: '',
                tag_id: 0,
                weight: '',
                is_active: 0,
                is_seen: 0,
                is_recomended: 0

            },
            // selected_tag: '',
            // tags: [],
            formErrors: []
        }
    },
    methods: {
        generateSlug (value) {
            if (this.item.name.length == 0) return this.item.slug = ''

            const cyrilic = ['а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ы', 'ь', 'э', 'ю', 'я', ' ', '+']
            const latin = ['a', 'b', 'v', 'g', 'd', 'e', 'e', 'j', 'z', 'i', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f', 'h', 'c', 'ch', 'sh', 'sh', '', 'i', '', 'e', 'yu', 'ya', '_', '_']

            const eng = 'abcdefghijklmnopqrstuvwxyz'

            const nameArray = this.item.name.split('')
            const slugArray = nameArray.map(transliterate)

            function transliterate (letter) {
                if (isNumber(letter) || isIng(letter)) {
                    return letter
                } else {
                    return latin[cyrilic.indexOf(letter.toLowerCase())]
                }
            }
            function isNumber (n) {
                return parseInt(n) == n
            }
            function isIng (l) {
                return eng.indexOf(l) !== -1
            }

            const gs = slugArray.join('')
            this.item.slug = gs
            return gs
        },
        validateForm () {
            this.formErrors = []

            if (!this.$refs.name.isValid) {
                this.formErrors.push('Наименование не подходящее')
                this.$refs.name.validationErrors.forEach(error => this.formErrors.push(error))
                this.$refs.name.$refs.input.focus()
                return false
            }
            if (!this.$refs.slug.isValid) {
                this.formErrors.push('Слаг не подходящий')
                this.$refs.slug.validationErrors.forEach(error => this.formErrors.push(error))
                this.$refs.slug.$refs.input.focus()
                return false
            }
            if (!this.$refs.price.isValid) {
                this.formErrors.push('Цена не подходящая')
                this.$refs.price.validationErrors.forEach(error => this.formErrors.push(error))
                this.$refs.price.$refs.input.focus()
                return false
            }

            return true
        },
        resetForm () {
            const item = {
                name: '',
                slug: '',
                description: '',
                recipe: '',
                price: 0,
                sub_type: '',
                tag_id: 0,
                weight: '',
                is_active: 0,
                is_seen: 0,
                is_recomended: 0
            }
            this.item = item
        },
        storeItem () {
            if (!this.validateForm()) return
            this.postServerData('meal_store', { data: this.item })
                .then((res) => {
                    if (res.data?.code == 200) {
                        this.$router.push({ name: 'ItemsEdit', params: { id: res.data.item_id } })
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        // this.getTags()
    }
}
</script>
<style lang="">

</style>
