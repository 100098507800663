<template>
        <div class="w-full md:w-6/12 flex justify-between py-2">
            <div class="w-full flex flex-row">
                <div class="flex flex-col px-2">
                    <div :class="[order?.times?.created_to_closed.raw > 60 ? 'text-red-500 font-semibold' : '']">
                        <router-link :to="`/orders/${order.id}/edit`">{{ order.id }}</router-link>
                    </div>
                    <div>{{ order?.current_status?.emoji || '' }}</div>
                    <div>{{ order?.times.created_to_closed.formatted }}</div>
                </div>
                <div class="w-full flex flex-col">
                    <div v-for="status, index in order?.statuses_history" :key="index" class="flex items-center text-xs md:text-sm">
                        <div>{{ status.created_at }}</div>
                        <div class="pl-2 hidden md:flex">{{ status.status?.name }}</div>
                        <div class="pl-2 flex md:hidden">{{ status.status?.emoji }}</div>
                    </div>
                </div>
            </div>
            <div class="w-full flex flex-col">
                <div class="w-full flex ">
                    <div v-if="editable">
                        <input :value="order.customer.phone" class="p-2 border" @change="event => updateOrderInfo(order.id, 'customer.phone', event.target.value)" />
                    </div>
                    <div v-else>{{ order?.customer?.phone }}</div>
                    <div v-if="editable">
                        <input :value="order.customer.customer_id" class="p-2 border" @change="event => updateOrderInfo(order.id, 'customer.customer_id', event.target.value)" />
                    </div>
                    <div v-else>{{ order?.customer?.customer_id || 'no customer id' }}</div>
                </div>
                <div>{{ order?.address?.street_appartment }}</div>
                <div>{{ order?.address?.address_details }}</div>
                <div>{{ order?.extra_info?.order_source }}</div>
                <!-- <div>{{ order.address.full_address }}</div> -->
            </div>
        </div>
        <div class="w-full md:w-3/12 flex justify-between py-2">
            <div class="w-full flex flex-col">
                <div v-for="item, index in order.items" :key="index" class="flex items-center text-xs md:text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                    <div class="w-7/12 flex items-center">
                        <div v-if="item.meal_id">
                            <div v-if="item.is_combo == 1">
                                <router-link :to="{ name: 'CombosEdit', params: { id: item.meal_id } }">{{ item.name }}</router-link>
                            </div>
                            <div v-if="item.is_combo == 0">
                                <router-link :to="{ name: 'ItemsEdit', params: { id: item.meal_id } }">{{ item.name }}</router-link>
                            </div>
                        </div>
                        <div v-else>{{ item.name }}</div>
                        <div class="">{{ item.is_combo ? '🍱' : ''  }}</div>
                    </div>
                    <div class="pl-2">{{ item.amount }} x </div>
                    <div>{{ item.price }}</div>
                    <div class="">{{ item.is_bonus ? '🎁' : ''  }}</div>
                </div>
            </div>
        </div>
        <div class="w-full md:w-3/12 flex justify-between py-2">
            <div class="w-full flex flex-col text-xs md:text-sm">
                <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                    <div>Итого</div>
                    <div>{{ order?.total_summ }}</div>
                </div>
                <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                    <div>Перевод</div>
                    <div>{{ order?.payment?.transfer }}</div>
                </div>
                <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                    <div>Бонусных</div>
                    <div>{{ order?.total_bonuses }}</div>
                </div>
                <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                    <div>Доставка</div>
                    <div>{{ order?.payment?.delivery_cost }}</div>
                </div>
                <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                    <div>Скидка</div>
                    <div>{{ order?.payment?.discount }}</div>
                </div>
                <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                    <div>Надбавка</div>
                    <div>{{ order?.payment?.upcount }}</div>
                </div><!-- <div>{{ order.address.full_address }}</div> -->
            </div>
        </div>
        <div v-if="order.rating" class="w-full flex justify-between p-4 border-t">
            <div class="w-3/12 flex flex-col">
                <div class="flex">
                    <div class="w-1/2">Оператор:</div>
                    <div class="flex">
                        <img v-for="index in order?.rating?.operator_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                    </div>
                </div>
                <div class="flex">
                    <div class="w-1/2">Кухня:</div>
                    <div class="flex">
                        <img v-for="index in order?.rating?.kitchen_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                    </div>
                </div>
                <div class="flex">
                    <div class="w-1/2">Доставка:</div>
                    <div class="flex">
                        <img v-for="index in order?.rating?.courier_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                    </div>
                </div>
            </div>
            <div class="w-9/12 flex">
                <div v-if="order?.rating?.body" class="bg-gray-100 rounded-md p-4"><i>{{ order.rating?.body }}</i></div>
            </div>
        </div>
</template>
<script>
export default {
    name: 'order-single',
    props: {
        order: { type: Object },
        editable: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        updateOrderInfo (order_id, info_index, new_value) {
            if (info_index === 'address.street' || info_index === 'address.building' || info_index === 'address.city') {
                this.orders[this.selected_order].address.address_id = null
            }

            this.postServerData('order_update_info', { data: { order_id, info_index, new_value } })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.getActiveOrdersList()
                        // this.orders[order_index].$info_index = new_value;
                    }
                })
                .catch(() => {
                    const notification = {
                        body: 'Ошибка',
                        header: 'Не удалось обновить заказ'
                    }
                    this.$root.notify(notification.body, notification.header)
                })
                .finally(() => {})
        },
    }
}
</script>
