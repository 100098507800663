<template lang="">
    <input :value="modelValue" @focus="updateInput" @input="updateInput" type="text" class="input outline-none" :class="[ valid ? 'text-green-700' : '']" />
</template>
<script>
export default {
    name: 'my-phone-input',
    data: ()=> {
        return { 
            valid: false
        }
    },
    props: {
        modelValue: [String,Number],
        emits: ['update:modelValue'],
    },
    methods: {
        updateInput(event) {
            let value = event.target.value;
            value = this.cleanValue(value)
            this.setValue(value)

            this.validateValue(value)
        },
        setValue(value){
            this.$emit('update:modelValue', value)
            this.$emit('change')
        },
        cleanValue(value){
            value = value.replaceAll("(", "");
            value = value.replaceAll(")", "");
            value = value.replaceAll("-", "");
            value = value.replaceAll(" ", "");
            value = value.replaceAll("+", "");
            value = value.replaceAll(" ", "");
            if(value[0] == '7' && value.length == 11)  value = value.replace('7', '8');
            // if(value.length == 10)  value = '8'+ value;
            return value;
        },
        validateValue(value){
            if(value.length == 11) this.valid = true
        }
    }
}
</script>
<style scoped>
    .input{
        /* min-width: 156px */
        padding-left: 0.5rem;
        width: 90%;
        border-bottom: 1px solid #aaa
    }
    .input:focus {
        border-bottom: 1px solid #333
    }
    .inpt_btns div:first-child{
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
    }
    .inpt_btns div:last-child{
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
    }
    .input_btn {
        /* height: 24px;  */
        padding: 2px 10px;
        line-height: 24px;
        /* font-weight: bold; */
        font-size: 18px;
        text-align: center;
        cursor: pointer;
        color: rgb(50, 50, 50)
    }
    .input_btn:hover {
        background: #333;
        color: white
    }
    .clear_btn {
        padding: 2px 10px;
        line-height: 24px;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
        background: rgb(243, 244, 246);
        /* border-radius: 14px */
    }
    .clear_btn:hover {
        background: rgb(140, 15, 15);
        color: white;
    }
</style>