<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 min-h-4">Создать промокод</h1>
                    <router-link :to="{ name: 'Promocodes' }">Все промокоды</router-link>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md">
                        <div class="flex flex-row p-4">
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Заголовок</label>
                                <input v-model="item.title" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Уникальное название</label>
                                <input v-model="item.slug" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Количество</label>
                                <input v-model="item.quantity" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Активно до</label>
                                <input v-model="item.expires_at" class="p-2 border outline-none" type="datetime-local"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Тип</label>
                                <input v-model="item.type" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Минимальная сумма</label>
                                <input v-model="item.min_sum" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4">
                            <div class="w-1/2 flex flex-col">
                                <label class="px-2">Описание</label>
                                <textarea v-model="item.body" class="p-2 border outline-none h-36"></textarea>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="flex flex-col py-4">
                    <div class="flex flex-row pb-6">
                        <div class="w-full flex flex-col items-end">
                            <button @click="storeItem()" class="py-3 px-6 outline-none rounded-md bg-green-100 hover:bg-green-300">Сохранить</button>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                slug: '',
                title: '',
                body: '',
                expires_at: '',
                quantity: '',
                min_sum: '',
                type: ''
            }
        }
    },
    methods: {
        storeItem () {
            this.postServerData('promocodes_store', { data: this.item })
                .then((res) => {
                    if (res.data?.code == 200) {
                        this.$router.push({ name: 'PromocodesEdit', params: { id: res.data.item_id } })
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
    }
}
</script>
<style lang="">

</style>
