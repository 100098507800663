<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 p-4 ">{{ item.first_name }} </h1>
                </div>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md">
                        <div class="flex flex-row p-4">
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Фамилия</label>
                                <input v-model="item.last_name" @change="updateItemInfo('last_name', item.last_name)" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Имя</label>
                                <input v-model="item.first_name" @change="updateItemInfo('first_name', item.first_name)" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Отчество</label>
                                <input v-model="item.middle_name" @change="updateItemInfo('middle_name', item.middle_name)" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Фотки</h3>
                    <div class="border rounded-md flex flex-row">
                        <div class="w-5/6 flex flex-row items-center">
                            <div v-if="item.avatar_url != null" class="flex flex-row p-4 w-full overflow-x-auto">
                                <!-- <div class="py-2 px-4 text-center">
                                    <input />
                                </div> -->
                                <div class="py-2 px-4 text-center relative hover:bg-gray-100 rounded-lg">
                                    <img :src="item.avatar_url.url" style="height: 256px; width: 256px; max-width: 256px"/>
                                    <div @click="detachAvatar()" class="w-6 absolute top-2 right-2  rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-red-500">x</div>
                                </div>
                            </div>
                            <div v-else class=" p-4">
                                <i>Нет привязанных фотографий</i>
                            </div>
                        </div>
                        <div class="w-1/6 px-4 flex flex-col border-l justify-center">
                            <label class="py-2">Добавить фотографию</label>
                            <select v-model="selected_server_image" @change="new_image.url = images_server + selected_server_image" class="mb-4 py-2 px-4 outline-none border rounded-md">
                                <option v-for="image in server_images" :value="`${image.dir}/${image.file}`">{{ image.dir }}/{{ image.file }}</option>
                            </select>
                            <select v-model="new_image.type" class="mb-4 py-2 px-4 outline-none border rounded-md">
                                <option value="avatar" selected>Avatar</option>
                            </select>
                            <!-- <input v-model="new_image.url" class="mb-4 p-2 border outline-none rounded-lg" type="text" placeholder="Ссылка"/>
                            <input v-model="new_image.type" class="mb-4 p-2 border outline-none rounded-lg" type="text" placeholder="Тип"/> -->
                            <div @click="attachAvatar()" class="p-2 rounded-lg text-white bg-gray-300 text-center cursor-pointer hover:bg-green-500">+ добавить</div>
                        </div>
                        <div class="w-1/6 px-4 flex flex-col border-l justify-center">
                            <label class="py-2">Загрузить фотографию</label>
                            <input @change="uploadAvatar()" class="mb-4 p-2 border outline-none rounded-lg" type="file" ref="file" />
                            <div @click="submitUploadedFile()" class="p-2 rounded-lg text-white bg-gray-300 text-center cursor-pointer hover:bg-green-500">+ добавить</div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Сменить пароль</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="flex flex-row p-4 w-full items-center">
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Пароль</label>
                                <input v-model="password.password" class="p-2 border outline-none" type="password"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Повтор</label>
                                <input v-model="password.password_confirm" class="p-2 border outline-none" type="password"/>
                            </div>
                            <div class="w-3/12 flex pt-6 pl-4">
                                <button @click="updateItemInfo('password', password)" class="py-2 px-4 outline-none rounded-md bg-green-100 hover:bg-green-300">Сохранить</button>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Информация</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="flex flex-row p-4 w-full">
                            <div class="w-3/12 flex flex-col">
                                <label class="pb-2">Последний визит</label>
                                {{ item.last_visit_at }}
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="pb-2">Последнее обновление</label>
                                {{ item.updated_at }}
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="pb-2">Создан</label>
                                {{ item.created_at }}
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import api_routes from '../../config/api_routes';
import { api_route } from '../../config/api_routes';

export default {
    data: () => {
        return {
            item : {
                id: 0,
            },
            password: {
                password: '',
                password_confirm: ''
            },
            new_image: {
                url: '',
                type: 'avatar',
            },
            images_server: 'https://static.omelette.kz/',
            selected_server_image: '',
            server_images: [],
            images: null,
            roles: [],
        }
    },
    methods: {
        uploadAvatar(){
            this.Images = this.$refs.file.files[0];
        },
        submitUploadedFile(){
            const formData = new FormData();
            formData.append('meal_name', 'user_avatar');
            formData.append('meal_type', 'pizza');
            formData.append('userfile', this.Images);
            const headers = { 'Content-Type': 'multipart/form-data' };
            axios
                .post(this.images_server + '_php/file_upload_handler.php', formData, { headers })
                .then((res) => {
                    res.data.files; // binary representation of the file
                    res.status; // HTTP status
                });
        },
        getStaffById(){
            axios
                .get(api_route('staff_by_id', [ this.item.id ]))
                .then((res)=>{
                    this.item = res.data
                })
                .catch((error)=>console.log(error))
        },
        getImages(){
            axios
                .get('https://static.omelette.kz/?meals_files')
                .then((res)=>{
                    this.server_images = res.data
                })
                .catch((errors)=>console.log(errors))
        },
        detachAvatar(){
            axios
                .post(api_route('staff_detach_avatar'), { item_id: this.item.id })
                .then((res)=>{
                    this.getStaffById()
                })
                .catch((error)=>console.log(error))
        
        },
        attachAvatar(){
            if(this.new_image.url != '' && this.new_image.type != ''){
                axios
                    .post(api_route('staff_attach_avatar'), { item_id: this.item.id, image_url: this.new_image.url })
                    .then((res)=>{
                        this.new_image.url = ''
                        this.getStaffById()
                    })
                    .catch((error)=>console.log(error))
            }
        },
        updateItemInfo(info_index, new_value){
            axios
                .post(api_route('staff_update_info'), { item_id: this.item.id, info_index, new_value })
                .then((res)=>{

                })
                .catch((error)=>console.log(error))

        },
    },
    mounted(){
        this.item.id = this.$store.getters.currentUser.id
        
        this.getStaffById()
        this.getImages()

    }
}
</script>
<style lang="">
    
</style>