<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-center p-4">
                    <div class="w-full flex flex-col justify-around ">
                        <h1 class="text-lg lg:text-2xl text-gray-800 font-semibold pt-2 pb-2">Ингредиенты</h1>
                    </div>
                    <div class="w-full flex flex-row justify-end">
                        <div v-if="can('create_ingredients')">
                            <router-link :to="{name: 'IngredientsCreate' }" class="">
                                <default-button type="confirm">Ингредиент</default-button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="w-full rounded-lg border-2 border-indigo-100">
                    <!-- mobile -->
                    <div class="w-full flex lg:hidden py-2 px-2 flex-col">
                        <div class="w-full flex mb-2 items-center">
                            <text-input icon-url="https://img.icons8.com/nolan/256/1A6DFF/C822FF/search.png" type="text" input-class="text-sm p-0" container-class="rounded-full w-full" placeholder="Поиск" v-model="name_like" @keyup.enter="getIngredients"/>
                        </div>
                        <div class="w-full flex mb-2 items-center justify-between">
                            <div class="text-gray-500 text-xs">Показаны {{ meta.to}} из {{ meta.total}}</div>
                            <toggle-button v-model="meta.per_page" :values="['25:25', '50:50', `${meta.total}:Все`]" @change="()=>{ meta.current_page = 1; getIngredients()}"></toggle-button>
                        </div>
                    </div>

                    <!-- desktop -->
                    <div class="w-full hidden lg:flex py-2 px-2 justify-between items-center">
                        <div class="flex items-center">
                            <div class="pl-4">
                                <toggle-button v-model="sort_by" :values="['name:По наименованию','priority:По приритету']" @change="getIngredients"></toggle-button>
                            </div>
                            <div class="pl-4">
                                <toggle-button v-model="sort_dir" :values="['ASC:А-Я','DESC:Я-А']" @change="getIngredients"></toggle-button>
                            </div>
                            <div class="pl-4">
                                <toggle-button v-model="meta.per_page" :values="['10:10','25:25', '50:50', '100:100', `${meta.total}:Все`]" @change="()=>{ meta.current_page = 1; getIngredients()}"></toggle-button>
                            </div>
                        </div>
                        <text-input icon-url="https://img.icons8.com/nolan/256/1A6DFF/C822FF/search.png" type="text" input-class="text-sm p-0" container-class="rounded-full" placeholder="Поиск" v-model="name_like" @keyup.enter="getIngredients" requestsEnterKey/>
                        <div class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}}</div>
                    </div>

                    <div v-if="ingredients.length > 0"  :class="{'opacity-50':isLoadingData}">

                        <div class="items_list">
                            <div class="w-full flex flex-row bg-indigo-100 text-indigo-700 select-none">
                                <div class="w-2/12 lg:w-1/12 p-2 text-center cursor-pointer transition-all"  @click="changeOrderByDir('priority')"
                                    :class="[sort_by == 'priority' ? 'font-semibold' : '',
                                            sort_by == 'priority' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'priority' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]">PR</div>
                                <div class="w-9/12 md:w-6/12 p-2 cursor-pointer transition-all"  @click="changeOrderByDir('name')"
                                    :class="[sort_by == 'name' ? 'font-semibold' : '',
                                            sort_by == 'name' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'name' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]">Наименование</div>
                                <div class="w-2/12 p-2 text-right hidden md:flex">Цена</div>
                                <div class="w-3/12 p-2 border-r text-right" >Склд</div>
                                <!-- <div class="w-2/12 p-2 text-center " >Ед.</div>
                                <div class="w-2/12 p-2 text-center " >Погр. %</div>
                                <div class="w-2/12 p-2 text-center " >Порог</div> -->
                                <!-- <div class="w-3/12 p-2 text-center hidden md:flex " >Обнулить</div> -->
                                <div class="w-3/12 p-2 text-center hidden md:flex justify-center" >Скорректировать</div>
                                <div class="w-1/12 p-2 text-center hidden md:flex" >Блюд</div>
                            </div>
                            <div v-for="item, index in ingredients" :key="item.id">
                                <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed">
                                    <div class="w-full flex flex-row hover:bg-gray-100 items-center" :class="[ingredients[index].in_stock < ingredients[index].notify_at && ingredients[index].notify_at != 0 ? 'text-red-500' : '' ]">
                                        <div class="w-2/12 lg:w-1/12 flex justify-center">
                                            <router-link :to="{ name: 'IngredientsEdit', params: {id: item.id}}">{{ index + 1 }}</router-link>
                                        </div>
                                        <div class="w-9/12 md:w-6/12 p-2 flex flex-col">
                                            <div>
                                                <router-link :to="{ name: 'IngredientsEdit', params: {id: item.id}}">
                                                    {{ item.name }}
                                                </router-link>
                                                <!-- <input v-model="item.name" @change="updateIngredientInfo(ingredients[index], 'name', item.name)" class="py-2 px-4 border-b outline-none w-4/5"/> -->
                                            </div>
                                            <!-- <router-link :to="{name: 'ItemsEdit', params: { id: item.id  }}">{{ item.name }}</router-link> -->
                                            <div class=" flex flex-row text-xs text-gray-300">
                                                <!-- | {{ item.name_short }}
                                                | {{ item.description }}
                                                | {{ item.emoji }}
                                                | {{ item.slug }}  -->
                                            </div>
                                        </div>
                                        <div class="w-2/12 px-2 text-right hidden md:flex">
                                            {{ item.latest_price }}
                                        </div>
                                        <div class="w-3/12 px-2 text-right md:border-r overflow-hidden">
                                            {{ item.in_stock }}{{item.unit}}
                                        </div>
                                        <!-- <div class="w-3/12 px-2 flex justify-center hidden md:flex">
                                            <button @click="setToZero(item)" class="py-2 px-4 rounded-md bg-gray-100 hover:bg-red-100">Обнулить</button>
                                        </div> -->
                                        <div class="w-3/12 px-2 flex justify-center hidden md:flex">
                                            <text-input v-model="item.set_in_stock" @keyup.enter="setInStock(item)" placeholder="Указать" requestsEnterKey input-class="pl-4 py-1" container-class="rounded-full"/>
                                        </div>
                                        <!-- <div class="w-2/12 px-2 flex justify-center">
                                            <input v-model="item.unit" @change="updateIngredientInfo(ingredients[index], 'unit', item.unit)" class="py-2 px-2 border-b outline-none w-2/5 text-right"/>
                                        </div>
                                        <div class="w-2/12 px-2 flex justify-center">
                                            <input v-model="item.error" @change="updateIngredientInfo(ingredients[index], 'error', item.error)" class="py-2 px-2 border-b outline-none w-2/5 text-right"/>
                                        </div>
                                        <div class="w-2/12 px-2 flex justify-center">
                                            <input v-model="item.notify_at" @change="updateIngredientInfo(ingredients[index], 'notify_at', item.notify_at)" class="py-2 px-2 border-b outline-none w-2/5 text-right"/>
                                        </div> -->
                                        <div class="w-1/12 px-2 flex justify-center hidden md:flex">{{ item.meals_count }}</div>
                                        <!--
                                        <div class="w-1/12 px-2">{{ item.weight }}</div>
                                        <div class="w-1/12 px-2 text-sm">{{ item.is_active ? 'Активно' : 'Не активно' }}</div>
                                        <div class="w-1/12 px-2 text-sm">{{ item.is_seen ? 'Видимо' : 'Скрыто' }}</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section>
                            <div class="flex flex-row justify-between items-center p-4 border-t text-xs">
                                <div class="w-1/2 flex flex-col justify-around pl-2">
                                    <span class="text-xs">Показаны {{ meta.from }} - {{ meta.to}} из {{ meta.total}}</span>
                                </div>
                                <div v-if="meta.links.length > 3" class="w-1/2 flex flex-row justify-end">
                                    <div v-for="link, index in meta.links">
                                        <div
                                            v-if="index != 0 && index != meta.links.length-1"
                                            class="py-2 px-4 bg-gray-100 rounded-md"
                                            :class="[
                                                    meta.current_page == parseInt(link.label) ? 'bg-yellow-300 font-semibold' : '',
                                                    link.url !== null ? 'hover:bg-yellow-300 cursor-pointer' : ''
                                                ]"
                                            @click="()=>{ if(link.url != null ){ changePage(parseInt(link.label)) }}"
                                            >{{ link.label }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div v-else>
                        <div class="w-full py-16 text-center">
                            Список пуст
                        </div>
                    </div>
                </div>
           </section>
       </div>
    </div>
    <confirm-alert ref="confirmAlert"></confirm-alert>
</template>
<script>
export default {
    data: () => {
        return {
            meta: {
                current_page: 0,
                per_page: 25,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0
            },
            sort_by: 'priority',
            sort_dir: 'DESC',
            name_like: '',
            selected_tag: '',
            items: [],
            ingredients: [],
            // showEditItemDialog: false,
            isLoadingData: false
        }
    },
    methods: {
        async setInStock (ingredient) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Указать количество?',
                description: '' + ingredient.name + ' на складе ' + ingredient.set_in_stock + '' + ingredient.unit,
                okButton: 'Указать',
                type: 'confirm-success'
            })
            if (confirmdel) {
                this.postServerData('ingredient_set_in_stock', { data: ingredient })
                .then((res) => {
                    this.getIngredients()
                })
                .catch((error) => {})
            } else {
                console.log(ingredient)
            }
        },
        async setToZero (ingredient) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Обнулить количество?',
                description: 'Обнулить количество на складе',
                okButton: 'Обнулить'
            })
            if (confirmdel) {
                this.postServerData('ingredient_set_to_zero', { data: ingredient })
                .then((res) => {
                    this.getIngredients()
                })
                .catch((error) => {})
            }
        },
        getIngredients () {
            this.getServerData('ingredients_list', { per_page: this.meta.per_page, sort_by: this.sort_by, sort_dir: this.sort_dir, current_page: this.meta.current_page, name_like: this.name_like })
                .then((res) => {
                    this.meta.current_page = res.data.current_page
                    this.meta.last_page = res.data.last_page
                    this.meta.links = res.data.links
                    this.meta.total = res.data.total
                    this.meta.from = res.data.from
                    this.meta.to = res.data.to
                    this.ingredients = res.data.data
                })
                .catch((error) => {})
        },
        changePage (page) {
            this.$router.push({ name: this.$route.name, params: { page } })
        },
        updateIngredientInfo (item, info_index, new_value) {
            this.postServerData('ingredient_update_info', { data: { item_id: item.id, info_index, new_value } })
                .then((res) => {})
                .catch((error) => {})
        },
        changeOrderByDir (criteria) {
            if (this.sort_by != criteria) {
                this.sort_by = criteria
                this.sort_dir = 'ASC'
            } else {
                if (this.sort_dir == 'ASC') {
                    this.sort_dir = 'DESC'
                } else {
                    this.sort_dir = 'ASC'
                }
            }
            this.meta.current_page = 1

            this.getIngredients()
        }
    },
    mounted () {
        if (this.$route.params.page) {
            this.meta.current_page = this.$route.params.page
        }
        this.getIngredients()
    },
    watch: {
        $route (to, from) {
            if (to.name == this.$route.name) {
                this.meta.current_page = to.params.page
                this.getIngredients()
            }
        }
    },
    components: {
    }
}
</script>
<style scoped>
    .sort-asc {
        background-image: url('https://img.icons8.com/nolan/256/1A6DFF/C822FF/sort-up--v2.png');
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 1.2rem;
    }
    .sort-desc{
        background-image: url('https://img.icons8.com/nolan/256/1A6DFF/C822FF/sort-down--v1.png');
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 1.2rem;
    }
</style>
