<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold p-4 ">Создать фирму</h1>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md">
                        <div class="w-full flex flex-row p-4">
                            <div class="w-full flex flex-col max-w-lg">
                                <label class="px-2">Название</label>
                                <input v-model="item.title" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                        <!-- <div class="w-3/12 flex flex-row p-4">
                            <div class="w-full flex flex-col">
                                <label class="px-2">Статус</label>
                                <select v-model="item.status" @change="updateItemInfo('status', item.status)" class="p-2 border outline-none">
                                    <option value="1">Активно</option>
                                    <option value="0">Не активно</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="flex flex-row p-4">
                            <div class="w-full flex flex-col max-w-lg">
                                <label class="px-2">Описание</label>
                                <textarea v-model="item.description" class="p-2 border outline-none h-36"></textarea>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <div class="flex flex-row pb-6">
                        <div class="w-full flex flex-col items-end">
                            <default-button @click="storeItem()" type="confirm">Сохранить</default-button>
                        </div>
                    </div>
                </section>

            </section>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                title: '',
                description: ''
            }
        }
    },
    methods: {
        storeItem () {
            this.postServerData('firms_store', { data: { title: this.item.title, description: this.item.description } })
                .then((res) => {
                    this.$router.push({ name: 'MyFirmEdit' })
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
    }
}
</script>
<style lang="">

</style>
