<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-end pt-6  pb-4">
                    <div class="w-2/3 flex flex-col justify-around pl-2 ">
                        <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-2">Список новостей</h1>
                        <span class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}}</span>
                    </div>
                    <div class="w-1/3 flex flex-row justify-end">
                        <div v-if="can('create_news') || can('debug')">
                            <router-link :to="{name: 'NewsCreate' }">
                                <default-button type="confirm">Новость</default-button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="w-full rounded-lg border-2 border-gray-100">
                    <div v-if="news.length > 0" class="items_list">
                        <div class="hidden md:flex w-full flex-row bg-gray-100">
                            <!-- <div class="w-1/12 p-2"></div> -->
                            <div class="w-8/12 p-2" >Наименование</div>
                            <div class="w-2/12 p-2" >Опубликовано</div>
                        </div>
                        <div v-for="item in news" :key="item.id">
                            <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed">
                                <div class="w-full flex flex-row hover:bg-gray-100 items-center">
                                    <!-- <div class="w-1/12 flex justify-center">
                                        <img :src="item.images[0]?.url" style="height: 40px; border-radius: 50%"/>
                                    </div> -->
                                    <div class="w-8/12 p-2 text-lg flex flex-col">
                                        <div v-if="can('edit_news')">
                                            <router-link :to="{name: 'NewsEdit', params: { id: item?.id  }}">{{ item.title }}</router-link>
                                        </div>
                                        <div v-else>{{ item.title }}</div>
                                    </div>
                                    <div class="w-2/12 px-2">{{ item.created_at }}</div>
                                    <!-- <div class="w-1/12 px-2">{{ item.meals.length }}</div>
                                    <div class="w-1/12 px-2 text-sm">{{ item.is_active ? 'Активно' : 'Не активно' }}</div>
                                    <div class="w-1/12 px-2 text-sm">{{ item.is_seen ? 'Видимо' : 'Скрыто' }}</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="p-16">
                        <i>Новостей пока нет</i>
                    </div>
                </div>
           </section>
           <div class="py-4"></div>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            meta: {
                current_page: 0,
                per_page: 20,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0
            },
            sort_by: 'created_at',
            sort_dir: 'DESC',
            news: []
            // showEditItemDialog: false,
        }
    },
    methods: {
        getNews () {
            this.getServerData('news_all')
                .then((res) => {
                    this.meta.total = res.data.length
                    this.meta.from = 1
                    this.meta.to = res.data.length
                    this.news = res.data
                })
                .catch(() => {})
        }
    },
    mounted () {
        this.getNews()
    },
    watch: {
        // $route(to, from) {
        //     console.log(this.$route)
        //     console.log(this.$route.query)
        //     console.log(this.$route.hash)
        // }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
