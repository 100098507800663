<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-center p-4">
                    <div class="w-full flex flex-col justify-around ">
                        <h1 class="text-lg lg:text-2xl text-gray-800 font-semibold pt-2 pb-2">Список блюд</h1>
                    </div>
                    <div class="w-full flex flex-row justify-end">
                        <div v-if="can('create_meals')">
                            <router-link :to="{name: 'ItemsCreate' }">
                                <default-button type="confirm">Блюдо</default-button>
                            </router-link>
                        </div>
                        <!-- <select v-model="selected_tag" @change="mealSearchByTag" class="border rounded-lg py-2 px-4 mr-4" >
                            <option selected value="">Все</option>
                            <option v-for="tag in tags" :value="tag.id">{{ tag.name }}</option>
                        </select>
                        <input v-model="name_like" @change="mealSearchByName" class="border rounded-lg py-2 px-4" placeholder="Поиск по названию"/> -->
                        <!-- <div v-for="link, index in meta.links">
                            <div
                                v-if="index != 0 && index != meta.links.length-1"
                                class="py-1 px-2 bg-gray-100 rounded-md text-sm"
                                :class="[
                                        meta.current_page == parseInt(link.label) ? 'bg-yellow-300 font-semibold' : '',
                                        link.url !== null ? 'hover:bg-yellow-300 cursor-pointer' : ''
                                    ]"
                                @click="()=>{ if(link.url != null ){ changePage(parseInt(link.label)) }}"
                                >{{ link.label }}
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="w-full rounded-lg border-2 border-yellow-100">
                    <!-- mobile -->
                    <div class="w-full flex lg:hidden py-2 px-2 flex-col">
                        <div class="w-full flex mb-2 items-center">
                            <text-input icon-url="https://static.breaktime.kz/uploads/images/breaktime/icons/search-64-bicolor.png" type="text" input-class="text-sm p-0" container-class="rounded-full" placeholder="Поиск" v-model="name_like" @keyup.enter="getMealsList"/>
                            <div class="pl-2">
                                <toggle-button v-model="listView" :values="['table:Таблицей','cards:Списком']" @change="getMealsList"></toggle-button>
                            </div>
                        </div>
                        <div class="w-full flex mb-2 items-center justify-between">
                            <div class="text-gray-500 text-xs">Показаны {{ meta.to}} из {{ meta.total}}</div>
                            <toggle-button v-model="meta.per_page" :values="['10:10','25:25', '50:50']" @change="()=>{ meta.current_page = 1; getMealsList()}"></toggle-button>
                        </div>
                    </div>

                    <!-- desktop -->
                    <div class="w-full hidden lg:flex py-2 px-2 justify-between items-center">
                        <div class="flex items-center">
                            <div class="pl-2">
                                <toggle-button v-model="listView" :values="['table:Таблицей','cards:Списком']" @change="getMealsList"></toggle-button>
                            </div>
                            <div class="pl-4">
                                <toggle-button v-model="sort_by" :values="['name:По наименованию','tag_id:По тэгу']" @change="getMealsList"></toggle-button>
                            </div>
                            <div class="pl-4">
                                <toggle-button v-model="sort_dir" :values="['ASC:А-Я','DESC:Я-А']" @change="getMealsList"></toggle-button>
                            </div>
                            <div class="pl-4">
                                <toggle-button v-model="meta.per_page" :values="['10:10','25:25', '50:50', '100:100']" @change="()=>{ meta.current_page = 1; getMealsList()}"></toggle-button>
                            </div>
                        </div>
                        <text-input icon-url="https://static.breaktime.kz/uploads/images/breaktime/icons/search-64-bicolor.png" type="text" input-class="text-sm p-0" container-class="rounded-full" placeholder="Поиск" v-model="name_like" @keyup.enter="getMealsList"/>
                        <div class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}}</div>
                    </div>

                    <div v-if="items.length > 0"  :class="{'opacity-50':isLoadingData}">
                        <div v-if="listView == 'table'">
                            <div class="w-full flex flex-row bg-yellow-100 text-stone-700 select-none">
                                <div class="w-1/12 p-2"></div>
                                <div class="w-8/12 p-2 cursor-pointer transition-all" @click="changeOrderByDir('name')"
                                    :class="[sort_by == 'name' ? 'font-semibold' : '',
                                            sort_by == 'name' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'name' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]">Наименование</div>
                                <div class="w-3/12 p-2 cursor-pointer transition-all" @click="changeOrderByDir('tag_id')"
                                    :class="[sort_by == 'tag_id' ? 'font-semibold' : '',
                                            sort_by == 'tag_id' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'tag_id' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]">Категория</div>
                                <div class="hidden lg:flex w-1/12 p-2 cursor-pointer transition-all" @click="changeOrderByDir('price')"
                                    :class="[sort_by == 'price' ? 'font-semibold' : '',
                                            sort_by == 'price' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'price' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]">Цена</div>
                                <div class="hidden lg:flex w-1/12 p-2 cursor-pointer transition-all" @click="changeOrderByDir('is_active')"
                                    :class="[sort_by == 'is_active' ? 'font-semibold' : '',
                                            sort_by == 'is_active' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'is_active' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]">Активно</div>
                                <div class="hidden lg:flex w-1/12 p-2 cursor-pointer transition-all" @click="changeOrderByDir('is_seen')"
                                    :class="[sort_by == 'is_seen' ? 'font-semibold' : '',
                                            sort_by == 'is_seen' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'is_seen' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]">Видимо</div>
                                <div class="hidden lg:flex w-1/12 p-2 cursor-pointer transition-all" @click="changeOrderByDir('is_recomended')"
                                    :class="[sort_by == 'is_recomended' ? 'font-semibold' : '',
                                            sort_by == 'is_recomended' && sort_dir == 'ASC' ? 'sort-asc' : '',
                                            sort_by == 'is_recomended' && sort_dir == 'DESC' ? 'sort-desc' : ''
                                            ]">Продвигаем</div>
                            </div>
                            <div class="w-full">
                                <div v-for="item in items" :key="item.id">
                                    <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed #aaa">
                                        <div class="w-full flex flex-row hover:bg-gray-100 items-center">
                                            <div class="w-1/12 flex justify-center">
                                                <img :src="item.main_image?.url" style="height: 60px; border-radius: 50%"/>
                                            </div>
                                            <div class="w-8/12 px-2 flex flex-col"
                                                    :class="[item.is_seen == 0 ? 'text-gray-500' : '', item.is_active == 0 ? 'italic' : '']">
                                                <router-link :to="{name: 'ItemsEdit', params: { id: item.id  }}">{{ item.name || '!noname!' }}</router-link>
                                                <div class=" flex flex-row text-xs text-gray-300">
                                                    Тэги: {{ item.tags.length }}
                                                    | Ингр.: {{ item.ingredients.length }}
                                                    | Дети: {{ item.children.length }}
                                                    | {{ item.sub_type }}
                                                    | {{ item.slug }}
                                                </div>
                                            </div>
                                            <div class="w-3/12 px-2">{{ item.tag?.name }}</div>
                                            <div class="hidden lg:flex w-1/12 px-2">{{ item.price }}</div>
                                            <div class="hidden lg:flex w-1/12 px-2">{{ item.is_active ? 'Активно' : 'Не активно' }}</div>
                                            <div class="hidden lg:flex w-1/12 px-2">{{ item.is_seen ? 'Видимо' : 'Скрыто' }}</div>
                                            <div class="hidden lg:flex w-1/12 px-2">{{ item.is_recomended ? 'Продвигаем' : 'Обычное' }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="listView == 'cards'">
                            <div class="w-full flex flex-wrap" :class="{'opacity-50':isLoadingData}">
                                <div v-for="item in items" :key="item.id" class="w-6/12 md:5/12 lg:w-3/12 xl:w-1/5 p-2">
                                    <div class="w-full flex flex-col text-sm hover:bg-yellow-50 rounded-md border ">
                                        <div class="w-full flex flex-col hover:bg-yellow-50 items-center">
                                            <div class="w-full flex justify-center py-4">
                                                <img :src="item.main_image?.url" style="height: 140px;" class="rounded-md"/>
                                            </div>
                                            <div class="w-full py-2 px-4 text-md flex flex-col">
                                                <!-- <div v-if="can('edit_items')"> -->
                                                    <router-link :to="{name: 'ItemsEdit', params: { id: item.id  }}">{{ item.name }}</router-link>
                                                <!-- </div> -->
                                                <!-- <div v-else>{{ item.name }}</div> -->
                                                <div>{{ item.price }} тг</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section>
                            <div class="flex flex-row justify-between items-center p-4 border-t text-xs">
                                <div class="w-1/2 flex flex-col justify-around pl-2">
                                    <span class="text-xs">Показаны {{ meta.from }} - {{ meta.to}} из {{ meta.total}}</span>
                                </div>
                                <div v-if="meta.links.length > 3" class="w-1/2 flex flex-row justify-end">
                                    <div class="flex border">
                                        <div v-for="link, index in meta.links" :key="index" class="first:rounded-tl-md first:rounded-bl-md last:rounded-tr-md last:rounded-br-md">
                                            <div
                                                v-if="index != 0 && index != meta.links.length-1"
                                                class="py-2 px-4"
                                                :class="[
                                                        meta.current_page == parseInt(link.label) ? 'bg-yellow-300 font-semibold' : '',
                                                        link.url !== null ? 'hover:bg-yellow-300 cursor-pointer' : ''
                                                    ]"
                                                @click="()=>{ if(link.url != null ){ changePage(parseInt(link.label)) }}"
                                                >{{ link.label }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div v-else>
                        <div class="w-full py-16 text-center">
                            Список пуст
                        </div>
                    </div>
                </div>
           </section>
       </div>
   </div>
</template>
<script>
export default {
    data: () => {
        return {
            meta: {
                current_page: 0,
                per_page: 25,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0
            },
            sort_by: 'name',
            sort_dir: 'ASC',
            name_like: '',
            selected_tag: '',
            items: [],
            tags: [],
            // showEditItemDialog: false,
            isLoadingData: false,
            listView: 'cards'
        }
    },
    methods: {
        mealSearchByTag () {
            this.meta.current_page = 1
            this.getMealsList()
        },
        mealSearchByName () {
            this.meta.current_page = 1
            this.getMealsList()
        },
        getMealsList () {
            this.isLoadingData = true
            this.getServerData('meals_list', { per_page: this.meta.per_page, sort_by: this.sort_by, sort_dir: this.sort_dir, current_page: this.meta.current_page, name_like: this.name_like, selected_tag: this.selected_tag, with: '' })
                .then((res) => {
                    this.meta = res.data.meta
                    this.items = res.data.data
                })
                .catch(() => {})
                .finally(this.isLoadingData = false)
        },
        changeOrderByDir (criteria) {
            if (this.sort_by !== criteria) {
                this.sort_by = criteria
                this.sort_dir = 'ASC'
            } else {
                if (this.sort_dir === 'ASC') {
                    this.sort_dir = 'DESC'
                } else {
                    this.sort_dir = 'ASC'
                }
            }
            this.meta.current_page = 1

            this.getMealsList()
        },
        changePage (page) {
            // this.$router.push({ name: 'ItemsPaged', params: { page }})
            this.meta.current_page = page
            this.getMealsList()
        },
        getTags () {
           this.getServerData('meals_tags')
                .then((res) => {
                    this.tags = res.data
                })
                .catch(() => {})
        }
    },
    mounted () {
        if (this.$route.params.page) {
            this.meta.current_page = this.$route.params.page
        }

        this.getMealsList()
        this.getTags()
    },
    watch: {
        // $route (to, from) {
        //     if(to.name == 'Items' || to.name == 'ItemsPaged'){
        //         this.meta.current_page = to.params.page
        //         this.getMealsList()
        //     }
        // }
    },
    components: {
    }
}
</script>
<style scoped>
    .sort-asc {
        background-image: url('https://img.icons8.com/nolan/256/1A6DFF/C822FF/sort-up--v2.png');
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 1.4rem;
    }
    .sort-desc{
        background-image: url('https://img.icons8.com/nolan/256/1A6DFF/C822FF/sort-down--v1.png');
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 1.4rem;
    }
</style>
