<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">Создать роль</h1>
                    <router-link :to="{ name: 'Departments' }">&larr; назад</router-link>
                </div>
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md">
                        <div class="flex flex-row p-4">
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Наименование</label>
                                <input v-model="item.title" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Департамент</label>
                                <select v-model="item.department_id" class="p-2 border outline-none">
                                    <option v-for="department in departments_all" :value="department.id">{{ department.title }}</option>
                                </select>
                            </div>
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Slug</label>
                                <input v-model="item.slug" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4">
                            <div class="w-1/2 flex flex-col">
                                <label class="px-2">Описание</label>
                                <textarea v-model="item.description" class="p-2 border outline-none h-36"></textarea>
                            </div>
                        </div>
                        <div class="flex flex-row pb-6">
                            <div class="w-1/2 flex flex-col items-end">
                                <button @click="storeItem()" class="py-3 px-6 outline-none rounded-md bg-green-100 hover:bg-green-300">Сохранить</button>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                title: '',
                slug: '',
                department_id: 1,
                description: ''
            },
            departments_all: []
        }
    },
    methods: {
        storeItem () {
            this.postServerData('role_store', { data: this.item })
                .then((res) => {
                    if (res.data?.code === 200) {
                        this.$router.push({ name: 'RolesEdit', params: { id: res.data.item_id } })
                    }
                })
                .catch((error) => console.log(error))
        },
        getDepartmentsAll () {
            this.getServerData('departments_all')
                .then((res) => {
                    this.departments_all = res.data.data
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        this.getDepartmentsAll()
    }
}
</script>
<style lang="">

</style>
