<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">{{ item.title || '...' }}</h1>
                    <router-link :to="{ name: 'Permissions' }">&larr; назад</router-link>
                </div>
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md">
                        <div class="flex flex-row p-4">
                            <div class="w-4/12 flex flex-col">
                                <label class="px-2">Наименование</label>
                                <input v-model="item.title" @change="updateItemInfo('title', item.title)" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-1/12 flex flex-col">
                                <label class="px-2">Группа</label>
                                <input v-model="item.collection" @change="updateItemInfo('collection', item.collection)" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-1/12 flex flex-col">
                                <label class="px-2">Slug</label>
                                <input v-model="item.slug" @change="updateItemInfo('slug', item.slug)" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4">
                            <div class="w-1/2 flex flex-col">
                                <label class="px-2">Описание</label>
                                <textarea v-model="item.description" @change="updateItemInfo('description', item.description)" class="p-2 border outline-none h-36"></textarea>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Роли</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="p-2 flex flex-col">
                            <label class="px-2">Кому разрешено</label>
                            <div class="flex flex-row flex-wrap">
                                <div v-for="role in item.roles" class="m-2 py-1 px-6 flex flex-row items-center hover:bg-gray-100 rounded-md">
                                    {{ role.title }}
                                    <div @click="detachRole(role.id)" class="ml-2 w-6 rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-red-500">x</div>
                                </div>
                                <div class="flex flex-row items-center justify-between hover:bg-gray-100 rounded-md pr-4">
                                    <select v-model="selected_role" class="py-2 px-4 outline-none border rounded-md">
                                        <option value='' disabed selected>Добавить к роли</option>
                                        <option v-for="role in roles" :value="role.id" :key="`kk_${role.id}`">{{ role.title }}</option>
                                    </select>
                                    <div @click="attachRole()" class="ml-2 w-6 rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-green-500">+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section v-if="can('delete_permissions')" class="flex flex-col pb-6">
                    <div class="w-1/2 flex flex-col items-end">
                        <button @click="deleteItem()" class="py-3 px-6 outline-none rounded-md bg-red-100 hover:bg-red-300">Удалить</button>
                    </div>
                </section>
            </section>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                id: 0
            },
            selected_role: '',
            roles: []
        }
    },
    methods: {
        getItemById () {
            this.getServerData('permission_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data
                })
                .catch((error) => console.log(error))
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('permission_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {

                })
                .catch((error) => console.log(error))
        },
        getRolesAll () {
            this.getServerData('roles_all')
                .then((res) => {
                    this.roles = res.data
                })
                .catch((error) => console.log(error))
        },
        detachRole (role_id) {
            this.postServerData('permission_detach_role', { data: { permission_id: this.item.id, role_id } })
                .then((res) => {
                    this.getItemById()
                })
                .catch((error) => console.log(error))
        },
        attachRole () {
            if (this.selected_role !== '') {
                this.postServerData('permission_attach_role', { data: { permission_id: this.item.id, role_id: this.selected_role } })
                    .then((res) => {
                        this.selected_role = ''
                        this.getItemById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        deleteItem () {
            this.postServerData('permission_delete', { data: { item_id: this.item.id } })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.$router.push({ name: 'Permissions' })
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getItemById()
        this.getRolesAll()
    }
}
</script>
<style lang="">

</style>
