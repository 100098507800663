<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="w-full lg:container lg:mx-auto bg-white">
          <section>
            <div class="flex flex-wrap">
                <div v-for="nav, index in navs" :key="index" class="w-full md:w-1/2 lg:w-1/3 p-4">
                    <div>{{ nav.title }}</div>
                    <div v-for="ni, index in nav.items" :key="index">
                        <div v-if="ni.inactive" class="hidden md:flex flex-col px-8 py-14 mb-4 rounded-md bg-gray-50 border opacity-100" active-class="bg-lime-300">
                            <div class="text-xl text-center h-7"></div>
                        </div>
                        <router-link v-else :to="ni.to" class="flex flex-col px-12 mb-8 justify-center hover:bg-lime-300 cursor-pointer rounded-md bg-white border shadow-sm main-nav" active-class="bg-lime-300" :class="[ni.rowspan == 2 ? 'h-80' : 'h-36']">
                            <div>
                                <img src="https://img.icons8.com/nolan/256/1A6DFF/C822FF/shop.png" style="height:48px"/>
                            </div>
                            <div class="text-xl text-start font-semibold">{{ ni.title }}</div>
                            <div class="text-sm text-start text-gray-500">{{ ni.description }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
          </section>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            navs: [
                {
                    // category
                    title: '',
                    items:
                    [
                        {
                            title: 'Фирма',
                            description: 'Редактировать информацию фирмы',
                            to: { name: 'MyFirmEdit' },
                            rowspan: 2
                        },
                        {
                            inactive: true
                        },
                    ]
                },
                {
                    // category
                    title: '',
                    items:
                    [
                        {
                            title: 'Филиал',
                            description: 'Редактировать информацию филиала',
                            to: { name: 'MyBranchEdit' },
                            rowspan: 2
                        },
                        {
                            title: 'Добавить Филиал',
                            // description: 'Добавить еще филиал',
                            to: { name: 'MyBranchEdit' }
                        }
                    ]
                },
                {
                    // category
                    title: '',
                    items:
                    [
                        {
                            title: 'Сотрудники',
                            description: 'Список сотрудников филиала',
                            to: { name: 'MyStaff' },
                            rowspan: 2
                        },
                        {
                            title: 'Добавить Сотрудника',
                            to: { name: 'MyStaffCreate' }
                        }
                    ]
                }
            ]
        }
    },
    methods: {
    },
    watch: {
    },
    mounted () {
    },
    components: {
    }
}
</script>
<style scoped>
.main-nav:hover{
    filter: blur(0)
}
.main-nav{
    /* background-image: url('https://img.icons8.com/nolan/256/1A6DFF/C822FF/shop.png'); */
    background-repeat: no-repeat;
    background-size: 240px;
    background-position: calc(100% + 120px) center;
    /* filter:saturate(0); */
    transition: all .3s;
    /* text-shadow: 1px 1px 1px #fff, -1px -1px 1px #fff, -1px 1px 1px #fff, 1px -1px 1px #fff; */
}
</style>
