<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
        <div class="container mx-auto bg-white">
           <section>
                <div class="p-4">
                    <h1 class="text-xl lg:text-2xl text-gray-800 font-semibold pt-2 min-h-4">Создать сотрудника</h1>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Фамилия</label>
                                    <text-input v-model="item.last_name" type="text" placeholder="Фамилия" ref="last_name" validate="min:2|max:255"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Имя</label>
                                    <text-input v-model="item.first_name" type="text" placeholder="Имя" ref="first_name" validate="min:2|max:255"/>
                                </div>
                            </div>

                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Отчество</label>
                                    <text-input v-model="item.middle_name" type="text" placeholder="Отчество" ref="middle_name" validate="min:2|max:255"/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Занятость</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Филиал</label>
                                    <select v-model="item.branch_id" class="p-2 border outline-none w-full">
                                        <option value="2">Омлет Аксу</option>
                                        <option value="1">Омлет Павлодар</option>
                                        <option value="">Без филиала</option>
                                    </select>
                                </div>

                                <div class="w-full flex flex-col">
                                    <label class="px-2">Департамент</label>
                                    <select v-model="item.department_id" class="p-2 border outline-none w-full">
                                        <option value="6">Саппорт</option>
                                        <option value="5">Аккаунтинг</option>
                                        <option value="4">Маркетинг</option>
                                        <option value="3">Доставка</option>
                                        <option value="2">Кухня</option>
                                        <option value="1">Фронтдеск</option>
                                        <option value="">Без департамента</option>
                                    </select>
                                </div>
                            </div>

                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Роль</label>
                                    <select v-model="item.role_id" class="p-2 border outline-none w-full">
                                        <option v-for="role in roles" :value="role.id">{{ role.title }}</option>
                                        <option value="">Без роли</option>
                                    </select>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Статус</label>
                                    <select v-model="item.status" class="p-2 border outline-none w-full">
                                        <option value="3">В отпуске</option>
                                        <option value="2">На больничном</option>
                                        <option value="1">Работает</option>
                                        <option value="0">Забанен</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full lg:w-1/2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Авторизация</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Email</label>
                                    <text-input v-model="item.email" type="email" validate="email|min:4|max:255" autocomplete="off"/>
                                </div>
                            </div>

                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Пароль</label>
                                    <text-input v-model="item.password" type="password" autocomplete="off" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section class="flex flex-col p-4 items-end lg:items-start">
                    <div class="flex flex-row pb-6">
                        <div class="w-full flex items-end">
                            <div class="pr-2">
                                <default-button type="cancel" @confirm="resetForm()" pressconfirm>Отмена</default-button>
                            </div>
                            <div class="pr-2">
                                <default-button @click="storeItem()" type="confirm">Сохранить</default-button>
                            </div>
                        </div>
                    </div>
                </section>
                <div v-if="formErrors.length > 0" class="py-4 flex">
                    <div class="py-2 px-8 rounded-md border border-yellow-500">
                        <ul class="text-yellow-500">
                            <li v-for="error in formErrors">{{ error }}</li>
                        </ul>
                    </div>
                </div>

            </section>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                last_name: '',
                first_name: '',
                middle_name: '',
                branch_id: '',
                department_id: '',
                role_id: '',
                status: 1,
                email: '',
                password: ''
            },
            roles: [],
            formErrors: []
        }
    },
    methods: {
        validateForm () {
            this.formErrors = []

            // if(!this.$refs.name.isValid){
            //     this.formErrors.push("Наименование не подходящее");
            //     this.$refs.name.validationErrors.forEach(error => this.formErrors.push(error))
            //     this.$refs.name.$refs.input.focus();
            //     return false;
            // }

            return true
        },
        resetForm () {
            const item = {
                last_name: '',
                first_name: '',
                middle_name: '',
                branch_id: '',
                department_id: '',
                role_id: '',
                status: 1,
                email: '',
                password: ''
            }
            this.item = item
        },
        storeItem () {
            if (!this.validateForm()) return
            this.postServerData('staff_store', { data: this.item })
                .then((res) => {
                    if (res.data?.code === 200) {
                        this.$router.push({ name: 'MyStaffEdit', params: { id: res.data.item_id } })
                    }
                })
                .catch((error) => console.log(error))
        },
        getRoles () {
            this.getServerData('roles_all')
                .then((res) => {
                    this.roles = res.data
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        this.getRoles()
    }
}
</script>
<style lang="">

</style>
