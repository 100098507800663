<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-center p-4">
                    <div class="w-full flex flex-col justify-around ">
                        <h1 class="text-lg lg:text-2xl text-gray-800 font-semibold pt-2 pb-2">Список категорий</h1>
                        <!-- <span class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}} категорий</span> -->
                    </div>
                    <div class="w-full flex flex-row justify-end">
                        <div v-if="can('create_tags')">
                            <router-link :to="{name: 'TagsCreate' }">
                                <default-button type="confirm">Категория</default-button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="w-full rounded-lg border-2 border-yellow-100">
                    <div v-if="tags.length > 0"  :class="{'opacity-50':isLoadingData}">
                        <div class="w-full flex flex-row bg-yellow-100 text-stone-700 select-none">
                            <div class="w-1/12 p-2"></div>
                            <div class="w-8/12 p-2" >Наименование</div>
                            <!-- <div class="w-2/12 p-2" >Блюд</div> -->
                            <div class="hidden lg:flex w-2/12 p-2" >Вес</div>
                            <div class="hidden lg:flex w-2/12 p-2" >Активно</div>
                            <div class="hidden lg:flex w-2/12 p-2" >Видимо</div>
                        </div>
                        <div class="w-full">
                            <div v-for="item, index in tags" :key="item.id">
                                <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed">
                                    <div class="w-full flex flex-row hover:bg-gray-100 items-center">
                                        <div class="w-1/12 flex justify-center text-xl">
                                            <!-- <img :src="item.images[0]?.url" style="height: 40px; border-radius: 50%"/> -->
                                            {{ item.emoji }}
                                        </div>
                                        <div class="w-8/12 p-2 flex flex-col">
                                            <router-link :to="{name: 'TagsEdit', params: { id: item.id  }}">{{ item.name }}</router-link>
                                            <div class=" flex flex-row text-xs text-gray-300">
                                                | {{ item.name_short }}
                                                | {{ item.description }}
                                                | {{ item.slug }}
                                            </div>
                                        </div>
                                        <!-- <div class="w-2/12 px-2">{{ item.meals?.length }}</div> -->
                                        <div class="hidden lg:flex w-2/12 px-2">{{ item.weight }}</div>
                                        <div class="hidden lg:flex w-2/12 px-2">
                                            <toggle-button v-model="item.is_active" :values="['1:Да','0:Нет']" @change="updateItemInfo(item.id, 'is_active', item.is_active)"/>
                                        </div>
                                        <div class="hidden lg:flex w-2/12 px-2">
                                            <toggle-button v-model="item.is_seen" :values="['1:Да','0:Нет']" @change="updateItemInfo(item.id, 'is_seen', item.is_seen)"/>
                                        </div>
                                    </div>
                                </div>
                                <div v-for="sub_item in item.children" class="w-full flex flex-row text-sm hover:bg-gray-100 items-center border-t">
                                    <div class="w-1/12 flex justify-center pl-8 text-xl">
                                        <!-- <img :src="sub_item.images[0]?.url" style="height: 40px; border-radius: 50%"/> -->
                                        {{ sub_item.emoji }}
                                    </div>
                                    <div class="w-8/12 px-2 flex flex-col  pl-8">
                                        <router-link :to="{name: 'TagsEdit', params: { id: sub_item.id  }}">{{ sub_item.name }}</router-link>
                                        <div class=" flex flex-row text-xs text-gray-300">
                                            | {{ sub_item.name_short }}
                                            | {{ sub_item.description }}
                                            | {{ sub_item.slug }}
                                        </div>
                                    </div>
                                    <!-- <div class="w-2/12 px-2">{{ sub_item.meals?.length }}</div> -->
                                    <div class="hidden lg:flex w-2/12 px-2">{{ sub_item.weight }}</div>
                                    <div class="hidden lg:flex w-2/12 px-2">
                                        <toggle-button v-model="sub_item.is_active" :values="['1:Да','0:Нет']" @change="updateItemInfo(sub_item.id, 'is_active', sub_item.is_active)"/>
                                    </div>
                                    <div class="hidden lg:flex w-2/12 px-2">
                                        <toggle-button v-model="sub_item.is_seen" :values="['1:Да','0:Нет']" @change="updateItemInfo(sub_item.id, 'is_seen', sub_item.is_seen)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="w-full py-16 text-center">
                            Список пуст
                        </div>
                    </div>
                </div>
           </section>
           <section>
                <div class="flex flex-row justify-between items-center pt-6 pb-24">
                    <div class="w-1/2 flex flex-col justify-around pl-2">
                        <span class="text-base">Показаны {{ meta.from }} - {{ meta.to}} из {{ meta.total}}</span>
                    </div>
                    <div v-if="meta.links.length > 3" class="w-1/2 flex flex-row justify-end">
                        <div v-for="link, index in meta.links">
                            <div
                                v-if="index != 0 && index != meta.links.length-1"
                                class="py-2 px-4 bg-gray-100 rounded-md"
                                :class="[
                                        meta.current_page == parseInt(link.label) ? 'bg-yellow-300 font-semibold' : '',
                                        link.url !== null ? 'hover:bg-yellow-300 cursor-pointer' : ''
                                    ]"
                                @click="()=>{ if(link.url != null ){ changePage(parseInt(link.label)) }}"
                                >{{ link.label }}
                            </div>
                        </div>
                    </div>
                </div>
           </section>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            meta: {
                current_page: 0,
                per_page: 20,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0
            },
            sort_by: 'name',
            sort_dir: 'ASC',
            name_like: '',
            selected_tag: '',
            items: [],
            tags: [],
            // showEditItemDialog: false,
            isLoadingData: false
        }
    },
    methods: {
        getTags () {
            this.getServerData('tags_all')
                .then((res) => {
                    this.tags = res.data
                    this.meta.total = res.data.length
                    this.meta.from = 1
                    this.meta.to = res.data.length
                    // console.log(res.data)
                })
                .catch(() => {})
        },
        updateItemInfo (item_id, info_index, new_value) {
           this.postServerData('tag_update_info', { data: { item_id, info_index, new_value } })
                .then((res) => {
                    this.getTags()
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        this.getTags()
    },
    watch: {
        // $route(to, from) {
        //     console.log(this.$route)
        //     console.log(this.$route.query)
        //     console.log(this.$route.hash)
        // }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
