<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
        <div class="w-full lg:container lg:mx-auto bg-white">
            <section>
                <div class="pt-2 pb-1 border-b-4 border-yellow-300">
                    <div class="w-full text-gray-800 font-semibold flex items-center justify-start">
                        <!-- <div>
                            <router-link :to="{name: $route.meta.parent}">&larr;</router-link>
                        </div> -->
                        <div class="overflow-x-auto flex items-center whitespace-nowrap  custom-scroll">
                            <router-link
                                :to="{ name: 'DailyReportTotals', params: { day, sector: 'orders_summs' }}"
                                class="px-6 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 whitespace-nowrap"
                                active-class="bg-yellow-300"
                                >Итоги
                            </router-link>
                            <router-link
                                :to="{ name: 'DailyReportOrders', params: { day, sector: 'orders_list' }}"
                                class="px-6 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 mr-2 whitespace-nowrap"
                                active-class="bg-yellow-300"
                                >Заказы
                            </router-link>
                        </div>
                        <h1 class="text-lg lg:text-2xl text-gray-800 font-semibold pt-1 pb-2 px-4 flex items-center whitespace-nowrap">
                            <div @click="pickDate" class="flex text-base lg:text-xl items-center p-1 px-4 rounded-md bg-yellow-100 hover:bg-yellow-200 cursor-pointer">
                                {{ day == 'current' ? 'сегодня' : day}}
                                <img src="https://img.icons8.com/nolan/256/1A6DFF/C822FF/tear-off-calendar.png" class="h-6 w-6 ml-2"/>
                            </div>
                        </h1>
                    </div>
                </div>
                <div class="w-full border-t">
                    <div class="w-full">
                        <div class="flex w-full justify-start overflow-x-auto whitespace-nowrap custom-scroll py-2">
                            <div
                                @click="setSector('orders_summs')"
                                class="px-4 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 whitespace-nowrap"
                                :class="[ sector == 'orders_summs' ? 'bg-yellow-300' : '']"
                                >Итоги
                            </div>
                            <div
                                @click="setSector('order_sources')"
                                class="px-4 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 whitespace-nowrap"
                                :class="[ sector == 'order_sources' ? 'bg-yellow-300' : '']"
                                >Источники
                            </div>
                            <div
                                @click="setSector('order_reviews')"
                                class="px-4 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 whitespace-nowrap"
                                :class="[ sector == 'order_reviews' ? 'bg-yellow-300' : '']"
                                >Отзывы
                            </div>
                            <div
                                @click="setSector('total_summs')"
                                class="px-4 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 whitespace-nowrap"
                                :class="[ sector == 'total_summs' ? 'bg-yellow-300' : '']"
                                >Расходы
                            </div>
                            <div
                                @click="setSector('ingredients')"
                                class="px-4 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 whitespace-nowrap"
                                :class="[ sector == 'ingredients' ? 'bg-yellow-300' : '']"
                                >Ингредиенты
                            </div>
                            <div
                                @click="setSector('refill')"
                                class="px-4 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8 whitespace-nowrap"
                                :class="[ sector == 'refill' ? 'bg-yellow-300' : '']"
                                >Пополнения
                            </div>
                        </div>
                    </div>
                </div>
                <section v-if="!is_loading" class="py-2 flex flex-wrap">
                   <div v-for="stat in stats" class="p-2" :class="[stat.width ? stat.width : 'w-full']">

                       <NumberBlock v-if="stat.type == 'number'" :stat="stat"/>
                       <SimpleGraph v-if="stat.type == 'collection-linear-plot'" :stat="stat"/>
                       <MultiGraph v-if="stat.type == 'collection-linear-plot-multiple'" :stat="stat"/>

                       <div v-if="stat.type == 'collection-piechart'" class="w-full">
                           <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                               <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                               <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                               <div class="flex w-full">
                                   <div v-for="data, index in stat.data" :style="{'width': (data.point/stat.meta.max)*100 + '%'}" class="">
                                       <div class="w-full h-2 flex flex-col justify-center items-center" :class="`${bg_colors[index]}`"></div>
                                   </div>
                               </div>
                               <div class="py-2 w-full">
                                   <div v-for="data, index in stat.data" class="w-full flex flex-col">
                                       <div class="flex items-center text-sm">
                                           <div class="w-1/12 font-semibold pr-2">
                                               <div class="w-2 h-2 rounded-full" :class="`${bg_colors[index]}`"></div>
                                           </div>
                                           <div class="w-2/12 font-semibold pr-2">{{ data.point }}</div>
                                           <div class="w-2/12 pr-2">{{ stat.meta.max ? Math.round(data.point/stat.meta.max*100) : 0 }}%</div>
                                           <div class="w-7/12 ">
                                               <router-link v-if="data.link" :to="data.link">{{ data.label }}</router-link>
                                               <div v-else>{{ data.label }}</div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>

                       <div v-if="stat.type == 'collection-numbers'" class="w-full">
                           <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                               <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                               <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                               <div class="pb-2 flex w-full">
                                   <div v-for="data, index in stat.data" class="w-full">
                                       <div class="py-2 flex flex-col justify-center items-center">
                                           <div class="text-3xl font-semibold">{{ data.point }}</div>
                                           <div>
                                               <router-link v-if="data.link" :to="data.link">{{ data.label }}</router-link>
                                               <div v-else>{{ data.label }}</div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>

                       <div v-if="stat.type == 'collection-orders'" class="w-full">
                           <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                               <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                               <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                               <div v-if="stat.data && stat.data.length > 0" class="pb-2 flex w-full flex flex-col">
                                   <div v-for="order, index in stat.data" class="w-full even:bg-gray-50 border-t flex flex-wrap  text-xs md:text-base">
                                       <div class="w-full md:w-6/12 flex justify-between py-2">
                                           <div class="w-full flex flex-row">
                                               <div class="flex flex-col px-2">
                                                   <div :class="[order.times.created_to_closed.raw > 60 ? 'text-red-500 font-semibold' : '']">
                                                       <router-link :to="`/orders/${order.id}/edit`">{{ order.id }}</router-link>
                                                   </div>
                                                   <div>{{ order.current_status?.emoji || '' }}</div>
                                                   <div>{{ order.times.created_to_closed.formatted }}</div>
                                               </div>
                                               <div class="w-full flex flex-col">
                                                   <div v-for="status in order.statuses_history" class="flex items-center text-xs md:text-sm">
                                                       <div>{{ status.created_at }}</div>
                                                       <div class="pl-2 hidden md:flex">{{ status.status.name }}</div>
                                                       <div class="pl-2 flex md:hidden">{{ status.status.emoji }}</div>
                                                   </div>
                                               </div>
                                           </div>
                                           <div class="w-full flex flex-col">
                                               <div>{{ order.customer?.phone }} | {{ order.customer?.customer_id }}</div>
                                               <div>{{ order.address?.street_appartment }}</div>
                                               <div>{{ order.extra_info?.order_source }}</div>
                                               <!-- <div>{{ order.address.full_address }}</div> -->
                                           </div>
                                       </div>
                                       <div class="w-full md:w-3/12 flex justify-between py-2">
                                           <div class="w-full flex flex-col">
                                               <div v-for="item in order.items" class="flex items-center text-xs md:text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div class="w-7/12 flex items-center">
                                                       <div v-if="item.meal_id">
                                                           <div v-if="item.is_combo == 1">
                                                               <router-link :to="{ name: 'CombosEdit', params: { id: item.meal_id } }">{{ item.name }}</router-link>
                                                           </div>
                                                           <div v-if="item.is_combo == 0">
                                                               <router-link :to="{ name: 'ItemsEdit', params: { id: item.meal_id } }">{{ item.name }}</router-link>
                                                           </div>
                                                       </div>
                                                       <div v-else>{{ item.name }}</div>
                                                       <div class="">{{ item.is_combo ? '🍱' : ''  }}</div>
                                                   </div>
                                                   <div class="pl-2">{{ item.amount }} x </div>
                                                   <div>{{ item.price }}</div>
                                                   <div class="">{{ item.is_bonus ? '🎁' : ''  }}</div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="w-full md:w-3/12 flex justify-between py-2">
                                           <div class="w-full flex flex-col text-xs md:text-sm">
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Итого</div>
                                                   <div>{{ order.total_summ }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Перевод</div>
                                                   <div>{{ order.payment?.transfer }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Бонусных</div>
                                                   <div>{{ order.total_bonuses }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Доставка</div>
                                                   <div>{{ order.payment?.delivery_cost }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Скидка</div>
                                                   <div>{{ order.payment?.discount }}</div>
                                               </div>
                                               <div class="flex justify-between bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                                   <div>Надбавка</div>
                                                   <div>{{ order.payment?.upcount }}</div>
                                               </div><!-- <div>{{ order.address.full_address }}</div> -->
                                           </div>
                                       </div>
                                       <div v-if="order.rating" class="w-full flex justify-between p-4 border-t">
                                           <div class="w-3/12 flex flex-col">
                                               <div class="flex">
                                                   <div class="w-1/2">Оператор:</div>
                                                   <div class="flex">
                                                       <img v-for="index in order.rating?.operator_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                                                   </div>
                                               </div>
                                               <div class="flex">
                                                   <div class="w-1/2">Кухня:</div>
                                                   <div class="flex">
                                                       <img v-for="index in order.rating?.kitchen_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                                                   </div>
                                               </div>
                                               <div class="flex">
                                                   <div class="w-1/2">Доставка:</div>
                                                   <div class="flex">
                                                       <img v-for="index in order.rating?.courier_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                                                   </div>
                                               </div>
                                           </div>
                                           <div class="w-9/12 flex">
                                               <div v-if="order.rating?.body" class="bg-gray-100 rounded-md p-4"><i>{{ order.rating?.body }}</i></div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div v-else class="p-8">
                                   <i>Список пуст</i>
                               </div>
                           </div>
                       </div>

                       <div v-if="stat.type == 'collection-reviews'" class="w-full">
                           <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                               <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                               <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                               <div class="pb-2 flex w-full flex flex-col">
                                   <div v-for="data, index in stat.data" class="w-full">
                                       <div class="py-4 flex flex-col justify-start items-start border-t">
                                           <div class="text-lg font-semibold">Заказ #{{ data.order_id }}</div>
                                           <div class="flex">
                                               <div class="text-base font-semibold pr-2">Доставка: {{ data.courier_rating }}</div>
                                               <div class="text-base font-semibold pr-2">Кухня: {{ data.kitchen_rating }}</div>
                                               <div class="text-base font-semibold">Оператор: {{ data.operator_rating }}</div>
                                           </div>
                                           <div class="py-2 italic ">{{ data.body || 'без отзыва' }}</div>
                                           <div class="text-bg-500">{{ data.created_at }}</div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>

                       <div v-if="stat.type == 'collection-top-customers'" class="w-full">
                            <collection-label-data :stat="stat"></collection-label-data>
                           <!-- <div class="w-full py-4 px-4 rounded-md flex flex-col"
                               :class="[stat.data ? stat.color ? `${stat.color}-100` : 'bg-indigo-100' : 'bg-gray-100' ]">
                               <div class="pt-2 text-lg font-semibold" @click="stat.data.meta.hidden = true">{{ stat.title }}</div>
                               <div class="pt-2 pb-4 text-sm text-gray-700" @click="stat.data.meta.hidden = false">{{ stat.description }}</div>
                               <div v-if="stat.data" class="pb-2 flex w-full flex flex-col relative">
                                   <div v-for="data, index in stat.data" class="w-full">
                                       <div class="py-2 flex justify-start items-center border-t border-gray-500">
                                            <div class="w-1/12 font-semibold text-sm">#{{ index }}</div>
                                            <div class="w-5/12 text-md pl-2">
                                               <router-link v-if="data.link" :to="data.link">{{ data.label }}</router-link>
                                               <div v-else>{{ data.label }}</div>
                                            </div>
                                            <div class="w-6/12 text-right text-md relative">
                                                <div class="relative z-10 pr-1" v-show="stat.data.meta?.hidden || false">{{ data.amount }}</div>
                                                <div v-if="stat.meta.max" class="absolute right-0 top-0 h-full rounded-md"
                                                    :class="`${stat.color}-200`"
                                                    :style="{'width':data.amount / stat.meta.max * 100 + '%'}"></div>
                                            </div>
                                       </div>
                                   </div>
                               </div>
                               <div v-else class="flex flex-col items-center justify-center bg-gray-300 rounded-md">
                                   <statistics-placeholder>Информации пока нет</statistics-placeholder>
                               </div>
                           </div> -->
                       </div>

                       <div v-if="stat.type == 'collection-stock-ingredients'" class="w-full">
                           <div class="w-full py-4 px-4 rounded-md flex flex-col"
                               :class="[stat.data.length > 0 ? stat.bg ? stat.bg : 'bg-indigo-100' : 'bg-gray-100' ]">
                               <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                               <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                               <div v-if="stat.data?.length > 0" class="flex w-full flex flex-col">
                                   <div class="py-2 flex justify-start items-center border-t border-gray-500">
                                       <div class="w-1/12 font-semibold"></div>
                                       <div class="w-4/12 text-sm">{{ stat.meta.header.title }}</div>
                                       <div class="w-7/12 text-right flex items-center text-sm">
                                           <div v-for="inner in stat.meta.header.labels" class="w-full">{{ inner }}</div>
                                       </div>
                                   </div>
                               </div>
                               <div v-if="stat.data?.length > 0" class="pb-2 flex w-full flex flex-col">
                                   <div v-for="data, index in stat.data" class="w-full">
                                       <div class="py-2 flex justify-start items-center border-t border-gray-500">
                                           <div class="w-1/12 font-semibold text-xs">#{{ index + 1 }}</div>
                                           <div class="w-4/12 text-sm">
                                               <router-link v-if="data.link" :to="data.link">{{ data.label }}</router-link>
                                               <div v-else>{{ data.label }}</div>
                                           </div>
                                           <div class="w-7/12 text-right flex items-center text-sm">
                                               <div v-for="inner in data.collection" class="w-full">{{ inner }}</div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div v-else class="flex flex-col items-center justify-center bg-gray-300 rounded-md">
                                   <statistics-placeholder>Информации пока нет</statistics-placeholder>
                               </div>
                           </div>
                       </div>

                       <div v-if="stat.type == 'debug'" class="w-full">
                           <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                               <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                               <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                               <div class="pb-2 flex w-full flex flex-col">
                                   {{ stat.data }}
                               </div>
                           </div>
                       </div>

                   </div>
               </section>
               <section v-else class="p-8">
                   <i>Загрузка...</i>
                   <div class="bg-indigo-100 bg-indigo-200 bg-rose-100 bg-rose-200 bg-yellow-100 bg-yellow-200"></div>
               </section>
           </section>
       </div>
   </div>
   <datepicker-alert ref="datePickerAlert"></datepicker-alert>
</template>
<script>
import SimpleGraph from '../../../../components/UI/Dash/SingleLayerGraphBlock'
import MultiGraph from '../../../../components/UI/Dash/MultiLayerGraph'
import NumberBlock from '../../../../components/UI/Dash/NumberBlock'

export default {
   data: () => {
       return {
           meta: null,
           is_loading: false,
           user: null,
           day: 'сегодня',
           sector: 'orders_summs',
           show_criteria: 'active',
           show_value: 'all',
           sort_criteria: 'created_at',
           sort_direction: 'DESC',
           stats: [],
           bg_colors: ['bg-green-300', 'bg-yellow-300', 'bg-red-300', 'bg-indigo-300']
       }
   },
    methods: {
        async pickDate () {
           const confirm = await this.$refs.datePickerAlert.show({
               title: 'Выбрать дату',
               description: 'Выбериате дату',
               okButton: 'Удалить'
           })
           if (confirm) {
               this.day = confirm.date
               this.$router.push({ name: 'DailyReportTotals', params: { day: this.day, sector: 'orders_summs' } })
           }
        },
        setSector (s) {
            this.sector = s
            this.getStats()
        },
       changeDate () {
           this.$router.push({ name: 'DailyReportTotals', params: { day: this.day, sector: 'orders_summs' } })
       },
       getStats () {
            this.stats = []
           this.is_loading = true
           this.getServerData('report_day', { day: this.day, sector: this.sector, show_criteria: this.show_criteria, show_value: this.show_value, sort_criteria: this.sort_criteria, sort_direction: this.sort_direction }).then((res) => {
               this.stats = res.data.data
               this.meta = res.data.meta
           }).finally(this.is_loading = false)
       }
   },
   watch: {
       $route (to, from) {
           if (to.name === 'DailyReportTotals') {
               if (to.params.day) {
                   this.day = to.params.day
               }
               if (to.params.sector) {
                   this.sector = to.params.sector
               }
               if (to.params.show_criteria) {
                   this.show_criteria = to.params.show_criteria
               }
               if (to.params.show_value) {
                   this.show_value = to.params.show_value
               }
               if (to.params.sort_criteria) {
                   this.sort_criteria = to.params.sort_criteria
               }
               if (to.params.sort_direction) {
                   this.sort_direction = to.params.sort_direction
               }

               this.getStats()
           }
       }
   },
   mounted () {
       this.user = this.$store.getters.currentUser

       if (this.$route.params.day) {
           this.day = this.$route.params.day
       }
       if (this.$route.params.sector) {
           this.sector = this.$route.params.sector
       }
       if (this.$route.params.show_criteria) {
           this.show_criteria = this.$route.params.show_criteria
       }
       if (this.$route.params.show_value) {
           this.show_value = this.$route.params.show_value
       }
       if (this.$route.params.sort_criteria) {
           this.sort_criteria = this.$route.params.sort_criteria
       }
       if (this.$route.params.sort_direction) {
           this.sort_direction = this.$route.params.sort_direction
       }

       this.getStats()
   },
   components: {
       NumberBlock,
       SimpleGraph,
       MultiGraph
   }
}
</script>
<style scoped>
   /* .text-4xl {} */
   .custom-scroll::-webkit-scrollbar {
       width: 4px;
       height: 4px;
   }
   .custom-scroll::-webkit-scrollbar-track {
       /* box-shadow: inset 0 0 5px grey; */
       border-radius: 2px;
       width: 4px;
       background: #eee;
   }
   .custom-scroll::-webkit-scrollbar-thumb {
       background: rgb(164, 164, 164);
       border-radius: 2px;
       width: 4px;
       height: 4px;
   }
   @media (min-width: 1024px) {
    .lg\:w-1\/6 {
        width: 16.666667%;
    }
   }
</style>
