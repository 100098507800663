<template>
    <div class="w-full">
        <div class="py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
            <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
            <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
            <div class="pb-2 flex">
                <div v-for="data in stat.data" class="w-full">
                    <MultiLayerBlock :points="data.points" :max="stat.meta.max" />
                    <!-- <div class="h-64 flex flex-col items-center justify-end">
                        <div :style="{'height': ()*100 + '%'}" class="bg-yellow-300 w-11/12 hover:bg-yellow-100 rounded-md transition-all"></div>
                    </div> -->
                    <div class="py-2 flex flex-col justify-center items-center">
                        <div class="text-lg font-semibold">{{ data.point }}</div>
                        <div v-if="data.link">
                            <router-link :to="data.link">{{ data.label }}</router-link>
                        </div>
                        <div v-else>{{ data.label }}</div>
                        <div>{{ data.description }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MultiLayerBlock from './MultiLayerGraphBlock'

export default {
    name: 'multi-linear-graph',
    data(){
        return {
        }
    },
    props: {
        stat: {
            type: Object,
            // default:,
        },

    },
    methods: {
        incNumber(){
        }
    },
    mounted(){
        // this.incTimeout = setInterval(this.incNumber, 10)
    },
    components: {
        MultiLayerBlock
    }
}
</script>
<style scoped>

</style>