<template>
    <div class="py-8 flex justify-center w-full rounded-md bg-gray-100" style="b1ackground-image: url(https://img.freepik.com/premium-vector/abstract-financial-graph-background-vector-illustration_230920-1540.jpg); background-size: cover; background-position: center">
        <div class="text-gray-300 py-2 px-8 rounded-md bg-gray-100"><slot></slot></div>
    </div>
</template>
<script>
export default {
    name: 'statistics-placeholder',
}
</script>
<style scoped>
</style>