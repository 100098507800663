<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-end pt-6  pb-4">
                    <div class="w-2/3 flex flex-col justify-around pl-2 ">
                        <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-2">Список адресов</h1>
                        <span class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}}</span>
                    </div>
                    <div class="w-1/3 flex flex-row justify-end">

                    </div>
                </div>
                <div class="w-full rounded-lg border-2 border-gray-100">
                    <div class="items_list">
                        <div class="w-full flex flex-row bg-gray-100">
                            <div class="w-1/12 p-2"></div>
                            <div class="w-8/12 p-2" >Наименование</div>
                            <div class="w-1/12 p-2" >Доставка</div>
                            <div class="w-1/12 p-2" >Лейбл</div>
                            <div class="w-1/12 p-2" >Клиентов</div>
                            <div class="w-1/12 p-2" ></div>
                        </div>
                        <div v-for="item in collection" :key="item.id">
                            <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed">
                                <div class="w-full flex flex-row hover:bg-gray-100 items-center">
                                    <div class="w-1/12 flex justify-center">
                                        {{ item.id }}
                                    </div>
                                    <div class="w-8/12 p-2 text-lg flex flex-col">
                                        <router-link :to="{name: 'AddressesEdit', params: { id: item.id  }}">{{ item.full_address }}</router-link>
                                        <div class=" flex flex-row text-sm text-gray-300">
                                            | {{ item.coords }}
                                            |
                                        </div>
                                    </div>
                                    <div class="w-1/12 px-2">{{ item.delivery_cost }}</div>
                                    <div class="w-1/12 px-2">{{ item.label }}</div>
                                    <div class="w-1/12 px-2">{{ item.customers_count }}</div>
                                    <div class="w-1/12 px-2">
                                        <section v-if="item.customers_count == 0 && can('delete_streets')" class="flex flex-col py-2">
                                            <div class="w-1/2 flex flex justify-end">
                                                <button @click="deleteItem(item)" class="w-8 h-8 flex justify-center items-center outline-none rounded-full bg-red-100 hover:bg-red-300">x</button>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>
           <section>
                <div class="flex flex-row justify-between items-center pt-6 pb-24">
                    <div class="w-1/2 flex flex-col justify-around pl-2">
                        <span class="text-base">Показаны {{ meta.from }} - {{ meta.to}} из {{ meta.total}}</span>
                    </div>
                    <div v-if="meta.links.length > 3" class="w-1/2 flex flex-row justify-end">
                        <div v-for="link, index in meta.links" :key="index">
                            <div
                                v-if="index != 0 && index != meta.links.length-1"
                                class="py-2 px-4 bg-gray-100 rounded-md"
                                :class="[
                                        meta.current_page == parseInt(link.label) ? 'bg-yellow-300 font-semibold' : '',
                                        link.url !== null ? 'hover:bg-yellow-300 cursor-pointer' : ''
                                    ]"
                                @click="()=>{ if(link.url != null ){ changePage(parseInt(link.label)) }}"
                                >{{ link.label }}
                            </div>
                        </div>
                    </div>
                </div>
           </section>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            meta: {
                current_page: 0,
                per_page: 25,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0
            },
            sort_by: 'name',
            sort_dir: 'ASC',
            name_like: '',
            selected_tag: '',
            items: [],

            collection: []
            // showEditItemDialog: false,
        }
    },
    methods: {
        getAddresses () {
            this.getServerData('addresses_list', { per_page: this.meta.per_page, sort_by: this.sort_by, sort_dir: this.sort_dir, current_page: this.meta.current_page })
                .then((res) => {
                    this.meta.current_page = res.data.current_page
                    this.meta.per_page = res.data.per_page
                    this.meta.last_page = res.data.last_page
                    this.meta.links = res.data.links
                    this.meta.total = res.data.total
                    this.meta.from = res.data.from
                    this.meta.to = res.data.to
                    this.collection = res.data.data
                })
                .catch(() => {})
        },
        changePage (page) {
            this.$router.push({ name: 'Addresses', params: { page } })
        },
        deleteItem (item) {
            this.postServerData('address_delete', { data: { id: item.id } })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.getAddresses()
                    } else {
                       console.log(res.data.message)
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        if (this.$route.params.page) {
            this.meta.current_page = this.$route.params.page
        }

        this.getAddresses()
    },
    watch: {
        $route (to, from) {
            if (to.name === 'Addresses') {
                this.meta.current_page = to.params.page
                this.getAddresses()
            }
        }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
