<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 min-h-4">{{ item.title || '...' }}</h1>
                    <router-link :to="{ name: 'News' }">&larr; назад</router-link>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md">
                        <div class="flex flex-row p-4">
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Заголовок</label>
                                <input v-model="item.title" @change="updateItemInfo('title', item.title)" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4">
                            <div class="w-1/2 flex flex-col">
                                <label class="px-2">Описание</label>
                                <textarea v-model="item.body" @change="updateItemInfo('body', item.body)" class="p-2 border outline-none h-36"></textarea>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Фотки</h3>
                    <div class="border rounded-md flex flex-row">
                        <div class="w-5/6 flex flex-row items-center">
                            <div v-if="item.images?.length > 0" class="flex flex-row p-4 w-full overflow-x-auto">
                                <!-- <div class="py-2 px-4 text-center">
                                    <input />
                                </div> -->
                                <div v-for="image, index in item.images" class="py-2 px-4 text-center relative hover:bg-gray-100 rounded-lg">
                                    <img :src="image.url" style="width: 256px;"/>
                                    <select v-model="item.images[index].type" @change="updateImage(image, 'type', item.images[index].type)">
                                        <option value="">неизвестно</option>
                                        <option value="big">оригинал</option>
                                        <option value="medium">средний</option>
                                        <option value="small">предпросмотр</option>
                                    </select>
                                    <div @click="detachImage(image)" class="w-6 absolute top-2 right-2  rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-red-500">x</div>
                                </div>
                            </div>
                            <div v-else class=" p-4">
                                <i>Нет привязанных фотографий</i>
                            </div>
                        </div>
                        <div class="w-1/6 px-4 flex flex-col border-l justify-center">
                            <div @click="openImagesDialog()" class="p-2 rounded-lg text-white bg-gray-300 text-center cursor-pointer hover:bg-green-500">Посмотреть все фотографии</div>
                        </div>
                    </div>
                </section>

                <section v-if="can('delete_combos')" class="flex flex-col pb-6">
                    <div class="w-1/2 flex flex-col items-end">
                        <button @click="deleteItem()" class="py-3 px-6 outline-none rounded-md bg-red-100 hover:bg-red-300">Удалить</button>
                    </div>
                </section>
            </section>
        </div>
    </div>
    <my-dialog v-model:show="isOpenImagesDialog">
        <template #header>Server photos</template>
        <template #default>
            <div class="border rounded-md">
                <div v-if="server_images.length > 0" class=" flex flex-row flex-wrap">
                    <div v-for="image in server_images" class="w-1/12 flex flex-col p-2 hover:bg-yellow-300 cursor-pointer rounded-md" @click="attachImage(`${images_server}/${image.dir}/${image.file}`)">
                        <img :src="`${images_server}/${image.dir}/${image.file}`" style="width: 100%"/>
                        <!-- <div class="text-center">{{ image.meta[0] || '' }}x{{ image.meta[1] || '' }}</div> -->
                    </div>
                </div>
                <div v-else class="p-6">
                    <i>На сервере еще нет фотографий в этой категории. Загрузите через форму загрузки.</i>
                </div>
            </div>
            <div class="w-full px-4 flex border-t justify-center items-center">
                <label class="p-2">Загрузить фотографию</label>
                <input @change="uploadImage()" class="p-2 border outline-none rounded-lg" type="file" ref="file" />
                <div @click="submitUploadedFile()" class="p-2 flex rounded-lg text-white bg-gray-300 text-center cursor-pointer hover:bg-green-500">загрузить</div>
            </div>
        </template>
    </my-dialog>
</template>
<script>
import axios from 'axios'

export default {
    data: () => {
        return {
            item: {
                id: 0
            },
            images_server: 'https://static.breaktime.kz/',
            server_images: [],
            isOpenImagesDialog: false
        }
    },
    methods: {
        getItemById () {
           this.getServerData('news_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data

                    this.getImages()
                })
                .catch((error) => console.log(error))
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('news_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {

                })
                .catch((error) => console.log(error))
        },
        openImagesDialog () {
            this.isOpenImagesDialog = true
        },
        attachImage (image_url) {
            if (image_url !== '') {
                this.postServerData('news_attach_image', { data: { item_id: this.item.id, image_url, image_type: '' } })
                    .then((res) => {
                        this.getItemById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        updateImage (image, info_index, new_value) {
            if (image) {
                this.postServerData('image_update', { data: { id: image.id, info_index, new_value } })
                    .then((res) => {
                        this.getItemById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        detachImage (image) {
            if (image) {
                this.postServerData('news_detach_image', { data: { item_id: this.item.id, image_id: image.id } })
                    .then((res) => {
                        this.getItemById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        uploadImage () {
            this.images = this.$refs.file.files[0]
        },
        submitUploadedFile () {
            const formData = new FormData()
            formData.append('upload_file', 'true')
            formData.append('dir', 'omelette.news')
            formData.append('userfile', this.images)
            const headers = { 'Content-Type': 'multipart/form-data' }
            axios
                .post(this.images_server + 'uploader', formData, { headers })
                .then((res) => {
                    // res.status // HTTP status
                    // this.new_image.url = this.images_server + res.data.file_path
                    // this.new_image.type = 'avatar'

                    this.attachImage(this.images_server + res.data.file_path)
                })
        },
        getImages () {
            this.getExternalServerData('get_images', ['omelette.news'])
                .then((res) => {
                    this.server_images = res.data
                })
                .catch((errors) => console.log(errors))
        },
        deleteItem () {
            this.postServerData('news_delete', { data: { item_id: this.item.id } })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.$router.push({ name: 'News' })
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getItemById()
    }
}
</script>
<style lang="">

</style>
