<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section class="w-full">
                <div class="flex flex-row justify-between items-end pt-6 p-4 w-full">
                    <h1 class="text-base text-gray-800 font-semibold pt-2 pb-2">Поиск по номеру</h1>
                    <text-input v-model="phone_number" @change="findCustomer" placeholder="Поиск по номеру"/>
                </div>
                <div class="flex flex-row justify-between items-end pt-6  pb-4">
                    <div class="w-2/3 flex flex-col justify-around pl-2 ">
                        <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-2">Список телефонов</h1>
                    </div>
                    <div class="w-1/3 flex flex-row justify-end">
                        <!-- <div v-if="can('create_roles') || can('debug')">
                            <router-link :to="{name: 'RolesCreate' }" class="py-2 px-6 rounded-md bg-gray-100 cursor-pointer hover:bg-yellow-300"> Создать </router-link>
                        </div>                         -->
                    </div>
                </div>
                <div v-if="is_loading">Загрузка...</div>
                <div class="w-full rounded-lg border-2 border-gray-100">
                    <div class="items_list" :class="[ is_loading ? 'opacity-70' : 'opacity-100']">
                        <div class="w-full flex flex-row bg-gray-100">
                            <div class="w-1/12 p-2"></div>
                            <div class="w-6/12 p-2" >Номер</div>
                            <div class="w-5/12 p-2" >Клиент</div>
                        </div>
                        <div v-for="item, index in collection" :key="index">
                            <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed">
                                <div class="w-full flex flex-row hover:bg-gray-100 items-center">
                                    <div class="w-1/12 flex justify-center">
                                        <!-- <img :src="item.images[0]?.url" style="height: 40px; border-radius: 50%"/> -->
                                    </div>
                                    <div class="w-6/12 p-2 text-lg flex flex-col">
                                        <div v-if="can('edit_phones')" class="flex">
                                            <router-link :to="{name: 'CustomersEdit', params: { id: item.customer_id  }}">{{ item.customer_id || 'no customer' }}</router-link>
                                            <div class="px-2">|</div>
                                            <router-link :to="{name: 'PhonesEdit', params: { id: item.id  }}">{{ item.number || 'no number' }}</router-link>
                                            <!-- <div>{{ item.number }}</div> -->
                                        </div>
                                        <div v-else>{{ item.number }}</div>
                                        <div class=" flex flex-row text-sm text-gray-300">
                                            <!-- | {{ item.slug }}
                                            | {{ item.description }}   -->
                                        </div>
                                    </div>
                                    <div class="w-5/12 p-2 flex flex-row flex-wrap">
                                        {{ item.orders_count}} |
                                        {{ item.customer?.id}} | {{ item.customer?.first_name}}
                                    </div>
                                    <div class="w-1/12 p-2 flex flex-row flex-wrap">
                                        <div v-if="!item.customer && item.number.length != 11">
                                            <div @click="deletePhone(item)" class="w-8 h-8 flex justify-center items-center bg-gray-100 rounded-full cursor-pointer hover:bg-red-100 text-gray-700 font-semibold">x</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>
       </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            collection: [],
            phone_number: '',
            is_loading: false
            // showEditItemDialog: false,
        }
    },
    methods: {
        findCustomer () {
            this.$router.push({ name: 'CustomersSearchResults', params: { q: this.phone_number } })
        },
        getCollection () {
            this.is_loading = true
            this.getServerData('customer_by_phone', { phone_number: this.phone_number })
                .then((res) => {
                    this.meta = res.data.meta
                    this.collection = res.data
                })
                .catch(() => {})
                .finally(this.is_loading = false)
        },
        deletePhone (item) {
            this.postServerData('phone_delete', { data: { phone_id: item.id } })
            this.getCollection()
        }
    },
    mounted () {
        if (this.$route.params) {
            this.phone_number = this.$route.params.q
            this.getCollection()
        }
    },
    watch: {
        $route (to, from) {
            if (to.name === this.$route.name) {
                this.getCollection()
            }
        }
    },
    components: {
    }
}
</script>
<style lang="">

</style>
