<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
            <div class="pt-6">
                <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">Сводка за месяц</h1>

                <div class="pt-2 flex items-center flex-wrap">
                    <div class="flex flex-col">
                        <label>Месяц</label>
                        <select v-model="selected_month" class="py-2 px-4 border">
                            <option value="01">Январь</option>
                            <option value="02">Февраль</option>
                            <option value="03">Март</option>
                            <option value="04">Апрель</option>
                            <option value="05">Май</option>
                            <option value="06">Июнь</option>
                            <option value="07">Июль</option>
                            <option value="08">Август</option>
                            <option value="09">Сентябрь</option>
                            <option value="10">Октябрь</option>
                            <option value="11">Ноябрь</option>
                            <option value="12">Декабрь</option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label>Год</label>
                        <select v-model="selected_year" class="py-2 px-4 border">
                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                        </select>
                    </div>
                    <div class="flex flex-col pt-6">
                        <label> </label>
                        <select v-model="sector" class="py-2 px-4 border">
                            <option value="orders_totals">Заказы</option>
                            <option value="summs_total">Суммы</option>
                            <option value="spendings_total">Расходы</option>
                            <option value="zakup_total">Закуп</option>
                            <option value="bonuses_skidki_total">Бонусы & скидки</option>
                            <option value="items">По блюдам</option>
                            <option value="customers">По клиентам</option>
                            <option value="couriers">По курьерам</option>
                        </select>
                    </div>
                    <div class="flex flex-col md:pt-7">
                        <label> </label>
                        <h1 class="text-lg text-gray-800 font-semibold pt-1 pb-2 md:px-4 flex items-center whitespace-nowrap">
                            <div v-if="!is_loading" @click="getStats" class="flex items-center text-base items-center py-2 px-6 rounded-md bg-yellow-100 hover:bg-yellow-200 cursor-pointer">
                                Сформировать
                            </div>
                            <div v-else class="flex items-center text-base lg:text-xl items-center py-2 px-6 rounded-md bg-gray-100">
                                <img src="https://static.breaktime.kz/uploads/images/breaktime/loading_black_circle.gif" class="rounded-full mr-2" />
                            </div>
                        </h1>
                    </div>
                </div>
            </div>
            <section v-if="stats.length == 0">
                <div class="flex flex-wrap py-4 justify-between">
                    <div class="w-1/5 h-48 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>
                    <div class="w-1/5 h-48 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>
                    <div class="w-1/5 h-48 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>
                    <div class="w-1/5 h-48 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>
                    <div class="w-1/5 h-48 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>

                    <div class="w-1/3 h-64 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>
                    <div class="w-2/3 h-64 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>

                    <div class="w-1/3 h-64 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>
                    <div class="w-1/3 h-64 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>
                    <div class="w-1/3 h-64 p-2"><div class=" bg-gray-100 rounded-md flex items-center justify-center w-full h-full"></div></div>
                </div>
            </section>
            <section v-if="!is_loading">

                <div class="py-6 flex flex-wrap">
                    <div v-for="stat, index in stats" class="p-2" :class="[stat.width ? stat.width : 'w-full']" :key="index">

                        <div v-if="stat.type == 'number'" class="w-full">
                            <div class="py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                                <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                                <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                                <div class="pb-2 text-5xl font-semibold">{{ stat.data }}</div>
                            </div>
                        </div>
                        <SimpleGraph v-if="stat.type == 'collection-linear-plot'" :stat="stat"/>

                        <div v-if="stat.type == 'collection-piechart'" class="w-full">
                            <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                                <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                                <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                                <div class="flex w-full">
                                    <div v-for="data, index in stat.data" :style="{'width': (data.point/stat.meta.max)*100 + '%'}" class="" :key="index">
                                        <div class="w-full h-2 flex flex-col justify-center items-center" :class="`${bg_colors[index]}`"></div>
                                    </div>
                                </div>
                                <div class="py-2 w-full">
                                    <div v-for="data, index in stat.data" class="w-full flex flex-col" :key="index">
                                        <div class="flex items-center text-sm">
                                            <div class="w-1/12 font-semibold pr-2">
                                                <div class="w-2 h-2 rounded-full" :class="`${bg_colors[index]}`"></div>
                                            </div>
                                            <div class="w-2/12 font-semibold pr-2">{{ data.point }}</div>
                                            <div class="w-2/12 pr-2">{{ Math.round(data.point/stat.meta.max*100) }}%</div>
                                            <div class="w-7/12 ">{{ data.label }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="stat.type == 'collection-numbers'" class="w-full">
                            <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                                <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                                <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                                <div class="pb-2 flex w-full">
                                    <div v-for="data, index in stat.data" class="w-full" :key="index">
                                        <div class="py-2 flex flex-col justify-center items-center">
                                            <div class="text-3xl font-semibold">{{ data.point }}</div>
                                            <div>{{ data.label }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="stat.type == 'collection-reviews'" class="w-full">
                            <div class="w-full py-4 px-4 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                                <div class="pt-2 text-lg font-semibold">{{ stat.title }}</div>
                                <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                                <div class="pb-2 flex w-full flex flex-col">
                                    <div v-for="data, index in stat.data" class="w-full" :key="index">
                                        <div class="py-4 flex flex-col justify-start items-start border-t">
                                            <div class="text-lg font-semibold">Заказ #{{ data.order_id }}</div>
                                            <div class="flex">
                                                <div class="text-base font-semibold pr-2">Доставка: {{ data.courier_rating }}</div>
                                                <div class="text-base font-semibold pr-2">Кухня: {{ data.kitchen_rating }}</div>
                                                <div class="text-base font-semibold">Оператор: {{ data.operator_rating }}</div>
                                            </div>
                                            <div class="py-2 italic ">{{ data.body || 'без отзыва' }}</div>
                                            <div class="text-bg-500">{{ data.created_at }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="stat.type == 'collection-top-max'" class="w-full">
                            <collection-label-data :stat="stat"></collection-label-data>
                        </div>

                        <div v-if="stat.type == 'collection-top-scrollable'" class="w-full">
                            <collection-label-data-scrollable :stat="stat"></collection-label-data-scrollable>
                        </div>

                        <div v-if="stat.type == 'collection-top-customers'" class="w-full">
                            <div class="w-full py-4 px-2 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
                                <div class="pt-2 px-2 text-lg font-semibold">{{ stat.title }}</div>
                                <div class="pt-2 px-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
                                <div class="pb-2 px-2 flex w-full flex flex-col h-112 overflow-y-auto">
                                    <div v-for="data, index in stat.data" class="w-full" :key="index">
                                        <div class="py-2 flex justify-start items-center border-t border-gray-500">
                                            <div class="w-1/12 font-semibold">#{{ index + 1 }}</div>
                                            <div class="w-7/12 ">{{ data.label }}</div>
                                            <div class="w-4/12 text-right">{{ data.amount }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="stat.type == 'debug'" class="w-full">
                            {{ stat }}
                        </div>

                    </div>
                </div>

            </section>
            <section v-else>
                <div>Загрузка...</div>
            </section>
        </div>
    </div>
   <datepicker-alert ref="datePickerAlert"></datepicker-alert>
</template>
<script>
import SimpleGraph from '@/components/UI/Dash/SingleLayerGraphBlock'

export default {
    data: () => {
        return {
            is_loading: false,
            stats: [],
            bg_colors: ['bg-green-300', 'bg-yellow-300', 'bg-red-300', 'bg-indigo-300'],
            date_from: 'current',
            date_to: 'today',
            sector: 'orders_totals',
            selected_month: '01',
            selected_year: '2024',
            years: [2021, 2022, 2023, 2024]
        }
    },
    methods: {
       async pickDate (param) {
           const confirm = await this.$refs.datePickerAlert.show({
               title: 'Выбрать дату',
               description: 'Выбериате дату',
               okButton: 'Удалить'
           })
           if (confirm) {
               this[param] = confirm.date
            //    this.getStats()
           }
       },
        getStats () {
            this.is_loading = true
            this.getServerData('report_month', { year: this.selected_year, month: this.selected_month, sector: this.sector }).then((res) => { this.stats = res.data; this.is_loading = false }).finally()
        }
    },
    mounted () {
        // this.user = this.$store.getters.currentUser
        // this.getStats()
    },
    components: {
        SimpleGraph
    }
}
</script>
<style scoped>
    .h-104 {
        height: 26rem;
    }
    .h-112 {
        height: 28rem
    }
    .h-120 {
        height: 30rem
    }
    .max-h-104 {
        max-height: 26rem;
    }
    .max-h-112 {
        max-height: 28rem
    }
    .max-h-120 {
        max-height: 30rem
    }
</style>
