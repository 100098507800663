<template>
    <div class="h-64 flex items-end justify-center rounded-md relative">
        <div v-for="point, index in points" :style="{'height': (point/max) * 100 + '%'}" 
            class="absolute bottom-0 left-0 rounded-md transition-all opacity-50 hover:opacity-100"
            :class="[colors[index], `z-${ index + 1 }`, `w-${11 - index}/12`]"
        ></div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            current_percent: 0,
            incTimeout: null,
            colors: [
                'bg-indigo-300 hover:bg-indigo-300 ',
                'bg-indigo-500 hover:bg-indigo-500 ',
            ],
        }
    },
    props: {
        points: {
            type: Array
        },
        max: {
            type: Number,
            default: 1,
        },

    },
    methods: {
        incNumber(){
            if(this.current_percent < this.percent){
                this.current_percent++
            }
            if(this.current_percent >= this.percent){
                this.current_percent = this.percent
                clearInterval(this.incTimeout)
            }
        }
    },
    mounted(){
        this.incTimeout = setInterval(this.incNumber, 300)
    }
}
</script>
<style scoped>

</style>