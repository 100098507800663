<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
      <div class="container mx-auto bg-white">
          <section>
               <div class="pt-6">
                   <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 min-h-4">{{ item.number || '...' }}</h1>
                   <router-link :to="{ name: 'PhonesList' }">&larr; назад</router-link>
                   <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
               </div>
               <section class="flex flex-col py-4">
                   <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                   <div class=" border rounded-md">
                       <div class="flex flex-row p-4">
                           <div class="w-3/12 flex flex-col">
                               <label class="px-2">Номер</label>
                               <my-phone-input v-model="item.number" @change="updateItemInfo('number', item.number)"  class="p-2 border outline-none"></my-phone-input>
                               <!-- <input v-model="item.number" @change="updateItemInfo('number', item.number)" class="p-2 border outline-none" type="text"/> -->
                           </div>
                           <div class="w-1/12 flex flex-col">
                               <label class="px-2">Лейбл</label>
                               <input v-model="item.label" @change="updateItemInfo('label', item.label)" class="p-2 border outline-none" type="text"/>
                           </div>
                           <div class="w-2/12 flex flex-col">
                               <label class="px-2">Вес</label>
                               <input v-model="item.weight" @change="updateItemInfo('weight', item.weight)" class="p-2 border outline-none" type="text"/>
                           </div>
                       </div>
                   </div>
               </section>

                <section v-if="item.customer" class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4" @click="getPhoneHistory">Клиент</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="flex flex-col p-4 w-full">
                            {{ item.customer }}
                        </div>
                    </div>
                </section>
                <section v-else>
                    <input v-model="item.customer_id" @change="attachCustomerPhone" />
                </section>

               <section class="flex flex-col py-4">
                   <h3 class="text-md font-semibold pb-4 pl-4" @click="getPhoneHistory">История</h3>
                   <div class="flex flex-row p-2 border rounded-md items-center">
                       <div class="flex flex-col p-4 w-full">
                           <div v-for="prev_order, index in history" class="w-full flex" :key="index">
                                <order-single :order="prev_order.order" :editable="true" />
                           </div>
                       </div>
                   </div>
               </section>

               <section class="flex flex-col py-4">
                   <h3 class="text-md font-semibold pb-4 pl-4">Информация</h3>
                   <div class="flex flex-row p-2 border rounded-md items-center">
                       <div class="flex flex-row p-4 w-full">
                           <div class="w-3/12 flex flex-col">
                               <label class="pb-2">Последнее обновление</label>
                               {{ item.updated_at }}
                           </div>
                           <div class="w-3/12 flex flex-col">
                               <label class="pb-2">Создан</label>
                               {{ item.created_at }}
                           </div>
                       </div>
                   </div>
               </section>
               <section v-if="can('delete_phones')" class="flex flex-col pb-6">
                   <div class="w-1/2 flex flex-col items-end">
                       <button @click="deleteItem()" class="py-3 px-6 outline-none rounded-md bg-red-100 hover:bg-red-300">Удалить</button>
                   </div>
               </section>
           </section>
       </div>
   </div>
</template>
<script>
import OrderSingle from '@/components/UI/OrderSingle'

export default {
   data: () => {
       return {
           item: {
               id: 0
           },
           selected_permission: '',
           permissions_all: [],
           history: [],
           result: ''
       }
   },
   components: { OrderSingle },
   methods: {
       getItemById () {
           this.getServerData('phone_by_id', { id: this.item.id, with: '' })
               .then((res) => {
                   this.item = res.data
                   this.getPhoneHistory(this.item.number)
               })
               .catch((error) => console.log(error))
       },
       encodeAddress (city_name, street_name, building_number) {
           const country_name = 'Казахстан'

           this.postServerData('address_encode', { data: { country_name, city_name, street_name, building_number } })
               .then((res) => {
                   this.result = res.data.message
                //    if (res.data.code === 200) {
                //    }
               })
               .catch(() => {})
       },
       storeAddress (city_name, street_name, building_number, address_index) {
           const country_name = 'Казахстан'

           this.postServerData('address_store', { data: { country_name, city_name, street_name, building_number } })
               .then((res) => {
                   this.result = res.data.message
                //    if (res.data.code === 200) {
                //    }
               })
               .catch(() => {})
       },
       getPhoneHistory () {
           this.getServerData('phone_history', { phone_number: this.item.number })
               .then((res) => {
                   this.history = res.data
               })
               .catch((error) => console.log(error))
       },
       updateItemInfo (info_index, new_value) {
           this.postServerData('phone_update_info', { data: { item_id: this.item.id, info_index, new_value } })
               .then((res) => {

               })
               .catch((error) => console.log(error))
       },
       deleteItem () {
           this.postServerData('phone_delete', { data: { phone_id: this.item.id } })
               .then((res) => {
                   if (res.data.code === 200) {
                       this.$router.push({ name: 'PhonesList', replace: true })
                   }
               })
               .catch((error) => console.log(error))
       },
       attachCustomerPhone () {
        this.postServerData('customer_attach_phone', { data: { customer_id: this.item.customer_id, phone_id: this.item.id}})
       }
   },
   watch: {
       $route (to, from) {
           if (to.name === 'PhonesEdit') {
               this.item.id = to.params.id
               this.getItemById()
           }
       }
   },
   mounted () {
       if (this.$route.params.id) {
           this.item.id = this.$route.params.id
       }
       this.getItemById()
   }
}
</script>
<style lang="">

</style>
