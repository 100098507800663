<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="p-4">
                    <h1 class="text-xl lg:text-2xl text-gray-800 font-semibold pt-2 min-h-4">{{ item.last_name || "..."}} {{ item.first_name || "..."}}</h1>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                        <div class=" border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Фамилия</label>
                                    <text-input v-model="item.last_name" @change="updateItemInfo('last_name', item.last_name)" type="text" validate="min:2|max:255"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Имя</label>
                                    <text-input v-model="item.first_name" @change="updateItemInfo('first_name', item.first_name)" type="text" validate="min:2|max:255"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Отчество</label>
                                    <text-input v-model="item.middle_name" @change="updateItemInfo('middle_name', item.middle_name)" type="text" validate="min:2|max:255"/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Занятость</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Роль</label>
                                    <select v-model="item.role_id" @change="updateItemInfo('role_id', item.role_id)" class="p-2 border outline-none">
                                        <option v-for="role in roles" :value="role.id">{{ role.title }}</option>
                                        <option value="">Без роли</option>
                                    </select>
                                </div>
                                <div class="w-4/12 flex flex-col">
                                    <label class="px-2">Статус</label>
                                    <select v-model="item.status" @change="updateItemInfo('status', item.status)" class="p-2 border outline-none">
                                        <option value="3">В отпуске</option>
                                        <option value="2">На больничном</option>
                                        <option value="1">Работает</option>
                                        <option value="0">Забанен</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">ИИН:</label>
                                    <text-input v-model="item.iin" @change="updateItemInfo('iin', item.iin)" type="text" validate="min:2|max:255"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Дата рождения:</label>
                                    <text-input v-model="item.birthday" @change="updateItemInfo('birthday', item.birthday)" type="text" validate="min:2|max:255"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section v-if="item.user" class="w-1/2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Авторизация</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-row p-4 w-full">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Email</label>
                                    <text-input v-model="item.user.email" @keyup.enter="updateItemInfo('user.email', item.user.email)" type="email" validate="email|min:4|max:255"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4 w-full">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Пароль</label>
                                    <text-input v-model="password.password" type="password"/>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Повтор</label>
                                    <text-input v-model="password.password_confirm" type="password"/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4 w-full justify-between items-center">
                                <div class="w-1/2">{{ serverResponse }}</div>
                                <div class="w-1/2 flex pt-0 justify-end">
                                    <default-button @click="updateItemInfo('user.password', password)">Сохранить</default-button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="w-1/2 lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Аватар</h3>
                        <div class="border rounded-md flex flex-row">
                            <div class="w-full flex flex-row items-center">
                                <div v-if="!item.avatar?.is_placeholder && item.avatar" class="flex flex-row p-4 w-full overflow-x-auto">
                                    <!-- <div class="py-2 px-4 text-center">
                                        <input />
                                    </div> -->
                                    <div class="py-2 px-4 text-center relative hover:bg-gray-100 rounded-lg">
                                        <img :src="item.avatar?.url" style="height: 256px; width: 256px; max-width: 256px"/>
                                        <div @click="detachAvatar()" class="w-6 absolute top-2 right-2  rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-red-500">x</div>
                                    </div>
                                </div>
                                <div v-else class="w-full p-4">
                                    <div class="w-full px-4 flex flex-col border-l justify-center">
                                        <label class="py-2">Загрузить фотографию</label>
                                        <input @change="uploadAvatar()" class="mb-4 p-2 border outline-none rounded-lg" type="file" ref="file" />
                                        <div @click="submitUploadedFile()" class="p-2 rounded-lg text-white bg-gray-300 text-center cursor-pointer hover:bg-green-500">+ добавить</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="w-1/6 px-4 flex flex-col border-l justify-center">
                                <label class="py-2">Добавить фотографию</label>
                                <select v-model="selected_server_image" @change="new_image.url = images_server + selected_server_image" class="mb-4 py-2 px-4 outline-none border rounded-md">
                                    <option v-for="image in server_images" :value="`${image.dir}/${image.file}`">{{ image.dir }}/{{ image.file }}</option>
                                </select>
                                <select v-model="new_image.type" class="mb-4 py-2 px-4 outline-none border rounded-md">
                                    <option value="avatar" selected>Avatar</option>
                                </select>
                                <div @click="attachAvatar()" class="p-2 rounded-lg text-white bg-gray-300 text-center cursor-pointer hover:bg-green-500">+ добавить</div>
                            </div> -->
                        </div>
                    </section>
                </div>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Информация</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="flex flex-row p-4 w-full">
                            <div class="w-4/12 flex flex-col">
                                <label class="pb-2">Последний визит</label>
                                {{ item.last_visit_at }}
                            </div>
                            <div class="w-4/12 flex flex-col">
                                <label class="pb-2">Последнее обновление</label>
                                {{ item.updated_at }}
                            </div>
                            <div class="w-4/12 flex flex-col">
                                <label class="pb-2">Создан</label>
                                {{ item.created_at }}
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data: () => {
        return {
            item: {
                id: 0,
                user: {
                    email: ''
                },
                avatar: {
                    url: null,
                    is_placeholder: false
                }
            },
            password: {
                password: '',
                password_confirm: ''
            },
            new_image: {
                url: '',
                type: 'avatar'
            },
            images_server: 'https://static.breaktime.kz/',
            selected_server_image: '',
            server_images: [],
            images: null,
            roles: [],
            serverResponse: ''
        }
    },
    methods: {
        uploadAvatar () {
            this.images = this.$refs.file.files[0]
        },
        submitUploadedFile () {
            const formData = new FormData()
            formData.append('upload_file', 'true')
            formData.append('dir', 'breaktime.avatars')
            formData.append('userfile', this.images)
            const headers = { 'Content-Type': 'multipart/form-data' }
            axios
                .post(this.images_server + 'uploader', formData, { headers })
                .then((res) => {
                    // res.status // HTTP status
                    this.new_image.url = this.images_server + res.data.file_path
                    this.new_image.type = 'avatar'

                    this.attachAvatar()
                })
        },
        getStaffById () {
            this.getServerData('staff_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data
                })
                .catch((error) => console.log(error))
        },
        // getImages(){
        //     axios
        //         .get('https://static.omelette.kz/?avatars_files')
        //         .then((res)=>{
        //             this.server_images = res.data
        //         })
        //         .catch((errors)=>console.log(errors))
        // },
        detachAvatar () {
            this.postServerData('staff_detach_avatar', { data: { item_id: this.item.id }})
                .then((res) => { this.getStaffById() })
                .catch((error) => console.log(error))
        },
        attachAvatar () {
            if (this.new_image.url !== '' && this.new_image.type !== '') {
                this.postServerData('staff_attach_avatar', { data: { item_id: this.item.id, image_url: this.new_image.url } })
                    .then((res) => {
                        this.new_image.url = ''
                        this.getStaffById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        getRoles () {
            this.getServerData('roles_all')
                .then((res) => {
                    this.roles = res.data
                })
                .catch((error) => console.log(error))
        },
        updateItemInfo(info_index, new_value) {
            this.postServerData('staff_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {
                    if (res.data) {
                        this.serverResponse = res.data.message
                    }
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
            this.getStaffById()
            // this.getImages()
            this.getRoles()
        }
    }
}
</script>
<style lang="">

</style>
