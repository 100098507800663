<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">{{ item.title }} </h1>
                    <router-link :to="{ name: 'Branches' }">&larr; назад</router-link>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md w-full">
                        <div class="flex flex-row p-4 w-full">
                            <div class="w-full md:w-3/12 flex flex-col">
                                <label class="px-2">Название</label>
                                <input v-model="item.title" @change="updateItemInfo('title', item.title)" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-full md:w-3/12 flex flex-col">
                                <label class="px-2">Основной телефон</label>
                                <input v-model="item.main_phone" @change="updateItemInfo('main_phone', item.main_phone)" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-full md:w-3/12 flex flex-col">
                                <label class="px-2">График с</label>
                                <input v-model="item.shift_begins_at" @change="updateItemInfo('shift_begins_at', item.shift_begins_at)" class="p-2 border outline-none" type="time"/>
                            </div>
                            <div class="w-full md:w-3/12 flex flex-col">
                                <label class="px-2">График до</label>
                                <input v-model="item.shift_ends_at" @change="updateItemInfo('shift_ends_at', item.shift_ends_at)" class="p-2 border outline-none" type="time"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4">
                            <div class="w-full md:w-2/12 flex flex-col">
                                <label class="px-2">Статус</label>
                                <select v-model="item.status" @change="updateItemInfo('status', item.status)" class="p-2 border outline-none">
                                    <option value="1">Активно</option>
                                    <option value="0">Не активно</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-row p-4">
                            <div class="w-1/2 flex flex-col">
                                <label class="px-2">Описание</label>
                                <textarea v-model="item.description" @change="updateItemInfo('description', item.description)" class="p-2 border outline-none h-36"></textarea>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Фирма</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="flex flex-row p-4 w-full">
                            <div class="w-3/12 flex flex-col rounded-md hover:bg-yellow-300 p-4 cursor-pointer">
                                <router-link :to="{ name:'FirmsEdit', params: { id: item.firm_id }}">
                                    <label class="pb-2">{{ item.firm?.title }}</label>
                                    <div class="pb-2">{{ item.firm?.status == 1 ? 'Активен' : 'Не активен'}}</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Информация</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="flex flex-row p-4 w-full">
                            <div class="w-3/12 flex flex-col">
                                <label class="pb-2">Последнее обновление</label>
                                {{ item.updated_at }}
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="pb-2">Создан</label>
                                {{ item.created_at }}
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </div>
    </div>
</template>
<script>

export default {
    data: () => {
        return {
            item: {
                id: 0,
                firm_id: 0
            }
        }
    },
    methods: {
        getItemById () {
            this.getServerData('branch_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data
                })
                .catch((error) => console.log(error))
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('branch_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {

                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getItemById()
    }
}
</script>
<style lang="">

</style>
