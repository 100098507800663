import axios from 'axios'

// axios.defaults.withCredentials = true

export default {
    data: () => {
        return {
            routes: {
                base_url: 'https://api.breaktime.kz/api/v2/crm',

                addresses_all: '/addresses/all',
                addresses_list: '/addresses/list/{per_page}/{sort_by}/{sort_dir}?page={current_page}',
                address_store: '/addresses', // post
                address_by_id: '/addresses/{id}',
                address_by_label: '/addresses/by_label/{label}',
                address_update_info: '/addresses/update/info', // post
                address_by_city_street_building: '/addresses/by_city_street_building/{city_id}/{street_id}/{building_id}',
                address_encode: '/addresses/encode', // post country_name city_name street_name building_number
                address_delete: '/addresses/delete', // post id

                branches_all: '/branches/all',
                branch_by_id: '/branches/{id}',
                branch_update_info: '/branches/update/info', // post

                building_by_id: '/buildings/{id}',
                building_update_info: '/buildings/update/info', // post
                building_delete: '/buildings/delete', // post id

                chats_all: '/chats/all',
                messages_with: '/messages/with/{$0}/{$1}',
                messages_post: '/messages/send',

                combos_all: '/combos/all',
                combos_list: '/combos/list/{per_page}/{sort_by}/{sort_dir}?page={current_page}&name={name_like}',
                combos_by_name: '/combos/by_name/{combo_name}', // используется при добавлении блюда в оформленный заказ
                combo_by_id: '/combos/{id}', // {id}
                combo_store: '/combos', // post
                combo_update_info: '/combos/update/info', // post
                combo_attach_image: '/combos/attach/image',
                combo_detach_image: '/combos/detach/image',
                combo_delete: '/combos/delete', // post item_id
                combo_attach_meal: '/combos/attach/meal', // post combo_id meal_id amount
                combo_detach_meal: '/combos/detach/meal', // post combo_id meal_id

                customers_all: '/customers/all',
                customers_list: '/customers/list/{per_page}?page={current_page}&with={with}&with_count={with_count}', // per_page
                customer_by_id: '/customers/{id}?with={with}', // id
                customer_by_id_dash: '/customers/{id}/dash', // id
                customer_update_info: '/customers/update/info', // post
                customer_by_phone: '/customers/by_phone/{phone_number}',
                customer_by_address: '/customers/by_address/{address_id}',
                customer_store: '/customers', // post
                customer_gifts: '/customers/{customer_id}/gifts', // get
                customer_create_by_phone: '/customers/create/by_phone', // post
                customer_attach_address: '/customers/attach/address', // post
                customer_update_address: '/customers/update/address', // post
                customer_detach_address: '/customers/detach/address', // post
                customer_update_or_create_address: '/customers/update/create/address', // post
                customer_latest_order: '/customers/{customer_id}/order/latest', // get by cus id
                customer_labels: '/customers/labels', // get
                customer_attach_phone: '/customers/attach/phone', // post customer_id phone_id
                customer_detach_phone: '/customers/detach/phone', // post customer_id phone_id
                customer_attach_label: '/customers/attach/label', // post customer_id label_id
                customer_detach_label: '/customers/detach/label', // post customer_id label_id
                customer_attach_gift: '/customers/attach/gift', // post customer_id gift_type gift_id
                customer_update_gift: '/customers/update/gift', // post gift_id
                customer_detach_gift: '/customers/detach/gift', // post gift_id
                customer_attach_branch: '/customers/attach/branch', // post customer_id
                customer_detach_branch: '/customers/detach/branch', // post customer_id branch_id
                customer_delete: '/customers/delete', // post customer_id

                departments_all: '/departments/all',

                expenses_all: '/expenses/all', // get
                expense_store: '/expenses', // post
                expense_delete: '/expenses/delete', // post

                firms_all: '/firms/all',
                firms_store: '/firms/store',
                firm_by_id: '/firms/{id}',
                firm_update_info: '/firms/update/info', // post

                incomes_all: '/incomes/all', // get
                income_store: '/incomes', // post
                income_delete: '/incomes/delete', // post

                ingredients_all: '/ingredients/all',
                ingredients_list: '/ingredients/list/{per_page}/{sort_by}/{sort_dir}?page={current_page}&name={name_like}',
                ingredient_store: '/ingredients', // post
                ingredient_by_id: '/ingredients/{id}', // get
                ingredient_update_info: '/ingredients/update/info', // post
                ingredients_stock_changes_all: '/ingredients/stock_changes/all/{criteria}/{period}', // get criteria period
                ingredients_delete: '/ingredients/delete',
                ingredient_attach_child: '/ingredients/attach/child', // post parent_id child_id amount
                ingredient_detach_child: '/ingredients/detach/child', // post parent_id child_id
                ingredient_child_update_info: '/ingredients/child/update/info', // post parent_id child_id info_index new_value
                ingredient_stock_change_store: '/ingredients/stock_change/store', // post change.ingredient_id change.amount change.type chane.issued_at?
                ingredient_stock_change_update_info: '/ingredients/stock_change/update/info', // post item_id info_index new_value
                ingredient_set_to_zero: '/warehouse/stock_changes/set_to_zero', // post ingredient object
                ingredient_set_in_stock: '/warehouse/stock_changes/set_in_stock', // post ingredient object

                meals_all: '/meals/all?with_rels={with}',
                // 'meals_list'   : '/meals/list',    // page - perpage
                meals_list: '/meals/list/{per_page}/{sort_by}/{sort_dir}?page={current_page}&name={name_like}&tag_id={selected_tag}&with={with}',
                // api_routes.meals_list}/${this.meta.per_page}/${this.sort_by}/${this.sort_dir}?page=${this.meta.current_page}&name=${this.name_like}&tag_id=${this.selected_tag}
                meals_by_name: '/meals/by_name/{meal_name}', // используется при добавлении блюда в оформленный заказ
                meals_by_tag: '/meals/by_tag', // not used yet
                meal_store: '/meals', // post
                meal_delete: '/meals/delete', // post id
                meal_by_id: '/meal/{id}',
                meal_update_info: '/meal/update/info',
                meal_attach_tag: '/meal/attach/tag',
                meal_detach_tag: '/meal/detach/tag',
                meal_attach_recommendation: '/meal/attach/recommendation',
                meal_detach_recommendation: '/meal/detach/recommendation',
                meal_attach_ingredient: '/meal/attach/ingredient',
                meal_update_ingredient: '/meal/update/ingredient',
                meal_detach_ingredient: '/meal/detach/ingredient',
                meal_attach_image: '/meal/attach/image',
                meal_detach_image: '/meal/detach/image',
                meals_tags: '/meals/tags',

                news_all: '/news/all', // get
                news_by_id: '/news/{id}', // {id}
                news_store: '/news', // post
                news_attach_image: '/news/attach/image',
                news_detach_image: '/news/detach/image',
                news_update_info: '/news/update/info', // post
                news_delete: '/news/delete', // post item_id

                orders_active: '/orders/active/{criteria}/{sort_by}/{sort_dir}',
                order_store: '/orders',
                order_update_info: '/orders/update/info', // update single specific info
                order_by_id: '/orders/{id}', // get
                order_items_in_progress: '/orders/items/in_progress',
                order_item_update_info: '/orders/items/update/info', // post
                order_items_add_to_stock_changes: '/orders/{order_id}/items/stock_changes/store', // get order_id
                order_items_remove_stock_changes: '/orders/{order_id}/items/stock_changes/delete', // get order_id
                order_items_remove_stock_changes_by_id: '/orders/items/stock_changes/{item_id}/delete', // get item_id

                permissions_all: '/permissions/all',
                permissions_list: '/permissions/list/{$0}/{$1}/{$2}?page={$3}',
                permission_by_id: '/permissions/{$0}', // {id}
                permission_update_info: '/permissions/update/info', // post
                permission_store: '/permissions/store', // post
                permission_attach_role: '/permissions/attach/role',
                permission_detach_role: '/permissions/detach/role',
                permission_delete: '/permissions/delete',

                phones_list: '/phones/list/{per_page}/{sort_by}/{sort_dir}?page={current_page}',
                phone_store: '/phones', // post
                phone_by_id: '/phones/{id}?with={with}', // get
                phone_by_number: '/phones/by_number/{number}', // get
                phone_update_info: '/phones/update/info', // post
                phone_delete: '/phones/delete', // post
                phone_history: '/phones/{phone_number}/history',

                promocodes_all: '/promocodes/all', // get
                promocodes_by_id: '/promocodes/{id}', // {id}
                promocode_by_slug: '/promocodes/by_slug/{slug}', // get
                promocodes_store: '/promocodes', // post
                promocodes_update_info: '/promocodes/update/info', // post
                promocodes_delete: '/promocodes/delete', // post item_id

                report_stats: '/reports/stats', // get
                report_day: '/reports/day/{day}/{sector}/{show_criteria}/{show_value}/{sort_criteria}/{sort_direction}', // get
                // 'report_day_orders' : '/reports/day/orders/{$0}', //get
                report_month: '/reports/month/{year}/{month}/{sector}', // get
                report_week: '/reports/week/{$0}', // get
                report_filtered: '/reports/filtered/{date_from}/{date_to}/{summ_from}/{summ_to}/{statuses}', // get
                stock_change_by_id: '/ingredients/stock_changes/{id}',

                roles_all: '/roles/all',
                roles_list: '/roles/list/{$0}/{$1}/{$2}?page={$3}',
                role_by_id: '/roles/{$0}', // {id}
                role_update_info: '/roles/update/info', // post
                role_store: '/roles/store', // post
                role_delete: '/roles/delete',

                sales_all: '/sales/all', // get
                sales_by_id: '/sales/{id}', // {id}
                sales_store: '/sales', // post
                sales_attach_image: '/sales/attach/image',
                sales_detach_image: '/sales/detach/image',
                sales_update_info: '/sales/update/info', // post
                sales_delete: '/sales/delete', // post item_id

                staff_all: '/staff/all',
                staff_by_my_branch: '/staff/by_branch/my', // ++
                staff_by_id: '/staff/{id}', // {id} ++
                staff_store: '/staff', // post
                staff_update_info: '/staff/update/info', // post ++
                staff_attach_avatar: '/staff/attach/avatar', // post
                staff_detach_avatar: '/staff/detach/avatar', // post
                staff_salaries: '/staff/{staff_id}/salaries', // get id
                staff_salary_store: '/staff/salaries', // post staff_id salary_amount date
                staff_salary_delete: '/staff/salaries/delete', // post salary_id
                couriers_active: '/staff/couriers/active',

                streets_all: '/streets/all',
                streets_list: '/streets/list/{per_page}/{sort_by}/{sort_dir}?page={current_page}&name={name_like}',
                street_by_id: '/streets/{id}',
                street_delete: '/streets/delete', // post id
                street_update_info: '/streets/update/info', // post
                streets_my_city: '/streets/city/my',

                tags_all: '/tags/all',
                tag_by_id: '/tags/{id}',
                tag_update_info: '/tags/update/info', // post
                tag_store: '/tags', // post
                tag_delete: '/tags/delete', // post

                paycheck_show: 'https://api.breaktime.kz/paycheck/{order_id}/{type}',
                get_images: 'https://static.breaktime.kz/images_{dir}',
                upload_images: 'https://static.breaktime.kz/uploader', // userfiles dir generatethumb=false generatename=false
                image_update: '/images/update/info' // post
            }
        }
    },
    methods: {
        api_route: function (routeName, params = null) {
            let routeStr = this.routes[routeName]
            if (params) {
                // params.forEach((value, index) => {
                //     routeStr = routeStr.replace('{$' + index.toString() + '}', value)
                // })
                for (const key in params) {
                    routeStr = routeStr.replace(`{${key}}`, params[key])
                }
            }
            return routeStr
        },
        getData: function (model, { method = null, params = null, rels = null }) {
            const baseUrl = this.routes.base_url + '/' + model
            return axios.get(baseUrl, {
                                headers: {
                                    Authorization: this.$store.getters.loggedIn
                                }
                            })
        },
        getSDRAW: function (url) {
            const baseUrl = this.routes.base_url + url
            return axios.get(baseUrl, {
                                headers: {
                                    Authorization: this.$store.getters.loggedIn
                                }
                            })
        },
        getServerData: function (routeName, params = null) {
            const baseUrl = this.routes.base_url + this.api_route(routeName, params)
            return axios.get(baseUrl, {
                                headers: {
                                    Authorization: this.$store.getters.loggedIn
                                }
                            })
        },
        getExternalServerData: function (routeName, params = null) {
            const baseUrl = this.api_route(routeName, params)
            return axios.get(baseUrl)
        },
        postServerData: function (routeName, { params = null, data }) {
            const baseUrl = this.routes.base_url + this.api_route(routeName, params)
            return axios.post(baseUrl, data, {
                                headers: {
                                    Authorization: this.$store.getters.loggedIn
                                }
                            })
        },
        postExternalServerData: function (routeName, { params = null, data }) {
            const baseUrl = this.api_route(routeName, params)
            return axios.post(baseUrl, data, {
                                headers: {
                                    Authorization: this.$store.getters.loggedIn
                                }
                            })
        }
    }
}
