<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">{{ item.first_name || '...' }} {{ item.last_name || '...' }} </h1>
                    <router-link :to="{ name: 'CustomersList' }">&larr; назад</router-link>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>
                <section class="flex w-full flex-wrap">
                    <section class="flex w-full lg:w-1/2 flex-col pr-2">
                        <section class="w-full flex flex-col py-4">
                            <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                            <div class="border rounded-md">
                                <div class="flex flex-row p-4">
                                    <div class="w-1/2 flex flex-col">
                                        <label class="px-2">Фамилия</label>
                                        <input v-model="item.last_name" @change="updateItemInfo('last_name', item.last_name)" class="p-2 border outline-none" type="text"/>
                                    </div>
                                    <div class="w-1/2 flex flex-col">
                                        <label class="px-2">Имя</label>
                                        <input v-model="item.first_name" @change="updateItemInfo('first_name', item.first_name)" class="p-2 border outline-none" type="text"/>
                                    </div>
                                </div>
                                <div class="flex flex-row p-4">
                                    <div class="w-6/12 flex flex-col">
                                        <label class="px-2">Пол</label>
                                        <select v-model="item.sex" @change="updateItemInfo('sex', item.sex)" class="p-2 border outline-none">
                                            <option value="male">М</option>
                                            <option value="female">Ж</option>
                                            <option value="">--</option>
                                        </select>
                                    </div>
                                    <div class="w-6/12 flex flex-col">
                                        <label class="px-2">День рождения</label>
                                        <input v-model="item.birthday" @change="updateItemInfo('birthday', item.birthday)" class="p-2 border outline-none" type="text"/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="flex flex-col py-4">
                            <h3 class="text-md font-semibold pb-4 pl-4">Телефоны</h3>
                            <div class="flex flex-col p-2 border rounded-md items-center">
                                <div class="flex w-full">
                                    <div v-if="item.phones && item.phones.length > 0" class="flex flex-col py-4 w-full">
                                        <div v-for="phone, index in item.phones" class="w-full flex py-2 px-4" :key="index">
                                            <div class="w-full flex items-center">
                                                <div class="text-lg mr-2"  @click="getPhoneHistory(phone)">
                                                    {{ phone.formated || phone.number || '!no_data!' }}
                                                </div>
                                                <default-button @click="detachPhone(phone.id)" type="delete" :icon-only="true"></default-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="p-8">
                                            <i>У клиента нет присвоенных телефонов</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-center w-full px-4">
                                    <text-input v-model="searchPhone" class="w-2/5" type="number" validate="phone" placeholder="Номер телефона" :requestsEnterKey="true" @keyup.enter="getPhones"/>
                                </div>
                                <div class="w-full p-4">
                                    <div v-for="phone, index in suggestedPhones" class="flex items-center" :key="index">
                                        {{ phone.formated || phone.number || '!no_data!' }}
                                        {{ phone.customer_id || '!no_customer!' }}
                                        <default-button v-if="!phone.customer_id"  @click="attachPhone(phone.id)" type="confirm" :icon-only="true"></default-button>
                                        <default-button v-if="!phone.customer_id"  @click="deletePhone(phone.id)" type="delete" :icon-only="true"></default-button>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </section>
                    <section class="flex w-full lg:w-1/2 flex-col pl-2">
                        <section class="w-full flex flex-col py-4">
                            <h3 class="text-md font-semibold pb-4 pl-4">Подарки</h3>
                            <div class="flex flex-row p-2 border rounded-md items-center">
                                <div class="flex flex-row p-4 w-full">
                                    <div v-if="item.gifts?.length > 0">
                                        <div v-for="gift, index in item.gifts" class="py-2 px-4 flex items-center hover:bg-gray-100 odd:bg-gray-50" :key="index">
                                            <div class="w-6/12">
                                                {{ gift.giftable.name}}
                                            </div>
                                            <div class="w-5/12 flex items-center">
                                                <div class="w-2/6 flex flex-col">
                                                    <label class="text-xs">Сумма заказа</label>
                                                    <input v-model="gift.min_order_summ" @change="updateGift(gift, 'min_order_summ', gift.min_order_summ)" placeholder="min order" class="w-11/12 p-2 rounded-md"/>
                                                </div>
                                                <div class="w-1/6 flex flex-col">
                                                    <label class="text-xs">Кол-во</label>
                                                    <input v-model="gift.amount" @change="updateGift(gift, 'amount', gift.amount)" placeholder="amount" class="w-11/12 p-2 rounded-md" />
                                                </div>
                                                <div class="w-3/6 flex flex-col">
                                                    <label class="text-xs">Срок годности</label>
                                                    <input v-model="gift.expires_at" @change="updateGift(gift, 'expires_at', gift.expires_at)" placeholder="ГГГГ-ММ-ДД ЧЧ:ММ:CC" class="w-11/12 p-2 rounded-md" />
                                                </div>
                                            </div>
                                            <div class="w-1/12 flex justify-center">
                                                <button @click="detachGift(gift)" class="w-6 h-6 flex items-center justify-center bg-gray-200 hover:bg-red-100 rounded-full">x</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <i>У клиента нет подарков</i>
                                    </div>
                                </div>
                                <div class="w-1/2">
                                    <div class="p-2">Добавить подарок</div>
                                    <div class="p-2 bg-gray-100 rounded-md flex items-center">
                                        <select v-model="selectedMealasGift" class="py-2 px-4 w-full">
                                            <option v-for="meal in meals" :value="meal.id" :key="meal.id">{{ meal.name || '---' }}</option>
                                        </select>
                                        <default-button @click="attachGiftMeal" type="confirm" :icon-only="true"></default-button>
                                    </div>
                                    <div class="p-2 bg-gray-100 rounded-md flex items-center">
                                        <select v-model="selectedComboasGift" class="py-2 px-4 w-full">
                                            <option v-for="item in combos" :value="item.id" :key="item.id">{{ item.name || '---' }}</option>
                                        </select>
                                        <default-button @click="attachGiftCombo" type="confirm" :icon-only="true"></default-button>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="flex flex-col py-4">
                            <h3 class="text-md font-semibold pb-4 pl-4">Филиалы</h3>
                            <div class="flex flex-row p-2 border rounded-md items-center">
                                <div v-if="item.branches && item.branches.length > 0" class="flex flex-col p-4 w-full">
                                    <div v-for="branch, index in item.branches" :key="index" class="flex justify-between items-center">
                                        <div>{{ branch.title }}</div>
                                        <default-button @click="detachBranch(branch.id)" type="delete" :icon-only="true"></default-button>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="p-8">
                                        <i>У клиента нет присвоенных филиалов</i>
                                        <default-button @click="attachBranch" type="confirm">Присвоить к моему филиалу</default-button>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="flex flex-col py-4">
                            <h3 class="text-md font-semibold pb-4 pl-4">Адреса</h3>
                            <div class="flex flex-col p-2 border rounded-md">
                                <div class="flex flex-col p-4 w-full">
                                    {{ item.all_addresses }}
                                </div>
                                <div v-if="item.addresses && item.addresses.length > 0" class="flex flex-col p-4 w-full">
                                    <div v-for="address, index in item.addresses" class="w-full flex flex-col py-4 px-0 items-center" :key="index">
                                        <div class="w-full">
                                            {{ address.full_address || '!no_data!' }}
                                        </div>
                                        <div class="w-full" v-if="address.pivot">
                                            <div class="flex items-center">
                                                <input v-model="address.pivot.appartment_number" @change="updateUserAddress(address)" class="px-2 border-b w-1/5" placeholder="кв"/>
                                                <input v-model="address.pivot.entrance" @change="updateUserAddress(address)" class="px-2 border-b w-1/5" placeholder="п" />
                                                <input v-model="address.pivot.floor" @change="updateUserAddress(address)" class="px-2 border-b w-1/5" placeholder="э" />
                                                <input v-model="address.pivot.label" @change="updateUserAddress(address)" class="px-2 border-b w-2/5" placeholder="лейбл" />
                                                <input v-model="address.pivot.details" @change="updateUserAddress(address)" class="px-2 border-b w-2/5" placeholder="детали" />
                                                <select v-model="address.pivot.has_intercom" @change="updateUserAddress(address)" class="px-2 border-b w-1/5">
                                                    <option value="0">Не работает</option>
                                                    <option value="1">Работает</option>
                                                </select>
                                                <default-button @click="detachAddress(address)" type="delete" :icon-only="true"></default-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="p-8">
                                        <i>У клиента нет присвоенных адресов</i>
                                    </div>
                                </div>
                                <div class="p-4">
                                    <i>Добавить адрес</i>
                                    <text-input placeholder="Айманова, 19" v-model="searchAddress" :requestsEnterKey="true" @keyup.enter="encodeAddress" />
                                    <div class="p-4" v-if="suggestedAddress">
                                        <div class="w-full flex items-center">
                                            <div class="pr-4">
                                                {{ suggestedAddress.full_address }}
                                            </div>
                                            <default-button type="confirm" @click="attachAddress(suggestedAddress.id)">добавить адрес</default-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="flex flex-col py-4">
                            <h3 class="text-md font-semibold pb-4 pl-4">Информация</h3>
                            <div class="flex flex-row p-2 border rounded-md items-center">
                                <div class="flex flex-row p-4 w-full">
                                    <div class="w-4/12 flex flex-col">
                                        <label class="pb-2">Последний визит</label>
                                        {{ item.lastseen_at }}
                                    </div>
                                    <div class="w-4/12 flex flex-col">
                                        <label class="pb-2">Последнее обновление</label>
                                        {{ item.updated_at }}
                                    </div>
                                    <div class="w-4/12 flex flex-col">
                                        <label class="pb-2">Создан</label>
                                        {{ item.created_at }}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Присвоенные заказы {{ item.orders?.length }}</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div v-if="item.orders && item.orders.length > 0" class="flex flex-col p-4 w-full">
                            <div class="py-4">
                                <div>Количество доставленных заказов: {{ item.orders_delivered_numb }}</div>
                                <div>Количество отмененных заказов: {{ item.orders_cancelled_numb }}</div>
                                <div>Количество отказавшихся заказов: {{ item.orders_declined_numb }}</div>
                                <div>Сумма заказов: {{ item.orders_summ }}</div>
                                <div>Средний чек: {{ item.orders_avg_summ }}</div>
                            </div>
                            <div v-for="order in item.orders" class="w-full border-b" :key="order.id">
                                <div class="w-full flex justify-between p-4">
                                    <div class="w-3/12 flex flex-row">
                                        <div class="w-5/12 flex flex-col">
                                            <div :class="[order.times.created_to_closed.raw > 60 ? 'text-red-500 font-semibold' : '']">{{ order.id }}</div>
                                            <div>{{ order.current_status?.emoji || '' }}</div>
                                            <div>{{ order.times.created_to_closed.formatted }}</div>
                                        </div>
                                        <div class="w-full flex flex-col">
                                            <div v-for="status, index in order.statuses_history" class="flex items-center text-sm" :key="index">
                                                <div>{{ status.created_at }}</div>
                                                <div class="pl-2">{{ status.status.name }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-3/12 flex flex-col">
                                        <div class="flex items-center">
                                            <div class="pr-2" @click="()=> { searchPhone = order.customer?.phone; getPhones() }">{{ order.customer?.phone }}</div>
                                            {{ order.customer?.customer_id }}
                                        </div>
                                        <div @click="()=> { searchAddress = order.address?.street_appartment; encodeAddress() }">{{ order.address?.street_appartment }}</div>
                                        <div>{{ order.address?.address_details }}</div>
                                        <div>{{ order.extra_info?.order_source }}</div>
                                        <!-- <div>{{ order.address.full_address }}</div> -->
                                    </div>
                                    <div class="w-3/12 flex flex-col">
                                        <div v-for="item, index in order.items" class="flex items-center text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2" :key="index">
                                            <div class="w-7/12 flex items-center">
                                                {{ item.name }}
                                                <div class="">{{ item.is_combo ? '🍱' : ''  }}</div>
                                            </div>
                                            <div class="pl-2">{{ item.amount }} x </div>
                                            <div>{{ item.price }}</div>
                                            <div class="">{{ item.is_bonus ? '🎁' : ''  }}</div>
                                        </div>
                                    </div>
                                    <div class="w-3/12 flex flex-col">
                                        <div class="flex justify-between text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                            <div>Итого</div>
                                            <div>{{ order.total_summ }}</div>
                                        </div>
                                        <div class="flex justify-between text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                            <div>Перевод</div>
                                            <div>{{ order.payment?.transfer }}</div>
                                        </div>
                                        <div class="flex justify-between text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                            <div>Бонусных</div>
                                            <div>{{ order.total_bonuses }}</div>
                                        </div>
                                        <div class="flex justify-between text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                            <div>Доставка</div>
                                            <div>{{ order.payment?.delivery_cost }}</div>
                                        </div>
                                        <div class="flex justify-between text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                            <div>Скидка</div>
                                            <div>{{ order.payment?.discount }}</div>
                                        </div>
                                        <div class="flex justify-between text-sm bg-gray-100 even:bg-gray-50 hover:bg-gray-200 px-2">
                                            <div>Надбавка</div>
                                            <div>{{ order.payment?.upcount }}</div>
                                        </div><!-- <div>{{ order.address.full_address }}</div> -->
                                    </div>
                                </div>
                                <div v-if="order.rating" class="w-full flex justify-between p-4 border-t">
                                    <div class="w-3/12 flex flex-col">
                                        <div class="flex">
                                            <div class="w-1/2">Оператор:</div>
                                            <div class="flex">
                                                <img v-for="index in order.rating?.operator_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="w-1/2">Кухня:</div>
                                            <div class="flex">
                                                <img v-for="index in order.rating?.kitchen_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="w-1/2">Доставка:</div>
                                            <div class="flex">
                                                <img v-for="index in order.rating?.courier_rating" :key="index" style="height:12px" src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-9/12 flex">
                                        <div v-if="order.rating?.body" class="bg-gray-100 rounded-md p-4"><i>{{ order.rating?.body }}</i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="p-8">
                                <i>У клиента нет заказов</i>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section class="flex flex-col py-4">
                <h3 class="text-md font-semibold pb-4 pl-4">
                    История по номеру телефона
                    {{ selectedPhone?.number || '' }}
                    <div @click="updateOrderCustomerByPhone">обновить</div>
                </h3>
                <div class="flex flex-row p-2 border rounded-md items-center">
                    <div class="flex flex-col p-4 w-full">
                        <div v-for="prev_order, index in history" class="w-full flex" :key="index">
                            <order-single :order="prev_order.order" :editable="true" />
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</template>
<script>
import OrderSingle from '@/components/UI/OrderSingle'
export default {
    data: () => {
        return {
            item: {
                id: 0,
                latest_order: {
                    id: 0
                }
            },
            selectedMealasGift: null,
            selectedComboasGift: null,
            searchPhone: '',
            suggestedPhones: [],
            meals: [],
            combos: [],
            history: null,
            searchAddress: '',
            suggestedAddress: null,
            selectedPhone: null
        }
    },
   components: { OrderSingle },
    methods: {
        updateOrderCustomerByPhone () {
            this.history.map(item => {
                if (item.customer_id === null || item.customer_id === 0) {
                    return this.postServerData('order_update_info', { data: { order_id: item.order_id, info_index: 'customer.customer_id', new_value: this.selectedPhone.customer_id } })
                }
                return true
            })
            this.getPhoneHistory(this.selectedPhone)
        },
        getItemById () {
            this.getServerData('customer_by_id', { id: this.item.id, with: 'gifts' })
                .then((res) => {
                    this.item = res.data

                    // this.item.phones.forEach(phone => {
                    //     this.getPhoneHistory(phone.number)
                    // })
                })
                .catch((error) => console.log(error))
        },
        getPhoneHistory (phone) {
            this.selectedPhone = phone
            this.getServerData('phone_history', { phone_number: phone.number })
                .then((res) => {
                    this.history = res.data
                })
                .catch((error) => console.log(error))
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('customer_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {

                })
                .catch((error) => console.log(error))
        },
        updateUserAddress (address) {
            this.postServerData('customer_update_address', { data: { customer_id: this.item.id, address_id: address.id, appartment: address.pivot } })
                .then()
        },
        detachAddress (address) {
            if (confirm('Удалить адрес?')) {
                this.postServerData('customer_detach_address', { data: { customer_id: this.item.id, address_id: address.id } })
                    .then((res) => this.getItemById())
            }
        },
        getPhones () {
            this.getServerData('phone_by_number', { number: this.searchPhone })
                .then((res) => {
                    this.suggestedPhones = res.data
                })
                .catch((error) => console.log(error))
        },
        attachPhone (phone_id) {
            this.postServerData('customer_attach_phone', { data: { customer_id: this.item.id, phone_id } })
                .then(() => {
                    this.suggestedPhones = []
                    this.searchPhone = ''
                    this.getItemById()
                })
        },
        detachPhone (phone_id) {
            if (confirm('Удалить телефон?')) {
                this.postServerData('customer_detach_phone', { data: { customer_id: this.item.id, phone_id } })
                    .then(() => {
                        this.getItemById()
                    })
            }
        },
        attachGiftMeal () {
            if (this.selectedMealasGift) {
                this.postServerData('customer_attach_gift', { data: { customer_id: this.item.id, gift_type: 'meal', gift_id: this.selectedMealasGift } })
                    .then(() => {
                        this.getItemById()
                    })
            }
        },
        attachGiftCombo () {
            if (this.selectedComboasGift) {
                this.postServerData('customer_attach_gift', { data: { customer_id: this.item.id, gift_type: 'combo', gift_id: this.selectedComboasGift } })
                    .then(() => {
                        this.getItemById()
                    })
            }
        },
        encodeAddress () {
            const address = this.searchAddress.split(', ')
            this.postServerData('address_encode', { data: { country_name: null, city_name: null, street_name: address[0], building_number: address[1] } })
                .then((res) => {
                    this.suggestedAddress = res.data.item
                })
        },
        attachAddress (address_id) {
            this.postServerData('customer_attach_address', { data: { customer_id: this.item.id, address_id } })
                .then(() => {
                    this.getItemById()
                })
        },
        attachBranch () {
            this.postServerData('customer_attach_branch', { data: { customer_id: this.item.id } })
                .then(() => {
                    this.getItemById()
                })
        },
        detachBranch (branch_id) {
            this.postServerData('customer_detach_branch', { data: { customer_id: this.item.id, branch_id: branch_id } })
                .then(() => {
                    this.getItemById()
                })
        },
        updateGift (gift, info_index, new_value) {
            if (gift) {
                const gift_id = gift.id

                this.postServerData('customer_update_gift', { data: { gift_id, info_index, new_value } })
                    .then(() => {})
            }
        },
        detachGift (gift) {
            const gift_id = gift.id
            // if(this.selectedMealasGift){
                this.postServerData('customer_detach_gift', { data: { customer_id: this.item.id, gift_id } })
                    .then(() => {
                        this.getItemById()
                    })
            // }
        },
        getMeals () {
            this.getServerData('meals_all')
                .then((res) => { this.meals = res.data.data })
        },
        getCombos () {
            this.getServerData('combos_all')
                .then((res) => { this.combos = res.data })
        },
       deletePhone (phone_id) {
        if (confirm('Удалить телефон из базы?')) {
            this.postServerData('phone_delete', { data: { phone_id } })
            .then((res) => {
                if (res.data.code === 200) {
                    this.getPhones()
                }
            })
            .catch((error) => console.log(error))
        }
       }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getItemById()
        this.getMeals()
        this.getCombos()
    }
}
</script>
<style lang="">

</style>
