<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
        <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">Создать сотрудника</h1>
                    <router-link :to="{ name: 'Staff' }">&larr; назад</router-link>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                    <div class=" border rounded-md">
                        <div class="flex flex-row p-4">
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Фамилия</label>
                                <input v-model="item.last_name" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Имя</label>
                                <input v-model="item.first_name" class="p-2 border outline-none" type="text"/>
                            </div>
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Отчество</label>
                                <input v-model="item.middle_name" class="p-2 border outline-none" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4">
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Филиал</label>
                                <select v-model="item.branch_id" class="p-2 border outline-none">
                                    <option value="2">Омлет Аксу</option>
                                    <option value="1">Омлет Павлодар</option>
                                    <option value="">Без филиала</option>
                                </select>
                            </div>
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Департамент</label>
                                <select v-model="item.department_id" class="p-2 border outline-none">
                                    <option value="6">Саппорт</option>
                                    <option value="5">Аккаунтинг</option>
                                    <option value="4">Маркетинг</option>
                                    <option value="3">Доставка</option>
                                    <option value="2">Кухня</option>
                                    <option value="1">Фронтдеск</option>
                                    <option value="">Без департамента</option>
                                </select>
                            </div>
                            <div class="w-2/12 flex flex-col">
                                <label class="px-2">Роль</label>
                                <select v-model="item.role_id" class="p-2 border outline-none">
                                    <option v-for="role in roles" :value="role.id">{{ role.title }}</option>
                                    <option value="">Без роли</option>
                                </select>
                            </div>
                            <div class="w-1/12 flex flex-col">
                                <label class="px-2">Статус</label>
                                <select v-model="item.status" class="p-2 border outline-none">
                                    <option value="3">В отпуске</option>
                                    <option value="2">На больничном</option>
                                    <option value="1">Работает</option>
                                    <option value="0">Забанен</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Авторизация</h3>
                    <div class="flex flex-row p-2 border rounded-md items-center">
                        <div class="flex flex-row p-4 w-full">
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Email</label>
                                <input v-model="item.email" class="p-2 border outline-none" type="email"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Пароль</label>
                                <input v-model="item.password" class="p-2 border outline-none" type="password"/>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col py-4">
                    <div class="flex flex-row pb-6">
                        <div class="w-full flex flex-col items-end">
                            <button @click="storeItem()" class="py-3 px-6 outline-none rounded-md bg-green-100 hover:bg-green-300">Сохранить</button>
                        </div>
                    </div>
                </section>

            </section>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            item: {
                last_name: '',
                first_name: '',
                middle_name: '',
                branch_id: '',
                department_id: '',
                role_id: '',
                status: 1,
                email: '',
                password: ''
            },
            roles: []
        }
    },
    methods: {
        storeItem () {
            this.postServerData('staff_store', { data: this.item })
                .then((res) => {
                    if (res.data?.code === 200) {
                        this.$router.push({ name: 'StaffEdit', params: { id: res.data.item_id } })
                    }
                })
                .catch((error) => console.log(error))
        },
        getRoles () {
            this.getServerData('roles_all')
                .then((res) => {
                    this.roles = res.data
                })
                .catch((error) => console.log(error))
        }
    },
    mounted () {
        this.getRoles()
    }
}
</script>
<style lang="">

</style>
