<template lang="">
    <Transition name="dialog">
        <div class="dialog" v-if="show === true" @click.stop="hideDialog">
            <div @click.stop class="dialog__content" :class="contentclass">
                <div class="dialog__header">
                    <div class="dialog__header_inner text-xl font-semibold">
                        <slot name="header"></slot>
                    </div>
                    <div class="dialog__close_btn" @click.stop="hideDialog">x</div>
                </div>
                <div class="dialog__content_inner">
                    <slot></slot>
                </div>
            </div>
        </div>
    </Transition>
</template>
<script>
export default {
    name: 'my-dialog',
    props: {
        contentclass: {
            type: String,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        hideDialog () {
            this.$emit('update:show', false)
        }
    },
    mounted () {

    }
}
</script>
<style scoped>
    .dialog {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0,0,0,.5);
        position: fixed;
        display: flex;
        transition: opacity 0.3s ease-in;
        z-index: 666;
        padding: 6px;
    }
    .dialog__content {
        position: relative;
        margin: 0 0 auto auto;
        /* margin: 3% auto auto auto; */
        /* margin: 0; */
        /* min-height: 450px; */
        /* min-width: 450px; */
        /* max-width: 50%; */
        /* max-height: 90%; */
        /* max-height: 100%;
        max-width: 98%; */
        /* overflow-y: auto; */
        transition: all .3s ease-out;
        background: #eee;
        border-radius: 12px;
        margin-right: 3px;
        height: 100%;
    }
    .dialog__content.fullscreen {
        /* height: 100%; */
        /* width: 50%; */
        overflow-y: auto;
    }
    .dialog__header {
        display: flex;
        flex-direction: row;
        margin-right: 6px;
        background: white;
        border-radius: 12px 12px 0 0;
        border-bottom: 1px solid #eee
    }
    .dialog__header_inner {
        display: flex;
        width: 100%;
        padding: 12px 0 12px 18px;
        line-height: 36px;
        align-items: center;
    }
    .dialog__content_inner {
        padding: 12px 18px;
        margin-right: 6px;
        background: white;
        border-radius: 0 0 12px 12px;
        max-height: 93%;
        overflow-y: auto;
    }
    .dialog__close_btn {
        height: 36px;
        width: 54px;
        line-height: 0px;
        border-radius: 0 12px 0 12px;
        background-color: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 24px;
        padding: 0 0 6px 0;
    }
    .dialog__close_btn:hover {
        background-color: #333;
        color: white
    }

    .dialog-enter-from {
    opacity: 0;
    }

    .dialog-leave-to {
    opacity: 0;
    }

    .dialog-enter-from .dialog__content {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    }
    .dialog-leave-to .dialog__content {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    }
</style>
