<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data: () => {
        return{
        }
    },
    methods: {
    },
    watch: {
    },
    mounted(){
    },
    components: {
    }
}
</script>
<style scoped>
</style>