<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="pt-6 pl-4 md:pl-0">
                    <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-4 ">Пополнение склада ингредиентов</h1>
                    <router-link :to="{ name: 'WarehouseIngredients' }">&larr; назад</router-link>
                    <!-- <router-link :to="{ name: 'ItemsExact', params: {page: 1, sort_by: 'name', sort_dir: 'ASC', tag_id: 2} }">&larr; назад</router-link> -->
                </div>
                <section class="flex flex-col py-4">
                    <h3 class="text-md font-semibold pb-4 pl-4">Пополнить склад</h3>
                    <div class=" border rounded-md flex flex-wrap">
                        <div class="flex flex-row w-full md:w-6/12 p-4">
                            <div class="w-6/12 flex flex-col">
                                <label class="px-2">Ингредиент</label>
                                <select v-model="stockchange.ingredient_id" class="p-2 border outline-none w-full">
                                    <option v-for="ingredient in ingredients" :value="ingredient.id">{{ ingredient.name }}</option>
                                </select>
                            </div>
                            <div class="w-6/12 flex flex-col">
                                <label class="px-2">Изменение</label>
                                <select v-model="stockchange.type" class="p-2 border outline-none w-full">
                                    <option value="refill">Пополнение</option>
                                    <option value="writeoff">Списание</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-row p-4 w-full md:w-6/12">
                            <div class="w-6/12 flex flex-col">
                                <label class="px-2">Поставщик</label>
                                <select v-model="stockchange.supplier_id" class="p-2 border outline-none w-full">
                                    <option value="">Склад</option>
                                    <option value="1">Поставщик</option>
                                </select>
                            </div>
                            <div class="w-6/12 flex flex-col">
                                <label class="px-2">Дата</label>
                                <input v-model="stockchange.date" class="p-2 border outline-none w-full" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4 w-full md:w-6/12">
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Количество</label>
                                <input v-model="stockchange.amount" class="p-2 border outline-none w-11/12" type="number"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Множитель</label>
                                <input v-model="stockchange.amount_multiplier" class="p-2 border outline-none w-11/12" type="number"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Сумма</label>
                                <input v-model="stockchange.price" class="p-2 border outline-none w-11/12" type="number"/>
                            </div>
                            <div class="w-3/12 flex flex-col">
                                <label class="px-2">Оплачено</label>
                                <input v-model="stockchange.payed_amount" class="p-2 border outline-none w-full" type="text"/>
                            </div>
                        </div>
                        <div class="flex flex-row p-4 w-full md:w-6/12 items-end">
                            <div class="w-8/12 flex flex-col">
                                <label class="px-2">Источник</label>
                                <select v-model="stockchange.payed_from" class="py-2 px-2 w-full outline-none border bg-white" >
                                    <option value="1" selected>Касса</option>
                                    <option value='2'>Депозит</option>
                                    <option value='3'>Другое</option>
                                </select>
                            </div>
                            <div class="w-4/12 flex flex-col items-end">
                                <button @click="storeItem()" class="py-2 px-6 outline-none rounded-md bg-green-100 hover:bg-green-300">Сохранить</button>
                            </div>
                        </div>
                    </div>
                </section>
           </section>
       </div>
    </div>
</template>
<script>
import axios from 'axios';
import { api_route } from '../../config/api_routes';

export default {
    data: () => {
        return {
            stockchange: {
                type: 'refill',
                amount: 0,
                amount_multiplier: 1,
                price: 0,
                ingredient_id: 0,
                supplier_id: '',
                payed_amount: 'все',
                payed_from: 1,
                date: 'сегодня',
            },
            ingredients: [],
            // showEditItemDialog: false,
        }
    },
    methods: {
        getIngredients(){
            axios
                .get(api_route('ingredients_all'))
                .then((res)=>{
                    this.ingredients = res.data
                })
                .catch((error)=>{})
        },
        storeItem(){
            axios
                .post(api_route('ingredient_stock_change_store'), this.stockchange)
                .then((res)=>{
                    if(res.data?.code == 200){
                        this.$router.push({ name: 'WarehouseIngredientsEdit', params: { id: res.data?.item_id } })
                    }
                })
                .catch((error)=>console.log(error))
        },
    },
    mounted(){
        this.getIngredients()
    },
    watch: {
        // $route(to, from) {
        //     console.log(this.$route)
        //     console.log(this.$route.query)
        //     console.log(this.$route.hash)
        // }
    },
    components: {
    }
}
</script>
<style lang="">
    
</style>