<template lang="">
     <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="p-4">
                    <h1 class="text-xl lg:text-2xl text-gray-800 font-semibold pt-2 min-h-4">{{ item.name || '...' }}</h1>
                </div>
                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Основная информация</h3>
                        <div class="border rounded-md">
                            <div class="flex flex-row p-4">
                                <div class="w-8/12 flex flex-col">
                                    <label class="px-2">Наименование</label>
                                    <text-input v-model="item.name" @change="updateItemInfo('title', item.name)" type="text" placeholder="Наименование" ref="name" validate="min:4|max:255"/>
                                </div>
                                <div class="w-4/12 flex flex-col">
                                    <label class="px-2">Цена</label>
                                    <text-input v-model="item.price" @change="updateItemInfo('price', item.price)" type="number" placeholder="Цена" ref="price" validate="number|max:99999" silent/>
                                </div>
                            </div>
                            <div class="flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Описание</label>
                                    <textarea v-model="item.description" @change="updateItemInfo('description', item.description)" class="p-2 border outline-none h-36" placeholder="Описание"></textarea>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Дополнительная информация</h3>
                        <div class="border rounded-md">
                            <div class="w-full flex flex-col p-4">
                                <label class="px-2">Слаг - уникальное название латиницей</label>
                                <text-input v-model="item.slug" @change="updateItemInfo('slug', item.slug)" type="text" placeholder="Слаг" ref="slug" validate="min:4|max:255"/>
                            </div>
                            <div class="w-full flex flex-row p-4">
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Активно</label>
                                    <toggle-button v-model="item.is_active" :values="['1:Да','0:Нет']" @change="updateItemInfo('is_active', item.is_active)"/>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Видимо</label>
                                    <toggle-button v-model="item.is_seen" :values="['1:Да','0:Нет']" @change="updateItemInfo('is_seen', item.is_seen)"/>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="px-2">Продвигаем</label>
                                    <toggle-button v-model="item.is_recomended" :values="['1:Да','0:Нет']" @change="updateItemInfo('is_recomended', item.is_recomended)"/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="w-full flex flex-col lg:flex-row lg:justify-between">
                    <section class="w-full flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Фотографии</h3>
                        <div class="border rounded-md">
                            <div class="w-full flex flex-row items-center">
                                <div v-if="item.images?.length > 0" class="flex flex-row p-4 w-full overflow-x-auto">
                                    <!-- <div class="py-2 px-4 text-center">
                                        <input />
                                    </div> -->
                                    <div v-for="image, index in item.images" class="py-2 px-4 text-center relative hover:bg-gray-100 rounded-lg" :key="index">
                                        <img :src="image.url" style="height: 256px; width: 256px; max-width: 256px"/>
                                        <select v-model="item.images[index].type" @change="updateImage(image, 'type', item.images[index].type)">
                                            <option value="">неизвестно</option>
                                            <option value="big">оригинал</option>
                                            <option value="medium">средний</option>
                                            <option value="small">предпросмотр</option>
                                        </select>
                                        <div @click="detachImage(image)" class="w-6 absolute top-2 right-2  rounded-full text-white bg-gray-300 text-center cursor-pointer hover:bg-red-500">x</div>
                                    </div>
                                </div>
                                <div v-else class=" p-4">
                                    <i>Нет привязанных фотографий</i>
                                </div>
                            </div>
                            <div class="w-full p-4 flex justify-end">
                                <default-button @click="openImagesDialog()">Добавить фотографии</default-button>
                            </div>
                        </div>
                    </section>

                    <section class="w-full lg:ml-2 flex flex-col py-2">
                        <h3 class="text-md font-semibold pb-4 pl-4">Состав</h3>
                        <div class="border rounded-md">
                            <div class="w-full flex flex-row p-2 items-center">
                                <div v-if="item.meals?.length > 0" class="flex flex-col w-full">
                                    <div v-for="child, index in item.meals" class="w-full hover:bg-indigo-50 p-2 rounded-md flex justify-between items-center border-b" :key="index">
                                        <router-link :to="{name: 'ItemsEdit', params: { id: child.id }}"  class="py-2 px-4 rounded-md cursor-pointer hover:bg-yellow-300 text-sm">{{ child.name }} x{{ child.pivot.amount }}шт</router-link>
                                        <default-button @confirm="detachMealFromCombo(item.id, child.id)" type="cancel" pressconfirm>убрать</default-button>
                                    </div>
                                    <!-- {{ item.children }} -->
                                </div>
                                <div v-else class="p-4 border-b w-full">
                                    <i>В составе нет блюд</i>
                                </div>
                            </div>
                            <div class="w-full p-4 flex flex-wrap justify-between items-center">
                                <div class="w-full lg:w-2/4">
                                    <label>Добавить в состав:</label>
                                    <select v-model="child_meal_id" class="w-full py-2 px-4 border-b outline-none">
                                        <option v-for="meal, index in meals" :value="meal.id" :key="index">{{ meal.name }}</option>
                                    </select>
                                </div>
                                <div class="w-full lg:w-2/4 flex items-end justify-between">
                                    <div class="w-2/4">
                                        <label>Количество:</label>
                                        <input v-model="child_meal_amount" type="number"  class="w-11/12 py-2 px-4 border-b text-right outline-none"/>
                                    </div>
                                    <div class="pb-1">
                                        <default-button @click="addMealToCombo()">добавить</default-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section v-if="can('delete_combos')" class="flex flex-col pb-6 px-4">
                    <div class="w-full flex flex-col items-end">
                        <default-button @confirm="deleteItem()" pressconfirm type="delete">Удалить комбо</default-button>
                    </div>
                </section>
            </section>
        </div>
    </div>
    <my-dialog v-model:show="isOpenImagesDialog">
        <template #header>Фотографии на сервере</template>
        <template #default>
            <div class="border rounded-md">
                <div v-if="server_images.length > 0" class=" flex flex-row flex-wrap">
                    <div v-for="image, index in server_images" class="w-6/12 lg:w-3/12 flex flex-col p-2 hover:bg-yellow-300 cursor-pointer rounded-md" @click="attachImage(`${images_server}/${image.dir}/${image.file}`)" :key="index">
                        <img :src="`${images_server}/${image.dir}/${image.file}`" style="width: 100%"/>
                        <div class="hidden lg:flex text-center text-xs">{{ image.file }}</div>
                        <div class="text-center text-xs">{{ image.meta[0] }}x{{ image.meta[1] }}</div>
                    </div>
                </div>
                <div v-else class="p-6">
                    <i>На сервере еще нет фотографий в этой категории. Загрузите через форму загрузки.</i>
                </div>
            </div>
            <div class="w-full px-4 flex border-t justify-center items-center">
                <label class="p-2">Загрузить фотографию</label>
                <input @change="uploadImage()" class="p-2 border outline-none rounded-lg" type="file" ref="file" />
                <div @click="submitUploadedFile()" class="p-2 flex rounded-lg text-white bg-gray-300 text-center cursor-pointer hover:bg-green-500">загрузить</div>
            </div>
        </template>
    </my-dialog>
    <confirm-alert ref="confirmAlert"></confirm-alert>
</template>
<script>
import axios from 'axios'

export default {
    data: () => {
        return {
            item: {
                id: 0
            },
            child_meal_id: null,
            child_meal_amount: 1,
            meals: [],
            images_server: 'https://static.omelette.kz/',
            server_images: [],
            isOpenImagesDialog: false
        }
    },
    methods: {
        // confirmAlert(title,  description = ""){
        //     this.isConfirmAlertOpen = true
        //     this.$refs.confirmAlert.title = title
        //     this.$refs.confirmAlert.description = description
        // },
        addMealToCombo () {
            const combo_id = this.item.id
            const meal_id = this.child_meal_id

            this.postServerData('combo_attach_meal', { data: { combo_id, meal_id, amount: this.child_meal_amount } })
                .then((res) => {
                    this.getItemById()
                })
                .catch((error) => console.log(error))
        },
        async detachMealFromCombo (combo_id, meal_id) {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Убрать блюдо?',
                description: '',
                okButton: 'Убрать'
            })
            if (confirmdel) {
                this.postServerData('combo_detach_meal', { data: { combo_id, meal_id } })
                    .then((res) => this.getItemById())
                    .catch((error) => console.log(error))
            }
        },
        getItemById () {
           this.getServerData('combo_by_id', { id: this.item.id })
                .then((res) => {
                    this.item = res.data
                })
                .catch((error) => console.log(error))
        },
        updateItemInfo (info_index, new_value) {
            this.postServerData('combo_update_info', { data: { item_id: this.item.id, info_index, new_value } })
                .then((res) => {

                })
                .catch((error) => console.log(error))
        },
        openImagesDialog () {
            this.isOpenImagesDialog = true
        },
        attachImage (image_url) {
            if (image_url !== '') {
                this.postServerData('combo_attach_image', { data: { item_id: this.item.id, image_url, image_type: '' } })
                    .then((res) => {
                        this.getItemById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        updateImage (image, info_index, new_value) {
            if (image) {
                this.postServerData('image_update', { data: { id: image.id, info_index, new_value } })
                    .then((res) => {
                        this.getItemById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        detachImage (image) {
            if (image) {
               this.postServerData('combo_detach_image', { data: { item_id: this.item.id, image_id: image.id } })
                    .then((res) => {
                        this.getItemById()
                    })
                    .catch((error) => console.log(error))
            }
        },
        uploadImage () {
            this.Images = this.$refs.file.files[0]
        },
        submitUploadedFile () {
            const formData = new FormData()
            formData.append('upload_file', 'upload_file')
            formData.append('image_type', 'meals')
            formData.append('sub_type', 'combo')
            formData.append('generate_name', 'false')
            formData.append('generate_thumb', 'true')
            formData.append('userfile', this.Images)
            const headers = { 'Content-Type': 'multipart/form-data' }
            axios
                .post(this.images_server + '_php/file_upload_handler.php', formData, { headers })
                .then((res) => {
                    // res.data.files // binary representation of the file
                    // res.status // HTTP status
                    // if (res.data.code === 200) { }
                    this.getImages()
                })
        },
        getImages () {
            axios
                // .get('get_images', ['meals', 'combo'])
                .get('https://static.omelette.kz/?get_images&it=meals&st=combo')
                .then((res) => {
                    this.server_images = res.data
                })
                .catch((errors) => console.log(errors))
        },
        getMeals () {
            this.getServerData('meals_all')
                .then((res) => {
                    this.meals = res.data.data
                })
                .catch((errors) => console.log(errors))
        },
        async deleteItem () {
            const confirmdel = await this.$refs.confirmAlert.show({
                title: 'Удалить комбо?',
                description: 'Удаление произойдет навсегда',
                okButton: 'Удалить'
            })
            if (confirmdel) {
                this.postServerData('combo_delete', { data: { item_id: this.item.id } })
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.$router.push({ name: 'Combos' })
                        }
                    })
                    .catch((error) => console.log(error))
            }
        }
    },
    mounted () {
        if (this.$route.params.id) {
            this.item.id = this.$route.params.id
        }
        this.getItemById()

        this.getMeals()
        this.getImages()
    }
}
</script>
<style lang="">

</style>
