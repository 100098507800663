import MyDialog from "./MyDialog";
import MySelect from "./MySelect";
import MyHider from "./MyHider";
import MyNumericInput from "./MyNumericInput";
import MyPhoneInput from "./MyPhoneInput";
import MyConfirmAlert from "./MyConfirmAlert";
import DatePickerAlert from "./DatePickerAlert";

import CollectionLabelData from "./Dash/CollectionLabelData";
import CollectionLabelDataScrollable from "./Dash/CollectionLabelDataScrollable";
import DefaultButton from "./Button/DefaultButton";
import ToggleButton from "./Button/ToggleButton";
import TextInput from "./Input/TextInput";
import StatisticsBlockPlaceholder from "./StatisticsBlockPlaceholder";

export default [
    MyDialog,
    MySelect,
    MyNumericInput,
    MyPhoneInput,
    MyHider,
    MyConfirmAlert,

    DatePickerAlert,
    DefaultButton,
    CollectionLabelData,
    CollectionLabelDataScrollable,
    ToggleButton,
    TextInput,
    StatisticsBlockPlaceholder,
]