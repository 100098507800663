<template>
    <div class="flex flex-wrap w-full h-full justify-center items-center">
        <div class="flex flex-wrap flex-col max-w-xl m-auto rounded-md w-10/12 md:w-3/12 xl:w-3/12 2xl:w-3/12">
            <div class="flex justify-center text-3xl py-8">
                <h1><span class="font-semibold">Breaktime</span> CRM</h1>
            </div>
            <div class="flex flex-wrap flex-col rounded-md w-full border shadow-lg bg-white py-2 px-4 "
                :class="[authError != null ? 'shake' : '']">
                <div class="pt-4 pb-4 text-gray-800 text-center"><h1>Авторизация в системе</h1></div>
                <div class="py-2">
                    <div class="p-2">
                        <text-input
                            v-model="user.email"
                            iconUrl="https://img.icons8.com/ios-glyphs/256/user.png"
                            placeholder="Email"
                            type="email"
                            validate="email"
                            required
                            ref="emailInput"
                            />
                    </div>
                    <div class="p-2">
                        <text-input
                            v-model="user.password"
                            iconUrl="https://img.icons8.com/ios-filled/256/lock-2.png"
                            placeholder="Пароль"
                            type="password"
                            validate="min:4|max:25"
                            ref="passwordInput"
                            />
                    </div>
                </div>
                <div class="p-4 w-full flex-col justify-center items-center mt-2 select-none">
                    <div v-if="!isLogginIn" class="w-full flex flex-col justify-center items-center">
                        <button @click.prevent="loginUser" type="submit" class="w-4/5 text-center text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-700 active:bg-lime-900 rounded-full">Войти</button>
                    </div>
                    <div v-else class="h-10 pb-2 flex justify-center items-center">
                        <img src="https://static.breaktime.kz/uploads/images/breaktime/loading_black_circle.gif" />
                    </div>
                    <div class="w-full py-4 mt-8 border-t">
                        <div @click="demoUserLogin" class="text-md text-center cursor-pointer hover:font-semibold">Демо-кабинет</div>
                    </div>
                    <div class="w-full py-4 mt-0 border-t">
                        <router-link :to="{ name: 'Register' }">
                            <div class="text-md text-center cursor-pointer hover:font-semibold hover:bg-lime-100 py-4 rounded-md">Регистрация</div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="flex justify-center pb-32 px-4">
                <div class="p-4 w-full text-rose-500 text-center bg-red-100 rounded-br-md rounded-bl-md" v-if="authError">
                    {{ authError }}
                </div>
                <div v-else class="p-4">&nbsp;</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            user: {
                email: '',
                password: ''
            },
            isLogginIn: false,
            authError: null
        }
    },
    computed: {
        isEmailValid () {
            return this.$refs.emailInput.isValid
            // if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.user.email)) return true
            // else return false
        },
        isPasswordValid () {
            return this.$refs.passwordInput.isValid
            // if (this.user.password.length > 0) return true
            // else return false
        }
    },
    methods: {
        loginUser () {
            this.authError = null
            this.isLogginIn = true
            if (!this.isEmailValid) {
                this.$refs.emailInput.$refs.input.focus()
                this.isLogginIn = false
                this.authError = this.$refs.emailInput.validationErrors
            } else
            if (!this.isPasswordValid) {
                this.$refs.passwordInput.$refs.input.focus()
                this.isLogginIn = false
                this.authError = this.$refs.passwordInput.validationErrors
            } else
            if (this.isEmailValid && this.isPasswordValid) {
                this.$store
                    .dispatch('loginUser', this.user)
                    .then()
                    .catch((error) => { this.authError = error })
                    .finally(() => setTimeout(() => { this.isLogginIn = false }, 300))
            }
        },
        demoUserLogin () {
            this.user = {
                email: 'email@email.com',
                password: 'asd123'
            }
            this.loginUser()
        }
    }
}
</script>
<style>
    html, body, #app {
        height: 100% !important;
    }
    .main_wrapper{
        background-image: url("https://breaktime.kz/_css/images/body_bg_1_1.png");
        background-size: cover;
        background-position: center;
        background-color: #ff7d7d;
        /* filter: blur(8px);
        -webkit-filter: blur(8px); */
    }
    .shake {
        /* animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both; */
        animation: shake 0.62s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
    }

    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }
</style>
