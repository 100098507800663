<template>
    <div class="w-full">
        <div class="w-full py-4 px-2 rounded-md flex flex-col" :class="[stat.bg ? stat.bg : 'bg-indigo-100' ]">
            <div class="pt-2 px-2 text-lg font-semibold">{{ stat.title }}</div>
            <div class="pt-2 px-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
            <div class="pb-2 px-2 flex w-full flex flex-col h-112 overflow-y-auto data-wrapper">
                <div v-for="data, index in stat.data" class="w-full">
                    <div class="py-2 flex justify-start items-center border-t border-gray-500">
                        <div class="w-1/12 font-semibold">#{{ index + 1 }}</div>
                        <div class="w-7/12 ">{{ data.label }}</div>
                        <div class="w-4/12 text-right">{{ data.amount }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'collection-label-data-scrollable',
    data: () => {
        return {
            statsSeen: false, 
        }
    },
    props: {
        stat: {
            type: Object,
            required: true
        }
    },
}
</script>
<style scoped>
    .masked
    {
        background-image: url('https://img.icons8.com/ios-glyphs/256/visible.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .unmasked
    {
        background-image: url('https://img.icons8.com/windows/256/hide.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .h-104 {
        height: 26rem;
    }
    .h-112 {
        height: 28rem
    }
    .h-120 {
        height: 30rem
    }
    .max-h-104 {
        max-height: 26rem;
    }
    .max-h-112 {
        max-height: 28rem
    }
    .max-h-120 {
        max-height: 30rem
    }
    /* width */
    ::-webkit-scrollbar {
        transition: all .3s;
        width: .25rem;
        opacity: .5;
    }
    .data-wrapper:hover::-webkit-scrollbar {
        opacity: 1;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        /* box-shadow: inset 0 0 5px grey;  */
        /* border-radius: 0; */
        background: #eee; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #aaa; 
        border-radius: 0.25rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #a2a2a2; 
    }
</style>