<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="w-full lg:container lg:mx-auto bg-white">
          <section>
               <div class="pt-2 pb-1 border-b-4 border-yellow-300">
                   <div class="text-gray-800 font-semibold flex items-center justify-start">
                        <router-link
                            to='/warehouse'>
                            <div class="text-lg lg:text-2xl pt-2 pb-2 px-4">Склад</div>
                        </router-link>
                        <div class="w-full overflow-x-auto flex items-center">
                            <!-- can('refill_ingredients') -->

                            <!-- <div v-if="can('refill_ingredients')">
                                <router-link :to="{name: 'IngredientsRefill' }" class="py-2 px-6 rounded-md bg-gray-100 cursor-pointer hover:bg-green-300 text-sm"> Пополнения </router-link>
                            </div> -->
                            <!-- <router-link
                                    :to="{ name: 'IngredientsPriority' }"
                                    class="flex px-6 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8"
                                    active-class="bg-yellow-300">
                                    Закуп
                            </router-link> -->
                            <router-link
                                    :to="{ name: 'StockChanges' }"
                                    class="flex px-6 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8"
                                    active-class="bg-yellow-300">
                                    Изменения
                            </router-link>
                            <router-link
                                    :to="{ name: 'Ingredients' }"
                                    class="flex px-6 py-2 hover:bg-yellow-200 cursor-pointer rounded-md text-base bg-gray-100 ml-2 lg:ml-8"
                                    active-class="bg-yellow-300">
                                    Ингредиенты
                            </router-link>
                        </div>
                   </div>
               </div>
          </section>
       </div>
       <router-view></router-view>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
        }
    },
    methods: {
    },
    watch: {
    },
    mounted () {
    },
    components: {
    }
}
</script>
<style scoped>
</style>
