<template lang="">
    <div class="flex flex-wrap w-full justify-center items-center">
       <div class="container mx-auto bg-white">
           <section>
                <div class="flex flex-row justify-between items-end pt-6  pb-4">
                    <div class="w-2/3 flex flex-col justify-around pl-2 ">
                        <h1 class="text-2xl text-gray-800 font-semibold pt-2 pb-2">Список сотрудников</h1>
                        <span class="text-gray-500 text-sm">Показаны {{ meta.to}} из {{ meta.total}}</span>
                    </div>
                    <div class="w-1/3 flex flex-row justify-end">
                        <div v-if="can('create_staff') || can('debug')">
                            <router-link :to="{name: 'StaffCreate' }" class="py-2 px-6 rounded-md bg-gray-100 cursor-pointer hover:bg-yellow-300"> Создать </router-link>
                        </div>                        
                    </div>
                </div>
                <div class="w-full rounded-lg border-2 border-gray-100">
                    <div class="items_list">
                        <div class="w-full flex flex-row bg-gray-100">
                            <div class="w-1/12 p-2"></div>
                            <div class="w-8/12 p-2" >ФИО</div>
                            <div class="w-1/12 p-2" >Статус</div>
                            <div class="w-2/12 p-2" >Последний визит</div>
                        </div>
                        <div v-for="item, index in staff" :key="item.id">
                            <div class="w-full flex flex-row text-sm hover:bg-gray-100" style="border-top: 1px dashed">
                                <div class="w-full flex flex-row hover:bg-gray-100 items-center py-4">
                                    <div class="w-1/12 flex justify-center">
                                        <img :src="item.avatar_url?.url" style="height: 40px; border-radius: 50%"/>
                                    </div>
                                    <div class="w-8/12 p-2 text-lg flex flex-col">
                                        <div v-if="can('edit_staff')">
                                            <router-link :to="{name: 'StaffEdit', params: { id: item.id  }}">{{ item.first_name }}</router-link>
                                        </div>
                                        <div v-else>{{ item.first_name }}</div>
                                        <div class=" flex flex-row text-sm text-gray-300">
                                            <!-- | {{ item.name_short }}  
                                            | {{ item.description }}  
                                            | {{ item.emoji }}  
                                            | {{ item.slug }}  -->
                                        </div>
                                    </div>
                                    <div class="w-1/12 px-2 text-sm">{{ item.status == 1 ? 'Работает' : 'Не работает' }}</div>
                                    <div class="w-2/12 px-2 text-sm">{{ item.last_visit_at }}</div>
                                    <!-- <div class="w-1/12 px-2">{{ item.meals.length }}</div>
                                        <div class="w-1/12 px-2">{{ item.weight }}</div>
                                    <div class="w-1/12 px-2 text-sm">{{ item.is_seen ? 'Видимо' : 'Скрыто' }}</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>
           <div class="py-4"></div>
       </div>
    </div>
</template>
<script>
import axios from 'axios';
import check_permissions from '../../mixins/CheckPermissions';
import {api_route} from '../../config/api_routes';

export default {
    data: () => {
        return {
            meta: {
                current_page: 0,
                per_page: 20,
                last_page: 0,
                links: [],
                total: 0,
                from: 0,
                to: 0,
            },
            sort_by: 'name',
            sort_dir: 'ASC',
            name_like: '',
            selected_tag: '',
            items: [],

            staff: [],
            // showEditItemDialog: false,
        }
    },
    mixins: [check_permissions],
    methods: {
        getStaff(){
            axios
                .get(api_route('staff_all'))
                .then((res)=>{
                    this.meta.total = res.data.length
                    this.meta.from = 1
                    this.meta.to = res.data.length
                    this.staff = res.data
                })
                .catch((error)=>{})
        },
    },
    mounted(){
        this.getStaff()
    },
    watch: {
        // $route(to, from) {
        //     console.log(this.$route)
        //     console.log(this.$route.query)
        //     console.log(this.$route.hash)
        // }
    },
    components: {
    }
}
</script>
<style lang="">
    
</style>