<template>
    <div class="w-full">
        <div class="py-4 px-4 rounded-md flex flex-col" :class="[stat.meta.max != 0 ? stat.bg ? stat.bg : 'bg-indigo-100' : 'bg-gray-100' ]">
            <div class="pt-2 text-lg font-semibold flex justify-between items-center">
                <div @click="replay">
                    {{ stat.title }}
                </div>
                <div class="cursor-pointer" @click="statsSeen = !statsSeen">
                    <div :class="[statsSeen ? 'masked' : 'unmasked']" class="h-4 w-4 transition-all"></div>
                </div>
            </div>
            <div class="pt-2 pb-4 text-sm text-gray-700">{{ stat.description }}</div>
            <div v-if="stat.meta.max != 0" class="pb-2 flex w-full overflow-x-auto">
                <div v-for="data, index in stat.data" class="w-full" :key="index">
                    <PlotSingleLayerBlock :percent="data.point/stat.meta.max" :points_color="stat.points_color" :index="index"/>
                    <!-- <div class="h-64 flex flex-col items-center justify-end">
                        <div :style="{'height': ()*100 + '%'}" class="bg-yellow-300 w-11/12 hover:bg-yellow-100 rounded-md transition-all"></div>
                    </div> -->
                    <div class="py-2 flex flex-col justify-center items-center text-xs md:text-md lg:text-sm">
                        <div v-if="statsSeen" class="transition-all">
                            <div v-if="data.link" class="font-semibold">
                                <router-link :to="data.link">{{ data.label}}</router-link>
                            </div>
                            <div v-else class="font-semibold">{{ data.label }}</div>
                        </div>
                        <div v-else class="font-semibold"> --- </div>
                        <div>{{ data.description }}</div>
                    </div>
                </div>
            </div>
            <div v-else class="py-2">
                <div class="h-48 pt-8 flex justify-center items-center">
                    <i class="text-gray-700">Не достаточно информации</i>
                </div>
                <div class="w-full py-8"></div>
            </div>
        </div>
    </div>
</template>
<script>
import PlotSingleLayerBlock from './PlotSingleLayerBlock'

export default {
    name: 'simple-linear-graph',
    data () {
        return {
            statsSeen: false
        }
    },
    props: {
        stat: {
            type: Object
            // default:,
        }

    },
    methods: {
        incNumber () {
        }
    },
    mounted () {
        // this.incTimeout = setInterval(this.incNumber, 10)
    },
    components: {
        PlotSingleLayerBlock
    }
}
</script>
<style scoped>
    .masked
    {
        background-image: url('https://img.icons8.com/ios-glyphs/256/visible.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .unmasked
    {
        background-image: url('https://img.icons8.com/windows/256/hide.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

</style>
